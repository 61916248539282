.component-CardCategory {
    .uk-card {
        border-radius: 12px;
        border: none;
        box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
        padding: 3px 16px;
        background-color: $white;
        text-decoration: none;
        &:hover {
            .image {
                [class*='img-'] {
                    opacity: 1;
                }
            }
        }
    }
    .image {
        margin-right: 8px;
        width: 100%;
        max-width: 120px;
        height: 112px;
        position: relative;
        .primary-img {
            max-width: 120px;
            max-height: 112px;
        }
        [class*='img-'] {
            position: absolute;
            opacity: 0;
            transition: all .3s ease-in-out;
        }
        .img-sx {
            left: 3px;
            top: 11px;
        }
        .img-centre {
            top: 2px;
            left: 50%;
        }
        .img-dx {
            right: 10px;
            top: 3px;
            max-width: 27px;
        }
    }
    .text {
        @include color-primary-black();
        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
    }

    @media (min-width: $breakpoint-xlarge) {
        .uk-card {
            flex-direction: column;
            text-align: center;
            padding: 16px 16px;
            min-height: 200px;
            &:hover {
                .image {
                    .img-sx {
                        left: 0;
                        max-width: 27px;
                    }
                    .img-centre {
                        top: -3px;
                        left: 45%;
                        opacity: 1;
                    }
                    .img-dx {
                        right: -4px;
                    }
                }
            }
        }
        .image {
            margin-right: 0;
            margin-bottom: 8px;
            .img-sx {
                left: -9px;
                top: 11px;
            }
            .img-centre {
                top: 3px;
                left: 50%;
            }
            .img-dx {
                right: -20px;
                top: -5px;
                max-width: inherit;
            }
        }
    }
}

.device_mobile {
    .component-CardCategory {
        .uk-card {
            [class*='img-'] {
                opacity: 1;
            }
            &:hover {
                [class*='img-'] {
                    opacity: 1;
                }
            }
        }
    }
}