.component-ModalVerifyEmail {
    .position-top-right {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 14px;
    }

    .subtitle a{
        font-weight: 500;
    }

    .input-padding {
        margin: 10px 0 20px;

        label {
            pointer-events: none;
        }
    }

    .link-padding {
        @media (min-width: $breakpoint-large-2) {
            padding-right: 15px;
        }
    }
    .uk-button-link {
        font-weight: 500;
        text-decoration: underline;
    }
}