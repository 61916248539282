.page-CheckoutNew {
    background-color: $cultured;

    //header simply
    .component-header {

        .bar-header {

            .btn-menu,
            .uk-navbar-right {
                display: none;
            }

            @media screen and (max-width: $breakpoint-large-2-max){
                .uk-navbar-center{
                    top: 0; 
                    transform: translate(-50%, 0);
                }

                .logo-mobile{
                    min-height: 32px;
                }

                .steps-checkout{
                    padding-top: 13px;
                    padding-bottom: 25px;
                    line-height: 1rem;
                }
            }
            
        }
    }

    &.skip-preauth{
        .steps-checkout{
            display: none !important;
        }
    }

    .margin-content {
        @media (max-width: $breakpoint-large-2-max) {
            margin-left: 32px;
            margin-right: 32px;
        }

        @media (max-width: $breakpoint-medium-max) {
            margin-left: 16px;
            margin-right: 16px;
        }
    }


    @media (min-width: $breakpoint-xlarge) {
        margin-bottom: 0;

        .main-content {
            padding-top: 46px !important;
            padding-bottom: 18px;
        }
    }

    .no-padding {
        padding: 0 !important;
    }

    .checkout-container {
        @media (max-width: $breakpoint-large-2-max) {
            padding: 0;
        }

        .main-content-body {
            .uk-card {
                &-body {
                    padding: 16px 16px 0 16px;

                    @media (max-width: $breakpoint-large-2-max) {
                        border: none !important;
                        box-shadow: 0 0 10px 0 rgba(204, 204, 204, 0.25);
                    }

                    &.internal {
                        margin-bottom: 16px;
                        border: 1px solid rgba($black, 0.3) !important;
                        border-radius: 12px;

                        &.ritiro {
                            border-radius: 0;
                            box-shadow: none !important;
                            border: none !important;
                            padding: 0 !important;

                            .store {
                                .icona-modifica {
                                    flex-flow: nowrap;
                                    margin-bottom: 16px;

                                    .icona {
                                        display: flex;
                                        justify-content: left;
                                        position: relative;
                                        top: 4px;
                                    }

                                    .edit {
                                        font-weight: 500;
                                    }
                                }
                                @media (max-width: $breakpoint-large-2-max) {
                                    margin-bottom: 16px;
                                }
                            }
                        }
                    }

                    &:not(.internal) {
                        border-radius: 0;

                        @media (min-width: $breakpoint-xlarge) {
                            border-radius: 12px;
                        }
                    }

                    &.ritiro {

                        @media (max-width: $breakpoint-large-2-max) {

                        }
                    }
                }
            }
        }
    }

    .extended {
        margin: 0 -16px 0 -16px;
        padding: 16px;
        border-top: 2px solid #e5e5e5 !important;
    }

    .user-blacklist-alert {
        display: none;

        a {
            color: $danger;
        }
    }

    .main-content {
        min-height: 200px;
        padding-top: 46px;

        &.hard-blacklist-user {
            display: block !important;

            //User Blacklist
            .main-content-body {
                position: relative;
                opacity: 0.5;
                z-index: 9999999;
                pointer-events: none;

                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                }
            }

            .user-blacklist-alert {
                display: flex;
            }
        }

        &.soft-blacklist-user {
            .sezione-paga-in-negozio {
                display: none;
            }
        }
    }

    //### INIT COUPON
    .section-codice-sconto {
        .uk-card {
            &-type-coupon {
                margin-bottom: 16px;

                .uk-card-body {
                    border-radius: 12px !important;

                    @media (max-width: $breakpoint-large-2-max) {
                        border-left: 1px solid var(---effects-01-opacity-20-2);
                        border-right: 1px solid var(---effects-01-opacity-20-2);
                    }
                }


                .coupon-title {
                    text-decoration: none;
                    cursor: pointer;

                    &:before {
                        font-size: 24px;
                        font-weight: 400;
                        position: relative;
                        top: -2px;
                    }

                    h3 {
                        border-bottom: none;
                        font-size: 14px;
                        font-weight: bold;
                        margin: 0;
                        padding: 0;
                    }

                    [class*='icon-'] {
                        font-size: 24px;
                        position: relative;
                        top: 5px;
                    }

                    .icon-coupon {
                        display: inline-block;
                        margin-right: 8px;
                        font-size: 20px;

                        &:before {
                            position: relative;
                            top: -1px;
                            font-weight: bold;

                            @media (min-width: $breakpoint-xlarge) {
                                top: -2px;
                            }
                        }
                    }

                    @media (min-width: $breakpoint-xlarge) {
                        pointer-events: none;
                        cursor: default;

                        h3 {
                            font-size: 18px;
                            margin-bottom: 16px;
                        }
                    }
                }

                .coupon-title:before {
                    top: 3px;
                }

                .coupon-content {
                    margin-top: 12px;

                    @media (min-width: $breakpoint-xlarge) {
                        margin-top: 16px;
                    }
                }

                .uk-card-body {
                    border-radius: 12px;
                    padding: 16px !important;

                    @media (min-width: $breakpoint-xlarge) {
                        margin: 0;
                    }

                    @media (max-width: $breakpoint-large-2-max) {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .box-coupon {
                    border-radius: 16px;
                    margin: 0;
                    height: 56px;
                    position: relative;
                    width: 100%;

                    .uk-label-float {
                        margin: 0;
                    }

                    .coupon-apply-button {
                        position: absolute;
                        right: 4px;
                        top: 4px;
                        height: 48px;
                        width: 100px;
                        line-height: 40px;
                        padding: 0;
                        background: $white;
                    }

                }
            }
        }

        .success-feedback {
            display: none;
            margin-top: 8px;
            font-size: 14px;
        }

        #coupon-warning-message-promo,
        #coupon-warning-message-coupons,
        .coupon-error-feedback,
        .error-feedback {
            display: none;
        }

        &.ko {
            &-conditions {
                .conditions-not-satisfied-error {
                    display: block;
                }
            }

            &-single-coupon {
                .single-coupon-error {
                    display: block;
                }
            }

            &-removed-promo {
                .removed-promo-warning {
                    display: block;
                }

                #coupon-warning-message-promo {
                  display: flex;
                }
            }
            
            &-removed-coupons {
                .removed-coupons-warning {
                    display: block;
                }

                #coupon-warning-message-coupons {
                  display: flex;
                }
            }

            &-conditions,
            &-single-coupon {
                .coupon-error-feedback {
                    display: flex;
                }
            }
        }

        .message-error {
            display: none;
            font-size: 14px;
            margin-left: 16px;
        }

        &.ko {
            &-other {
                .coupon-other-error {
                    display: block;
                }
            }

            &-expired {

                .coupon-expired-error {
                    display: block;
                }
            }

            &-other,
            &-expired {
                .uk-input {
                    border-color: var(---colour-03-semantic-02-error-2);
                }
            }
        }

        .coupon-applied-list {
            margin-bottom: -10px;
            margin-top: 8px;

            .uk-tag {

                .icon-check {
                    display: none;
                }

                margin-bottom: 10px;
                position: relative;
                padding-right: 35px;
                padding-left: 25px;
                text-align: center;

                a {
                    @include color-primary-black();
                    border-radius: 50%;
                    display: flex;
                    text-decoration: none;
                    background-color: rgba($black, 0.2);
                    position: absolute;
                    top: 5px;
                    font-size: 15px;
                    right: 5px;
                    padding: 4px;
                }
            }
        }

        &.success {

            .uk-tag:first-of-type {
                background-color: $alabaster;

                a {
                    background-color: #5eb223;
                }

                .icon-check {
                    display: block;
                    color: $white;
                }

                .icon-close {
                    display: none;
                }
            }
        }

        & .coupon-applied-list.coupon-applied ~ .success-feedback{
            display: block;
        }
    }

    //### END COUPON


    .primary-panel {
        h3 {
            border-bottom: 1px solid rgba($gray, 0.3);
            padding: 0 0 16px 16px;
            margin: 0 -16px 16px -16px;
            font-size: 18px;
            font-weight: bold;
        }
    }

    .icon-locked {
        font-size: 12px !important;
    }

    .tooltip-icon {
        font-size: 17px;
        position: relative;
        top: 3px;
        right: -2px;
    }

    .box-indirizzo {
        margin-top: 16px;

        .opzioni-modifica-indirizzo {

            .separator,
            .scegli-indirizzo {
                display: none;
            }

            .edit {
                font-weight: 500;
            }
        }
    }

    .box-pagamento {
        margin-top: 16px;

        .checkout-sales-conditions-message-error {
            font-size: 13px;
            color: var(---colour-03-semantic-02-error-2) !important;
        }
    }

    .box-data {
        margin-top: 16px;
        font-weight: 700;

        div.uk-flex {
            margin-bottom: 16px;
        }

        a {
            font-weight: 500;
        }
    }

    .btn-prosegui {
        font-size: 16px;
        line-height: 56px;
        width: calc(100% - 32px);

        &-pagamento {
            width: 100%;
            margin-top: 16px;

            @media (max-width: $breakpoint-large-2-max) {
                margin-bottom: 16px;
                line-height: 48px;
            }
        }
    }


    .box-coup-pag {

        @media (max-width: $breakpoint-large-2-max) {
            z-index: 2000;
        }
    }

    #box-dati {
        margin-bottom: 16px;
    }

    .titolo,
    .titolo-2 {
        background-color: white;
        padding: 16px;
        border-radius: 12px;
    }

    #toggleCoupon {
        padding: 0px;
    }

    .cifra {
        font-size: 24px;
        font-weight: 700;
        align-items: center;
    }

    .cifra-2 {
        position: relative;
        top: -3px;
    }

    .accettazione {
        padding: 16px;

        a {
            text-decoration: underline;
        }

        &-2 {
            padding: 0;
            margin-top: 14px;
            font-size: 12px;

            a {
                text-decoration: underline;
            }

            .label-text {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    .box-scon-pag {

        .box-pagamento {
            margin-top: 16px;

            .scontrino-content {
                @media (min-width: $breakpoint-xlarge) {
                    border-bottom: 1px solid rgba($gray, 0.3);
                }
            }

            .uk-card-body {
                padding: 0;

                ul {
                    margin: 0;
                }
            }


            .uk-accordion-title {
                background-color: $white;
                padding: 16px;
                border-radius: 12px 12px 0 0;
                text-decoration: none;

                &:before {
                    font-size: 25px;
                    position: relative;
                    top: -3px;
                }

                @media (min-width: $breakpoint-xlarge) {
                    padding: 33px 16px 16px 16px;
                    
                    &:before {
                        top: 0;
                        right: -2px;
                    }
                }

                @media (max-width: $breakpoint-large-2-max) {
                    background-color: $pin-yellow;
                    box-shadow: 0 -1px 8px 0 rgba($black, 0.13);
                }
            }

            .totale {
                font-size: 16px;
                font-weight: 700;
            }

            .iva {
                font-size: 10px;
                font-weight: 400;
                @include color-primary-black(0.6);

                @media (min-width: $breakpoint-xlarge) {
                    margin-top: 3px;
                }
            }

            .totale-cifra {
                padding: 0 16px 0 16px;

                @media (max-width: $breakpoint-large-2-max) {
                    margin-top: 10px;
                }
            }

            .btn-prosegui {
                width: -webkit-fill-available;
                margin: 16px;
            }

            .box-scontrino {
                border: none;
                box-shadow: none;
                margin: 0;
                padding: 0 16px 16px 16px;
                border-radius: 0;
                background-color: $white;

                .text,
                .value {
                    font-size: 12px;
                    font-weight: 500;

                    &.t-bold {
                        font-weight: 700;
                    }
                }

                @media (max-width: $breakpoint-large-2-max) {
                    background-color: #F8F6EF;
                }

                .costo-tipo-consegna {

                    .text,
                    .value {
                        font-size: 14px !important;
                    }

                    .icon-info {
                        position: relative;
                        top: 2px;
                    }
                }

                .totale-prodotti {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    text-decoration: underline;

                    @media (min-width: $breakpoint-xlarge) {
                        font-size: 16px;
                    }
                }

                .prezzo-totale-prodotti {
                    font-weight: 500;
                    font-size: 12px;
                    text-decoration: line-through;
                }

                .min-distance {
                    margin-right: 4px;
                }

                .prezzo-scontato {
                    font-weight: 700;
                    font-size: 14px;

                    @media (min-width: $breakpoint-large-2-max) {
                        color: var(---colour-01-primary-01-p-2);
                    }
                }

                .pre-authorization {
                    .text {
                        font-size: 14px;
                        font-weight: 400;
                    }
                }

                .scontrino-mobile {
                    display: none;

                    @media (max-width: $breakpoint-large-2-max) {
                        display: block;
                    }
                }

                .sconto-prodotto,
                .scontrino-desktop {
                    display: block;

                    @media (max-width: $breakpoint-large-2-max) {
                        display: none;
                    }
                }

                .sconto-prodotto {
                    @include color-primary-black(0.6);
                    margin-top: 4px;
                    line-height: 20px;
                }

                .new-hr-divider {
                    padding-top: 12px;
                    border-top: 1px solid $global-border;

                    &.dashed {
                        border-top: none;
                        background-image: linear-gradient(to right, $global-border 50%, rgba(255, 255, 255, 0) 0%);
                        background-position: top;
                        background-size: 15px 1px;
                        background-repeat: repeat-x;
                    }
                }

                .wrapper-border-first {

                    &>div:first-of-type {
                        padding-top: 12px;
                        border-top: 1px solid $global-border;
                    }

                    &.dashed>div:first-of-type {
                        padding-top: 12px;
                        border-top: none;
                        background-image: linear-gradient(to right, $global-border 50%, rgba(255, 255, 255, 0) 0%);
                        background-position: top;
                        background-size: 15px 1px;
                        background-repeat: repeat-x;
                    }
                }

                .line-price {
                    width: 100%;
                    font-size: 12px;
                    line-height: 16px;
                    padding-bottom: 13px;

                    &.riepilogo {
                        font-size: 14px;
                        line-height: 20px;

                        .value {
                            font-size: 14px;
                        }
                    }

                    &.extra {
                        font-size: 11px;
                        line-height: 16px;

                        .value {
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }
                }
            }

            .card-scontrino {
                &-bottom {
                    box-sizing: border-box;
                    margin-top: 3px;

                    .points-and-cost {
                        margin-bottom: 12px;

                        >* {
                            display: flex;
                            justify-content: space-between;

                            .text,
                            .value {
                                font-size: 12px;
                                justify-content: flex-start;
                            }

                            &.font-medium {
                                .text {
                                    font-size: 14px;
                                }

                                .value {
                                    font-size: 16px;
                                }
                            }
                        }

                        .pre-authorization {
                            .text {
                                font-size: 14px;
                            }

                            .info {
                                display: block;
                                font-size: 11px;
                                color: $blue-info;
                                text-decoration: underline;
                                font-weight: 500;
                                line-height: 16px;

                                @media (min-width: $breakpoint-xlarge) {
                                    margin-top: 4px;
                                }
                            }
                        }

                        .totale-ordine {
                            margin-bottom: 7px;

                            .text {
                                font-size: 16px;

                                h2 {
                                    font-size: 16px;
                                    margin: 0;
                                }

                                span {
                                    font-size: 10px;
                                    color: $base-body-color;
                                    margin-top: -4px;

                                    @media (min-width: $breakpoint-xlarge) {
                                        margin-top: -4px;
                                    }
                                }
                            }

                            .value {
                                @include color-primary-black();
                                font-size: 16px;
                            }
                        }

                        .voce-dettaglio-risparmio {
                            margin-bottom: 13px;
                            margin-left: 16px;
                            margin-top: 10px;
                            color: rgba(51, 51, 51, 0.7);
                        }
                    }

                    .risparmio {
                        .value {
                            font-size: 12px;
                            color: rgba(0, 0, 0, 0.8);
                        }
                    }

                    &-footer {
                        .info-punti-bollini {
                            @include color-primary-black();
                            margin-bottom: 13px;
                            margin-top: 5px;
                            font-size: 10px;
                        }

                        .info-sconto {
                            color: var(--02-effects-03-lighten-20);
                            margin-bottom: 15px;
                            font-size: 12px;
                        }

                        .carta-insieme {
                            font-size: 12px;

                            img {
                                height: 16px;
                                margin-right: 5px;
                            }
                        }

                        .hr-divider {
                            margin-top: 10px;
                            margin-bottom: 9px;
                        }

                        .uk-checkbox-custom {
                            font-size: 12px;
                            line-height: 2.2;

                            .label-text {
                                a {
                                    font-weight: 500;
                                }
                            }
                        }
                    }

                    .btn-prosegui {
                        font-size: 16px;
                        margin-top: 16px;
                        line-height: 56px;
                    }
                }
            }
        }

        @media (max-width: $breakpoint-large-2-max) {

            .box-pagamento {
                .uk-card-body {
                    border: none;
                }
            }

            .box-scontrino {
                max-height: calc(100vh - 315px);
                overflow: auto;
            }
        }
    }

    .section-dati-completi {
        font-size: 14px;
        font-weight: 400;

        .prima-linea {
            font-size: 16px;
            font-weight: 700;
        }

        .linea-dati-b {
            font-weight: 700;
        }

        .linea-dati {
            margin-bottom: 4px;
        }

        .icon-check {
            font-family: "icomoon";
            position: relative;
            top: 4px;
            left: 0;
            font-weight: 700;
            font-size: 18px;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            background-color: $primary-black;
            color: $white;
            border: 2px solid $primary-black;
        }
    }

    .form-container {
        .form-email {
            &.blocked {
                .uk-input {
                    background-color: $cultured;
                }
            }
        }

        .form-scala {
            width: 100%;
        }

        .form-citofono {
            width: 65%;
        }

        .form-piano {
            margin-left: 16px;
            width: 35%;

            select[data-valid=true] {
                height: 56px;
                padding-top: 13px;
            }
        }

        .form-nota.textarea-icon {
            margin-bottom: 16px !important;
        }

        .form-phone-alert {
            font-size: 14px;
            line-height: 1.43;
            display: block;
            margin-bottom: 16px;

            @media (min-width: $breakpoint-xlarge) {
                padding-left: 16px;
            }
        }

        textarea {
            min-height: 56px;
            min-width: 100%;
            line-height: normal;
            background-color: transparent;
            z-index: 2;
            position: relative;
            transition: all 0.2s;

            &:focus {
                border-color: $yellow;
                background-color: rgba($yellow, 0.2);
                min-height: 90px;
            }
            &:not(:placeholder-shown) {
                border-color: rgba($black, 0.8);
            }
        }

        .textarea-icon::after {
            z-index: 1;
            right: 2px;
            bottom: -6px;
        }

        @media (max-width: $breakpoint-small-max) {
            .uk-child-width-1-2 {
                flex-direction: column;

                >* {
                    width: 100%;
                    margin-left: 0 !important;
                }
            }
        }

        /* ------------------ switch slider --------------------------*/
        .uk-switch {
            top: 0;
            position: relative;
            display: inline-block;
            width: 40px;
            height: 24px;

            &-slider {
                box-shadow: 0 0 0 2px $primary-orange;
                background-color: $white;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                border-bottom-radius: 500px;
                bottom: 0;
                cursor: pointer;
                transition-property: background-color;
                transition-duration: 0.2s;

                &:before {
                    content: "";
                    background-color: $primary-orange;
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    left: 2px;
                    bottom: 2px;
                    border-radius: 50%;
                    transition-property: transform, box-shadow;
                    transition-duration: 0.2s;
                }

                &:after {
                    font-family: "icomoon" !important;
                    content: "\e92e";
                    color: $white;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 4px;
                    font-size: 16px !important;
                }
            }

            input:not(:checked)+.uk-switch-slider {
                box-shadow: 0 0 0 2px var(---effects-01-opacity-30-2);

                &:before {
                    background-color: var(---effects-01-opacity-30-2);
                }
            }

            input[disabled]+.uk-switch-slider {
                opacity: 0.3;
            }

            @media (max-width: $breakpoint-large-2-max) {
                input:checked + .uk-switch-slider:before {
                    transform: translateX(calc(40px - (calc(24px - 4px) * 2 - (calc(24px - 4px) - (4px)))));
                }
            }
        }

        /* ------------------ switch slider end --------------------------*/

        .uk-label-float {
            margin-bottom: 16px;

            .uk-form-icon-flip:not([disabled]) {
                background-color: transparent;
                @include color-primary-black();

                &:hover,
                &:active,
                &:focus {
                    background-color: $primary-orange;
                    //@include color-primary-black();
                    color: $white;
                }
            }
        }

        .uk-flex>[class*=form-]+[class*=form-] {
            margin-left: 16px;
        }

        .uk-checkbox-custom {
            .label-text {
                font-weight: bold;
            }
        }
    }

    .manage-preferite-address {
        border-top: 1px solid rgba($gray, 0.3);
        padding: 16px 0;
        display: flex;

        span.label {
            position: relative;
            display: inline-block;
            top: 0;
            left: 8px;
            padding-left: 8px;
        }
    }

    div.uk-input {
        height: auto;
        padding: 0 24px 0 16px;
    }

    .flex-left {
        display: flex;
        justify-content: left;
    }

    .flex-center {
        display: flex;
        justify-content: center;
    }

    .flex-right {
        display: flex;
        justify-content: right;
    }

    .separator {
        flex: none;
        width: 1px;
        background-color: rgba($gray, 0.3);
        margin: 8px 10px;
    }

    .uk-label-float {
        margin-bottom: 16px;
    }

    .negozio-spesa {
        margin: 0 -16px;

        .desc {
            padding: 14px 16px;
            width: 100%;
            background-color: $buttary-white;
            border-radius: 0 0 12px 12px;
        }
    }

    .uk-alert-button-action>p {
        padding-right: 30px;
    }

    .informativa-obbligatorio {
        margin-top: 8px;
        line-height: 1.43;
        font-size: 14px;
        font-weight: 500;

        @media (max-width: $breakpoint-large-2-max) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    .checkboxes {
        >div {
            margin-bottom: 16px;
        }
    }

    .hr-divide {
        margin: 16px -16px;
        border: 1px solid rgba($gray, 0.3);
    }

    .down-arrow {
        &.inversione {
            @media (max-width: $breakpoint-large-2-max) {
                &:before {
                    content: "\e92f" !important;
                }
            }
        }
    }

    .fattura {
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 700;

        &-text {
            display: flex;
            justify-content: left;
            position: relative;
            top: 1px;

            .icon-file-text {
                font-size: 20px;
                position: relative;
                top: 0;
            }

            .label {
                margin-left: 8px;
            }
        }
    }
    .envoice-detail-container {
        margin-bottom: 16px;
    }

    .uk-modal-body {
        padding: 24px 24px 16px 24px;

        p {
            margin: 0;
        }
    }

    .nel-ordine {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
    }

    .numero-prodotti {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .box-dati-negozio {
        .uk-card-body {
            padding: 16px 16px 0 16px;
        }

        .servizi {
            padding: 24px 16px;
            width: 100%;
            background-color: $buttary-white;
            border-radius: 0 0 12px 12px;
        }

        .box-dati-ritiro>div {
            margin-bottom: 8px;
        }

        .box-servizi .box-servizi-title {
            margin-bottom: 16px;
        }

        .box-servizi {
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 16px;

            .immagine-servizio {
                min-width: 24px;
                min-height: 24px;
                max-width: 24px;
                max-height: 24px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .entry-servizio {
                margin-bottom: 18px;
                font-size: 14px;
                align-items: flex-start;
                column-gap: 8px;

                @media (min-width: $breakpoint-xlarge) {
                    align-items: center;
                }
            }
        }

        hr {
            margin: 0 0 16px 0;

            &#hr-mobile {
                margin: 16px 0 8px 0;
            }
        }

        .orari-ordina-ritira {
            margin-bottom: 8px;
        }

        .orari {
            font-size: 14px;
            flex-direction: column;

            @media (min-width: $breakpoint-xlarge) {
                flex-direction: row;
                column-gap: 55px;
            }

        }

        .icon-shop-3 {
            font-family: "icomoon";
            position: relative;
            top: 0px;
            left: 0px;
            font-weight: 500;
            font-size: 24px;
            width: 1em;
            height: 1em;
        }
    }

    .section-dati-personali {
        .cf {
            font-size: 14px;
            margin-top: 14px;
            line-height: 1.43;
            @include color-primary-black();

            a {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.29;
            }
        }

        .add-envoice {
            .uk-button {
                font-size: 14px;
                font-weight: bold;
                text-align: left;
                padding: 10px;
                margin: 0 0 0px -10px;
                @include color-primary-black();
            }
        }

        .uk-card-body {
            padding-bottom: 16px;
        }
    }

    .section-privacy {
        margin-top: 16px;
        font-size: 12px;
        line-height: 1.17;
        @include color-primary-black();

        @media (max-width: $breakpoint-large-2-max) {
            padding-left: 16px;
            padding-right: 16px;
        }

        @media (min-width: $breakpoint-xlarge) {
            margin-bottom: 0;
        }

        a {
            font-weight: 500;
        }
    }

    .no-margin {
        margin: 0 !important;
    }

    /* ------------------ checkboxes --------------------------*/

    /* .uk-checkbox-custom:hover input~.checkmark {
        border-color: $black;
    }

    .uk-checkbox-custom:hover input~.checkmark.selected {
        border-color: $black;
    }

    .uk-checkbox-custom input~.checkmark.selected {
        background-color: $white;
        border-color: rgba($gray, 0.3);
    }

    .uk-checkbox-custom .checkmark:after {
        border-color: inherit;
        transition: all 0s ease-in-out;
    } */

    /* ------------------ checkboxes end --------------------------*/


    .bar-header {
        height: 80px;

        @media (max-width: $breakpoint-large-2-max) {
            height: 111px;
        }

        .uk-visible\@l-2 {
            @media (max-width: $breakpoint-large-2-max) {
                display: none !important;
            }
        }

        .uk-hidden\@l-2 {
            .uk-navbar-center {
                display: none !important;

                @media (max-width: $breakpoint-large-2-max) {
                    display: flex !important;
                }
            }
        }

        //custom fix
        .logo-mobile {
            display: flex !important;
            @media (min-width: $breakpoint-xlarge) {
                display: none !important;
            }
        }
    }

    @media screen and (max-width: $breakpoint-large-2-max) {
        .component-Breadcrumb {
            position: fixed;
            top: 17px;
            left: 16px;
            z-index: 2000;

            span {
                display: none;
            }
        }
    }

    .component-header {
        padding-top: 0 !important;
    }

    .step2-content {
        display: none;
    }

    .iframe-paga-online,
    .sezione-paga-in-negozio {
        display: none;
    }

    .iframe-paga-online {
        height: 1200px;

        iframe {
            height: 100%;
            width: 100%;
        }
    }


    /* esportare tutto il css per lo step 2 qua dentro */
    /* IMPORTANTE: AGGIUNGERE QUESTA CLASSE VIA JS */
    &.step-2 {
        min-height: 100vh;

        .component-header {
            .step-indicator-2 {
                background-color: $banana-yellow !important;
            }

            .step-text-1 {
                display: none !important;
            }

            .step-text-2 {
                display: block !important;
            }

            .menu-pagamento {
                display: flex;
            }
        }


        /* Classe da aggiungere in js quando ci sono le opzioni di pagamento */
        &.with-payment-options {
            .main-content {
                padding-top: 46px !important;
            }
        }

        .selected {

            &.iframe-paga-online,
            &.sezione-paga-in-negozio {
                display: block;
            }
        }

        .component-header {
            padding-bottom: 0;
        }

        .step2-content {
            display: block;
            padding: 0;

            &.menu-pagamento {
                display: flex;
            }
        }

        .step1-content {
            display: none;
        }


        @media (max-width: $breakpoint-large-2-max) {
            &.checkout-container {
                padding: 0 16px;
            }
        }

        .checkout-container {
            .main-content {
                &-body {
                    margin-left: 0;

                    .uk-card-body {
                        border-radius: 12px !important;
                        border: none;
                    }

                    .box-scon-pag {
                        padding: 0 0 0 16px;
                        margin: 0 0 16px 0;
                    }

                    .paga-nagozio-content {
                        margin: 0;

                        .primary-panel {
                            margin: 0;
                            padding: 0;
                        }
                    }

                    @media (max-width: $breakpoint-large-2-max) {
                        padding: 0 16px 20px;
                        margin-top: 7px;
                        .box-scon-pag {
                            padding: 0;
                            box-shadow: none;
                        }

                        .paga-nagozio-content {
                            flex-wrap: wrap-reverse;
                        }
                    }
                }
            }
        }

        .torna-ai-dati {
            display: flex;
            margin-bottom: 24px;
            font-weight: 500;
            background: none;
            border: none;
            cursor: pointer;

            .left-arrow {
                font-size: 12px;
                font-weight: 500;
                color: black;
                display: flex;
                text-decoration: none;
                margin-right: 9px;

                &:before {
                    font-size: 16px;
                }
            }

            span {
                font-size: 12px;
            }

            @media  (min-width: $breakpoint-xlarge) {
                margin-top: 10px;
            }

            @media  (max-width: $breakpoint-large-2-max) {
                position: fixed;
                top: 17px;
                left: 16px;
                z-index: 2000;
                padding: 0;
                height: 24px;
                width: 24px;

                .left-arrow {
                    font-size: 21px;

                    &:before {
                        font-size: 21px;
                    }
                }

                span {
                    display: none;
                }
            }
        }

        .component-Breadcrumb {
            display: none;
        }

        .box-scon-pag {
            .box-pagamento {
                margin-top: 0;
            }
        }
    }

    .select-native-ui-floor {

        select:not(:invalid) {
            line-height: 42px;
            padding: 12px 14px 0px;
        }

        select.uk-form-danger {
            border-color: $form-danger-border;
        }

        select:invalid {
            @include color-primary-black(0.6);
        }

        option:first-child {
            display: none !important;
        }

        .select-label {
            top: 5px;
        }
    }

    .modal-overlay-prodotti-checkout {
        .component-CardProdottoOmaggio {
            margin-bottom: 8px;
            height: 160px;
            background-color: $white;

            .omaggio-header-image {
                padding-top: 32px;
                position: relative;
                z-index: 1;
            }

            .omaggio-description-image {
                .omaggio-image {
                    max-width: 42px;
                    max-height: 38px;
                }
            }

            .omaggio-container-img-mobile {
                z-index: 0
            }

            @media (min-width: $breakpoint-xlarge) {
                height: 61px;

                .omaggio-header-title {
                    padding-left: 41px;
                }

                .card-omaggio-container {
                    height: 100%;
                }

                .omaggio-container-img-desktop {
                    height: 100%;
                    z-index: 0;
                }

                .omaggio-header-image {
                    padding-top: 0;
                    height: 20px;
                    z-index: 1;
                }

                .omaggio-description-image {
                    align-items: center;
                }

                .omaggio-gratis {
                    font-weight: 500;
                    font-size: 20px;
                }

            }
        }

        
    }

    .component-footer {
        display: none !important;
    }

    .icon-coupon {
        &:before {
            position: relative;
            top: 2px;
            font-weight: 700;

            @media (max-width: $breakpoint-large-2-max) {
                top: 0px;
            }
        }
    }

    .component-alert-primary {
        margin-top: 16px;
        @media (max-width: $breakpoint-large-2-max) {
            margin: 16px 16px 0;
        }
        @media (min-width: $breakpoint-xlarge) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}


//componente checkout timer validità prenotazione (presente anche nell'header)
.banner-timer-container {
    position: fixed;
    width: 100%;
    z-index: 1101;
    background-color: $cosmic-latte;
    font-weight: 700;
    @include color-primary-black(0.6);
    line-height: 37px;
    text-decoration: none !important;
    color: inherit !important;

    //sticky desktop
    @media (min-width: $breakpoint-xlarge) {
        &.uk-sticky-fixed {
            top: 88px !important;
        }
    }

    * {
        font-size: 12px;
    }

    p {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .countdown {
        margin-left: 16px;
    }

    [class*='icon-'] {
        margin-left: 9px;
        font-weight: bold;
    }

    &.timer-expired {
        background-color: $pink-light-2;
        color: $red;
        font-weight: bold;
        text-decoration: none;

        span {
            text-decoration: underline;
        }

        [class*='icon-'] {
            text-decoration: none;
        }
    }


    @media (min-width: 320px) {
        * {
            font-size: 14px;
        }
    }
}

// Page Checkout Guest
.container-guest {
    padding-bottom: 15px;
    @include color-primary-black();

    .box-info {
        margin-top: -5px;

        .uk-button {
            line-height: 56px;
        }

        @media (max-width: $breakpoint-medium-max) {
            .uk-button:not(.uk-button-large) {
                font-size: 16px;
            }
        }

        .uk-input {
            font-weight: 500;
        }

        &-content {
            .title {
                font-size: 28px;
                font-weight: bold;
                line-height: 1.07;
                margin-bottom: 12px;
            }

            .desc {
                font-size: 14px;
                line-height: 1.43;
                margin-bottom: 20px;
            }

            .button-action {
                margin: 20px 0 14px;
            }

            .underline-title {
                position: relative;
                margin-bottom: 35px;

                &:before {
                    border-radius: 4px;
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: -13px;
                    left: 0;
                    width: 32px;
                    height: 4px;
                    background-color: $yellow;
                }
            }

            &-guest {
                position: relative;

                @media(min-width: $breakpoint-xlarge) {
                    .form {
                        .uk-button {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        }
                    }
                }
            }
        }

        &-guest {
            .desc {
                font-size: 14px;
                line-height: 1.43;
                margin-bottom: 20px;
            }

            .form {
                margin-top: 30px;
                margin-bottom: 16px;
            }
        }

        .reasons {
            margin-top: 40px;
            padding-bottom: 17px;
            border-bottom: 1px solid var(---effects-01-opacity-30-2);

            .title {
                font-weight: normal;
                font-size: 16px;
                margin-bottom: 22px;
                padding-bottom: 0;
            }

            >* {
                font-size: 14px;
                padding-bottom: 15px;
                display: flex;
                align-items: center;

                i {
                    padding-right: 16px;
                    font-size: 24px;
                }
            }
        }
    }

    @media (min-width: $breakpoint-xlarge) {
        margin-bottom: 33px;
        padding-top: 11px;

        .box-info {
            &-content {
                &:not(.box-info-content-guest) {
                    border-right: 1px solid var(---effects-01-opacity-30-2);
                }

                .title {
                    font-size: 24px;
                }

                .desc {
                    font-size: 16px;
                }

                &-info {
                    padding-right: 39px;

                    .uk-button {
                        min-width: 182px;
                    }
                }

                &-guest {
                    padding-left: 39px;
                }
            }

            .reasons {
                border-bottom: none;

                >* {
                    padding-bottom: 24px;
                }
            }
        }
    }
}



//TODO: inserito nel css sbagliato
.icon-alarm {
    &:before {
        font-family: "icomoon";
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        content: "\e914" !important;
        background: none;
        width: 1em;
        height: 1em;
        float: right;
    }
}

//TODO: inserito nel css sbagliato
.uk-navbar-center {
    flex-direction: column;
    width: max-content;
    top: 19px;

    @media (max-width: $breakpoint-large-2-max) {
        width: 100%;
    }

    li {
        &::marker {
            content: none;
        }
    }
}