.component-BannerListing {
    &.offerte-video {
        .offerte-video-container {
            display: block;
            .uk-flex {
                @media (min-width: $breakpoint-xlarge) {
                    flex-direction: row;
                }
            }

            .banner-video {
                margin-top: 24px;
                margin-bottom: 24px;
            }

            .banner-video .component-BannerSponsor {
                margin: 0;

                @media (min-width: $breakpoint-xlarge) {
                    //width: calc(50% - 8px);
                    //margin-right: 8px;
                    padding: 0;
                    .content-container {
                        min-height: 264px;
                    }

                    .banner-title {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.25;
                        max-width: 200px;
                    }

                    .img-container {
                        max-width: 200px;
                    }
                }
            }

            .video-container {
                border-radius: 12px;
                overflow: hidden;
                width: 100%;
                margin: 0;
                min-height: 264px;
                position: relative;
                iframe {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                }

                @media (min-width: $breakpoint-large) {
                    min-height: 350px;
                }

                @media (min-width: $breakpoint-xlarge) {
                    margin-left: 8px;
                    min-height: inherit;
                    max-height: 264px;
                }

                img {
                    border-radius: 12px;
                    height: 264px;
                    width: 100%;

                    @media (min-width: $breakpoint-xlarge) {
                        height: inherit;
                    }
                }
            }

            .banner-text {
                font-size: 14px;
            }
        }
    }
}