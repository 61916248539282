.component-BadgeStatiOrdine{

    .uk-badge{
        font-weight: bold;
    }

    .blue{
        background-color: rgba(#0072ca, 0.1);
        color: #0072ca !important;
    }

    .grey{
        color: $primary-black !important;
    }

    .red{
        background-color: rgba(#e20714, 0.1);
        color:  #e20714 !important;
    }

    .yellow{
        background-color: rgba(#ffb602, 0.1);
        color: #ffb602 !important;
    }

    .green{
        background-color: rgba(#5eb223, 0.1);
        color: #5eb223 !important;
    }
}