.page-Listinglevel1 {

    .main-content {

        .swiper-container {
            &.listing-swiper-offers {
                padding-left: 7px;
                padding-right: 7px;
                margin-left: -7px;
            }
            @media (min-width: $breakpoint-xlarge) {
                width: 100%;
            }

            @media (min-width: $breakpoint-xlarge) and (max-width: $breakpoint-xxlarge-max) {
                &.listing-swiper-offers {
                    margin-right: -40px !important;
                }
            }
        }

        .section-full {
            .uk-container {
                padding-top: 32px;
            }


            @media (min-width: $breakpoint-xlarge) {
                .section-content {
                    display: flex;
                    .content-desc {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }

        .section-category {
            @media (min-width: $breakpoint-xlarge) {
                .section-title {
                    margin-bottom: 50px;
                }
            }
            @media (max-width: $breakpoint-large-max) {
                .listing-swiper-category {
                    padding-right: 16px;
                    margin-left: -16px;
                    padding-left: 16px;
                }
                .swiper-scrollbar {

                }
                .uk-container {
                    padding-right: 0;
                }
            }
            @media (max-width: $breakpoint-medium-max) {
                .swiper-scrollbar {
                    width: calc(100% - 32px);
                    left: 16px;
                }
            }
        }


        .hr-section-divider {
            margin: 0 0 20px 0;
            border-color: black;
            opacity: 0.2;

            @media (min-width: $breakpoint-large-2) {
                margin: 6px 0 10px 0;
            }
            @media (min-width: $breakpoint-xxlarge) {
                margin: 0 0 8px;
            }
        }

        .listing-swiper-category {
            padding: 10px 7px 40px;
            margin-top: -10px;

            .swiper-scrollbar {
                @media (min-width: $breakpoint-small) and (max-width: $breakpoint-large-max) {
                    left: 50% !important;
                    max-width: 344px;
                    margin-left: -180px;
                }
            }
        }

        .section-what-offer {
            box-sizing: border-box;
            padding-top: 0;
            padding-bottom: 0;

            .swiper-button-next {
                min-width: 30px;
                min-height: 22px;
                text-align: center;
                bottom: 1px;
                top: auto;
                margin-top: 0;
                right: 48px;
                --swiper-navigation-size: 16px;
                --swiper-theme-color: black;
                @media (min-width: $breakpoint-xxxlarge) {
                    right: 0;
                }
            }

            .swiper-button-prev {
                min-width: 30px;
                min-height: 22px;
                text-align: center;
                bottom: 1px;
                top: auto;
                margin-top: 0;
                right: 80px;
                left: auto;
                --swiper-navigation-size: 16px;
                --swiper-theme-color: black;
                @media (min-width: $breakpoint-xxxlarge) {
                    right: 32px;
                }
            }

            .section-title {
                font-size: 32px;
                margin-bottom: 16px;

                &::before {
                    bottom: -16px;
                }
            }

            .section-body {
                margin-bottom: 24px;

                > * {
                    flex-direction: row;
                    align-items: center;
                }

                @media (min-width: $breakpoint-large) {
                    margin-bottom: 43px;
                }
            }

            .content-desc {
                width: 100%;
                max-width: 317px;
                box-sizing: border-box;
                font-size: 20px;
                margin-right: 139px;
                margin-top: 78px;
                margin-bottom: auto;

                p{
                    margin-top: 0px;
                    margin-bottom: 33px;
                }

            }

            .explore-button {
                min-width: auto;
                padding: 20px 32px;
                line-height: 22px;
                font-size: 16px;
            }

            .listing-swiper-offers {
                padding: 40px 0 30px;
                margin-top: -18px;
                @media (min-width: $breakpoint-large) {
                    padding: 40px 0 45px;
                }
            }


            .swiper-button-next,
            .swiper-button-prev {
                bottom: auto;
                top: 1px;
            }

            .swiper-scrollbar {
                display: block;
            }

            @media (min-width: 375px) {
                .swiper-scrollbar {
                    left: 50% !important;
                    max-width: 300px;
                    margin-left: -150px;
                }
            }

            @media (max-width: $breakpoint-xxlarge-max) {
                .section-body {
                    margin-bottom: 43px;
                }

                .content-desc {
                    margin-right: 79px;
                }

                .listing-swiper-offers {
                    padding: 0 0 45px;
                    margin-top: 0;
                }
            }

            @media (min-width: $breakpoint-xlarge) {
                .listing-swiper-offers {
                    padding: 40px 7px 45px;
                    margin-top: -40px;
                }
            }

            @media (max-width: $breakpoint-large-2-max) {
                .section-title {
                    font-size: 28px;
                    margin-bottom: 0;

                    &::before {
                        bottom: -12px;
                    }
                }

                .section-body {
                    margin-bottom: 0;
                }

                .content-desc {
                    max-width: 704px;
                    box-sizing: border-box;
                    margin-right: 0;
                    margin-bottom: 0;

                    .section-description {
                        margin-top: -44px;
                        margin-bottom: 0;
                    }

                }

                .listing-swiper-offers {
                    margin-left: -32px;
                    margin-right: -32px;
                    padding: 26px 32px 38px;

                    /* Override di css della product card */
                    .card-middle {
                        .product-info {
                            margin-left: 0;
                        }
                    }

                }

                .explore-button {
                    margin: 34px auto 38px;
                    display: block;
                }

            }

            @media (max-width: $breakpoint-medium-max) {
                .content-desc {
                    max-width: 343px;
                    margin-right: 0;

                    .section-description {
                        margin-top: 74px;
                    }
                }

                .listing-swiper-offers {
                    margin-left: -16px;
                    margin-right: -16px;
                    padding: 26px 16px 38px;
                }

                .swiper-scrollbar {
                    width: calc(100% - 16px);
                    left: 0;
                }

                .explore-button {
                    line-height: 22px;
                    width: calc(100% - 32px);
                    max-width: 220px;
                    margin: 40px auto 40px;
                    display: block;
                    font-size: 16px;
                }
            }

            @media (max-width: $breakpoint-large-max) {
                .explore-button {
                    max-width: 343px;
                }
            }

            @media (max-width: 374px) {
                .swiper-scrollbar {
                    width: calc(100% - 32px);
                    left: 16px;
                }
            }

        }

        .section-promo-banners{
            padding: 0;
            .component-CarouselBannerSponsor{
                .section-banner-sponsor{
                    padding: 0;
                }

                .component-BannerSponsor{
                    padding: 0;
                }

                .swiper-banner-sponsor{
                    padding: 0;
                }
            }
        }

        .section-evidenza-alternative{
            padding: 0;

            .section-prod-evid{
                @media (min-width: $breakpoint-xlarge){
                    padding-bottom: 0;
                }
            }
        }

    }

    &.Listinglevel1Offers {

        .page-title {
            font-size: 32px;
            font-weight: bold;
            line-height: 1.25;
            margin-bottom: 24px;

            @media (min-width:$breakpoint-xlarge) {
                font-size: 40px;
                line-height: 1.2;
            }
        }

        .component-BannerStripeVisibilita {
            .img-logo {
                border-radius: 12px;
            }
        }
    }
}
