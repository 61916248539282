@import '../../scss/uikit/variables-theme.scss';


.replacement-mode-desktop {
    padding: 16px;
    background-color: $lavander;
    box-shadow: none;
    border-radius: 12px;

    @media (max-width: $breakpoint-large) {
        margin: 0 -16px;
        border-radius: 0;
    }

    .uk-card-body {
        background-color: $white;
        border: solid 1px #005ba2;
        margin: 0;
        padding: 16px;
    }

    .replacement-content-info{
        position: relative;
        font-size: 14px;

        @media (max-width: $breakpoint-large-2-max) {
            padding-right: 5px;
        }

        .replace-choice{
            display: none;

            .substitution{
                &-text, &-description{
                    display: none;
                }

                &-text{
                    font-weight: bold;
                }
            }
        }

        &.replacement-no-substitution{
            .no-replace{
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        &.replacement-scelgo-io, &.replacement-chiamami, &.replacement-sceglie-conad{
            .replacement-active{
                display: block !important;
            }
        }

        &.replacement-scelgo-io{
            .scelgo-io{
                display: block !important;
            }
        }

        &.replacement-chiamami{
            .chiamami{
                display: block !important;
            }
        }

        &.replacement-sceglie-conad{
            .sceglie-conad{
                display: block !important;
            }
        }
    }

    .active-text{
        padding: 0 16px;
    }

    .replace-choice.no-replace{
        padding: 0 16px;
    }

    .icon-switch {
        font-family: 'icomoon' !important;
        font-size: 16px;
        color: #666;

        &::before {
            position: absolute;
            top: 37px;
            left: 8px;
        }
    }

    .icon-info {
        position: relative;
        top: 1px;
    }

    .card-title {
        font-size: 18px;
        margin-bottom: 16px;

        @media (max-width: 453px) {
            .tooltiptext-drop {
                left: 16px !important;
            }
        }
    }

    .card-content {
        width: 100%;

        .footer {
            padding: 0 16px;
            align-items: center;
        }
    }

    .button-blue {
        border: solid 2px #005ba2;
        color: #005ba2;
        line-height: 38px;
        background-color: white;
    }

    a {
        &.uk-button {
            padding: 0;
            min-width: 108px;
            height: 40px;
            font-size: 14px;
            margin-left: 5px;
        }
    }

    hr {
        margin: 16px;
        background: #b3b3b3;
        height: 1px;
    }
}