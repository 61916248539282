.cards-question {

    .swiper-slide{
        max-width: 280px;

        @media (min-width: $breakpoint-xlarge){
            height: 270px;
        }

        @media (min-width: $breakpoint-xxxlarge){
            max-width: 431px;
        }
    }

    .swiper-faq {
        padding: 0 0 58px;
    }

    .swiper-scrollbar {
        display: none;
    }

    .swiper-button-next {
        bottom: 1px;
        top: auto;
        margin-top: 0;
        right: 4px;
        --swiper-navigation-size: 16px;
        --swiper-theme-color: black;
    }

    .swiper-button-prev {
        bottom: 1px;
        top: auto;
        margin-top: 0;
        right: 40px;
        left: auto;
        --swiper-navigation-size: 16px;
        --swiper-theme-color: black;
    }

    @media (min-width: $breakpoint-xlarge) {
        .swiper-faq, .swiper-offers {
            padding: 80px 0 40px;
            margin-top: -95px;
        }
        .swiper-button-next, .swiper-button-prev {
            bottom: auto;
            top: 1px;
        }
        .swiper-scrollbar {
            display: block;
            width: 32%;
            left: 34%;
        }
    }
    @media (min-width: $breakpoint-xxxlarge) {
        .swiper-faq, .swiper-offers {
            padding: 95px 0 38px;
            margin-top: -85px;
        }
    }
}