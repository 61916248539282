@import '../../scss/uikit/variables-theme.scss';

.component-ProductCardLastView {
    @media (min-width: $breakpoint-xlarge) {
        margin-bottom: 16px;

        &:last-child { 
            margin-bottom: 0; 
        }
    }

    .product-card {
        box-shadow: -2px 6px 8px 0px rgba(171, 147, 67, 0.25), 0 0 1px 0 rgba(171, 147, 67, 0.2);
        border: none;
        padding: 6px 15px 8px 10px;
        @include color-primary-black(0.8);
        position: relative;

        @media (min-width: $breakpoint-xlarge) {
            max-width: 444px;
        }
        

        .card-top {
            padding-bottom: 30px;
            margin-bottom: auto;
        }

        .card-middle {
            a{
                text-decoration: none;
            }
            
            .product {
                text-decoration: none;
                @include color-primary-black(0.8);
                position: relative;

                &-description-container {
                    margin-left: 20px;

                    .description {
                        @include line-clamp(3);
                        padding-right: 25px;
                        height: 32px;
                        font-size: 12px;
                        line-height: 16px;
                        font-weight: bold;
                        margin-bottom: 0;
                    }

                    .price {
                        line-height: 20px;

                        .red {
                            color: var(---colour-01-primary-01-p-2);
                            margin-left: 10px;
                        }
                    }
                }

                &-img {
                    min-width: 52px;
                    min-height: 52px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 12px;
                    background-color: $white;
                    overflow: hidden;

                    img {
                        max-height: 52px;
                        max-width: 52px;
                    }
                }

                .alert-out-of-stock {
                    box-sizing: border-box;
                    display: flex;
                    box-shadow: 0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
                    background-color: $pink-light-2;
                    max-width: 100%;
                    margin-right: 8px;
                    margin-bottom: 0px;
                    height: 100%;
                    position: absolute;
                    p{
                        padding-right: 0;
                    }
                }
            }

            .icon-container {
                .manage-preferite-product {
                    z-index: 1;
                    font-size: 19px;
                    margin-right: -6px;
                }
                .favorite-item {
                    padding-bottom: 10px;
                }

                .add-cart {
                    padding: 0;
                    margin: 0;
                    width: 40px;
                    line-height: 40px;
                    position: relative;
                    margin-right: 2px;

                    .icon-plus,
                    .icon-cart {
                        margin: 0;
                        font-size: 16px;
                        line-height: 1;
                    }

                    .uk-badge{
                        font-family: $global-font-family;
                        padding: 0 3px;
                        background-color: #fce2cc;
                        color: var(---colour-01-primary-01-p-2) !important;
                        top: -12px;
                        left: 0;
                        transform: translate(-50%, 0);
                        border-radius: 12px;
                        position: absolute;
                        z-index: 2;
                    }
                }
            }
        }
    }

    .feedback-overlay-pcard {
        text-align: center;
        display: none;
        opacity: 0;
        animation: 3000ms ease-in-out 0s 1 normal forwards running feedback-productcard-add-to-cart;

        &-icon {
            margin-left: 15px;
            height: 52px;
            width: 52px;
            position: absolute;
            top: 15%;
            left: 0;
            right: 0;

            img{
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
}
