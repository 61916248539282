.component-OfferteInCorso {
    .section-off-in-corso {
        .section-title {
            margin: 0;
            font-size: 18px;        
            font-weight: normal;
            line-height: 1.33;
            float: left;
            width: 79%;

            @media (min-width:$breakpoint-xlarge) {
                margin-bottom: 44px;
                width: 100%;
                font-size: 28px;
                line-height: 1.07;

                &::before {
                    bottom: -13px;
                }
            }
        }

        > .uk-container > a {
            float: right;
            margin-top: 32px;
            font-size: 14px;
            font-weight: 500;
        }

        .section-body {
            clear: both;
        }

        .card-container {
            display: flex;
            flex-wrap: wrap;
            clear: both;

            .component-OfferCard {
                width: calc((100% - 38px)/ 3);
                margin-bottom: 16px;

                &:not(:nth-child(3n-2)) {
                    margin-left: 9.5px;
                }

                &:not(:nth-child(3n)) {
                    margin-right: 9.5px;
                }
            }
        }
    }
}