.component-StorytellingC {
    .section-storytelling {
        &-c {
            .section-title {
                font-size: 18px;
                font-weight: normal;
                line-height: 1.33;
                margin-bottom: 36px;

                @media (min-width: $breakpoint-xlarge) {
                    font-size: 32px;
                    line-height: 1.25;
                    margin-bottom: 44px;
                }

                &:before {
                    bottom: -12px;

                    @media (min-width: $breakpoint-xlarge) {
                        bottom: -20px;
                    }
                }
            }

            .section-body {

                .swiper-storytelling-c {

                    overflow: visible;
                    padding-bottom: 32px;

                    @media (min-width: $breakpoint-xlarge) {
                        padding-bottom: 36px;
                        overflow: hidden;
                    }
                }

                .component-card-image-top {
                    margin-bottom: 0;
                }

                .scrollbar-storytelling-c {
                    width: 100%;
                    left: 0;
                    height: 4px;

                    @media (min-width: $breakpoint-xlarge) {
                        width: 46%;
                        left: 27%;
                        bottom: 1px;
                    }
                }
            }
        }
    }
}