
@import './uikit/variables-theme.scss';
@mixin line-clamp($line: 3) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}

// @mixin bg-image-gradient-horizontal($colour-left,$perc-left, $colour-right, $perc-right) {
//     background-image: -moz-linear-gradient(left, $colour-left $perc-left, $colour-right $perc-right );
//     background-image: -webkit-linear-gradient(left, $colour-left $perc-left , $colour-right $perc-right );
//     background-image: linear-gradient(to right, $colour-left $perc-left , $colour-right  $perc-right );
//     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$colour-left, endColorstr=$colour-right, GradientType=1);
// }

@mixin btnNoHover($className, $bgColor, $fontColor: $white) {
    #($className) {
        &:hover,
        &:focus {
            background-color: $bgColor;
            color: $fontColor;
        }
    }
}

@mixin scrollbar() {
    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: rgba($black, 0.2);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: rgba($gray, 1);
    }
    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }
    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

@mixin hook-form-success() {
    background-color: $white;
    border-color: $form-success-border;
}
@mixin hook-form-danger() {
    background-color: $white;
    border-color: $form-danger-border;
}

@mixin color-primary-black($alpha: 0.8) {
    @if $alpha == 0.8 {
        color: $primary-black;
    } @else {
        color: rgba($black, $alpha);
    }
}

@mixin hide-ps__rail_x {
    .ps__rail-x {
        height: 0px;

        &:hover {
            background-color: transparent;
            height: 0px;

            .ps__thumb-x {
                background-color: transparent;
                height: 0px;
            }
        }

        .ps__thumb-x {
            background-color: transparent;
            height: 0px;
        }
    }
}

@mixin hide-ps__rail_y {
    .ps__rail-y {
        height: 0px;

        &:hover {
            background-color: transparent;
            height: 0px;

            .ps__thumb-y {
                background-color: transparent;
                height: 0px;
            }
        }

        .ps__thumb-y {
            background-color: transparent;
            height: 0px;
        }
    }
}