.component-PromoMdd {
    
    .section-prom-vis {

        .swiper-prom-vis {
            overflow: hidden;
        }
        .swiper-slide {
            width: 275px;
    
            @media (min-width: $breakpoint-xlarge) {
                width: auto;
            }
    
            &.slide-link {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 90px !important;
                padding: 0;
                border-radius: 12px;
                background-color: var(---colour-02-secondary-01-s-2);
                /* background-color: $black; */
                margin-right: 0 !important;
            }
    
            a {
                text-decoration: none;
    
                .link-prom-vis {
                    width: 100%;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1;
                    text-align: center;
                    @include color-primary-black();
                    text-decoration: none;
                }
            }
        }
    }

    /* &.offers {
        .slide-link {
            display: none !important;
        }
    } */

    &.homepage {
        .swiper-slide:nth-child(3) {
            display: none;
        }
    }
}
