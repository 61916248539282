.component-ModalProductImages {

    .back-loading {
        display: none;
    }

    &.loading {
        .back-loading {
            display: block;
            background-color: $white;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .preview-image-container-modal {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        height: 100%;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;

        .main-img {
            margin-top: auto;
        }
        .main-swiper {
            margin-top: auto;
            margin-bottom: 50px;
        }

        .img-active {
            width: 250px;
            height: 500px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                max-height: 100%;
                max-width: 100%;
            }

            @media (min-width: $breakpoint-small) {
                height: 300px;
                width: 300px;
            }

            @media(min-width: $breakpoint-medium) {
                height: 400px;
                width: 400px;
            }

            @media (min-width: $breakpoint-large) {
                height: 600px;
                width: 600px;
            }


        }

        .zoomArea {
            width: 100%;
            overflow: hidden;
            #NZoomContainerMobile {
                width: 100%;
                height: 100%;
            }
        }

    }

    .uk-modal-dialog {
        border-radius: 0;
        position: relative;
    }

    /*.uk-slideshow {
        > * {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .uk-slideshow-items {
        width: 100%;
        margin: auto;
        max-width: 70%;
        min-height: calc(100vh - 100px);
        > * {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
    .uk-slideshow-nav {
        position: relative;
        top: -30px;
        height: 0;
    }*/

    .swiper-product-detail-image-modal {
        padding: 5px 20px;

        .swiper-slide {
            text-align: center;

            img {
                max-width: 170px;
            }
        }

        .swiper-slide {
            max-width: 53px;
            margin: 0 5px;

            > div {
                width: 53px;
                height: 53px;
                padding: 8px;
                box-sizing: border-box;
                cursor: pointer;
                border-radius: 12px;
                box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            }

            &.active {
                > div {
                    box-shadow: 0 0 5px 0px var(---dusty-orange), 0 0 0px 4px rgba($dusty-orange, 0.15) inset;
                }
            }

            &.slide-video {
                > div {
                    position: relative;

                    &:after {
                        background: url('./resources/ecommerce/img/icon-play-bg.svg') no-repeat center;
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        display: block;
                    }
                }
            }

            img {
                max-width: 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }

        .swiper-button-prev, .swiper-button-next {
            background: $white;
            height: 100%;
            width: 10px;
            top: 0;
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;
        }
    }
}
