.component-StorytellingB {
    .section-storytelling {
        
        &-b {
            .section-body {
                display: flex;
                flex-direction: column;

                @media (min-width: $breakpoint-xlarge) {
                    flex-direction: row;
                }

                .banner {
                    height: 174px;
                    border-radius: 12px;
                    padding: 24px 16px 18px;
                    margin: 0 0 24px;
                    background-position: center center;
                    background-size: cover;
                    position: relative;

                    @media (min-width: $breakpoint-xlarge) {
                        width: 34.9%;
                        height: 176px;
                        padding: 24px 32px 16px;
                        margin: 0;
                    }

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background-color: rgba($black,.4);
                        border-radius: 12px;
                    }
                    > * {
                        position: relative;
                        z-index: 1;
                    }

                    .title {
                        margin: 0 0 8px;
                        font-size: 28px;
                        font-weight: normal;
                        line-height: 1.29;
                        color: $white !important;
                    }

                    .description {
                        margin: 0 0 18px;
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 1.43;
                        color: $white !important;

                        @media (min-width: $breakpoint-xlarge) {
                            margin-bottom: 55px;
                        }
                    }

                    .link-storytelling-b {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 1.29;
                        color: $white !important;
                    }
                }

                .swiper-storytelling-b {

                    overflow: visible;
                    width: 100%;
                    margin-top: 8px;

                    margin-left: -7px;
                    padding-left: 7px;
                    padding-right: 7px;

                    @media (min-width: $breakpoint-xlarge) {
                        width: calc(100% - 384px);
                        margin-left: 16px;
                        margin-right: unset;
                        overflow: hidden;
                        padding: 0;
                    }

                    .swiper-slide {
                        display: flex;
                        flex-direction: column;

                        @media (min-width: $breakpoint-xlarge) {
                            width: calc((100% - 8px) / 2) !important;
                        }

                        &:last-child {
                            margin-right: 0 !important;
                        }

                        .component-ProductCardLastView {

                            .product-card {
                                @media (min-width: $breakpoint-xlarge) {
                                    max-width: unset;
                                }
                            }

                            .description {
                                min-width: unset;
                            }

                            .icon-container {

                                margin-right: -8px;

                                .favorite-item {
                                    font-size: 16px;
                                    @include color-primary-black();
                                }
                            }

                            &:first-child {
                                margin-bottom: 16px;
                            }
                        }
                    }
                }
            }
        }

    }
}