@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';

.component-modal-manage-cookies {
    .uk-offcanvas-bar {
        height: 100%;
        @include scrollbar();
    }

    .uk-offcanvas-body {
        button {
            line-height: 56px;
        }
    }

    .btn-container {
        margin: 32px 0;
    }

    h3 {
        font-size: 20px;
    }

    @media (min-width: $breakpoint-large) {
        h3 {
            font-size: 24px;
        }
    }
}
