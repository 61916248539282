.component-Navigazione {
    .section-offer {
        padding: 20px 0;

        .section-title {
            font-size: 18px;
            line-height: 1.33;
            margin-bottom: 16px;

            @media (min-width: $breakpoint-xlarge) {
                min-width: 232px;
                font-size: 28px;
                line-height: 1.07;
            }
        }

        .section-body {
            flex-direction: column;
        }

        .offer-card-container {
            .text {
                margin-top: -3px;

                @media (min-width: $breakpoint-xlarge) {
                    margin-top: -2px;
                    margin-right: -2px;
                }
            }
        }

        .section-nav-off {
            .offer-card-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0;
                > * {
                    max-width: 90px;
                    width: 100%;
                }
            }

            .card-offer {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                padding: 0 2px;
                .uk-card-type-flat-offer {
                    width: 100%;
                }
            }

            @media (min-width: $breakpoint-medium) {
                .offer-card-container {
                    margin-left: -10px;
                }
                .card-offer {
                    padding: 0 10px;
                }
            }

        }

        .section-carousel {
            @media (min-width: $breakpoint-xlarge) {
                .section-title-2 {
                    margin-top: 3px;
                }
            }

            .swiper-slide {
                width: 94px;
                height: auto;
            }

            .swiper-nav-cat-small {
                overflow: visible;

                @media (min-width: $breakpoint-large) {
                    padding: 35px 0 30px;
                    overflow: hidden;
                }
                @media (min-width: $breakpoint-xlarge) {
                    padding-top: 45px;
                }

                .next-off-cat {
                    top: 4px;
                    right: 7px;
                }

                .prev-off-cat {
                    top: 4px;
                    right: 40px;
                }

                .scrollbar-off-cat {
                    width: 69%;
                    left: 15.5%;
                    bottom: 10px;
                    height: 4px;
                }

            }

            .swiper-carousel {
                padding-bottom: 30px;

                .swiper-button-next,
                .swiper-button-prev,
                .swiper-pagination-custom {
                    display: none;
                }

                .component-ProductCard {
                    .product-card {
                        background-color: $white;
                    }
                }

                @media (min-width: $breakpoint-large) {
                    padding-top: 45px;
                    margin-top: -45px;
                    padding-bottom: 35px;

                    .swiper-button-next {
                        display: block;
                        top: 1px;
                        margin-top: 0;
                        right: 10px;
                        --swiper-navigation-size: 16px;
                        --swiper-theme-color: black;
                    }

                    .swiper-button-prev {
                        display: block;
                        top: 1px;
                        margin-top: 0;
                        right: 45px;
                        left: auto;
                        --swiper-navigation-size: 16px;
                        --swiper-theme-color: black;
                    }

                    .swiper-scrollbar {
                        width: 48%;
                        left: 26%;
                        @media (min-width: $breakpoint-xlarge) {
                            width: 36%;
                            left: 32%;
                        }
                    }

                    .swiper-pagination-custom {
                        display: none;
                        position: absolute;
                        width: 80px;
                        height: 20px;
                        top: 0;
                        margin-top: 0;
                        right: 52px;
                        left: auto;
                        font-weight: 500;
                        color: $black;
                    }
                }

                @media (min-width: $breakpoint-large) {
                    .component-ProductCard {
                        .product-card {
                            .card-middle {
                                .product {
                                    flex-direction: column;
                                }

                                .product-img {
                                    margin-bottom: 30px;
                                }

                                .product-info {
                                    margin-left: 0;

                                    .product-quantity {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (min-width: $breakpoint-large) {
            .section-body {
                flex-direction: row;
            }
            .section-nav-off {
                border-right: 1px solid rgba($black, 0.1);
                padding-right: 30px;

            }
            .section-carousel {
                padding-left: 43px;
            }
        }

        @media (min-width: $breakpoint-xlarge) {
            padding: 50px 0 40px;
            .section-nav-off {
                padding-right: 44px;
            }
        }

        .component-FlatCategory {
            img {
                max-width: 62px;
                max-height: 62px;
            }
        }

        &-a {
            .section-nav-off {
                display: none;
            }

            .section-carousel {
                width: 100%;
                padding: 0;

                &:before {
                    display: block;
                    bottom: -11px;
                }

                .swiper-nav-cat-small {
                    padding: 8px 0;

                    @media (min-width: $breakpoint-large) {
                        padding: 62px 0 16px;
                    }

                    @media (min-width: $breakpoint-xlarge) {
                        padding: 76px 0 46px;
                    }

                    .scrollbar-off-cat {
                        width: 46%;
                        left: 27%;
                    }
                }
            }
        }

        &-b {
            background-color: $floral-white;

            .section-title:before {
                display: none;
            }
        }
    }
}