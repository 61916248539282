@import '../../scss/uikit/variables-theme.scss';

.page-DetailProduct {

    &.no-typeofservice-selected {
        .component-Breadcrumb {
            margin-top: -70px;
            margin-bottom: 50px;
        }
    }

    #embedded-service {
        display: none !important;
        visibility: hidden !important;
    }
    
    .alert-promo {
        background-color: $light-grayish-blue;
    }

    .alert-out-of-stock, .alert-out-of-stock-google{
        background-color: $pink-light-2;
    }

    .logo-iniziativa{
        max-width: 48px;
        max-height: 48px;
        margin-right: 8px;
    }

    .message-feedback {
        position: absolute !important;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100% !important;
        display: none;
        z-index: 2 !important;

        &.ok {
            background: url('./resources/ecommerce/img/icon-margherita-feedback-ok.svg') no-repeat center rgba($white, 0.8);
        }
    }

    .marchio-conad {
        z-index: 2;
        right: 40px;
        background-color: $white;
        padding: 8px;
        display: flex;
        flex-direction: column;

        .conad-60 {
        }

        img {
            max-width: 70px;
        }

        @media (min-width: $breakpoint-large-2) {
            right: 0;
            img {
                max-width: 150px;
            }
        }
    }

    //sottocosto
    .element-sottocosto {
        .component-PromoBadgeCard{
            margin-right: 4px;
            
            .badge-text {
                font-size: 16px;
                line-height: 1;
                padding: 8px;
                font-weight: bold;
                border-radius: 8px;
                margin-right: 4px;
            }
        }

        img{
            margin-right: 8px;
        }
    }

    //Promozione
    .element-promozione {
        font-size: 16px;
        line-height: 21px;
        color: var(---colour-01-primary-01-p-2);

        b {
            font-size: 14px;
        }
        .link {
            font-size: 14px;
            line-height: 14px;
            color: var(---colour-01-primary-01-p-2);
            text-decoration: none;
            .icon {
                position: relative;
                top: 2px;
            }
        }
    }

    //preferite
    .element-preferite {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        border: none;
        border-top-width: initial;
        box-shadow: none;

        &.promo{
            top: 52px;
        }
    }

    .product-image-container {
        width: 100%;
        @media (max-width: $breakpoint-large-2-max) {
            padding-top: 16px;
        }

        .product-image-content {
            > * {
                margin: 0 auto;
                text-align: center;
            }
        }

        img {
            max-height: 100%;
        }

        .btn-zoom {
            z-index: 2;
            font-size: 20px;
            @include color-primary-black(0.2);
            padding: 3px 5px;
            text-decoration: none;
            bottom: 45px;
        }

        .zoomArea {
            width: 100%;
            overflow: hidden;
            #NZoomContainer {
                width: 100%;
                height: 100%;
            }
        }

        @media (min-width: $breakpoint-large-2) {
            .preview-image-container {
                height: 370px;
                margin-bottom: 10px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                .lds-ring {
                    height: auto;
                    top: 50%;
                    transform: translate(-21px, -21px);
                }

                > * {
                    height: 100%;
                    position: relative;
                    z-index: 1;
                }
            }
        }

        @media (min-width: $breakpoint-xlarge) {
            .preview-image-container {
                height: 385px;
                position: relative;
                overflow: hidden;
            }
        }

        //swiper image product
        .swiper-button-next,
        .swiper-button-prev {
            font-size: 18px;
            padding: 7px;
            margin-top: -16px;
        }

        .swiper-button-next {
            padding-left: 0;
            right: -10px;
        }

        .swiper-button-prev {
            padding-right: 0;
            left: -10px;
        }

        .swiper-product-detail-image {
            padding-bottom: 60px;

            .swiper-wrapper {
                align-items: center;
            }

            .swiper-slide {
                text-align: center;

                img {
                    max-width: 170px;
                }
            }

            .swiper-pagination-bullet {
                margin: 0 10px;
                opacity: 1;
                width: 4px;
                height: 4px;
            }

            .swiper-pagination-bullet-active {
                background: $yellow;
                width: 16px;
                height: 16px;
                margin: -6px 6px;
            }

            @media (min-width: $breakpoint-large-2) {
                padding: 5px;
                width: calc(100% - 66px);
                .swiper-slide {
                    > div {
                        width: 53px;
                        height: 53px;
                        padding: 8px;
                        box-sizing: border-box;
                        cursor: pointer;
                        border-radius: 12px;
                        box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
                    }

                    &.active {
                        > div {
                            box-shadow:
                                            0 0 5px 0px var(---dusty-orange),
                                            0 0 0px 4px rgba($dusty-orange, 0.15) inset;
                        }
                    }

                    &.slide-video {
                        > div {
                            position: relative;

                            &:after {
                                background: url('./resources/ecommerce/img/icon-play-bg.svg') no-repeat center;
                                content: '';
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                display: block;
                            }
                        }
                    }

                    img {
                        max-width: 100%;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
            }
        }

        @media (min-width: $breakpoint-large-2) {
            max-width: 370px;
            //.swiper-product-detail-image {
            //    .swiper-slide {
            //        img {
            //            max-width: 80%;
            //        }
            //    }
            //}
        }

        @media (min-width: $breakpoint-xlarge) {
            max-width: 450px;
            > * {
                padding-right: 40px;
            }
            //position: inherit !important;
        }

        @media (min-width: $breakpoint-xxlarge) {
            max-width: 580px;
        }
    }

    .product-summary-button-container{
        margin-bottom: 32px;

        @media (min-width: $breakpoint-large-2){
            display: flex;
            justify-content: space-between;
        }
    }

    .product-summary {
        @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
            flex-direction: row;
            justify-content: space-between;
            > *:last-child {
                text-align: right;
            }
            .product-price {
                justify-content: flex-end;
            }
        }

        .product-summary-2nd-child{
            @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max){
                display: flex;
                flex-direction: column;
                .component-PromoBadgePotentialDiscount{
                    align-self: flex-end;
                }
            }

            @media (min-width: $breakpoint-large-2){
                margin-top: auto;
            }
        }
    }

    .product-title {
        margin-top: 0;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.25;

        @media (min-width: $breakpoint-large-2) {
            margin-top: 32px;
        }
    }

    .badge-container{
        margin-bottom: 16px;

        @media (min-width: $breakpoint-xlarge){
            margin-bottom: 38px;
        }
    }

    .product-data-container .product-quantity {
        font-weight: 500;
        line-height: 1;
        color: rgba($black, 0.6);
        margin-bottom: 16px;

        .price {
            color: rgba($black, 0.6);;
            font-size: 14px;
            margin-top: 8px;
        }
    }

    .product-fidelity {
        padding: 6px 8px;
        border-radius: 8px;
        background: var(---effects-02-darker-05-2);
        display: inline-flex;
        align-items: center;

        [class*='icon-'] {
            margin-right: 8px;
        }

        .price {
            color: $black;
            font-size: 12px;
            font-weight: bold;
            line-height: 1;

            &.sale {
                margin-left: 2px;
                color: var(---colour-01-primary-01-p-2);
            }
        }
    }

    .product-weighty {
        font-weight: 500;
        font-size: 12px;
        color: rgba($black, 0.6);
        margin-top: 16px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;

        [class*='icon-'] {
            font-size: 16px;
            margin-right: 5px;
        }

        &-info {
            margin-bottom: 16px;
            font-weight: 500;
            display: flex;
            align-items: center;

            [class*='icon-'] {
                background-color: rgba($black, 0.4);
                margin-right: 6px;
            }
        }
    }

    .product-promo {
        font-family: "DM Sans";
        
        & > * {
            color: var(---colour-01-primary-01-p-2);
            font-size: 14px;
            line-height: 1.43;
            margin-bottom: 4px;
        }

        .promo-target{
            font-weight: bold;
        }

        .prezzo-tagliato{
            text-decoration: line-through;
        }
    }

    // .product-data-container {
    //     padding-left: 0;
    // }

    .product-data-container .product-price {
        margin: 0 0 24px 0;

        > *:first-child {
            font-size: 32px;
            font-weight: 500;
            @extend %roboto-font;
            display: flex;
            align-items: center;
            color: var(---colour-01-primary-01-p-2);
        }

        [class*='icon-'] {
            color: inherit;
            font-size: 34px;
            margin-right: 8px;
        }

        .old {
            @include color-primary-black;
            font-size: 16px;
            position: relative;
            top: -4px;
            margin-right: 6px;
        }

        .price-note {
            font-size: 12px;
            line-height: 1.33;
            color: $primary-black;
            margin-top: 5px;
        }

        .black{
            @include color-primary-black;
        }

        @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
            > *:first-child {
                justify-content: flex-end;
            }
        }
    }

    .product-button-container {
        position: relative;
        margin-bottom: 24px;
        flex-direction: column;

        .fixed-button-add-cart {
            animation: 240ms ease 0s 1 normal none running animation-btn-add-cart;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: 1001;
            border-radius: 0;
            background-color: $white;

            > * {

                padding: 16px;

                > span {
                    color: $black;
                    font-size: 28px;
                    font-weight: 500;
                    line-height: 1.29;
                }
            }

            .uk-button {
                width: auto;
                font-size: 14px;
                line-height: 40px;
            }
        }

        .uk-alert-warning {
            .uk-alert-close {
                position: unset;
            }
        }

        .alert-heavy-product,
        .alert-contingency-product,
        .alert-promo,
        .alert-out-of-stock, .alert-out-of-stock-google {
            position: absolute;
            transform: translateY(-100%);
            margin: 0;
            top: -5px;

            @media (min-width: $breakpoint-large) and (max-width: $breakpoint-large-2-max) {
                width: unset;
                left: 0;
                right: 0;
            }
        }

        @media (min-width: $breakpoint-large-2) {
            max-width: 304px;
            width: 100%;
            margin-bottom: 0;
            .fixed-button-add-cart {
                display: none !important;
            }
        }

        @media (min-width: $breakpoint-large){
            flex-direction: row;
            justify-content: flex-end;
        }
        @media (min-width: $breakpoint-large-2){
            flex-direction: column;
        }
        @media (min-width: $breakpoint-xxxlarge) {
            max-width: 350px;
        }
        /* @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
            flex-direction: row;
        } */
    }

    .product-preparation-notes {
        width: 100%;
        @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
            margin-right: 8px;
        }
    }

    .product-button-add {
        position: relative;

        &:disabled {
            color: $primary-black !important;
        }

        .button-cart-add {
            position: relative;
            .lds-ring {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .uk-button {
            line-height: 56px;
            font-size: 16px;
            width: 100%;
        }

        @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
            margin-top: auto;
        }

        @media (min-width: $breakpoint-large) {
            max-width: 408px;
            width: 100%;
        }

        .var-weight-menu {
            flex-direction: column;
            justify-content: space-between;
            border-radius: 12px;
            background-color: $white;
            border: 2px solid $primary-orange;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: auto;
            z-index: 2;

            &-header {
                justify-content: center;
                position: relative;
                min-height: 16px;
                margin-top: 8px;
                margin-bottom: 44px;

                &-title {
                    position: absolute;
                    left: 16px;
                    font-size: 16px;
                    font-weight: bold;
                }

                .var-weight-menu-close {
                    position: absolute;
                    right: 13px;
                    padding-top: 3px;
                }
            }

            &-content {
                margin-bottom: 36px;

                .weight-list {
                    li {
                        display: block;

                        > * {
                            display: block;
                            margin: 0px auto;
                            max-width: 129px;
                            width: 100%;
                        }

                        &.active {
                            background-color: transparent;
                            border: none;
                            color: var(---colour-01-primary-03-p-2);
                        }

                        &.active-non-selectable {
                            pointer-events: none;
                        }

                        .uk-tag.disabled {
                            pointer-events: none;
                        }

                        .uk-tag *:last-child {
                            color: $primary-black;
                        }
                    }
                }
            }

            .alert-variable-weight {
                margin: 0 8px 8px 8px;
            }
        }

        /* Copiato dal product card */
        .add-quantity {
            padding: 8px;
            background-color: var(---colour-02-secondary-01-s-2);
            border-radius: 12px;
            position: relative;

            .lds-ring {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                text-align: center;
                background-color: rgba($white,0.7);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-button {
                width: 40px;
                padding: 0;
                height: 40px;
                line-height: 40px;
            }

            .total {
                .quantity {
                    @include color-primary-black(0.8);
                }

                .price {
                    font-size: 12px;
                    @include color-primary-black(0.9);

                    .price-old{
                        margin-right: 4px;
                        text-decoration: line-through;
                    }
                }
            }
        }
    }

    .product-other-info {
        .uk-accordion {
            > * {
                border-bottom: 1px solid #e6e6e6;

                &:nth-child(n + 2) {
                    margin: 0;
                }
            }

            &-title {
                font-size: 14px;
                font-weight: 500;
                padding: 13px 0;

                &:before {
                    font-size: 26px;
                    margin-top: -6px;
                }
            }

            &-content {
                margin: 5px 0 10px;
            }
        }

        .wysiwyg_editor {
            font-size: 12px;
            line-height: 16px;
            @include color-primary-black(0.6);

            .no-margin-top {
                margin-top: 0;
            }

            .no-margin-bottom {
                margin-bottom: 0;
            }

            .html-esb-field {
                *:first-child {
                    margin-top: 0;
                }
                table {
                    tr {
                        th,td {
                            min-width: 100px;
                            max-width: 250px;
                        }
                        th:first-child,
                        td:first-child {
                            min-width: 90px;
                            @media (min-width: $breakpoint-large) {
                                min-width: 160px;
                            }
                        }
                    }
                }
            }

            ul {
                list-style: disc;
            }

            .certifications {
                display: flex;
                flex-wrap: wrap;

                & > img {
                    max-height: 48px;

                    &:not(:last-child) {
                        margin-right: 4px;
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }

    .filter {
        margin: 0 0 8px;
        width: 100%;
        font-weight: 500;
        @include color-primary-black(0.8);

        &-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 2px;
            line-height: 23px;
        }

        &.showAlert {
            margin: 24px 0;

            @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
                margin: 0;
            }

            @media (min-width: $breakpoint-large-2) {
                margin: 8px 0 60px;
            }
        }

        &.uk-disabled {
            border: 1px solid var(---effects-01-opacity-10-2);
            color: var(---effects-01-opacity-10-2);

            .arrow {
                @include color-primary-black(0.1);
            }
        }

        .arrow {
            @include color-primary-black(0.8);
        }

        &[aria-expanded='true'] {
            border-radius: 16px 16px 0 0;
        }

        &-dropdown {
            border-radius: 0 0 16px 16px;
            box-shadow: none;
            top: 67px !important;
            left: 0 !important;
            width: 100% !important;
            min-width: inherit;
            border-top: none;
            padding-top: 0;
            margin-top: -20px;

            .norequire {
                font-size: 12px;
                font-weight: 500;
                line-height: 1;
                margin-top: -3px;
                margin-bottom: 15px;
                display: block;
                @include color-primary-black(0.2);
            }
        }

        @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
            margin-bottom: 0;
        }
    }

    /* .single-choice-radio {
        label {
            margin: 0 0 16px !important;
            display: block;
        }

        .free-note {
            margin-left: 8px;
            padding-left: 0;
            font-weight: 500;
            font-size: 14px;
            color: rgba($black, 0.8);
        }

        .uk-radio:not(:checked) {
            & + .free-note {
                opacity: 0.5;
            }
        }
    } */

    //.swiper-product-detail-image {
    //    .swiper-slide {
    //        &.slide-video {
    //
    //        }
    //        > div {
    //            width: 53px;
    //            height: 53px;
    //            padding: 8px;
    //            box-sizing: border-box;
    //            border: solid 4px var(---02-effects-03-lighten-90-2);
    //            border-radius: 12px;
    //            border-radius: 0 0 5px -2px var(---dusty-orange);
    //        }
    //        img {
    //            max-width: 100%;
    //            width: 100%;
    //            height: 100%;
    //            object-fit: cover;
    //            object-position: center center;
    //        }
    //    }
    //}

    .alert-lavorazione {
        margin-top: 32px;

        .number {
            color: $blue-info;
        }
    }

    .product-type {
        margin-bottom: 30px;
        @media (max-width: $breakpoint-large-2-max) {
            .element-sottocosto,
            .element-promozione,
            .discount,
            .yellow-promo {
                min-height: 32px;
            }
        }

        @media only screen and (min-width: $breakpoint-large-2) {
            &.no-promo-margin {
                .product-title {
                    margin-top: 56px;
                }
            }
        }

        &.no-promo {
            .element-promozione {
                visibility: hidden;
            }

            @media only screen and (min-width: $breakpoint-large-2) {
                .element-promozione {
                    display: none;
                }
            }
        }

        /* &.no-sottocosto {
            .element-sottocosto {
                visibility: hidden;
            }

            @media only screen and (min-width: $breakpoint-large-2) {
                .element-sottocosto {
                    display: none;
                }
            }
        } */

        &.no-promo-yellow {
            .promo-yellow {
                visibility: hidden;
            }

            @media only screen and (min-width: $breakpoint-large-2) {
                .promo-yellow {
                    display: none;
                }
            }
        }

        /* &.no-discount {
            .discount {
                visibility: hidden;
            }

            @media only screen and (min-width: $breakpoint-large-2) {
                .discount {
                    display: none;
                }
            }
        } */

        .product-price {
            @media only screen and (min-width: $breakpoint-large-2) {
                // margin: 0 0 24px 0;
                margin: 0;
            }
        }

        .base-product {
            .product-promo,
            .old,
            .icon-fidelity-double,
            .product-quantity .price > *:last-child,
            .product-fidelity {
                display: none;
            }

            .product-price > *:first-child,
            .product-price {
                @include color-primary-black;
            }

            .product-quantity .price > *:first-child {
                color: rgba($black, 0.6);
            }
        }

        .product-weighty-info,
        .product-weighty,
        .product-price .price-note {
            display: none;
        }

        .var-weight {
            /* .product-weighty, .product-price .price-note {
                display: none;
            } */

            .product-weighty-info {
                display: block;
            }
        }

        .incremental-weight {
            /* .product-weighty {
                display: none;
            } */

            .product-weighty-info,
            .product-price .price-note {
                display: block;
            }
        }

        .heavy-weight {
            /* .product-weighty-info, .product-price .price-note {
                display: none;
            } */

            .product-weighty {
                display: block;
            }
        }

        .promo-yellow,
        .discount {
            font-size: 16px;
        }

        .discount {
            margin-right: 16px;
            min-height: 32px;
            min-width: 60px;
        }

        .promo-yellow {
            font-weight: bold;
            height: min-content;
            margin-right: 16px;

            .icon-delivery {
                margin-right: 4px;
            }
        }

        /* Promo */
        .product-summary {
            position: relative;

            .alert-heavy-product,
            .alert-contingency-product,
            .alert-promo,
            .alert-out-of-stock, .alert-out-of-stock-google {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .contingency-pz{
                font-size: 12px;
                color: rgba($black, 0.6);
            }
        }
    }

    .feedback-overlay {
        z-index: 10;
        animation: 1900ms linear 0s 2 alternate forwards running animation-feedback-overlay;
        display: none;
        opacity: 0;

        &-icon {
            display: none;
            animation: 1900ms linear 0s 2 alternate forwards running animation-feedback-add-cart;
        }
    }

    .alert-heavy-product, .alert-contingency-product {
        color: rgba($black, 0.6);
    }

    .alert-heavy-product, .alert-contingency-product, .alert-promo {
        .icon-close {
            text-decoration: none;
            padding: 11px;
            color: $primary-black;
            position: absolute;
            top: auto;
            bottom: auto;
            right: 9px;
            font-size: 16px;
            font-weight: bold;
        }

        & > i.icon-weight{
            background-color: transparent !important;
        }
    }

    .scrollUp {
        display: none !important;
        visibility: hidden;
    }

    @media (max-width: $breakpoint-xlarge-max) {
        .embedded-service .fab {
            transition: all 0.3s ease;

            &.move {
                bottom: 80px;
            }
        }
    }
    .banner-promo-detail {
        height: 30px;
        margin-bottom: 16px;
        background-color: #f6e7ec;
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        color: #9f0839;

        .icon-promo {
            line-height: 1.17;
            margin-left: 8px;
            position: relative;
            width: 16px;
            height: 16px;
        }

        .promo-desc {
            font-weight: bold;
            font-size: 14px;
            line-height: 1;
            margin-left: 5px;
        }

        .tooltip-margin {
            margin-left: 8px;

            .icon-info {
                line-height: inherit;
            }

            .tooltiptext {
                margin: 0 15px 0 15px;
                padding: 12px
            }
        }
    }
}

@keyframes animation-btn-add-cart {
    from {
        //width: 50%;
        //left: calc(50% - 30%);
        transform: scaleX(0.75);
    }
    to {
        //left: 0;
        //width: 100%;
        transform: scaleX(1);
    }
}

@keyframes animation-feedback-overlay {
    0% {
        display: none;
        opacity: 0;
    }

    15% {
        display: flex;
        opacity: 0.8;
    }

    100% {
        display: flex;
        opacity: 0.8;
    }
}

@keyframes animation-feedback-add-cart {
    0% {
        transform: translateY(15%);
    }

    15% {
        transform: translateY(-16%);
    }

    18% {
        transform: translateY(1%);
    }

    21% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}
