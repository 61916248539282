.page-General {
    .general {
        &-title {
            font-size: 32px;
            font-weight: bold;
            line-height: 1.25;

            @media only screen and (min-width: $breakpoint-large) {
                font-size: 40px;
                line-height: 1.2;
            }
        }

        &-desc {
            font-size: 16px;
            color: $primary-black;
            line-height: 1.5;
            width: 100%;
            max-width: 774px;
            margin: 16px 0;
        }

        &-tile-container {
            margin-top: 40px;
            margin-bottom: 56px;
            row-gap: 12px;
            display: grid;
            justify-items: center;
            grid-template-columns: repeat(2, 1fr);

            @media only screen and (min-width: $breakpoint-large) {
                justify-items: unset;
                grid-template-columns: repeat(3, 1fr);
            }
            @media only screen and (min-width: $breakpoint-xlarge) {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        &-paragraph {
            box-sizing: border-box;
            margin: 47px 0 24px;
            width: 100%;

            @media (min-width: $breakpoint-large) {
                margin-bottom: 40px;
            }

            .section-title {
                margin-bottom: 52px;

                &::before {
                    bottom: -16px;
                }
            }

            .section-description {
                max-width: 775px;
                width: 100%;
                color: $primary-black;

                b {
                    @include color-primary-black(0.95);

                    i {
                        visibility: visible;

                        @media (min-width: $breakpoint-large) {
                            visibility: hidden;
                        }
                    }
                }

            }

            @media only screen and (max-width: $breakpoint-xxlarge-max) {
                margin-top: 82px;
                margin-bottom: 40px;
                .section-description {
                    max-width: 826px;
                }
            }

            @media only screen and (max-width: $breakpoint-large-2-max) {
                margin-top: 97px;
                margin-bottom: 48px;

                .section-title::before {
                    bottom: -12px;
                }

                .section-description {
                    max-width: 645px;
                }
            }

            @media only screen and (max-width: $breakpoint-large - 1) {
                margin-top: 67px;

                .section-title {
                    padding-right: 67px;
                    margin-bottom: 43px;
                }

                .section-description {
                    max-width: 343px;
                }

            }

            .section-more {
                margin-top: 14px;
                color: $black;
                align-items: center;

                .title {
                    margin-right: 10px;
                }

                i {
                    font-size: 24px;
                    margin-left: inherit !important;
                }
            }
        }
    }
}