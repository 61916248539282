.component-SpinteMarketing {
    .section-popolari {
        .section-popolari-container {
            display: flex;
            background-color: rgba($wine, 0.95);
            border-radius: 12px;
            flex-grow: 1;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            object-fit: contain;
            padding: 24px 24px 10px;

            @media (min-width: $breakpoint-large-2) {
                padding: 25px 16px 15px;
            }

            .section-body {
                width: 100%;
            }

            .title {
                color: $white;
                font-size: 20px;
                margin: 0;
            }

            .link-popolari {
                font-weight: 500;
                font-size: 14px;
                text-align: right;
                margin-left: auto;
                color: $white;
                margin-top: -27px;
                margin-right: -4px;

                @media (min-width: $breakpoint-large-2) {
                    color: $yellow;
                    margin-top: -22px;
                    margin-right: 1px;
                }
            }
        }

        .swiper-popolari {
            @media (min-width: $breakpoint-large-2) {
                padding-top: 0;
                padding-bottom: 0;
                margin-top: 0;
                .swiper-slide {
                    > * {
                        /* 40px del margine tra le card */
                        max-width: calc((100% * 1 / 3.001) - 40px);
                        width: 100%;
                    }
                }
            }
        }

        .swiper-wrapper {
            display: flex;
            flex-direction: row;
            padding-top: 9px;

            @media (min-width: $breakpoint-large-2) {
                flex-direction: column;
                padding-top: 11px;
            }
        }

        .swiper-slide {
            width: 100%;

            @media (min-width: $breakpoint-large-2) {
                display: flex;
                justify-content: space-between;
                padding-top: 4px;
            }

            .component-ProductCardLastView {
                padding: 13px 0;
                border-bottom: 1px solid $white;

                @media (min-width: $breakpoint-large-2) {
                    padding: 12px 0;
                    border-bottom: none;
                }

                span {
                    color: $white !important;
                }

                .product {

                    color: $white !important;

                    &-card {
                        box-shadow: none;
                        padding: 5px 0;

                        @media (min-width: $breakpoint-large-2) {
                            padding: 0;
                        }
                    }

                    .product-img {
                        min-width: 80px;
                        min-height: 80px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 12px;
                        background-color: $white;
                        overflow: hidden;

                        img {
                            max-height: 80px;
                            max-width: 80px;
                        }
                    }

                    .product-description-container {
                        margin-left: 8px;

                        .description {
                            min-width: 0;
                            color: $white;
                        }
                    }

                    .feedback-overlay-pcard {
                        background-color: rgb(165, 29, 67, .6);
                    }
                }

                .icon-container {
                    margin-right: 10px;
                    margin-top: -5px;
                    color: $white;

                    @media (min-width: $breakpoint-large-2) {
                        margin-right: 15px;
                        margin-top: 0;
                    }

                    [class*='icon-'] {
                        color: $white;
                    }

                    .manage-preferite-product {
                        padding: 0 0 7px 6px;
                        margin-right: 2px;
                        font-size: 16px;
                    }

                    .add-cart {
                        box-shadow: 0 0 0 2px inset $white;
                        color: $white;

                        .lds-ring {
                            box-shadow: 0 0 0 2px inset $white;
                            background-color: rgba($wine, 0.95);

                            svg {
                                color: $white;
                            }

                        }
                    }
                }
            }

            >div:last-child { 
                .component-ProductCardLastView {
                    border-bottom: none;
                }
            }
        }

        .pagination-popolari {
            bottom: 7px;
            left: 2px;
        }

        .swiper-pagination-bullet {
            margin: 0 4px;
            opacity: 1;
            width: 16px;
            height: 16px;
            position: relative;
            background: transparent;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: '';
                position: absolute;
                width: 4px;
                height: 4px;
                background-color: $white;
                border-radius: 50%;
            }

            &-active {
                &:before {
                    width: 16px;
                    height: 16px;
                    background-color: $yellow;
                }
            }
        }
        .alert-out-of-stock {
            span {
                color: inherit !important;
            }
        }
    }
}