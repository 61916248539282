.component-DotButton {

    .dot-button {
        width: 32px;
        height: 32px;
        border-radius: 90px;
        background-color: rgba(51,51,51,0.8);

        &:before {
            border-radius: 90px;
            content: "";
            display: block;
            position: relative;
            top: 12px;
            left: 12px;
            width: 8px;
            height: 8px;
            background-color: $white;
        }

        &:hover {

            &:before {
                top: 10px;
                left: 10px;
                width: 12px;
                height: 12px;
                background-color: $yellow;
            }

            .dot-card {
                display: block;
            }
        }
    }

    .dot-card {
        display: none;

        position: relative;
        top: -153px;
        left: -47px;
        background-color: white;
        border-radius: 12px;
        width: 114px;
        height: 108px;
        padding: 16px 8px;

        .product {
            @include line-clamp(3);
            font-size: 12px;
            font-weight: 500;
            line-height: 1.33;
            @include color-primary-black();
            text-decoration: underline;
            margin: 0 0 16px;
        }

        .info {
            font-size: 12px;
            font-weight: 500;
            line-height: 1;
            color: rgba(0,0,0,0.4);
            margin: 0 0 8px;
        }

        .price {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;
            @include color-primary-black();
            margin: 0;
        }
    }
}