@import '../../scss/uikit/variables-theme.scss';

.component-CardProdottoOmaggio{

    overflow: hidden;
    box-shadow: 0 1px 1px 0px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
    border-radius: 12px;

    .a-wrapper{
        text-decoration: none;
        color: #333333;
    }
    
    .card-omaggio-container{
        
        box-sizing: border-box;
        padding: 8px;
        height: 155px;
        position: relative;
        z-index: 0;
        @media(min-width: $breakpoint-xlarge){
            height: 110px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 42px;
            padding-left: 23px;
        }
        

        .omaggio{

            &-header{

                &-image{
                    height: 78px;
                    box-sizing: border-box;
                    padding-top: 22px;
                }

                &-title{
                    font-weight: 500;
                    font-size: 16px;
                }

                &-description{
                    margin-top: 4px;
                    font-size: 12px;
                }
                
            }

            &-container-img-mobile{
                position: absolute;
                top: -1px;
                left: -1px;
                z-index: -1;
                max-height: 86px;
                max-width: 111px;
            }

            &-container-img-desktop{
                position: absolute;
                top: 1px;
                left: -1px;
                z-index: -1;
                max-height: 110px;
                max-width: 200px;
            }

            &-description-image{
                display: flex;
                .omaggio{
                    &-image{
                        max-height: 56px;
                        max-width: 56px;
                    }

                    &-description{
                        margin-left: 8px;
                        font-size: 12px;

                        @media(min-width: $breakpoint-xlarge){
                            width: 160px;
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }

            &-gratis{
                position: absolute;
                bottom: 8px;
                right: 8px;
                font-weight: 500;
                font-size: 16px;
                line-height: 1;
                @media (min-width: $breakpoint-xlarge){
                    position: static;
                }
            }
        }

        
    }

    .omaggio-checkbox{
        font-size: 14px;
        height: 40px;
        box-sizing: border-box;
        padding: 8px 0 8px 18px;
        border-top: 1px solid #e5e5e5;
        .checkmark{
            top: 0 !important;
        }

        label{
            display: inline-block;
            height: 24px;

            span{
                display: flex;
                align-items: center;
            }
        }
    }

    &Checkout{
        overflow: hidden;
        background-color: $white;
        box-shadow: 0 1px 1px 0px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
        border-radius: 12px;

        .card-omaggio-container{
            height: 122px;
            position: relative;
            z-index: 0;
            box-sizing: border-box;
            padding: 22px 13px 11px 8px;

            .omaggio{
                &-container-img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    max-height: 57px;
                    max-width: 78px;
                }

                &-header-title{
                    font-size: 14px;
                }

                &-description-image{
                    position: absolute;
                    top: 57px;
                    display: flex;

                    .omaggio-image{
                        max-height: 42px;
                        max-width: 42px;
                    }

                    .omaggio-description{
                        margin-left: 8px;

                        .description{
                            font-size: 12px;
                            line-height: 1;
                        }

                        .gratis{
                            margin-top: 8px;
                            font-size: 16px;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }

}