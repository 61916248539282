.component-NavigaPromozioni {
    .section-nav-prom {

        margin-bottom: 32px;
        .section-title:before {
            bottom: -16px;
        }

        .offer-card-container {
            display: flex;
            justify-content: flex-start;

            .card-offer {
                margin-right: 16px;

                @media (min-width: $breakpoint-xlarge) {
                    margin-right: 72px;
                }

                &:last-child {
                    margin-right: 0; 
                }

                .component-FlatCategory {
                    border: none;
                    img {
                        width: 88px;
                    }

                    .text {
                        @media (min-width: $breakpoint-xlarge) {
                            font-weight: bold;
                        }  
                    }
                }
            }
        }
    }
}