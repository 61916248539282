@import '../../scss/uikit/variables-theme.scss';

.component-ModalSearchFilter {
    .uk-offcanvas {
        &-title {
            font-size: 20px;
            color: rgba(black, 0.8);
            margin-bottom: 0px;
        }

        &-bar {
            overflow-y: hidden;
            -webkit-overflow-scrolling: auto;
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;

            .uk-offcanvas-header {
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                height: 97px;
                padding-top: 52px;

                span {
                    font-size: 16px;
                }

                .uk-offcanvas-close {
                    top: 2px;
                    right: 2px;
                }

                &.scroll {
                    box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
                }
            }

            @media (min-width: 768px) {
                .uk-offcanvas-header {
                    height: 129px;
                    padding-top: 80px;

                    .uk-offcanvas-close {
                        top: 26px;
                        right: 58px;
                    }
                }
            }
        }

        &-body {
            min-height: 0px;
            position: relative;

            @media only screen and (max-width: $breakpoint-large - 1) {
                padding: 0;
            }

            .filters-container {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 0 4px;
                box-sizing: border-box;
            }
        }
    }
}

//List filters
.list-filters {
    .scroll {
        box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
    }

    .ps__rail-y {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .uk-badge {
        position: relative;
        float: right;
    }

    &.uk-accordion {
        margin-top: -12px;

        > * {
            position: relative;
            border: 1px solid var(---effects-01-opacity-30-2);
            border-radius: 16px;
            font-weight: 500;
            font-size: 17px;

            *.open-filter {
                display: none;
            }

            &.enabled-filter {
                border: solid 1px rgba($black, 0.8);
                .uk-accordion-title {
                    font-weight: bold;
                }
            }

            &.filter-div {
                display: flex;
                flex-direction: column;

                &.uk-open {
                    border: solid 1px rgba($black, 0.8);
                    top: 0;
                    //margin-top: 33px;
                    //height: calc(100vh - 42px);
                    height: calc(100vh - 95px);

                    .uk-accordion-title {
                        font-weight: bold;

                        &.open-filter {
                            display: block;
                            font-weight: 500;
                        }

                        &.closed-filter {
                            display: none;
                        }
                    }
                }
            }

            .uk-accordion-title {
                padding: 16px;

                &:before {
                    font-weight: normal;
                    background: none;
                    font-family: 'icomoon';
                    content: '\e932';
                    transition: all 0.3s ease-in-out;
                }

                &[aria-expanded='true'] {
                    &:before {
                        transform: rotate(180deg);
                    }
                }
            }

            .uk-accordion-content {
                display: flex;
                flex-direction: column;

                font-size: 14px;
                margin-top: 0px;
                min-height: 0px;
                flex-grow: 1;

                .scroll {
                    box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 1px 1px 0 rgba(171, 147, 67, 0.2);
                }

                .box-overflow {
                    min-height: 0px;
                    flex-grow: 1;
                    padding: 0 16px 0 4px;

                    .custom-list {
                        & > * {
                            display: block;
                            margin-top: 16px;
                        }

                        & > *:first-child {
                            margin-top: 0px;
                        }

                        .uk-checkbox-custom {
                            /* margin-left: -3px; */
                            line-height: 25px;

                            .checkmark {
                                width: 16px;
                                height: 16px;
                            }
                        }

                        &.single-choice-radio > * span {
                            margin-left: 8px;
                        }
                    }
                }

                .accordion-footer {
                    display: flex;
                    justify-content: flex-end;

                    padding: 18px 15px;

                    &-shadow {
                        box-shadow:
                            0 -4px 8px -2px rgba(171, 147, 67, 0.15),
                            0 0 1px 0 rgba(171, 147, 67, 0.2);
                    }
                }

                .search-bar {
                    z-index: 3001;
                    padding: 0 16px;

                    .uk-search {
                        margin-bottom: 0;

                        &-icon {
                            color: $black;
                        }

                        &-input {
                            border: 1px solid rgba($black, 0.4);
                            min-width: 100%;
                            @include color-primary-black(0.6);
                            transition: all 0.3s ease-in-out;

                            &:focus {
                                background-color: rgba($yellow, 0.1);
                                border: solid 2px rgba($yellow, 0.6);
                                color: rgba($gray, 0.9);
                            }
                        }

                        .clear-search {
                            background-color: black;
                            color: $white;
                            width: 16px;
                            height: 16px;
                            margin: auto;
                            top: 0;
                            right: 16px;

                            i {
                                font-size: 14px;
                            }
                        }
                    }

                    .hidden-btn-close {
                        display: none;
                    }
                }
                /* Margine custom-list del filtro Marchi */
                .search-bar + .box-overflow {
                    .custom-list {
                        margin-top: 16px;
                        margin-bottom: 16px;
                    }
                }
            }

            .uk-badge-custom {
                position: absolute;
                float: right;
                top: 3px;
                right: 42px;
                box-sizing: border-box;
                min-width: 24px;
                height: 24px;
                padding: 0px;
                border-radius: 50%;
                vertical-align: middle;
                /* 1 */
                background: $primary-orange;
                color: $white;
                font-size: 12px;
                /* 2 */
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }

            a {
                text-decoration: none;
            }
        }
    }

    &.no-subcat {
        padding-left: 0;

        .uk-checkbox-custom {
            width: 100%;
            border: 1px solid var(---effects-01-opacity-30-2);
            border-radius: 16px;
            font-weight: 500;
            font-size: 17px;
            padding: 16px;
            line-height: 16px;

            &.active {
                border: solid 1px rgba($black, 0.8);
                font-weight: bold;
            }

            .checkmark {
                margin-top: 12px;
                margin-right: 13px;
                width: 16px;
                height: 16px;
            }
        }

        .enabled-filter {
            .filter-no-subcat {
                @include color-primary-black();
                font-weight: bold;
            }
        }
    }

    .uk-disabled {
        color: var(---effects-01-opacity-30-2);

        *.uk-accordion-title {
            color: var(---effects-01-opacity-30-2);
        }

        * input {
            pointer-events: none;
        }
    }
}
