
.component-AccordionTimeslotInfo {
    position: relative;
    bottom: -24px;
    z-index: 10001;
    margin: 0 16px 15px;
    min-height: 56px;
    transition: all .3s ease-in-out;
    @media (min-width: $breakpoint-large) {
        position: fixed;
        right: 45px;
        bottom: 132px;
        max-width: 385px;
        width: 100%;
        margin: 0;
    }

    .uk-accordion {
        background-color: $lavander;
        color: $alert-primary-color;
        font-size: 14px;
        border-radius: 12px;
        text-align: left;
        position: absolute;
        bottom: 0;
        width: 100%;
        overflow-y: auto;
        max-height: calc(100vh - 160px);
        margin-bottom: 0;
        &-title {
            text-decoration: none;
            font-size: 14px;
            color: $alert-primary-color;
            font-weight: bold;
            padding: 18px 16px 15px 16px;

            [class^='icon-'] {
                margin-right: 8px;
            }

            &:before {
                font-size: 20px;
                content: "\e911";
            }

            .title-content {
                display: flex;
                align-items: center;
                height: 20px;
            }
        }

        li.uk-open {
            .uk-accordion {
                &-title {
                    padding-bottom: 0;

                    &:before {
                        transform: rotate(225deg);
                    }
                }
            }
        }
    
        hr {
            border: 1px solid $azureish-white;
            margin: 8px 16px;
        }
        .accordion {
            &-first {
                .uk-accordion-title:before {
                    display: none;
                }

                .uk-accordion-content {
                    display: block;
                    margin-top: 0;
                    padding: 2px 48px 15px 40px;
                }
            }
            
            &-second {
                .uk-accordion-title:before {
                    display: none;
                }

                .uk-accordion-content {
                    display: block;
                    margin-top: 0;
                    padding: 2px 48px 15px 40px;
                }

                .part {
                    &-second {
                        margin-top: 16px;
                    }
                }

                .costo-consegna, .minimo-spesa {
                    font-weight: bold;
                }

                .progress-bar {
                    //max-width: 272px;

                    .checkpoint {
                        .uk-badge::before {
                            color: $white;
                        }

                        .label {
                            b {
                                font-size: 10px;
                            }

                            span {
                                font-size: 9px;
                            }
                        }
                    }
                }
            }

        }
    }
    
}