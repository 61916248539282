.component-AreaUtente {
    .section-area-utente {
        background-color: var(---colour-02-secondary-01-s-2);
        padding: 23px 0 8px !important;

        @media (min-width: $breakpoint-xlarge) {
            padding: 40 0 20px !important;
        }

        .swiper-button-utente {
            overflow: auto;
            position: relative;
            z-index: 100;
            list-style: none;
            padding: 10px 10px 10px 10px;
            margin-left: -10px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            @media (min-width: $breakpoint-xlarge){
                width: 80%;
            }
            
            > * {
                padding-left: 16px;

                .uk-tag {
                    margin: 0;
                    padding: 0 24px;
                    white-space: nowrap;

                    &.disabled {
                        opacity: 0.4;
                        cursor: default;
                        pointer-events: none;
            
                        &:hover,
                        &:focus {
                            box-shadow: none;
                            @include color-primary-black;
                        }
            
                        &.remove {
                            > a {
                                cursor: default;
                            }
                        }
                    }
                }

                &.uk-active {
                    .uk-tag {
                        background-color: $white;
                        text-decoration: none;
                        @include color-primary-black;
                        box-shadow: 0 0 10px -2px var(---dusty-orange), 0 0 0 4px inset rgba(243, 137, 51, 0.1);
                        transition: all 0.3ms ease-in-out;
                    }
                }
            }
        }

        .uk-container {
            padding-left: 0;
            padding-right: 0;
        }

        @media (min-width: $breakpoint-large) {
            .uk-container {
                padding-left: 32px;
                padding-right: 32px;
            }
            .swiper-button-utente {
                > *:first-child {
                    padding-left: 0;
                }
            }
        }

        @media (min-width: $breakpoint-xlarge) {
            padding-top: 48px;
            padding-bottom: 0;
            .uk-container {
                padding-left: 40px;
                padding-right: 40px;
            }
        }

        .button-container {
            display: flex;
            flex-direction: row;
            margin-left: 2px;
            margin-top: 12px;

            @media (min-width: $breakpoint-xlarge) {
                margin-top: 4px;
            }

            .swiper-slide {
                border: none;
                width: auto;
            }

            .button {
                a {
                    padding: 0 24px;
                    margin-right: 0;
                }
            }
        }

        .section-body {
            .swipers-container {
                margin: 0;
                padding: 0;

                .swiper-empty {
                    margin: 16px 0 47px;
                    padding: 67px 36px 83px;
                    text-align: center;
                    background-color: $white;

                    @include color-primary-black(1);
                    border-radius: 12px;

                    i {
                        font-size: 24px;
                    }

                    .sub-title {
                        margin: 24px 0 8px;
                        font-size: 24px;
                        font-weight: bold;
                        line-height: 1.33;
                    }

                    .sub-description {
                        color: $black;
                        margin: 0;
                        font-size: 16px;
                        font-weight: normal;
                        line-height: 1.5;
                    }

                    &.accedi {
                        padding: 32px 40px 52px;

                        .icon-container {
                            i {
                                @include color-primary-black(0.2);

                                &:not(:first-child) {
                                    margin-left: 24px
                                }
                            }
                        }


                        .sub-description {
                            padding-bottom: 50px;
                        }

                        a {
                            text-decoration: none;
                        }

                        .button-sblocca-area {
                            padding: 20px 32px;
                            color: $primary-orange;
                            border: 2px solid $primary-orange;
                            text-decoration: none;
                            font-weight: bold;
                            border-radius: 12px;
                            display: flex;
                            align-items: center;
                            margin: 0 auto;
                            width: fit-content;

                            i {
                                color: $primary-orange;
                                font-size: 16px;
                                margin-left: 8px;
                            }

                            span {

                                &.icon-unlocked {
                                    margin-left: 8px;
                                }

                                &::before {
                                    color: $primary-orange;
                                }
                            }
                        }
                        
                    }
                }

                .swiper-utente {
                    padding-bottom: 15px;
                    padding-left: 16px;
                    padding-right: 16px;
                    @media (min-width: $breakpoint-large) {
                        padding-bottom: 23px;
                        padding-left: 0;
                        padding-right: 0;
                        .swiper-wrapper {
                            margin-right: 1px;
                        }
                    }
                    @media (min-width: $breakpoint-xlarge) {
                        margin-top: -65px;
                    }

                    .next-utente {
                        top: 16px;
                        right: 2px;
                        text-decoration: none;
                        &:hover {
                            color: $black;
                        }
                        @media (min-width: $breakpoint-xlarge) {
                            top: 19px;
                        }
                    }

                    .prev-utente {
                        top: 16px;
                        right: 36px;
                        left: unset;
                        text-decoration: none;
                        &:hover {
                            color: $black;
                        }
                        @media (min-width: $breakpoint-xlarge) {
                            top: 19px;
                        }
                    }

                    .pagination-utente {
                        top: 12px;
                        right: 66px;
                        left: unset;
                        bottom: unset;
                        width: auto;
                        color: $gray;
                        font-weight: 500;
                        @media (min-width: $breakpoint-xlarge) {
                            top: 15px;
                        }
                    }

                    .scrollbar-utente {
                        width: 72%;
                        height: 4px;
                        left: 16px;
                        bottom: 24px;

                        @media (min-width: $breakpoint-large) {
                            left: 0;
                        }

                        @media (min-width: $breakpoint-xlarge) {
                            width: 49%;
                            left: 25.5%;
                            bottom: 30px;
                        }
                    }

                    .swiper-wrapper {
                        padding: 0 0 15px;

                        @media (min-width: $breakpoint-xlarge) {
                            padding: 55px 0 14px;
                        }

                        .swiper-slide {
                            .card-container {
                                padding: 16px 0;
                                background-color: $white;
                            }

                            &:not(:last-child){
                                .component-CardSuggest{
                                    .section-body.product{
                                        border-right: 1px solid rgba($black, 0.1);
                                    }                                
                                }
                            }

                            .component-CardSuggest {
                                
                                
                                .manage-preferite-product {
                                    margin: -6px 14px 0 0;

                                    @media (min-width: $breakpoint-xlarge) {
                                        margin: -6px 10px 0 0;
                                    }
                                }

                                .button-add {
                                    margin-top: 30px;
                                }
                            }
                        }
                    }
                }
            }

            .link-container {
                text-align: right;
                margin: 0;
                padding: 0;
                z-index: 5;
                float: right;
                width: auto;
                background-color: var(---colour-02-secondary-01-s-2);
                position: relative;

                .link-area-utente {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.33;
                    color: $blue-info;
                    padding: 5px 5px;
                    display: inline-block;
                }

                @media (min-width: $breakpoint-xlarge) {
                    bottom: 2px;
                    left: 5px;
                }
            }
        }

        .lds-ring-4{
            margin-bottom: 20px;
        }
    }
}