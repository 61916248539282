.component-ModalFatturazioneElettronica {
    .uk-offcanvas {

        &-bar {
            max-width: 100%;
            //text-align: -webkit-center;
        }

        &-header {
            padding: 0px;
        }

        &-title {
            font-size: 24px;
            font-weight: bold;
            margin-top: 72px;
            margin-bottom: 16px;
            text-align: center;
        }

        &-body {
            width: 100%;
            height: calc(100vh - 178px);
            padding: 0 16px;
            box-sizing: border-box;
            position: relative;

            hr {
                margin: 8px 0;
                height: 0px;
                border: solid 1px var(---effects-01-opacity-30-2);
            }

            .blocco-form {
                text-align: start;
            }

            .box-overflow {
                position: relative;
                width: 100%;
                height: 100%;
                //overflow-y: auto;
                padding: 0 0;
                box-sizing: border-box;

                &-container {
                    padding-left: 38px;

                    @media (max-width: $breakpoint-large) {
                        padding-left: 12px;
                    }
                }

                &.scroll-container{
                    max-width: 625px;
                    margin: auto;
                }
            }

            .form-container {
                .form-title {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.5;
                    @include color-primary-black(0.6);
                    text-align: center;

                    &:not(:first-child) {
                        margin-top: 24px;
                    }
                }

                .fattura {
                    margin-bottom: 16px;

                    .label {
                        font-size: 14px;
                        font-weight: bold;
                    }
                }

                .uk-switch {
                    $wdt: 40px;
                    $hgt: 24px;

                    width: $wdt;
                    height: $hgt;

                    @media (max-width: $breakpoint-large) {
                        width: $wdt;
                        height: $hgt;
                    }

                    &-slider {
                        border: 2px solid var(---effects-01-opacity-30-2);
                    }

                    input {
                        &:not(:checked)+.uk-switch-slider {
                            &:before {
                                @media (min-width: 10px) {
                                    background-color: var(---effects-01-opacity-30-2);
                                    width: 17px;
                                    height: 17px;
                                    left: 2px;
                                    bottom: 2px;
                                }
                            }

                            &:after {
                                @media (min-width: 10px) {
                                    top: 0px;
                                    left: 3.5px;
                                    font-size: 14px;
                                }

                                @media (min-width: $breakpoint-large) {
                                    top: -2px;
                                    left: 2px;
                                    font-size: 16px;
                                }
                            }
                        }

                        &:checked+.uk-switch-slider {
                            border-color: var(---effects-01-opacity-80-2);
                            background-color: white !important;

                            &:before {
                                @media (min-width: 10px) {
                                    background-color: var(---effects-01-opacity-80-2);
                                    width: 17px;
                                    height: 17px;
                                    left: 5px;
                                    bottom: 2px;
                                }

                                @media (min-width: $breakpoint-large) {
                                    left: 2px;
                                }
                            }

                            &:after {
                                @media (min-width: 10px) {
                                    color: white;
                                    top: 0px;
                                    right: 3.5px;
                                    font-size: 14px;
                                }

                                @media (min-width: $breakpoint-large) {
                                    top: -1.5px;
                                    right: 2px;
                                    font-size: 16px;
                                }
                            }
                        }

                        &[disabled]+.uk-switch-slider {
                            opacity: 0.3;
                        }
                    }
                }

                .form-subtitle {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.43;
                    margin-bottom: 13px;
                    text-align: center;
                    @include color-primary-black(0.6);
                }

                .uk-flex {
                    >[class*="form-"] {
                        +[class*="form-"] {
                            margin-left: 12px;
                        }
                    }

                    @media (max-width: $breakpoint-small-max) {
                        &.uk-child-width-1-2 {
                            flex-direction: column;

                            >* {
                                width: 100%;
                                margin-left: 0 !important;
                            }
                        }
                    }
                }

                .form-surname {}

                .form-address {}

                .form-cap {
                    max-width: 165px;
                }

                .form-provincia {
                    max-width: 193px;
                }

                .uk-checkbox-custom {
                    display: flex;
                    line-height: 25px;
                    margin-top: 30px;

                    .label {
                        @include color-primary-black();
                    }
                }

                .uk-button {}
            }
        }

        &-footer {
            text-align: center;
            box-shadow: -2px 6px 8px -2px rgba(171, 147, 67, 0.15), -5px 0 9px 0 rgba(171, 147, 67, 0.2);
        }
    }

    .select-native-ui {

        select {
            font-size: 17px;
            font-weight: 500;
            background-size: 36px;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2012%208%206%2016%206%22%20%2F%3E%0A%3C%2Fsvg%3E%0A");
        }

        select:not(:invalid) {
            line-height: 42px;
            padding: 12px 14px 0px;
        }

        select.uk-form-danger {
            border-color: $form-danger-border;
        }

        select:invalid {
            color: rgba(0, 0, 0, 0.6);
        }

        option:first-child:invalid {
            display: none !important;
        }
    }

    .selection-invoice-type {
        padding-bottom: 24px;

        .title {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
            margin-bottom: 16px;
            text-align: center;
        }

        .select-custom {
            width: 100%;
            max-width: 395px;
            padding: 18px 50px 0 16px;
            line-height: 54px;
        }

        .js-select-custom .options {
            min-height: auto;
        }
    }

    @media (min-width: $breakpoint-xlarge) {
        .uk-offcanvas {
            &-title {
                font-size: 24px;
            }

            &-body {
                padding: 0 200px;
                height: calc(100vh - 220px);

                >.box-overflow {
                    padding: 0 16px;
                    margin-left: -12px;
                }
            }
        }
    }

    &List {
        .uk-offcanvas {
            &-title {
                font-size: 20px;
                margin-top: 33px;
                margin-bottom: 8px;
            }

            &-body {
                height: calc(100vh - 178px);
                padding: 10px 0 0;
                position: relative;

                .box-overflow {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    //overflow-y: auto;
                    padding: 0 0;
                    box-sizing: border-box;
                }
            }
        }

        .list-billing-address-user {
            >* {
                position: relative;
                padding-bottom: 33px;
                border-bottom: 1px solid #e6e6e6;

                &:nth-child(n + 2) {
                    margin-top: 23px;
                }
            }

            .uk-radio {
                margin-right: 5px;
            }

            .name {
                font-size: 14px;
                font-weight: bold;
                @include color-primary-black();

                .uk-badge {
                    color: $primary-orange;
                    background-color: rgba($primary-orange, 0.1);
                }
            }

            .address {
                margin-top: 15px;
                font-size: 16px;
                line-height: 1.5;
            }

            .note {
                color: $primary-black;
                margin-top: 5px;
            }

            .edit {
                font-size: 14px;
                font-weight: 500;
                bottom: 11px;
            }
        }

        @media (min-width: $breakpoint-large) {
            .uk-offcanvas {
                &-title {
                    font-size: 24px;
                }

                &-body {
                    padding: 0 56px;
                    height: calc(100vh - 220px);

                    .box-overflow {
                        padding: 0 16px;
                        margin-left: -12px;
                    }
                }
            }
        }

        @media (max-width: 335px) {
            .uk-offcanvas-footer {
                .uk-button:not(.uk-button-large) {
                    font-size: 12px;
                }
            }
        }
    }
}

.browser-type {
    &__ios {

        //&.device_tablet {
        //    .uk-offcanvas {
        //        &-body {
        //            height: calc(100vh - 240px);
        //        }
        //    }
        //}
        &.browser_safari {
            .component-ModalFatturazioneElettronica {
                .uk-offcanvas {
                    &-body {

                        /* ----------- iPhone 6, 6S, 7 and 8 ----------- */
                        /* Portrait and Landscape */
                        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
                            height: calc(100vh - 223px);
                        }

                        @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
                            height: calc(100vh - 184px);
                        }

                        @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
                            height: calc(100vh - 184px);
                        }
                    }
                }
            }
        }
    }
}