@import '../../scss/uikit/variables-theme.scss';

.component-PreHeader {
    position: relative;
    background-color: $cosmic-latte;
    padding: 8px 0;
    font-size: 12px;
    font-weight: normal;
    z-index: 1100;
    @include color-primary-black;
    box-shadow: -2px 6px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);

    /* .pre-header {
        &-standard {
            display: none;
        }

        &-undo {
            display: flex;
        }
    } */

    .order-receive {
        white-space: nowrap;

        i {
            margin-right: 5px;
        }

        .label {
            font-weight: bold;
        }
    }

    .divider {
        height: 24px;
        margin: 0 16px;
    }

    .manage {
        font-size: 12px;
        font-weight: bold;
        padding: 0 13px;
        margin-right: -13px;
        line-height: 38px;
        position: relative;
        text-decoration: underline;
        .lds-ring {
            color: var(---colour-03-semantic-04-inform-2);
            background-color: $cosmic-latte;
        }
    }

    .delivery-address {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 55%;

        @media (min-width: $breakpoint-large-2) and (max-width: 1130px) {
            max-width: 42%;
        }

        .address {
        }
    }

    .availability {
        line-height: 1;
        
        &-label {
            margin-right: 3px;
            i {
                display: none;
            }
        }

        .hour {
            font-weight: bold;
        }
        .icon-info {
            margin-left: 8px;
        }
        .tooltiptext {
            .info p:last-child {
                margin-bottom: 5px;
            }
        }

        @media (min-width: $breakpoint-xlarge){
            line-height: 38px;
        }

        &.js-availability-esaurita-html{
            line-height: 38px;
        }
    }

    @media (min-width: 365px) {
        font-size: 14px;
        .manage {
            font-size: 14px;
        }
    }

    @media (min-width: $breakpoint-small) {
        font-size: 14px;
        .manage {
            font-size: 16px;
        }
    }

    @media (min-width: $breakpoint-large-2) {
        z-index: 1102;
        box-shadow: none;
        .manage {
            font-size: 14px;
            font-weight: 500;
        }
    }

    @media (min-width: $breakpoint-xlarge) {
        .uk-container {
            padding-left: 24px;
            padding-right: 24px;
        }
        .delivery-address {
            .delivery {
                margin-right: 4px;
            }
        }
        .manage {
            padding: 0 13px;
            margin-right: -13px;
        }
        .availability {
            &-label {
                font-weight: 500;
                i {
                    display: inline-block;
                }
            }
        }
    }

    @media (min-width: $breakpoint-xxlarge) {
        .order-receive i {
            font-size: 16px;
        }
        .availability-label i {
            font-size: 16px;
            top: 1px;
            position: relative;
        }
    }

    @media (min-width: 1424px) {
        .availability {
            .manage {
                margin-right: 0;
            }
        }
    }

    @media (max-width: $breakpoint-xlarge-max) {
        .pre-header-standard {
            line-height: 38px;

            .order-receive,
            .delivery-address,
            .uk-divider-vertical {
                display: none;
            }

            .availability {
                margin-left: 0 !important;
                width: 100%;
                .manage {
                    margin-left: auto;
                }
                .tooltiptext-drop {
                    left: 15px !important;
                }
            }
        }

    }

    .pre-header-undo {
        justify-content: space-between;

        .text {
            max-width: 50%;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.43;
        }

        .undo-changes {
            font-size: 14px;
            font-weight: bold;
            padding: 13px 24px;
            line-height: 1;
        }
    }

    @media (max-width: $breakpoint-xlarge-max) {
        position: fixed;
        top: 74px;
        left: 0;
        right: 0;
        transition: all .3s ease-in-out;

        &.timeslot-non-disponibili{
            top: 127px !important;
            
            &.scrolling_down {
                top: 74px !important;
                transition-delay: .3s;
            }
    
            &.scrolling_up {
                top: 127px !important;
            }
        }
    }
}
