@import '../../scss/uikit/variables-theme.scss';

.page-Search {
    @include color-primary-black;

    h1 {
        font-size: 28px;
        margin-bottom: 27px;
        margin-top: 0;

        & + .label-forse-cercavi  {
            margin-top: -12px;
        }

    }

    .suggest-label {
        background-color: #f8f6ef;
        padding-bottom: 65px;
        .suggest-title {
            padding-top: 33px;
            padding-left: 47px;
            margin-bottom: 33px;
            .icon-search {
                font-size: 24px;
            }
            .ricerche {
                font-size: 28px;
            }
        }
        .suggest-list {
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;
            flex-direction: column;
            @media (min-width: $breakpoint-xlarge) {
                flex-direction: row;
            }
            padding-left: 47px;
            font-size: 20px;
            font-weight: bold;
            a {
                margin-right: 40px;
            }
        }
    }

    .no-result {
        //min-height: 420px;

        .lens {
            margin-bottom: 24px;
        }
    }

    .col-main {
        margin-top: 0;

        .divider {
            margin-top: 0;

            &:before {
                background-color: var(---colour-02-secondary-01-s-2);
                height: 2px;
            }
        }

        .results {
            font-size: 14px;
        }

        .filter-sort {
            i {
                margin-right: 4px;
            }

            .filter {
                @include color-primary-black;
                font-weight: 500;
            }
        }

        /* .product-results {
            .component-ProductCard {
                min-width: 100%;
                width: 100%;
                margin: 8px 0;
            }
        } */

        .uk-pagination {
            a {
                text-decoration: none;
            }

            .uk-pagination-previous,
            .uk-pagination-next {
                @include color-primary-black;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    @media (min-width: $breakpoint-large) {
        .col-main {
            .results-info {
                margin-bottom: 42px;
            }

            /* .product-results {
                .component-ProductCard {
                    max-width: 47%;
                    margin: 8px;
                    min-width: inherit;
                }
            } */
        }
    }

    @media (min-width: $breakpoint-xlarge) {
        .col-main {
            .results-info {
                margin-bottom: 12px;

                .results {
                    margin-left: auto;
                }
            }
        }
    }
}
