.component-ModalCheckoutAddressDelivery {
    .uk-offcanvas-bar {
        max-width: 100%;
        .uk-offcanvas-header {
            
            .uk-offcanvas-title {
                margin-left: auto;
                margin-right: auto;
                max-width: 595px;
                text-align: center;
                margin-bottom: 40px;
            }
            button {
                width: 100%;
                max-width: 593px;
            }
        }
       
        .uk-offcanvas-body {
            max-width: 592px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &List {
        .uk-offcanvas {
            &-title {
                font-size: 20px;
                margin-top: 33px;
                margin-bottom: 8px;
            }

            &-header{
                box-sizing: border-box;
                height: 200px;
            }

            &-body {
                box-sizing: border-box;
                height: calc(100vh - 288px);
                padding: 10px 0 0;
                position: relative;

                .box-overflow {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    //overflow-y: auto;
                    padding: 0 0;
                    box-sizing: border-box;
                }
            }

            &-footer{
                box-sizing: border-box;
                height: 88px;
                box-shadow: -2px 6px 8px -2px rgba(171, 147, 67, 0.15), -5px 0 9px 0 rgba(171, 147, 67, 0.2);
            }
        }

        .list-address-user {
            > * {
                position: relative;
                padding-bottom: 33px;
                border-bottom: 1px solid #e6e6e6;

                &:nth-child(n+2) {
                    margin-top: 23px;
                }
            }

            .uk-radio {
                margin-right: 5px;
            }

            label {
                cursor: pointer;
            }

            .name {
                font-size: 14px;
                font-weight: bold;
                @include color-primary-black();

                .uk-badge {
                    color: $primary-orange !important;
                    background-color: rgba($primary-orange, 0.1);
                }
            }

            .address {
                margin-top: 15px;
                font-size: 16px;
                line-height: 1.5;
                color: $primary-gray;
            }

            .note {
                color: $primary-black;
                margin-top: 5px;
                color: $primary-gray;
            }

            .edit {
                font-size: 14px;
                font-weight: 500;
                bottom: 11px;
            }
        }

        @media (min-width: $breakpoint-large) {
            .uk-offcanvas {
                &-title {
                    font-size: 24px;
                }

                &-body {
                    padding: 0 16px 16px 72px;
                    height: calc(100vh - 288px);

                    .box-overflow {
                        padding: 0 52px 0 0;
                    }
                }
            }
        }
        @media (max-width: 335px) {
            .uk-offcanvas-footer {
                .uk-button:not(.uk-button-large) {
                    font-size: 12px;
                }
            }
        }
    }

    &Add {
        .uk-offcanvas {
            &-title {
                @media (max-width: $breakpoint-small-max) {
                    font-size: 18px;
                    a {
                        margin-right: 14px;
                        vertical-align: -4px;
                    }
                }
            }
        }
    }
}

.device_mobile{
    .component-ModalCheckoutAddressDeliveryList{
        .uk-offcanvas {

            &-header{
                box-sizing: border-box;
                height: 176px;
            }

            &-body {
                box-sizing: border-box;
                height: calc(100dvh - 264px);
            }
            &-footer{
                box-sizing: border-box;
                height: 88px;
                }
        }
    }
}
