.component-card-timeslot {
    .uk-card {
        background: none;
        cursor: pointer;

        .uk-card-body {
            background: $white;
            padding: 0;
            width: 112px;
            height: 85px;
            border-color: $bright-gray;
            transition: all .3s ease-in-out;
            @media (min-width: $breakpoint-large) {
                height: 95px;
            }

            .availability {
                border-radius: 12px 12px 0 0;
                padding: 4px 14px 2px 14px;
                background-color: $alabaster;
                font-size: 12px;
                font-weight: bold;
                line-height: 16px;
            }

            .day-month {
                font-size: 18px;
                padding: 8px 14px 4px 14px;
                line-height: 1.33;
                @media (min-width: $breakpoint-large) {
                    padding: 16px 14px 4px 14px;
                }
            }

            .letter-day {
                font-size: 12px;
                padding: 0 14px 10px 14px;
                color: $primary-gray;
                line-height: 1.33;
            }
        }
    }

    &.available {
        .uk-card {
            .uk-card-body {
                .availability {
                    background-color: $alabaster;

                    .limited, .not-available {
                        display: none;
                    }
                }
            }
        }
    }

    &.limited {
        .uk-card {
            .uk-card-body {
                .availability {
                    background-color: $isabelline;

                    .available, .not-available {
                        display: none;
                    }
                }
            }
        }

    }

    &.not-available {
        .uk-card {
            .uk-card-body {
                background: $platinum;

                .availability {
                    background-color: $cultured;

                    span {
                        opacity: 0.5;
                    }

                    .available, .limited {
                        display: none;
                    }
                }

                .day-month, .letter-day {
                    opacity: 0.5;
                }
            }
        }
    }

    &.selected {
        .uk-card {
            background: none;

            .uk-card-body {
                background: $pin-yellow;
                border-color: $gargoyle-gas;
                transform: scale(1.2);

                .availability {
                    background-color: transparent;
                }
            }
        }
    }
}

/* SKELETON */
.card-timeslot-day-skeleton {
    overflow: hidden;
    background: $white;
    border-radius: 12px;
    box-sizing: border-box;
    padding: 0;
    max-width: 100%;
    border: 1px solid var(---effects-01-opacity-20-2);
    width: 112px;
    height: 85px;

    .card-skeleton {
        &-availability {
            width: 100%;
            height: 22px;
            background-color: $cultured !important;
        }
        &-info {
            height: 62px;
            width: 100%;
        }
    }

    .loading {
        position: relative;
        background-color: #E2E2E2;

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .2), transparent);
            animation: loadingSK 1.5s infinite;
        }
    }
}

/* SKELETON */
.card-timeslot-hours-skeleton {
    overflow: hidden;
    background: $white;
    border-radius: 16px;
    border: solid 1px $bright-gray;
    box-sizing: border-box;
    padding: 0;
    max-width: 100%;
    width: 89px;
    height: 34px;

    .card-skeleton {
        &-info {
            height: 34px;
            width: 100%;
            background-color: $white;
        }
    }

    .loading {
        position: relative;
        background-color: #E2E2E2;

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .2), transparent);
            animation: loadingSK 1.5s infinite;
        }
    }
}