.component-StorytellingA {
    .section-storytelling {

        &-a {
            .section-body {
                display: flex;
                flex-direction: column;
            }

            .main-container {
                display: flex;
                flex-direction: row;
                margin-bottom: 24px;

                @media (min-width: $breakpoint-xlarge) {
                    margin-bottom: 0;
                }

                .img-container {
                    margin-left: -16px;
                    border-radius: 0 12px 12px 0;
                    min-width: 106px;
                    height: 216px;
                    background-position: center center;
                    background-size: cover;

                    @media (min-width: $breakpoint-xlarge) {
                        margin-left: 0;
                        min-width: 384px;
                        height: 224px;
                        border-radius: 12px;
                    }
                }

                .txt-container {
                    margin-left: 16px;
                    @include color-primary-black();

                    .title {
                        font-size: 28px;
                        font-weight: normal;
                        line-height: 1.07;
                        margin-bottom: 16px;

                        @media (min-width: $breakpoint-xlarge) {
                            font-size: 32px;
                            line-height: 1.25;
                            margin-top: 0;
                        }
                    }

                    .description {
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 1.43;
                        margin: 0 0 32px;
                        height: 84px;
                        overflow: hidden;
                        color: $primary-gray;

                        @media (min-width: $breakpoint-xlarge) {
                            height: 80px;
                            max-width: 464px;
                        }
                    }

                    .link-storytelling-a {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                        color: $blue-info;
                    }
                }
            }

            .swiper-storytelling-a {
                width: 100%;
                overflow: visible;
                margin-left: -7px;
                padding-left: 7px;
                padding-right: 7px;

                .manage-preferite-product {
                    color: $primary-orange !important;
                }

                @media (min-width: $breakpoint-xlarge) {
                    padding: 64px 7px 28px;
                    position: relative;
                    overflow: hidden;
                }

                .next-storytelling-a {
                    top: 8px;
                    right: 0;
                }

                .prev-storytelling-a {
                    top: 8px;
                    right: 34px;
                    left: unset;
                }

                .scrollbar-storytelling-a {
                    bottom: 0;
                    width: 46%;
                    left: 27%;
                    height: 4px;
                }
            }
        }
    }
}