.component-emptyListing{
    padding-bottom: 88px;
    
    .title{
        font-size: 28px;
        @include color-primary-black();
        line-height: 30px;
        font-weight: bold;
        margin-bottom: 8px;
        margin-top: 49px;
    }

    .description{
        font-size: 16px;
        line-height: 24px;
        color: $primary-black;
    }
}