.component-ProductCard {
    position: relative;

    .banner-promo {
        height: 24px;
        border-top-left-radius: 12px;
        background-color: #f6e7ec;
        border-top-right-radius: 12px;
        z-index: 1;
        position: relative;
        top: 12px;
        display: flex;
        align-items: center;
        color: #9f0839;

        .icon-promo {
            line-height: 1.17;
            margin-left: 8px;
            position: relative;
            width: 16px;
            height: 16px;
        }

        .promo-desc {
            font-weight: bold;
            font-size: 12px;
            line-height: 1.17;
            margin-left: 5px;
        }

        .tooltip-margin {
            margin-left: 8px;

            .icon-info {
                line-height: inherit;
            }

            .tooltiptext {
                margin: 0 15px 0 15px;
                padding: 12px;
            }
        }
    }

    .uk-dark {
        position: relative;
    }

    .component-PromoBadgeCard {
        .badge-text {
            font-size: 16px;
            line-height: 1;
            padding: 8px;
            font-weight: bold;
            border-radius: 8px;
            margin-right: 4px;

            @media (min-width: $breakpoint-large) {
                font-size: 10px;
                padding: 8px 4px;
            }

            @media (min-width: $breakpoint-xxlarge) {
                font-size: 12px;
            }

            @media (min-width: $breakpoint-xxxlarge) {
                font-size: 16px;
            }
        }
    }

    .logo-iniziativa {
        max-width: 48px;
        max-height: 48px;

        @media (min-width: $breakpoint-xlarge){
            max-width: 42px;
            max-height: 42px;
        }
        margin-right: 8px;
    }

    .taglio-prezzo {
        position: absolute;
        top: 0;
        width: 100%;

        & > *:not(:first-child) {
            margin-top: 1px;
        }

        & > *:first-child {
            & > *:not(:first-child) {
                margin-top: 1px;
            }
        }

        .icon-fidelity-grey, .icon-fidelity-double {
            font-size: 32px;
            margin-right: 4px;
        }

        .promo-badges {
            flex-direction: column;

            .badges-container {
                flex-direction: row;
                max-width: 80%;
                flex-wrap: wrap;
            }
        }

    }


    .badges-potential-discount {
        .component-PromoBadgePotentialDiscount:last-child {
            margin-left: 8px;
        }
    }

    .uk-dark{
        box-shadow: -2px 6px 8px 0px rgba(171, 147, 67, 0.25), 0 0 1px 0 rgba(171, 147, 67, 0.2);
        border-radius: 12px;

        &.scale-sostitutivo{
            display: flex;
            flex-direction: column;

            @media (min-width: $breakpoint-xlarge){
                flex-direction: row;
                width: 896px;
            }

            .box-scale-sostitutivi{
                height: 240px;
                padding: 4px 16px 12px;

                @media (min-width: $breakpoint-xlarge){
                    height: 259px;
                    padding: 16px 12px 16px 79px;
                }

                &-content{
                    height: 100%;
                    @media (min-width: $breakpoint-xlarge){
                        margin-left: 32px;
                        width: 308px;
                    }

                    .content-title{
                        font-size: 16px;
                        font-weight: bold;
                        height: 24px;
                        color: rgba($black, 0.8);
                        margin-bottom: 28px;
                    }
                }

                .scale-divider{
                    margin: 24px 0 32px 0 !important;
                    border: solid 2px #f06c00;
                    border-radius: 12px;
                    background-color: #f06c00;

                    @media (min-width: $breakpoint-xlarge){
                        margin: 0 0 20px 0 !important;
                        height: 100%;
                    }
                }

                @media (min-width: $breakpoint-xlarge){
                    display: flex;
                }
            }

            .weight-list-sostitutivi {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                max-height: 144px;
                @media (min-width: $breakpoint-xlarge){
                    flex-direction: column;
                    
                    justify-content: none;
                }

                &-row{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;

                    @media (min-width: $breakpoint-xlarge){
                        height: 100%;
                        flex-direction: column;
                        &:not(:last-child){
                            margin-right: 16px;
                        }
                    }
                }
                .weight-list-sostitutivi-chip {
                    pointer-events: all;
                    width: 100%;
                    max-width: 145px;
                    display: block;

                    &:not(:last-child){
                        margin-bottom: 24px;
                    }

                    @media (min-width: $breakpoint-xlarge){
                        &:nth-child(3n){
                            margin-bottom: 0 !important;
                        }
                    }

                    > * {
                        display: block;
                        margin: 0px auto;
                        width: 100%;
                    }

                    &.active {
                        background-color: transparent;
                        border: none;
                        color: var(---colour-01-primary-03-p-2);
                    }

                    .uk-tag *:last-child {
                        @include color-primary-black(0.4);
                    }

                    @media (min-width: $breakpoint-xlarge){
                        &:not(:last-child){
                            margin-bottom: 24px;
                        }
                    }
                }
            }
        }
    }
    .product-card {
        border: none;
        padding: 20px 16px 16px;
        height: 357px;
        max-height: 357px;
        position: relative;
        @include color-primary-black(0.8);
        /* box-shadow: -2px 6px 8px 0px rgba(171, 147, 67, 0.25), 0 0 1px 0 rgba(171, 147, 67, 0.2); */

        .card-top-mid {
            height: 100%;
            max-height: 271px;
        }

        .card-top {
            position: relative;
            height: 100%;
            max-height: 50px;
            padding-bottom: 11px;
            margin-bottom: auto;

            .discount {
                background-color: var(---colour-01-primary-01-p-2);
                color: $white !important;
                padding: 8px !important;
                font-size: 16px !important;
                border-radius: 8px !important;
                font-weight: bold;
            }

            .discount-date {
                font-size: 12px;
                color: var(---colour-01-primary-01-p-2);
                font-weight: 500;

                .link {
                    font-size: 12px;
                    line-height: 12px;
                    color: var(---colour-01-primary-01-p-2);
                    text-decoration: none;
                    .icon {
                        position: relative;
                        top: 2px;
                    }
                }
            }

            .cards-icons {
                margin-left: 8px;
                width: 100%;
                height: 100%;

                .icon-fidelity-double {
                    width: 34px;
                    height: 32px;
                }
            }
        }

        .logo-bassiefissi {
            max-width: 48px;
            max-height: 48px;
            overflow: hidden;
            object-fit: cover;
            object-position: center;
        }

        .card-middle {
            max-height: 210px;
            height: 100%;
            margin-bottom: auto;
            position: relative;

            .contingency-pz {
                font-size: 12px;
                text-align: right;
                padding-right: 8px;
                color: rgba($black, 0.6);
            }

            .product {
                text-decoration: none;
                height: 100%;
                max-height: 105px;
                position: relative;

                &-description {
                    margin-left: 8px;
                    width: 100%;
                    max-width: calc(100% - 105px);
                    height: 100%;
                    //max-height: 91px !important;

                    h3 {
                        position: absolute;
                        top: 22px;
                        margin-top: 8px;
                        margin-bottom: 0;
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 1.43;
                        @include color-primary-black();
                        @include line-clamp(3);
                    }
                }

                &-img {
                    margin-top: 8px;
                    height: 100%;
                    width: 100%;
                    max-height: 105px;
                    max-width: 105px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        height: -webkit-fill-available;
                        max-height: 100%;
                        max-width: 100%;
                    }
                }

                .badge-marchi {
                    position: absolute;
                    display: flex;
                    align-items: flex-start;
                    top: -5px;
                    right: 0;
                    justify-content: flex-end;
                    &-conad {
                        max-width: 48px;
                        max-height: 33px;
                        margin-left: 5px;
                    }

                    &.conad-60 {

                    }
                }

                @media (min-width: $breakpoint-large) {
                    .badge-marchi {
                        top: 0;
                    }
                    &-img {
                        max-width: 130px;
                        max-height: 130px;
                    }
                }

                @media (min-width: $breakpoint-xxlarge) {

                }

                @media (min-width: $breakpoint-xxxlarge) {
                    &-img {
                        max-height: 170px;
                        max-width: 170px;
                    }
                }
            }

            .product-info {
                margin-top: 10px;
                max-height: 100%;
                position: relative;

                .product-quantity {
                    font-size: 14px;

                    @media (min-width: $breakpoint-xlarge) and (max-width: $breakpoint-xxlarge) {
                        font-size: 12px;
                    }
                }

                .product-price-kg {
                    font-size: 12px;
                    @include color-primary-black(0.6);
                    font-weight: bolder;

                    @media (min-width: $breakpoint-xlarge) and (max-width: $breakpoint-xxlarge) {
                        font-size: 10px;
                    }
                }

                .product-price {
                    font-size: 28px;


                    &-red {
                        color: var(---colour-01-primary-01-p-2);
                    }

                    &-original {
                        font-size: 14px;
                        text-decoration: line-through;
                        margin-right: 2px;
                    }


                }

                .badge {

                    &:first-child {
                        margin-right: 8px;
                    }

                    border-radius: 8px;
                    padding: 8px;

                    .icon-fidelity-orange {
                        font-size: 16px;
                    }

                    .icon-fidelity-grey {
                        font-size: 20px;
                    }

                    .price {
                        font-size: 12px;

                        &-original {
                            text-decoration: line-through;
                            margin-left: 4px;
                        }

                        &-red {
                            color: var(---colour-01-primary-01-p-2);
                            margin-left: 4px;
                        }
                    }
                }
            }

            .info-weight {
                color: $primary-black;
                font-size: 12px;
                line-height: 12px;
                font-weight: 500;
                width: 100%;
                height: 100%;
                max-height: 32px;
                margin-bottom: 6px;

                .icon-info {
                    margin-right: 4px;
                    font-size: 16px;
                    color: $white;
                    background: rgba($black, 0.4);
                    border-radius: 100%;
                }

                & > *:first-child {
                    white-space: nowrap;
                }

                & > *:last-child {
                    text-align: right;
                }

                @media (max-width: 325px) {
                    right: 16px;
                }

                @media (min-width: $breakpoint-xlarge) {
                    width: 100%;
                }

                &:last-child {
                    span:last-child {
                        margin-left: 2px;
                    }
                }
            }
        }

        .card-bottom {
            position: relative;

            button {
                width: 100%;
                line-height: 56px;
                font-size: 16px;
                font-weight: bold;
            }

            .add-quantity {
                padding: 8px;
                background-color: var(---colour-02-secondary-01-s-2);
                border-radius: 12px;

                &-button {
                    width: 40px;
                    padding: 0;
                    height: 40px;
                    line-height: 40px;
                }

                .total {
                    .quantity {
                    }

                    .price {
                        font-size: 12px;
                        @include color-primary-black(0.9);

                        .price-old {
                            margin-right: 4px;
                            text-decoration: line-through;
                        }
                    }
                }
            }

            .alert-add-to-cart, .alert-pdv-google {
                box-sizing: border-box;
                display: flex;
                box-shadow: 0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
                width: 100%;
                background-color: $light-grayish-blue;
                bottom: 50px;
                position: absolute;
            }

            .alert-out-of-stock {
                box-sizing: border-box;
                display: flex;
                box-shadow: 0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
                background-color: $pink-light-2;
                width: 100%;
                bottom: 50px;
                position: absolute;

                p {
                    padding-right: 0;
                }
            }

            .alert-heavy-warning, .alert-contingency-warning {
                display: flex;
                box-sizing: border-box;
                box-shadow: 0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
                width: 100%;
                bottom: 50px;
                position: absolute;

                .uk-alert-close {
                    position: unset;
                }

                span {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: normal;
                    @include color-primary-black(0.6);
                }
            }

            .alert-heavy-warning, .alert-add-to-cart, .alert-contingency-warning, .alert-pdv-google {
                .icon-close {
                    text-decoration: none;
                    padding: 11px;
                    @include color-primary-black(0.6);
                    position: absolute;
                    top: auto;
                    bottom: auto;
                    right: 9px;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }

        @media (min-width: $breakpoint-large) {
            // max-width: 320px;
            height: 486px;
            max-height: 486px;
            .card-top-mid {
                height: 100%;
                max-height: 398px;
            }

            .card-top {
                max-height: 48px;
                padding-bottom: 12px;
            }

            .card-middle {
                height: 100%;
                max-height: 338px;

                .product {
                    height: 100%;
                    max-height: 193px;
                    flex-direction: column;

                    &-img {
                        margin: 0 0 8px 0;
                    }

                    &-description {
                        margin-left: 0;
                        line-height: 20px;
                        max-width: 100%;
                        margin-top: -25px;
                    }

                    .badge-marchi {
                        &-conad {
                            max-height: 48px;
                        }
                    }
                }

                .product-info {
                    position: relative;
                    margin-left: 0 !important;
                    margin-top: 12px;

                    .product-price {
                        font-size: 32px;

                        @media (min-width: $breakpoint-xlarge) and (max-width: $breakpoint-xxlarge) {
                            font-size: 24px;

                            &-original {
                                font-size: 10px;
                            }
                        }

                        @media (min-width: ($breakpoint-xxlarge + 1)) and (max-width: $breakpoint-xxlarge-max) {
                            font-size: 28px;

                            &-original {
                                font-size: 12px;
                            }
                        }
                    }

                    .badge-container {
                        width: 100%;
                        position: absolute;
                        bottom: -4px;
                        right: 0;
                        justify-content: flex-end;

                        .badge {

                            i {
                                margin-right: 8px;
                            }
                        }
                    }
                }

                .info-weight {
                    width: 100%;
                    margin-top: auto;
                }

            }

            &-horizontal {
                min-height: 0px;
                height: 340px;
                max-height: 340px;

                .card-top {
                    height: 100%;
                    max-height: 52px;
                    padding-bottom: 12px;

                }

                .card-middle {
                    padding-bottom: 8px;
                    height: 100%;
                    max-height: 188px !important;
                    position: relative;

                    .product {
                        flex-direction: row;
                        align-items: flex-start;

                        @media (min-width: $breakpoint-xlarge) {
                            max-height: 95px;
                        }


                        &-img {
                            margin-bottom: 0;
                            max-width: 88px;
                            max-height: 88px;

                            @media (min-width: $breakpoint-xlarge) {
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                        }

                        &-info {
                            @media (min-width: $breakpoint-xlarge) {
                                margin-left: 88px !important;
                            }
                        }

                        &-description {
                            margin-top: 0;
                            max-width: calc(100% - 88px);
                            margin-left: auto;
                            padding-left: 10px;
                        }

                        .badge-marchi {
                            right: 0;
                            top: 0;
                            &-conad {

                            }
                        }
                        @media (min-width: $breakpoint-xlarge) {
                            .badge-marchi {
                                right: inherit;
                                top: 92px;
                                left: 0;
                            }
                        }
                    }

                    .product-info {
                        justify-content: space-between;
                        align-items: flex-start;
                        max-width: calc(100% - 88px);
                        width: 100%;
                        margin-left: auto !important;
                        margin-top: 0;
                        padding-left: 10px;
                    }

                    .info-weight {
                        max-width: calc(100% - 88px);
                        align-self: flex-end;
                        margin-top: auto;
                        margin-bottom: 0px;
                        box-sizing: border-box;
                    }
                }


            }
        }

        @media (min-width: $breakpoint-xlarge) {
            .card-middle {
                .product {
                    .badge-marchi {
                        flex-direction: column;
                        justify-content: inherit;
                        &-conad {
                            margin-left: 0;
                            margin-bottom: 5px;
                        }
                        > .conad-60:first-child {
                            margin-top: 41px;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &-horizontal {
                .badge-marchi-conad {
                    top: calc(100% + 22px) !important;
                }
            }
        }

        @media (min-width: $breakpoint-xxlarge) {
            .card-middle {
                .product {
                    .badge-marchi {
                        > .conad-60:first-child {
                            margin-top: 60px;
                        }
                    }
                }
            }
        }

        @media (min-width: $breakpoint-xxxlarge) {
            height: 514px;
            max-height: 514px;

            .card-top-mid {
                max-height: 426px !important;
            }

            .card-middle {
                max-height: 365px !important;

                .product {
                    max-height: 244px;
                    &-info {
                        align-items: flex-start;
                        max-height: 84px;
                        margin-top: 5px !important;

                        .badge-container {
                            bottom: 10px;
                        }
                    }
                }
            }

            .info-weight {
                align-items: flex-end;
            }

            &-horizontal {
                min-height: 0;
                height: 340px;
                max-height: 340px;

                .card-top {
                    padding-bottom: 6px !important;
                }
                .card-middle {
                    max-height: 188px !important;
                    .product {
                        &-description {
                            padding-left: 0;
                        }
                        &-info {
                            margin-top: -1px !important;
                        }
                    }
                }

                .product {
                    max-height: 95px !important;

                    &-img {
                        height: 104px !important;
                        width: 104px !important;
                        margin-top: 2px !important;
                    }

                    &-description {
                        max-width: calc(100% - 112px) !important;
                    }

                    &-info {
                        max-height: 72px !important;
                        max-width: calc(100% - 112px) !important;
                        margin-top: 4px !important;

                        .badge-container {
                            bottom: 4px !important;
                        }
                    }
                    .badge-marchi-conad {
                        right: calc(100% + 64px) !important;
                        top: calc(100% + 12px) !important;
                    }
                }

                .info-weight {
                    margin-top: 0 !important;
                    max-width: calc(100% - 112px) !important;
                    height: 16px !important;
                }
            }
        }

        .var-weight {
            /* box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2); */
            flex-direction: column;
            justify-content: space-between;
            border-radius: 12px;
            background-color: $white;
            border: 2px solid $primary-orange;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;

            &-header {
                justify-content: center;
                position: relative;
                margin-top: 8px;

                &-title {
                    font-size: 16px;
                    font-weight: bold;
                }

                .var-weight-close {
                    position: absolute;
                    right: 16px;
                    padding-top: 3px;
                }
            }

            &-content {
                margin-top: auto;
                margin-bottom: auto;

                .weight-list {
                    width: fit-content;
                    margin: 0 auto;
                    li {
                        display: block;

                        > * {
                            display: block;
                            margin: 0px auto;
                            min-width: 129px;
                            width: 100%;
                        }

                        &.active {
                            background-color: transparent;
                            border: none;
                            color: var(---colour-01-primary-03-p-2);
                        }

                        &.active-non-selectable {
                            pointer-events: none;
                        }

                        .uk-tag.disabled {
                            pointer-events: none;
                        }

                        .uk-tag *:last-child {
                            color: $primary-black;
                        }
                    }
                }
            }

            .alert-variable-weight {
                margin: 0 8px 8px 8px;
            }
        }

        .alert-heavy-block-oncart{
            position: absolute;
            top: 100%;
            right: -16px;
            width: 80%;
            max-width: 227px;
            padding: 8px 10px 8px 10px;
            z-index: 1;
            box-sizing: border-box;
            box-shadow: 0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

            .icon-weight{
                margin-right: 4px;
            }
            .uk-accordion{
                width: 100%;
            }
            .uk-accordion-title{
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;

                &::before{
                    margin-left: 0;
                }
            }

            .uk-accordion-content{
                margin-top: 4px;
            }

            @media (min-width: $breakpoint-large){

                .uk-accordion-title, .uk-accordion-content{
                    font-size: 11px;
                }
            }
        }

    }

    .alert-heavy-block-offcart{
        position:absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 16px 8px;

        .heavy-block{
            &-title{
                font-weight: bold;
                font-size: 14px;
            }

            &-desc{
                font-size: 14px;
            }
        }

        .icon-weight{
            background-color: transparent !important;
            font-weight: bold;
        }
    }

    .overlay-out-of-stock {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba($white, 0.8);
        //z-index: 200;
        border-radius: 12px;

        .uk-alert {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 16px 8px;
            background: $pink-light-2;
        }
    }

    .feedback-overlay-pcard {
        text-align: center;
        display: none;
        opacity: 0;
        animation: 3000ms ease-in-out 0s 1 normal forwards running feedback-productcard-add-to-cart;

        &-icon {
            display: none;
            position: absolute;
            top: 15%;
            left: 0;
            right: 0;
        }
    }

    .card-partner {
        border-radius: 12px;
        height: 357px;
        padding: 22px 16px 16px;
        @media (min-width: $breakpoint-large) {
            padding: 22px 20px 16px;
            height: 486px;
        }
        @media (min-width: $breakpoint-xxxlarge) {
            padding: 30px 24px 16px;
            height: 514px;
        }
        box-shadow: -2px 6px 8px 0px rgb(171 147 67 / 25%), 0 0 1px 0 rgb(171 147 67 / 20%);
        .background {
            height: 60%;
            z-index: -1;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            @media (min-width: $breakpoint-large) {
                height: 65%;
            }
        }
        .logo-wrapper {
            height: 56px;
            .logo {
                max-height: 100%;
            }
        }
        .text-wrapper {
            height: 37px;
            padding: 15px 0 5px;
            @media (min-width: $breakpoint-large) {
                height: 155px;

            }
            @media (min-width: $breakpoint-xlarge) {
                padding: 15px 0;
            }
            @media (min-width: $breakpoint-xxxlarge) {
                height: 117px;
            }
            .text {
                padding: 0 22px;
                font-size: 14px;
                line-height: 24px;
                color: #333;
                @include line-clamp(3);
                @media (min-width: $breakpoint-large) {
                    @include line-clamp(5);
                    font-size: 24px;
                    line-height: 38px;
                    padding: 0;
                }
                @media (min-width: $breakpoint-xlarge) {
                    font-size: 24px;
                    line-height: 38px;
                    padding: 0;
                }
                @media (min-width: $breakpoint-xxxlarge) {
                    @include line-clamp(4);
                    font-size: 30px;
                    line-height: 38px;
                }
                
            }
        }
        .product-img {
            max-width: 146px;
            max-height: 134px;
            width: auto;
            height: auto;
            @media (min-width: $breakpoint-xlarge) {
                max-width: 125px;
                max-height: 115px;
            }
            @media (min-width: $breakpoint-xxxlarge) {
                max-width: 175px;
                max-height: 161px;
            }
            
        }
        
        .card-button {
            line-height: 56px;
            font-size: 16px;
            font-weight: bold;
            margin-top: 20px;
            margin: 0 16px 16px;
            &:hover{
                @include color-primary-black();
            }
        }
    }

    .alert-pdv-google{
        display: none !important;
    }
}

.device_mobile, .device_tablet{
    .component-ProductCard[data-clicked='clicked']{
        .alert-pdv-google{
            display: flex !important;
        }
    }
}

.device_not_mobile_not_tablet{
    .component-ProductCard:hover{
        .alert-pdv-google{
            display: flex !important;
        }
    }
}

@keyframes feedback-productcard-add-to-cart {
    0% {
        display: none;
        opacity: 0;
    }

    10% {
        display: block;
        opacity: 1;
    }

    90% {
        display: block;
        opacity: 1;
    }

    100% {
        display: block;
        opacity: 0;
    }
}
