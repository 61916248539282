.component-modal-agevolazione {
    
    .section-form-container {
        padding-top: 52px;

        @media (min-width: $breakpoint-xlarge) {
            padding-top: 74px;
        }
        
        .uk-container {
            margin: 0 16px;
            padding: 0;

            @media (min-width: $breakpoint-xlarge) {
                margin: 0 74px;
            }
        }

        .form-title {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.33;
        }

        .input-container {

            display: flex;
            flex-direction: column;

            @media (min-width: $breakpoint-xlarge) {
                flex-direction: row;

                div {
                    &:first-child {
                        margin-right: 8px;
                    }
                    
                    
                    &:last-child {
                        margin-left: 8px;
                    }
                } 
            }
        }

        .radio-container {
            padding-top: 24px;
            color: rgba(0,0,0,.8);
            font-size: 14px;

            .text {
                line-height: 1.43;
                margin-bottom: 8px;
            }

            label {
                margin: 0 12px 0 10px;
                font-weight: bold;
            }
        }

        .uk-label-float {
            position: relative;

            .uk-form-icon {
                display: none;

                box-sizing: border-box;
                text-align: center;
                line-height: 50px;

                * {
                    font-size: 15px;
                }
                ~ .uk-input {
                    padding-right: 45px !important;
                }
            }
            &.blocked {
                input {
                    border-color: $primary-disabled;
                    background-color: $white;
                }
                .uk-form-icon {
                    display: block;
                    background: transparent;
                    width: 40px;
                    @include color-primary-black();
                    * {
                        font-size: 12px;
                    }
                }
            }

            &.no-card {
                flex-direction: row;
                align-items: center;

                .text {
                    text-align: center;
                    font-size: 14px;
                    font-weight: bold;
                    @include color-primary-black(0.6);
                    line-height: 1;
                }

                .icon-info {
                    margin: 16px;
                }

                .tooltiptext-drop {
                    .info-blue {
                        padding: 6px 12px;
                    }

                    .info {
                        font-size: 13px;

                        a {
                            color: $white;
                        }
                    }
                }

                div {
                    &:first-child {
                        margin-right: unset;
                    }

                    &:nth-child(2) {
                        margin: 10px 10px 10px 0;
                    }

                    &:last-child {
                        margin-left: unset;
                    }
                }
            }
        }

        .allega-file {
            padding: 40px 0 0;

            .subtitle {
                font-size: 16px;
                font-weight: bold;
                line-height: 1.25;
                margin-bottom: 0;
                padding: 0;
            }

            .label-allega-file {
                font-size: 14px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.6);
                margin-top: 8px;
            }

            .content {
                position: relative;
                padding: 16px;
                margin-top: 24px;
                border: solid 1px rgba(0, 0, 0, 0.2);
                border-radius: 12px;
                display: flex;
                flex-direction: column;
                gap: 16px;

                > * {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    @media (min-width: $breakpoint-xlarge) {
                        align-items: center;
                    }
                }

                &.multiple {
                    > .js-ag-doc-showfile{
                        &:not(:last-child) {
                            border-bottom: solid 1px rgba(0,0,0,0.2);
                        }
                    }
                }

                > *:nth-child(2n-1){
                    flex-direction: column;
                    position: relative;
                    
                    @media (min-width: $breakpoint-xlarge) {
                        flex-direction: row;
                    }
                }

                .desc {
                    font-size: 14px;
                    margin: 0;
                    @include color-primary-black(0.6);
                    .icon-info {
                        cursor: pointer;
                        position: relative;
                        top: 2px;
                        margin-left: 2px;
                    }

                    .info {
                        div>div:not(:first-child) {
                            padding: 8px 0;
                        }

                        .border {
                            border-bottom: 1px solid rgba($white, 0.5);
                        }
                    }
                }

                .subdesc {
                    background: #fff8e6;
                    padding: 12px 8px 12px 16px;
                    border-radius: 12px;
                    font-size: 10px;
                    display: flex;
                    flex-direction: column;
                    color: rgb(102, 73, 1);

                    @media (min-width: $breakpoint-xlarge) {
                        gap: 16px;
                        flex-direction: row;
                    }

                    &-text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 16px;

                        i {
                            background-color: #ffb602;
                        }

                        p {
                            margin: 0;
                            font-size: 13px;
                            line-height: 16px;
                        }
                    }
                }

                .message-error {
                    position: absolute;
                    font-size: 13px;
                    font-weight: normal;
                    line-height: 1.23;
                    min-height: 30px;
                    top: 20px;

                    @media (min-width: $breakpoint-xlarge) {
                        top: 42px;
                    }
                }

                .link-carica {
                    color: $primary-orange;
                    border: 2px solid $orange-hover;
                    width: 100%;
                    margin-top: 16px;
                    padding: 12px 14px;
                    font-size: 14px;
                    line-height: 14px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 4px;


                    @media (min-width: $breakpoint-xlarge) {
                        margin-top: 0;
                        width: auto;
                    }

                    .icon-paperclip {
                        font-size: 16px;
                        line-height: 16px;
                        margin-left: 0;
                    }

                    &--disabled {
                        border-color: $primary-disabled;
                        background-color: $white;
                        color: #999999;
                        pointer-events: none;
                    }
                }

                .link-fac-simile {
                    color: rgb(102, 73, 1);
                    border: 2px solid $warning;
                    width: 100%;
                    margin-top: 16px;
                    padding: 12px 24px;
                    font-size: 14px;
                    line-height: 14px;
                    justify-content: center;
                    align-items: center;
                    white-space: nowrap;
                    background: transparent;

                    @media (min-width: $breakpoint-xlarge) {
                        margin-top: 0;
                        width: auto;
                    }
                }

                .details {
                    font-size: 16px;
                    font-weight: bold;
                    @include color-primary-black();
                    display: flex;
                    flex-direction: column;
                    max-width: calc(100% - 40px);
                    .text {
                        display: flex;
                        flex-direction: column;

                        .upper-text {
                            display: flex;
                        }

                        .bottom-text {
                            text-align: left;
                        }
                    }
                    .nome-allegato {
                        margin-left: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .label {
                        font-size: 10px;
                        font-weight: bold;
                        line-height: 2.4;
                        @include color-primary-black(0.6);
                    }
                    i {
                        position: relative;
                        font-size: 18px;
                        top: 3px;

                        &.icon-alert {
                            font-size: 12px;
                        }
                    }
                    .error {
                        color: $danger;
                        font-size: 13px;
                        line-height: 1.23;
                        margin-top: 5px;
                        display: block;
                        font-weight: normal;
                    }
                }
                .remove {
                    @include color-primary-black();
                    font-size: 22px;
                    position: relative;

                }
                .banner-error {
                    line-height: 16px;
                    font-size: 13px;
                    display: block;
                    background-color: $misty-rose;
                    position: absolute;
                    top: -50px;
                    padding: 13px 8px;
                    color: #333333;
                    left: 0;
                    right: 0;
                    border-radius: 12px;
                    opacity: 0;
                    transition: opacity .5s;

                    @media (max-width: $breakpoint-small) {
                      top: -65px;
                    }

                    i {
                      margin-right: 16px;
                      display: inline-block;
                    }

                    &-info {
                        background-color: #fff8e6;
                        color: rgba(0,0,0,0.6);

                        i {
                            background-color: #ffb602;
                        }
                    }

                    &.show {
                        opacity: 1;
                    }
                }

                .add-doc-moved {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: end;
                }

                .progress-bar-total-file {
                    width: 100%;
                    &-back {
                        height: 4px;
                        background-color: #d8d8d8;
                        border-radius: 24px;
                    }

                    &-draw {
                        height: 4px;
                        display: block;
                        background-color: #ffb602;
                    }

                    p {
                        display: block;
                        font-size: 13px;
                        line-height: 16px;
                        font-weight: normal;
                        margin: 7px 0 16px 0;
                    }
                }

                .dimensioni-allegato {
                    color: rgb(0, 0, 0, 0.6);
                    font-size: 0.875rem;
                    font-weight: normal;
                }
            }
        }

        .check-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            padding: 42px 0;

            .text {
                color: $black;
                font-size: 12px;
                font-weight: 500;
            }

            .message-error {
                position: relative;
                bottom: -6px;
                padding: 0;
                font-size: 13px;
                font-weight: normal;
                line-height: 1.23;
                min-height: 30px;
            }
        }

        .footer-form {
            text-align: center;

            @media (min-width: $breakpoint-xlarge) {
                text-align: right;
            }
            
            .button-submit {
                width: 100%;

                @media (min-width: $breakpoint-xlarge) {
                    width: auto;
                }
            }
        }
    }

    hide {
        display: none;
    }
}