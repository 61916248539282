@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';


.modal-checkout-error-popup {
    @include color-primary-black();

    .btn-group {
        margin-top: 20px;
    }

    @media (min-width: $breakpoint-large) {
        .btn-group {
            margin-top: 80px;
        }
    }
}

