.component-faqSectionFormMain {

    .section-form-main {
        background-color: $floral-white;
        padding: 0;

        .title {
            font-size: 32px;
        }

        .desc {
            font-size: 14px;
        }

        .subtitle {
            font-size: 20px;
            font-weight: bold;
        }

        .button-switcher {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            padding-bottom: 5px;
            border-bottom: 1px solid #ccc;
            .uk-tag {
                margin: 0 16px 24px 0;
                line-height: normal;
                padding: 8px 24px;
                background-color: $gray-chips;
            }
        }

        &.section-main {
            border-bottom: none;

            .card-container {
                display: flex;
                flex-wrap: none;
                flex-direction: column;
                padding-bottom: 30px;

                @media (min-width: $breakpoint-xlarge) {
                    padding: 32px 160px 48px;
                }

                .card {
                    @include color-primary-black(0.8);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 12px;
                    cursor: pointer;
                    width: auto;
                    margin-bottom: 16px;
                    padding: 16px;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 1.33;
                    border: none;
                    box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
                    background-color: $white;
                    text-align: center;
                    box-sizing: border-box;
                    text-decoration: none;

                    &.active {
                        box-shadow: 0 0 10px -2px $dusty-orange;
                    }
                }
            }
        }
    }
}