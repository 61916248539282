@import '../../scss/uikit/variables-theme.scss';
.component-Breadcrumb {

    & > div{
        position: relative;
        z-index: 2;
    }
    .uk-breadcrumb {
        li {
            font-weight: 500;
            a {
                text-decoration: none;
                @include color-primary-black();
                @media (min-width: $breakpoint-large-2) {
                    [class*='icon-'] {
                        font-size: 16px;
                        position: relative;
                        top: 3px;
                        margin-right: 5px;
                    }
                }
            }
            span {
                @include color-primary-black(0.4);
            }
        }
    }

    //GO BACK
    .go-back {
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        padding: 10px 0;
        margin-top: -10px;
        margin-bottom: -10px;
        display: inline-flex;
        align-items: center;
        @include color-primary-black();
        [class*='icon-'] {
            margin-right: 4px;
            font-size: 21px;
        }
    }

    @media (min-width: $breakpoint-large) {
        margin-bottom: 34px;
    }
}
