
.component-CardSimple{

    a {
        text-decoration: none;

        .card{
            box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            border-radius: 12px;
            padding: 18px 16px 14px 23px;
            text-decoration: none;
            @include color-primary-black();
        
            span{
                margin-left: 18px;
                font-size: 18px;
                font-weight: bold;
            }

            img {
                max-width: 80px;
                max-height: 80px;
            }
        }
    }
    
}