html,
body {
    text-rendering: geometricPrecision;
}

body {
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    //visibility: hidden;
    //opacity: 0;
    //transition: opacity 0.3s ease-in-out;
    //&.loaded {
    //    visibility: visible;
    //    opacity: 1;
    //}
}

* {
    -webkit-tap-highlight-color: transparent;
}

//FIX RESPONSIVE BP
@media (max-width: $breakpoint-xlarge-max) {
    .uk-visible\@l-2 {
        display: none !important;
    }
}

@media (min-width: $breakpoint-large-2) {
    .uk-hidden\@l-2 {
        display: none !important;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
}

.f-roboto {
    font-family: "Roboto Slab", serif;
}

%roboto-font {
    font-family: "Roboto Slab", serif;
}

a,
.uk-link {
    text-decoration: underline;
}

.no-t-decoration {
    text-decoration: none !important;
}

button {
    outline: none;
    font-family: "DM Sans", sans-serif;
}

:-ms-input-placeholder {
}

input::-ms-input-placeholder {
}

input::placeholder {
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
}

input[type="number"] {
    -moz-appearance: textfield;
}

//BG COLOR
.uk-background-white {
    background-color: $white;
}

.uk-background-dark {
}

.uk-background-white-two {
}

.uk-background-floral-white {
    background-color: var(---colour-02-secondary-01-s-2);
}

.override-color-red {
    color: var(---colour-01-primary-01-p-2) !important;
}

//Textarea -- FIX REMOVE IN FIREFOX
@supports (-moz-appearance: none) {
    .textarea-icon {
        &::after {
            display: none !important;
        }
    }
}

// Textarea -- FIX ONLY CHROME / SAFARI
.textarea-icon {
    position: relative;

    textarea {
        &::-webkit-resizer {
            display: none;
        }
    }

    &::after {
        font-family: "icomoon";
        position: absolute;
        right: 4px;
        bottom: -3px;
        content: "\e928";
        color: rgba(51, 51, 51, 0.3);
        display: block;
        font-size: 25px;

        @media only screen and (max-width: $breakpoint-large-2-max) {
            font-size: 18px;
            right: 2px;
            bottom: -2px;
        }
    }
}

//TEXT COLOR
.uk-text-white {
    color: $white !important;
}

[class*="icon-info"].uk-border-circle {
    width: 16px;
    height: 16px;
    font-size: 16px;
    background-color: var(---colour-03-semantic-04-inform-2);
    color: $white;
}

[class*="icon-alert"].uk-border-circle {
    width: 16px;
    height: 16px;
    font-size: 12px;
    background-color: var(---colour-03-semantic-02-error-2);
    color: $white;

    &:before {
        position: relative;
        top: 2px;
        left: 2px;
    }
}

[class*="page-"] {
    .main-content {
    }
}

.main-content {
    padding-top: 30px;
    position: relative;

    //&:after {
    //    content: '';
    //    position: absolute;
    //    width: 100%;
    //    height: 100%;
    //    top: 0;
    //    left: 0;
    //    right: 0;
    //    bottom: 0;
    //    background: url('https://www.andreapastore.com/wp-content/themes/blogand/loader-orange.gif') no-repeat center top / 20% $white;
    //    transition: background 0.3s ease-in-out;
    //    z-index: 10000;
    //}
    //&.loaded {
    //    &:after {
    //        display: none;
    //    }
    //}
}

a.uk-button-primary-outline {
    &:hover,
    &:focus,
    &:active {
        @include color-primary-black;
    }
}

.uk-button-primary-outline {
    &:disabled {
        box-shadow: 0 0 2px inset $primary-disabled;
        color: $primary-disabled-text !important;
    }
}

.box-overflow,
.box-overflow-arrow {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.uk {
    //CONTAINER
    &-container {
        @media (min-width: $breakpoint-large) {
            padding-left: $container-padding-horizontal-l;
            padding-right: $container-padding-horizontal-l;
        }
        @media (min-width: $breakpoint-xlarge) {
            padding-left: $container-padding-horizontal-xl;
            padding-right: $container-padding-horizontal-xl;
        }
    }

    //LINK
    &-link {
        font-weight: 500;
    }

    //BUTTON
    &-button {
        font-weight: bold;
        border-radius: 12px;
        transition: all 0.3s ease-in-out;
        position: relative;

        > [class*="icon-"],
        > [class*="uk-icon"] {
            margin-right: 5px;
            vertical-align: -1px;

            svg {
                width: 16px;
            }
        }

        &.uk-icon-left {
            display: flex;
            align-items: center;

            > * {
                + * {
                    margin: 0 auto !important;
                }
            }
        }

        &.uk-icon-right {
            display: flex;
            align-items: center;

            > * {
                + * {
                    margin: 0 0 0 auto !important;
                }
            }
        }

        > [class*="icon-"]:last-child,
        > [class*="uk-icon"]:last-child {
            margin-left: 5px;
            margin-right: 0;
        }

        &.uk-button-icon {
            border-radius: 50%;
            padding: 0;
            min-width: 38px;

            > * {
                margin: 0 !important;
            }
        }

        &-small {
            > [class*="icon-"],
            > [class*="uk-icon"] {
                font-size: 14px;

                svg {
                    width: 14px;
                }
            }

            &.uk-button-icon {
                min-width: 40px;
                width: 40px;
            }
        }

        &-large {
            &.uk-button-icon {
                min-width: 80px;
                width: 80px;
            }
        }

        &-primary {
            &-outline {
                box-shadow: 0 0 0 2px inset var(---colour-01-primary-03-p-2);
                @include color-primary-black;
                background-color: transparent;
                @media not all and (min-resolution: 0.001dpcm) {
                    @supports (-webkit-appearance: none) {
                        box-shadow: 0 0 0 2px var(---colour-01-primary-03-p-2);
                    }
                }
            }
        }

        &-secondary {
            &-outline {
                box-shadow: 0 0 0 2px inset var(---colour-03-semantic-01-succes-2);
                color: var(---colour-03-semantic-01-succes-2);
                background-color: transparent;
                @media not all and (min-resolution: 0.001dpcm) {
                    @supports (-webkit-appearance: none) {
                        box-shadow: 0 0 0 2px var(---colour-03-semantic-01-succes-2);
                    }
                }
            }
        }

        &-default {
            &-outline {
                box-shadow: 0 0 0 2px inset var(---effects-02-darker-60-2);
                @include color-primary-black;
                background-color: transparent;
                @media not all and (min-resolution: 0.001dpcm) {
                    @supports (-webkit-appearance: none) {
                        box-shadow: 0 0 0 2px var(---effects-02-darker-60-2);
                    }
                }
            }
        }

        &-tertiary {
            background-color: $button-tertiary-background;
            color: $button-tertiary-color;
            text-decoration: none;

            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
                color: $button-tertiary-color;
            }
        }

        @media (max-width: 325px) {
            padding: 0 25px;
        }

        @media (max-width: $breakpoint-medium-max) {
            &:not(.uk-button-large) {
                line-height: 38px;
                font-size: 14px;
            }
        }
        @media (min-width: $breakpoint-large) {
            &.uk-button-icon {
                min-width: 56px;
            }
        }
    }

    //INPUT / SEARCH
    &-input,
    &-search-input {
        border-radius: 16px;
        border: 1px solid rgba($black, 0.3);

        &.location-finder {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &.location-finder-number:not(:focus):not(:placeholder-shown) {
            border: none;
            background: transparent;
        }

        &.uk-form {
            &-success {
                @include hook-form-success();
            }

            &-danger {
                @include hook-form-danger();
            }
        }

        &.yellow-bottom-border {
            width: auto;
            border-radius: 0;
            height: auto;
            border: none;
            border-bottom: 1px solid rgba($black, 0.2);

            &:focus {
                border: none;
                background: transparent;
                border-bottom: 1px solid rgba($black, 0.8);

                &.blank {
                    border-bottom: 1px solid $pin-yellow;
                }
            }
        }
    }

    &-checkbox,
    &-radio {
        border: 2px solid var(---effects-01-opacity-30-2);
        transition: all 0.3s ease-in-out;

        &:hover {
            border-color: rgba($primary-orange, 0.6);
        }

        &:checked {
            border-color: rgba($primary-orange, 0.6);
        }

        &.error-required-checkout {
            border: 2px solid var(---colour-03-semantic-02-error-2);
        }
    }

    &-checkbox {
        &:checked,
        &:indeterminate {
            background-color: $primary-orange;
        }

        border-radius: 3px;
    }

    &-radio {
        &:checked {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23EB5305%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%222%22%20%2F%3E%0A%3C%2Fsvg%3E");
            background-size: 46px;
        }
    }

    &-checkbox-custom {
        position: relative;
        padding-left: 28px;
        cursor: pointer;

        .checkmark {
            position: absolute;
            left: 0;
            top: 2px;
            height: 18px;
            width: 18px;
            background-color: $white;
            border: 2px solid var(---effects-01-opacity-30-2);
            border-radius: 3px;
            transition: all 0.3s ease-in-out;

            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 6px;
                top: 3px;
                width: 4px;
                height: 8px;
                border: solid $white;
                border-width: 0 2px 2px 0;
                transition: all 0.3s ease-in-out;
                border-radius: 1px;
                transform: rotate(45deg);
            }

            @media only screen and (min-width: $breakpoint-xlarge) {
                &:after {
                    left: 6px;
                    top: 3px;
                }
            }

            &.error-required-checkout {
                border: 2px solid var(---colour-03-semantic-02-error-2);
            }
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            left: 0;

            ~ .checkmark.selected {
                background-color: $primary-orange;
                border-color: rgba($primary-orange, 0.6);

                &:after {
                    display: block;
                }
            }
        }

        &:hover input ~ .checkmark {
            border-color: rgba($primary-orange, 0.6);
        }

        &.inverse {
            padding-left: 0;
            padding-right: 30px;

            .checkmark {
                left: inherit;
                right: 0;
            }
        }
    }

    //ACCORDION
    &-accordion {
        &-title {
            cursor: pointer;

            &:before {
                font-family: "icomoon" !important;
                content: "\e932";
                background: none;
                transition: all 0.3s ease-in;
                width: 1em;
                height: 1em;
                margin-left: 10px;
                float: right;
            }
        }

        //applica un divider tra gli elementi <li> dell'accordion
        &.accordion-divider {
            li {
                position: relative;
                margin-bottom: 35px;

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background: rgba(0, 0, 0, 0.1);
                    bottom: -13px;
                    left: 0;
                }
            }
        }
    }

    &-open > .uk-accordion-title::before {
        transform: rotate(180deg);
    }

    //OFFCANVAS
    &-offcanvas {
        &-close {
            top: 10px;
            right: 10px;
            padding: 14px;
            visibility: hidden;
            > * > * {
                stroke-width: 2;
            }
        }

        &-header {
            padding: 16px;

            &.uk-active,
            &.scroll {
                box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            }

            span {
                @include color-primary-black();
            }
        }

        &-body {
            padding: 16px;

            > * {
                padding-right: 5px;
            }

            .uk-nav {
                border-top: 1px solid #e6e6e6;
                border-bottom: 1px solid #e6e6e6;
                padding: 5px 0;
                margin-bottom: 15px;

                &-elem {
                    @include color-primary-black();
                    font-size: 14px;
                    line-height: 1;
                    padding: 16px 0;
                    text-decoration: none;
                    flex-direction: row;

                    i {
                        padding: 0 16px 0 5px;
                    }

                    .icon-chevron-right {
                        float: right;
                        padding: 0;
                    }
                }
            }

            .no-result {
                padding: 30px 10px 0;
                font-weight: bold;
            }
        }

        &-footer {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            padding: 16px;
            background-color: $white;

            @media (max-width: $breakpoint-medium-max) {
                .btn-change-service {
                    width: 100%;
                }
            }
        }

        &-title {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.33;
            margin-top: 0;

            a {
                text-decoration: none;
            }
        }
        &.uk-open {
            .uk-offcanvas-close {
                visibility: visible;
            }
        }

        @media (min-width: $breakpoint-large) {
            &-bar {
                min-width: $offcanvas-bar-width-l;
                width: 100%;
                max-width: 720px;
                left: -50%;
            }
            &-close {
                top: 30px;
                right: 60px;
            }
            &-header {
                padding: 24px 72px;
            }
            &-body {
                padding: 40px 68px 40px 72px;
            }
            &-footer {
                padding: 16px 72px;
            }
        }
    }

    //PAGINATION
    &-pagination {
        li {
            a {
                @include color-primary-black(0.4);
            }
        }
        a {
            text-decoration: none;
            font-weight: 500;
            [class*="icon-"] {
                @include color-primary-black();
                font-size: 20px;
                position: relative;
                top: 2px;
            }
        }
    }

    //LABEL FLOAT
    &-label-float {
        display: flex;
        flex-flow: column-reverse;
        transition: all 0.3s ease-in;
        margin-bottom: 1em;
        min-height: 56px;
        height: 56px;
        position: relative;

        .pac-container-custom.custom {
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
        }

        .uk-form-icon {
            border: none;

            &:disabled {
                background-color: $lotion;
            }
        }

        label,
        input {
            transition: all 0.2s;
            touch-action: manipulation;
        }

        &.form-control-error {
            margin-bottom: 40px;
            @media (min-width: $breakpoint-medium) {
                margin-bottom: 33px;
            }
        }

        .message-error {
            position: absolute;
            bottom: -35px;
            font-size: 13px;
            font-weight: normal;
            line-height: 1.23;
            padding: 0 16px 0px 16px;
            min-height: 30px;
            &.msg-error-street-address {
                width: 100%;
                box-sizing: border-box;
                > * {
                    display: block;
                }
                .street-address-proceed {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1;
                    @include color-primary-black();
                    padding-top: 3px;
                }
            }
            @media (min-width: $breakpoint-medium) {
                &.msg-error-street-address {
                    &:before {
                        clear: both;
                        content: "";
                    }
                    > * {
                        float: left;
                        display: inline-block;
                    }
                    .street-address-proceed {
                        float: right;
                        padding-top: 0;
                        margin-left: 0;
                        button {
                            font-weight: normal;
                            line-height: inherit;
                            font-size: inherit;
                            padding: 0;
                            padding-left: 4px;
                            margin: 0;
                            text-align: inherit;
                            text-decoration: underline;
                            position: relative;
                            top: -3px;
                        }
                    }
                }
            }
        }

        input {
            -webkit-appearance: none;
            margin-top: -25px;
            min-height: 56px;
            height: 56px;
            &:not(:placeholder-shown) {
                border-color: rgba($black, 0.8);
            }

            &:placeholder-shown + label {
                cursor: text;
                max-width: 80%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                transform-origin: left bottom;
                transform: translate(17px, 15px);
                font-size: 17px;
                color: rgba($black, 0.6);
                font-weight: 500;
            }

            &:focus::-webkit-input-placeholder {
                opacity: 1;
            }

            &:focus,
            &:not(:placeholder-shown) {
                padding-top: 15px;
                @include color-primary-black;
            }

            &:focus {
                border-color: $yellow;
                background-color: rgba($yellow, 0.2);
            }

            /*&:not(:placeholder-shown) {
                border-color: $black;
            }*/

            &:not(:placeholder-shown) + label,
            &:focus + label {
                transform: translate(16px, -1px);
                cursor: pointer;
                font-size: 13px;
                max-width: 70%;
                font-weight: normal;
                @include color-primary-black;
            }

            &.uk-form-danger {
                border-color: var(---colour-03-semantic-02-error-2);
            }

            &.uk-form-warning {
                border-color: var(---colour-03-semantic-03-warnin-2);
            }

            &.uk-form-success {
                border-color: var(---colour-03-semantic-01-succes-2);
            }

            @-moz-document url-prefix() {
                &::placeholder {
                    color: transparent;
                }

                &:focus {
                    &::placeholder {
                        color: rgba($black, 0.6);
                    }
                }
            }
        }

        ::-webkit-input-placeholder {
            opacity: 0;
            transition: inherit;
        }

        .uk-form-icon:not(.uk-form-icon-flip) {
            @include color-primary-black;
            background-color: transparent;

            > [class*="icon-"] {
                font-size: 24px;
            }

            + input {
                &:placeholder-shown + label {
                    transform: translate(60px, 15px);
                }
            }

            + input:not(:placeholder-shown) + label,
            + input:focus + label {
                transform: translate(60px, -1px);
            }
        }

        .uk-form-icon {
            text-decoration: none;
            cursor: pointer;

            &-flip {
                cursor: default;
                border-radius: 14px;
                color: $primary-disabled-text;
                width: 50px;
                height: 50px;
                top: 3px;
                right: 3px;
                transition: all 0.3s ease-in-out;

                &:not([disabled]) {
                    background-color: var(---colour-01-primary-03-p-2);
                    color: $white;
                    cursor: pointer;

                    &:not(.remove):hover {
                        background-color: rgba($primary-orange, 0.9);
                    }
                }

                * {
                    font-size: 20px;
                }

                svg {
                    width: 20px;
                }

                &.no-bg {
                    background-color: transparent;
                    @include color-primary-black;
                }

                &.remove {
                    display: none;
                    right: 54px;
                    background: transparent;
                    @include color-primary-black;
                    width: 30px;
                    height: 40px;
                    padding: 0;
                    top: 9px;

                    * {
                        font-size: 14px;
                    }

                    svg {
                        width: 14px;
                    }

                    + input {
                        padding-right: 115px !important;
                    }

                    + .location-finder.street-number-visible {
                        padding-right: 200px !important;
                    }

                    + label {
                        max-width: 78%;
                    }
                }
            }
        }
    }

    //CART
    &-card {
        &-body {
            border-radius: 12px;
            border: 1px solid var(---effects-01-opacity-20-2);
            box-sizing: border-box;
        }

        &-type-default {
            font-size: 14px;

            .label {
                margin-bottom: 8px;
            }

            .desc {
                @include color-primary-black;
                line-height: 1.43;
            }

            @media (min-width: $breakpoint-large) {
                font-size: 16px;
            }
        }

        &-type-image-top {
            // view component
        }

        &-type-question {
            // view component
        }

        &-type-illustration {
            padding: 0;
            min-height: 80px;
            font-size: 18px;
            font-weight: bold;
            @include color-primary-black();
            text-decoration: none;

            &:hover {
                text-decoration: none;
                @include color-primary-black();
            }

            i {
                font-size: 33px;
                margin: 0 23px;
                color: $primary-disabled-text;
            }
        }

        &-type-product-minicart {

            .alert-quantita-aggiornata{
                i {
                    background-color: var(---colour-03-semantic-03-warnin-2) !important;
                    margin-right: 4px;
                }
        
                span{
                    font-size: 9px;
                    line-height: 16px;
                    font-weight: bold;
                    text-shadow: #ffb602 0px 0 0.3px;
                }
            }
            
            .uk-card-body {
                padding: 16px 8px;
                border: none;
                @include color-primary-black();
                box-shadow: 0 1px 1px 0 rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);

                img {
                    margin-right: 4px;
                    max-width: 42px;
                }
            }

            .uk-card-title {
                font-size: 12px;
                font-weight: normal;
                line-height: 1.33;
                margin: 0 0 4px;
            }

            .category {
                font-size: 10px;
                font-weight: bold;
                line-height: 1.2;
                margin: 0 0 6px;
            }

            .price {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.25;
                font-family: "Roboto Slab", serif;
                span {
                    font-family: "Roboto Slab", serif;
                }
                a {
                    @include color-primary-black();
                    text-decoration: none;
                }
            }

            .trash {
                [class*="icon-"] {
                    position: relative;
                    top: 2px;
                }
            }

            &.not-available {
                img {
                    filter: grayscale(100%);
                }

                .uk-card-title,
                .category {
                    color: $primary-black;
                }

                .unavailable-badge {
                    font-size: 12px;
                    font-weight: 500 !important;
                    padding: 0 16px;
                    white-space: nowrap;
                    background-color: $pink-light;
                    font-family: 'DM Sans', sans-serif;
                }
            }
        }

        &-type-flat {
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 16px 3px;
            border: solid 1px var(---effects-01-opacity-20-2);
            text-align: center;
            font-size: 12px;

            &:hover {
                text-decoration: none;
            }

            a {
                text-decoration: none;
            }

            .text {
                @include color-primary-black;
                display: block;
                font-weight: bold;
                line-height: 1;
                text-decoration: none;
            }

            img {
            }

            &-offer {
                display: flex;
                border: none;
                padding: 0;
                font-size: 14px;

                .text {
                    @include color-primary-black;
                    text-align: center;
                    font-weight: normal;
                    line-height: 1.43;
                }

                img {
                    border-radius: 50%;
                    margin: 0 2px 16px;
                    padding: 12px;
                    box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
                    background-color: $white;
                }
            }
        }

        //CARD BADGE
        &-badge {
            @media (min-width: $breakpoint-large) {
                right: 16px;
            }
        }
    }

    //MODAL
    &-modal {
        &-dialog {
            box-shadow: 0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            border-radius: 12px;
        }

        &-close {
            &-default {
                background: none;
                border: none;
                outline: none;
                font-size: 18px;
                top: 14px;
                right: 12px;
                color: $primary-black;
                cursor: pointer;
            }
        }

        &-footer {
            border-radius: 0 0 12px 12px;
        }

        @media (min-width: $breakpoint-large) {
            &-close {
                &-default {
                    //right:
                }
            }
            &-body {
                padding: 24px 24px;
            }
        }
    }

    //SWITCH
    &-switch {
        position: relative;
        display: inline-block;
        width: $switch-width;
        height: $switch-height;

        @media only screen and (max-width: $breakpoint-large-2-max) {
            flex-shrink: 0;
            width: $switch-width-small;
            height: $switch-height-small;
        }

        input {
            display: none;

            &:checked + .uk-switch-slider {
                background-color: $primary-orange !important;

                &:before {
                    background-color: $white;
                    transform: translateX(
                        calc(
                            #{$switch-width} - (#{$switch-bullet-size} * 2 - (#{$switch-bullet-size} - (#{$switch-bullet-distance +
                                                        $switch-bullet-distance})))
                        )
                    );
                    @media only screen and (max-width: $breakpoint-large-2-max) {
                        transform: translateX(
                            calc(
                                #{$switch-width-small} - (#{$switch-bullet-size-small} * 2 - (#{$switch-bullet-size-small} -
                                                    (#{$switch-bullet-distance-small + $switch-bullet-distance-small})))
                            )
                        );
                    }
                }

                &.with-icon {
                    &::after {
                        font-family: "icomoon" !important;
                        content: "\e933";
                        color: var(---effects-01-opacity-30-2);
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        right: 4px;
                        left: auto;
                        font-size: 16px;

                        @media only screen and (max-width: $breakpoint-large-2-max) {
                            top: 1px;
                            right: 3px;
                            left: auto;
                            font-size: 10px;
                        }
                    }
                }
            }
        }

        &-slider {
            background-color: $white;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 500px;
            bottom: 0;
            cursor: pointer;
            transition-property: background-color;
            transition-duration: 0.2s;

            &:before {
                content: "";
                background-color: $primary-orange;
                position: absolute;
                width: $switch-bullet-size;
                height: $switch-bullet-size;
                left: $switch-bullet-distance;
                bottom: $switch-bullet-distance;
                border-radius: 50%;
                transition-property: transform, box-shadow;
                transition-duration: 0.2s;

                @media only screen and (max-width: $breakpoint-large-2-max) {
                    width: $switch-bullet-size-small;
                    height: $switch-bullet-size-small;
                    left: $switch-bullet-distance-small;
                    bottom: $switch-bullet-distance-small;
                }
            }

            &.with-icon {
                &::after {
                    font-family: "icomoon" !important;
                    content: "\e92e";
                    color: $white;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 4px;
                    font-size: 16px;

                    @media only screen and (max-width: $breakpoint-large-2-max) {
                        top: 2px;
                        left: 4px;
                        font-size: 8px;
                    }
                }
            }
        }
    }

    //ALERT
    &-alert {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.43;
        border-radius: 12px;
        display: flex;
        align-items: center;

        > i {
            font-size: 18px;
            margin-right: 17px;
            min-width: 16px;
        }

        .uk-alert-close,
        .uk-alert-hide {
            text-decoration: none;
            @include color-primary-black;
            padding: 11px;
        }

        > p {
            margin: 0;
            padding-right: 30px;
        }

        .uk-button {
            &.uk-alert-close,
            &.uk-alert-hide,
            &.action {
                position: absolute;
                line-height: 38px;
                padding: 0 17px;
                background-color: transparent;
                top: 50%;
                margin-top: -19px;
                right: 10px;

                + p {
                    margin-right: 100px;
                }
            }
        }
        &-danger {
            background-color: $pink-light-2;
        }

        button {
            &.icon-close {
                box-shadow: none !important;
                border: none;
                border-radius: 0;
                background: transparent;
                cursor: pointer;
            }
        }

        &-button-action {
            > p {
                padding-right: 180px;
            }
            a + button,
            button + button {
                right: 50px !important;
            }
            .uk-alert-close,
            .uk-alert-hide {
                top: 50%;
                margin-top: -18px;
            }
        }

        &-info,
        &-primary {
            button.uk-alert-close,
            button.uk-alert-hide,
            button.action {
                box-shadow: 0 0 0 2px var(---colour-03-semantic-04-inform-2);
                color: var(---colour-03-semantic-04-inform-2);
            }
        }

        &-warning {
            > i {
                background-color: var(---colour-03-semantic-03-warnin-2) !important;
            }

            button.uk-alert-close,
            button.uk-alert-hide,
            button.action {
                box-shadow: 0 0 0 2px var(---colour-03-semantic-03-warnin-2);
                color: rgba($black, 0.6);
            }
        }

        &-danger {
            button.uk-alert-close,
            button.uk-alert-hide,
            button.action {
                box-shadow: 0 0 0 2px var(---colour-03-semantic-02-error-2);
                color: var(---colour-03-semantic-02-error-2);
            }
        }
    }

    //TAG
    &-tag {
        border: none;
        padding: 0 8px;
        border-radius: 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 32px;
        min-width: 70px;
        text-align: center;
        text-decoration: none;
        background-color: $primary-disabled;
        cursor: pointer;
        margin-right: 8px;
        margin-bottom: 24px;
        position: relative;
        @include color-primary-black;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        transition: all 0.3ms ease-in-out;

        &:focus,
        &:hover,
        &:active {
            text-decoration: none;
            box-shadow: none;
            @include color-primary-black;
        }

        &.remove {
            padding-right: 22px;

            > a {
                position: absolute;
                width: 24px;
                height: 24px;
                right: 4px;
                top: 7px;
                text-decoration: none;
                display: inline-block;
                border-radius: 50%;
                background-color: var(---effects-01-opacity-10-2);
                margin-left: 10px;

                i {
                    font-size: 13px;
                    display: inline-block;
                    color: $black;
                    transform: translate(0px, -3px);
                }
            }
        }

        &.active {
            background-color: $white;
            text-decoration: none;
            @include color-primary-black;
            box-shadow: 0 0 10px -2px var(---dusty-orange), 0 0 0 4px inset rgba(243, 137, 51, 0.1);
            transition: all 0.3ms ease-in-out;

            &.remove {
                > a {
                    background-color: var(---colour-01-primary-03-p-2);

                    i {
                        color: $white;
                    }
                }
            }
        }

        &.disabled {
            opacity: 0.4;
            cursor: default;

            &:hover,
            &:focus {
                box-shadow: none;
                @include color-primary-black;
            }

            &.remove {
                > a {
                    cursor: default;
                }
            }
        }
    }

    //TILE
    &-tile {
        font-size: 12px;
        font-weight: bold;
        min-height: 120px;
        text-decoration: none;
        color: $white;

        &:hover {
            text-decoration: none;
            color: $white;
        }

        border-radius: 12px;

        > * {
            position: relative;
            z-index: 1;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(---effects-02-darker-80-2));
            border-radius: 12px;
        }
    }

    &-badge {
        &.discount {
            background-color: var(---colour-01-primary-01-p-2);
            color: $white;
            padding: 6px 8px;
            font-size: 12px;
            border-radius: 8px;
        }

        &.promo-yellow {
            font-size: 12px;
            color: var(---colour-01-primary-03-p-2);
            background-color: $very-pale-yellow;
            border-radius: 8px;
            padding: 6px 8px;
            width: max-content;
        }
    }

    &-notification-message {
        border-radius: 8px;
        font-weight: bold;
        &-success {
            background-color: var(---colour-03-semantic-01-succes-2);
            color: $white;
        }
        .uk-notification-close {
            color: $white;
        }
    }
}

//PREFERITE PRODUCT ICON
.manage-preferite-product {
    color: $primary-orange;
    background: transparent;
    font-size: 24px;
    border: none;
    box-shadow: none;
    z-index: 2;
    cursor: pointer;
    &.active {
        > [class*="icon-"]:before {
            content: "\e921";
        }
    }
}

//FIX ORARI DI CONSEGNA CHIP LABEL ONLY
.js-hours-container .uk-tag {
    padding-left: 15px;
    padding-right: 15px;
    width: 135px;
    background-color: $gray-chips;
    &.active {
        background-color: $white;
    }
}

//SCOLLDOWN Element button
.scrollDown {
    border-radius: 12px;
    box-shadow: 0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
    background-color: $gray;
    color: $white;
    position: relative;
    margin: 16px 0;
    &:not(.icon-close) {
        i {
            display: inline-block;
            animation: bounceInUp 1s infinite linear;
        }
    }

    &:hover,
    &:active,
    &:focus {
        color: $white;
    }
}

.scrolldown-container {
    z-index: 999;
}
.scrollUp {
    z-index: 1000;

    > * {
        color: $white;
        animation: none;
    }
    i {
        display: inline-block;
        animation: bounceInUp 1s infinite linear;
    }
}

//Custom select nativa
.select-native-ui {
    position: relative;
    select {
        color: inherit;
        margin: 0;
        width: 100%;
        font-family: inherit;
        font-size: inherit;
        outline: none;
        border: 1px solid #000000cc;
        border-radius: 16px;
        padding: 0 14px;
        line-height: 54px;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTYgMTMuMzMzYTEgMSAwIDAgMSAuODcgMS40OTVsLS4wNy4xMDUtNCA1LjMzNGExIDEgMCAwIDEtMS41MTguMDk1bC0uMDgyLS4wOTUtNC01LjMzNGExIDEgMCAwIDEgLjY4My0xLjU5M0w4IDEzLjMzM2g4ek0xMS40IDMuMmExIDEgMCAwIDEgMS4zMTkuMTA0bC4wODEuMDk2IDQgNS4zMzNhMSAxIDAgMCAxLS42ODMgMS41OTRsLS4xMTcuMDA2SDhhMSAxIDAgMCAxLS44NjktMS40OTVsLjA3LS4xMDUgNC01LjMzM2EuOTk5Ljk5OSAwIDAgMSAuMi0uMnoiIGZpbGw9IiMwMDAiLz4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTYgMTMuMzMzYTEgMSAwIDAgMSAuODcgMS40OTVsLS4wNy4xMDUtNCA1LjMzNGExIDEgMCAwIDEtMS41MTguMDk1bC0uMDgyLS4wOTUtNC01LjMzNGExIDEgMCAwIDEgLjY4My0xLjU5M0w4IDEzLjMzM2g4ek0xMS40IDMuMmExIDEgMCAwIDEgMS4zMTkuMTA0bC4wODEuMDk2IDQgNS4zMzNhMSAxIDAgMCAxLS42ODMgMS41OTRsLS4xMTcuMDA2SDhhMSAxIDAgMCAxLS44NjktMS40OTVsLjA3LS4xMDUgNC01LjMzM2EuOTk5Ljk5OSAwIDAgMSAuMi0uMnoiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iLjIiLz4KPC9zdmc+Cg==");
        background-repeat: no-repeat;
        background-position-x: calc(100% - 10px);
        background-position-y: 50%;
        option {
            background: $white;
        }
        //&:focus {
        //    background-color: rgba($yellow, 0.1);
        //    border: solid 1px rgba(254, 212, 4, 0.6);
        //    box-shadow: 0 0 0 1px rgba(254, 212, 4, 0.6);
        //
        //}

        &::-ms-expand {
        }

        &:invalid {
            border: 1px solid rgba(0, 0, 0, 0.3);

            & ~ .select-label {
                display: none;
            }
        }
    }

    .select-label {
        position: absolute;
        z-index: 1;
        left: 16px;
        top: 7px;
        font-size: 13px;
        font-weight: normal;
        background-color: transparent;
        transition: all 0.3s ease-in-out;
        @include color-primary-black();
    }
}

//Custom Select
.js-select-custom {
    position: relative;
    height: 100%;
    &.empty {
        .select-label {
            top: 16px;
            font-size: 16px;
            @include color-primary-black(0.6);
            font-weight: 500;
        }
        .styledSelect {
            border: 1px solid rgba($black, 0.3);
            //box-shadow: 0 0 0 1px inset rgba($black, 0.3);
        }
    }
    select,
    .s-hidden {
        visibility: hidden;
        padding-right: 10px;
        width: 1px;
        height: 1px;
        &.uk-form-danger + .styledSelect {
            border-color: var(---colour-03-semantic-02-error-2);
            //box-shadow: 0 0 0 1px inset var(---colour-03-semantic-02-error-2);
        }
    }
    .select-custom {
        cursor: pointer;
        position: relative;
        height: 100%;
        max-height: 56px;
        box-sizing: border-box;
    }
    .select-label {
        position: absolute;
        z-index: 1;
        left: 16px;
        top: 7px;
        font-size: 13px;
        font-weight: normal;
        background-color: transparent;
        transition: all 0.3s ease-in-out;
        @include color-primary-black();
    }
    .styledSelect {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 17px 50px 0 16px !important;
        line-height: 39px;
        font-size: 17px;
        font-weight: 500;
        text-align: left;
        border-color: rgba($black, 0.8);
        //border: none;
        //box-shadow: 0 0 0 2px inset var(---effects-02-darker-60-2);
        z-index: 1;
        background-color: transparent;
        @include color-primary-black();

        &.active {
            &:after {
                transform: rotate(180deg);
            }
        }
        &:after {
            content: "\e909";
            font-family: "icomoon";
            position: absolute;
            top: 50%;
            right: 16px;
            line-height: 10px;
            margin-top: -5px;
            font-size: 22px;
            transition: all 0.3s ease-in-out;
        }
        &.active {
        }

        &.error-required-checkout {
            border: 1px solid var(---colour-03-semantic-02-error-2) !important;
        }
    }
    .options {
        display: none;
        position: absolute !important;
        background-color: $gray;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        overflow: auto;
        min-height: 175px;
        max-height: 200px;
        margin: 4px 0 0 0;
        padding: 0 !important;
        border-radius: 16px;
        list-style: none;
        li {
            margin: 0 0;
            &:hover {
                background-color: $barely-black;
            }
            &.selection {
                background-color: $barely-black;
                &:after {
                    font-family: "icomoon";
                    content: "\e933";
                    font-size: 20px;
                    line-height: 15px;
                    position: relative;
                    top: 3px;
                    left: 10px;
                }
            }
        }
        .mCSB_container {
            margin-right: 0 !important;
            padding: 0 !important;
            > * {
                color: $white;
                padding: 16px;
                border: 1px solid transparent;
                border-color: transparent transparent rgba($black, 0.1) transparent;
                cursor: pointer;
                line-height: normal;
            }
        }
        .mCSB_scrollTools {
            right: 10px;
            .mCSB_draggerContainer {
                background-color: rgba($white, 0.2);
                top: 20px;
                bottom: 20px;
                opacity: 0.8;
            }
            .mCSB_dragger .mCSB_dragger_bar {
                background-color: $white;
            }
        }
    }

    &-cart-txt-desktop-scale {
        .styledSelect {
            height: 16px !important;
            text-align: left;
            padding: 0 10px 0 0 !important;
            line-height: 1;
            top: 7px;
            border-radius: 0;

            &:after {
                right: 0;
                top: 35%;
            }
        }

        .options {
            width: 179px;
            font-size: 17px;
            line-height: 17px;

            .selection {
                position: relative;

                &:after {
                    position: absolute !important;
                    top: 19px !important;
                    right: 10% !important;
                    left: auto !important;
                }
            }
        }
    }

    &-cart-txt-mobile-qty {
        .styledSelect {
            height: 16px !important;
            text-align: left;
            padding: 0 10px 0 0 !important;
            line-height: 1;
            top: 7px;
            border-radius: 0;

            &.active {
                &:after {
                    transform: rotate(0);
                }
            }

            &:after {
                right: 0;
                content: "\e90a";
                font-family: "icomoon";
                position: absolute;
                line-height: 10px;
                margin-top: -5px;
                font-size: 16px;
            }
        }

        .options {
            width: 179px;
            font-size: 17px;
            line-height: 17px;

            .selection {
                position: relative;

                &:after {
                    position: absolute !important;
                    top: 19px !important;
                    right: 10% !important;
                    left: auto !important;
                }
            }
        }
    }
}

//Dropdown black
.dropdown-black {
    padding: 0;
    min-width: 0;
    width: 179px;
    margin-top: 0;
    list-style-type: none;
    background-color: $global-emphasis-color;
    border-radius: 16px;
    overflow: hidden;

    > * {
        cursor: pointer;
        &.dropdown-item {
            display: flex;
            justify-content: space-between;
            color: $white;
            padding: 16px;

            &:hover,
            &.selected {
                background-color: $barely-black;
            }

            .icon-check {
                visibility: hidden;
            }

            &.selected {
                .icon-check {
                    visibility: visible;
                }
            }
        }
    }
}

// CUSTOM TOOLTIP
.custom-tooltip-container {
    position: relative;
    cursor: pointer;

    &:hover .tooltiptext {
        visibility: visible;
    }
    .tooltiptext {
        visibility: hidden;
        min-width: 250px;
        position: absolute;
        z-index: 2;

        &.bottom-left {
            top: 100%;
            right: 0;
            margin-top: 4px;
        }
    }
}
.tooltiptext {
    box-sizing: border-box;
    font-family: $global-font-family;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    padding: 6px 12px;
    border-radius: 6px;
    &.info-blue {
        color: $white;
        background-color: $blue-info;
    }
    &.info-red {
        color: $white;
        background-color: $danger;
    }
    &.warning-yellow {
        background-color: $warning;
        @include color-primary-black(0.8);
    }
    &-drop {
        display: none;
        border-radius: 6px;
        z-index: 1101;
        .tooltiptext {
            max-height: 400px;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            padding-top: 15px;
        }
        @media (min-height: 600px) {
            max-height: 500px;
        }
        @media (min-height: 800px) {
            .tooltiptext {
                max-height: 600px;
            }
        }

        @media (min-height: 900px) {
            .tooltiptext {
                max-height: 850px;
            }
        }
    }
}

.tooltiptext-aggiungi-prodotti {
    min-width: 100px;
    max-width: 200px;
}

.warning-aggiungi-prodotti {
    position: relative;
    background: none;
    margin: 0;
    padding: 0;
    margin-left: auto;
}

//Progress Steps
.progress-steps {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        display: block;
    }
    > * {
        border-radius: 8px;
        height: 8px;
        min-width: 24px;
        display: inline-block;
        margin: 0 4px;
        box-shadow: 0 0 0 2px var(---colour-01-primary-02-p-2) inset;
        transition: all 0.3s ease-in-out;
        &.complete:not(.uk-active) {
            box-shadow: 0 0 0 4px var(---colour-01-primary-02-p-2) inset;
            opacity: 0.5;
        }

        &.uk-active {
            box-shadow: 0 0 0 4px var(---colour-01-primary-02-p-2) inset;
        }
    }
}

//PROGRESS DELIVERY COSTS
.delivery-costs-progress-bar {
    position: relative;
    margin-top: 10px;

    .progress-bar-custom {
        position: absolute;
        top: 0;
        z-index: 0;
        margin-top: 5px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        border: 0;
        border-radius: 10px;
        height: 8px;

        &::-webkit-progress-bar {
            border-radius: 10px;
        }

        &::-webkit-progress-value {
            background-image: linear-gradient(135deg, $yellow, var(---colour-02-secondary-02-s-2));
            border-radius: 10px;
        }

        &::-moz-progress-bar {
            border-radius: 10px;
            background-image: linear-gradient(135deg, $yellow, var(---colour-02-secondary-02-s-2));
        }

        &::-ms-fill {
            border-radius: 10px;
            background-color: linear-gradient(135deg, $yellow, var(---colour-02-secondary-02-s-2));
        }
    }

    .checkpoints {
        width: 100%;
        .checkpoint.active:not(.current) {
            .label {
                display: none;
            }
        }

        .checkpoint {
            text-align: right;
            position: relative;

            .uk-badge {
                z-index: 1;
                padding: 0;
                min-width: 17px;
                width: 17px;
                height: 17px;
                margin-right: 0;
                position: absolute;
                right: 0;
                color: var(---colour-02-secondary-01-s-2);
                background: var(---colour-02-secondary-01-s-2);

                &:before {
                    font-family: "icomoon";
                    content: "\e933";
                    display: block;
                }
            }

            .label {
                text-align: right;
                margin-left: auto;
                font-size: 9px;
                font-weight: normal;
                line-height: 1.2;
                padding-top: 17px;
                @include color-primary-black();
                opacity: 0.5;

                > * {
                    display: block;
                }

                > b {
                    font-family: "Roboto Slab", serif;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.67;
                }
            }

            &.active {
                .uk-badge {
                    background: linear-gradient(135deg, $yellow, var(---colour-02-secondary-02-s-2));
                    &:before {
                        color: $white;
                    }
                }

                .label {
                    opacity: 1;
                }
            }
        }
    }

    /* Da usare nei casi di doppia soglia di consegna */
    &.doppia-soglia {
        .checkpoint:first-child {
            max-width: 25%;
        }
    }
}

//Divider
.divider {
    display: block;
    position: relative;
    min-height: 16px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    text-align: center;

    &:before {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: -0.5px;
        background-color: rgba($black, 0.2);
    }

    > * {
        position: relative;
        display: inline-block;
        padding: 0 15px;
        background-color: $white;
        margin: 0 auto;
        z-index: 1;
    }
}

//SECTION
.section {
    padding: 32px 0;

    &-title {
        font-size: 28px;
        font-weight: normal;
        position: relative;
        margin-bottom: 60px;

        &:before {
            border-radius: 4px;
            content: "";
            display: block;
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 32px;
            height: 4px;
            background-color: $yellow;
            @media (min-width: $breakpoint-xxlarge) {
                bottom: -20px;
            }
        }

        &-2 {
            font-size: 28px;
            margin-bottom: 16px;
        }

        /* Le section title nell'entrypage sono tutte centrate, aggiungere questa classe per centrarle */
        &-entrypage {
            text-align: center;

            &::before {
                left: calc(50% - 16px);
            }
        }
    }

    &-description {
    }

    @media (min-width: $breakpoint-xxlarge) {
        padding: 32px 0 30px;
        &-title {
            font-size: 32px;
            margin-bottom: 80px;
        }
    }
    @media (min-width: $breakpoint-xxxlarge) {
        padding: 56px 0 38px;
    }

    &.section-hidden {
        overflow: hidden;
    }

    &.section-carousel {
        .section-title {
            margin-bottom: 28px;
        }

        .swiper-carousel {
            //fix for shadow
            padding: 3px 7px 30px 7px;
            margin-left: -7px;

            .swiper-button-next,
            .swiper-button-prev,
            .swiper-pagination-custom {
                display: none;
            }

            .component-ProductCard {
                .product-card {
                    background-color: $white;
                }
            }

            @media (min-width: $breakpoint-large) {
                padding-top: 45px;
                margin-top: -35px;
                padding-bottom: 35px;

                .swiper-button-next {
                    display: block;
                    top: 1px;
                    margin-top: 0;
                    right: 10px;
                    --swiper-navigation-size: 16px;
                    --swiper-theme-color: black;
                }

                .swiper-button-prev {
                    display: block;
                    top: 1px;
                    margin-top: 0;
                    right: 45px;
                    left: auto;
                    --swiper-navigation-size: 16px;
                    --swiper-theme-color: black;
                }

                .swiper-scrollbar {
                    width: 48%;
                    left: 26%;
                    @media (min-width: $breakpoint-xlarge) {
                        width: 36%;
                        left: 32%;
                    }
                }

                .swiper-pagination-custom {
                    display: block;
                    position: absolute;
                    width: 80px;
                    height: 20px;
                    top: 0px;
                    margin-top: 0;
                    right: 52px;
                    left: auto;
                    font-weight: 500;
                    color: $black;
                }
            }

            @media (min-width: $breakpoint-large) {
                .component-ProductCard {
                    .product-card {
                        .card-middle {
                            .product {
                                flex-direction: column;
                            }

                            /* .product-img {
                                margin-bottom: 30px;
                            } */

                            .product-info {
                                margin-left: 0px;

                                /* .product-quantity {
                                    margin-bottom: 0px;
                                } */
                            }

                            .info-weight {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

//SECTIONS SINGLE
.section {
    &-order-receive {
        .uk-offcanvas {
            &-bar {
                overflow-y: hidden;
                -webkit-overflow-scrolling: unset;
            }

            &-body {
                height: calc(100vh - 220px);
                padding: 0;
                position: relative;

                .box-overflow {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    //overflow-y: auto;
                    padding: 0 4px;
                    box-sizing: border-box;
                    //@include scrollbar();
                    padding-bottom: 70px;

                    @media (min-width: $breakpoint-large) {
                        padding-bottom: 0;
                    }
                }

                .result-bar {
                    padding-top: 24px;
                }

                .no-result {
                    padding: 30px 10px 0;
                    font-weight: bold;
                }
            }

            &-title {
                font-size: 20px;

                a {
                    @include color-primary-black;
                    font-size: 24px;
                    margin-right: 24px;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    vertical-align: -2px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        width: 24px;
                    }
                }
            }

            &-footer {
                .uk-button {
                    + .uk-button {
                        margin-left: 16px;
                    }
                }
            }
        }

        .progress-steps {
            padding: 16px 16px 0 16px;
            text-align: center;
            box-sizing: border-box;

            @media (min-width: $breakpoint-large) {
                padding: 40px 72px 12px 72px;
                text-align: left;
            }
        }

        .result-bar {
        }

        .back {
            position: absolute;
            top: 8px;
            left: 6px;
            width: 40px;
            height: 40px;
            text-decoration: none;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            @include color-primary-black;
        }

        .section-subtitle {
            font-weight: bold;
            font-size: 16px;
            color: rgba($black, 0.6);
        }

        .uk-list {
            > :nth-child(n + 2) {
                margin-top: 16px;
            }
        }

        .shipping-cost {
            .label {
                font-size: 14px;
                font-weight: 500;
                color: rgba($black, 0.5);
            }

            .price {
                font-size: 28px;
                line-height: 1.29;
                @include color-primary-black;
            }
        }

        .step-2 {
            .uk-offcanvas {
                &-body {
                    height: calc(100vh - 335px);
                }
            }

            .section-subtitle {
                margin-top: 24px;
            }

            .uk-alert {
                margin-bottom: 20px;
            }
        }

        @media (min-width: $breakpoint-large) {
            .mCSB_scrollTools .mCSB_draggerContainer,
            .ps__rail-y {
                margin-top: 25px;
                margin-bottom: 25px;
            }

            .uk-offcanvas {
                &-body {
                    //.box-overflow {
                    //    height: calc(100% - 88px);
                    //}
                    .result-bar {
                        padding-top: 40px;
                    }
                }
            }
            .step-2 {
                .section-subtitle {
                    margin-top: 40px;
                }
            }
        }

        @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
            .uk-offcanvas {
                &-body {
                    .box-overflow {
                        padding: 0 34px 0 60px;
                    }
                }
            }
        }

        @media (min-width: $breakpoint-xlarge) {
            .uk-offcanvas {
                &-title {
                    font-size: 24px;
                }

                &-body {
                    height: calc(100vh - 382px);

                    .box-overflow {
                        height: calc(100% - 88px);
                        padding: 0 32px 0 60px;
                    }
                }
            }
            .step-2 {
                &.layer {
                    &.uk-active {
                        &::before {
                            content: "";
                            left: 512px;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            display: block;
                            background: $modal-background;
                            width: 100%;
                            height: 100%;
                            position: fixed;
                            z-index: 1;
                        }
                    }
                }

                .uk-offcanvas {
                    &-body {
                        height: calc(100vh - 415px);
                    }
                }
            }
        }

        @media (min-width: $breakpoint-xxlarge) {
            .step-2 {
                &.layer {
                    &.uk-active {
                        &::before {
                            left: 622px;
                        }
                    }
                }
            }
        }

        @media (min-width: $breakpoint-xxxxlarge) {
            .step-2 {
                &.layer {
                    &.uk-active {
                        &::before {
                            left: 722px;
                        }
                    }
                }
            }
        }

        .swiper-container {
            width: calc(100% + 32px);
            height: 100%;
            padding-top: 50px;
            padding-bottom: 20px;
            padding-left: 16px;
            padding-right: 16px;
            margin-top: -50px;
            margin-left: -16px;
            box-sizing: border-box;

            .swiper-slide {
                /* width: auto; */
                display: flex;
                justify-content: center;
                align-items: center;

                &:nth-child(1n) {
                    /* width: 112px !important; */
                }
            }

            .swiper-button {
                &-next,
                &-prev {
                    padding: 5px 12px;
                    top: 3px;
                }

                &-next {
                    right: -7px;
                }

                &-prev {
                    left: inherit;
                    right: 28px;
                }
            }

            .swiper-scrollbar {
                width: 100%;
                max-width: 320px;
                left: 50%;
                margin-left: -160px;
            }

            @media (min-width: $breakpoint-large) {
                padding-bottom: 37px;
            }
        }
    }
}

//SWIPER TYPES
.swiper {
    //swiper card categorie
    &-type-category {
        .swiper-slide {
            width: 90px;
        }
    }

    &-days-delivery {
        .swiper-slide {
            width: auto;
        }
    }
}

/* Card dentro gli swiper */
.swiper-slide {
    .component-ProductCard {
        .product-info {
            @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge) {
                .product-price {
                    font-size: 24px !important;

                    &-original {
                        font-size: 10px !important;
                    }
                }

                .product-quantity {
                    font-size: 12px !important;
                }

                .product-price-kg {
                    font-size: 10px !important;
                }
            }
        }
    }
}

.browser-type__ios {
    // se non funziona solo con safari allora aggiungere la regola dedicata:
    //&.ios_safari {}

    &.uk-modal-page {
        body {
            overflow: hidden !important;
            position: fixed !important;
        }
    }
}

//GOOGLE MAPS MARKERS
.store_marker_map_container {
    width: 100%;
    max-width: 135px;
}

body.uk-offcanvas-container {
    [class*="pac-container"] {
        z-index: 9000 !important;
    }
}

html.uk-modal-page {
    [class*="pac-container"] {
        z-index: 9000 !important;
    }
}

//GOOGLE PLACES AUTOCOMPLETE SUGGESTION BOX
[class*="pac-container"] {
    background-color: $global-emphasis-color;
    border-radius: 16px;
    margin-top: 4px;
    z-index: 1100 !important;

    .pac-item,
    .pac-item-query {
        font-size: 17px;
    }

    .pac-item {
        border: none;
        padding: 16px;
        cursor: pointer;

        .pac-item-query {
            color: $white;
        }

        .pac-icon {
            display: none;
            font-size: 24px;
            vertical-align: -4px;
            margin-right: 5px;
        }
    }

    .pac-item-selected,
    .pac-item:hover,
    .pac-item:first-child {
        background-color: $barely-black;
    }

    .pac-item-selected {
        .pac-icon {
            display: inline-block;
            float: right;
        }
    }

    &.pac-logo {
        &:after {
            background-image: url("./resources/ecommerce/img/powered_by_google_on_non_white_hdpi.png") !important;
            height: 24px !important;
            position: relative;
            top: -2px;
            right: 2px;
        }
    }

    &.custom {
        width: 100%;
        display: none;
        z-index: 1000;
        box-shadow: 0 2px 6px rgba($black, 0.3);

        i {
            color: $white;
            font-size: 24px;
            vertical-align: -4px;
            margin-right: 5px;
        }

        .pac-item {
            border-radius: 16px 16px 0 0;
        }
    }
}

.hdpi.pac-logo:after {
    margin: 8px 16px;
    background-image: url("./resources/ecommerce/img/powered_by_google_on_non_white_hdpi.png");
}

//Lista indirizzi utenti (modal order receive)
.listUserAddress {
    .list-address-user {
        .card-user {
            padding: 16px;
            box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            background-color: $white;
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;
            @include color-primary-black();
            border-radius: 12px;
            display: flex;
            align-items: center;
            [class*="icon-"] {
                margin-right: 16px;
            }
            span {
            }
        }
    }
}

//WYSIWYG EDITOR
.wysiwyg_editor {
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 10px;
    }
    * + p {
        margin-top: 10px;
    }

    .html-esb-field {
        table {
            overflow: auto;
            width: 100%;
            border-spacing: 0;
            border: 1px solid rgba($black, 0.1);
            border-radius: 12px;

            tr {
                th,
                td {
                    padding: 8px;
                    font-size: 12px;
                    min-height: 28px;
                    max-width: 60vw;
                }
                th,
                td {
                    border-right: 1px solid rgba($black, 0.1);
                    border-bottom: 1px solid rgba($black, 0.1);
                    &:last-child {
                        border-right: none;
                    }
                }
                &:last-child {
                    th,
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    .certifications {
        display: flex;
        flex-wrap: wrap;

        & > img {
            max-height: 48px;
            max-width: 48px;

            &:not(:last-child) {
                margin-right: 4px;
                margin-bottom: 4px;
            }
        }
    }
}

// LOADER
.lds-ring {
    display: inline-block;
    position: relative;
    > * > *,
    [stroke*="#"] {
        stroke-width: 2 !important;
    }
    &.orange {
        > * > *,
        [stroke*="#"] {
            stroke: $primary-orange !important;
        }
    }

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid $silver;
        border-color: $silver transparent transparent transparent;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        :nth-child(3) {
            animation-delay: -0.15s;
        }
    }

    &-1 {
        position: absolute;
        right: 90px;
        top: 17px;

        div {
            width: 24px;
            height: 24px;
            margin: 0;
            border-width: 3px;
        }
    }

    &-2 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-30px, -30px);

        div {
            border-color: $primary-orange transparent transparent transparent;
            width: 56px;
            height: 56px;
            border-width: 4px;
        }
    }

    &-3 {
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &-4 {
        left: 50%;
        top: 50%;
    }

    &-fullpage {
        position: fixed;
        z-index: 999999;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba($white, 0.75);
        transform: none;
        > div {
            border-color: $primary-orange transparent transparent transparent;
            border-width: 2px;
            top: 50%;
            left: 50%;
            margin-left: -32px;
            margin-top: -32px;
        }
    }
}
.uk-button {
    .lds-ring {
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        margin: 0 !important;
        cursor: default;
        z-index: 1;
    }
    &-primary {
        .lds-ring {
            background: $primary-orange;
            color: $white;
        }
        &-outline {
            .lds-ring {
                background: $white;
                box-shadow: 0 0 0 2px inset var(---colour-01-primary-03-p-2);
                @media not all and (min-resolution: 0.001dpcm) {
                    @supports (-webkit-appearance: none) {
                        box-shadow: 0 0 0 2px var(---colour-01-primary-03-p-2);
                    }
                }
            }
            &.action {
                .lds-ring {
                    background: #e9f0fa;
                    box-shadow: none;
                    @media not all and (min-resolution: 0.001dpcm) {
                        @supports (-webkit-appearance: none) {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }
}

//HOTSPOTS
.s7mapoverlay.s7icon {
    width: 32px !important;
    height: 32px !important;
    border-radius: 90px !important;
    background-color: rgba(51, 51, 51, 0.8) !important;
    background-image: unset !important;
    opacity: 1 !important;
    pointer-events: all !important;

    &:before {
        border-radius: 90px !important;
        content: "" !important;
        display: block !important;
        position: relative !important;
        top: 12px !important;
        left: 12px !important;
        width: 8px !important;
        height: 8px !important;
        background-color: $white !important;
    }

    &:hover {
        &:before {
            top: 10px !important;
            left: 10px !important;
            width: 12px !important;
            height: 12px !important;
            background-color: $yellow !important;
        }
    }
}

//Note di preparazione - Nota libera
.single-choice-radio {
    label {
        margin: 0 0 16px !important;
        display: block;
    }

    .free-note-detail-product {
        margin-left: 8px;
        padding-left: 0;
        font-weight: 500;
        font-size: 14px;
        color: $primary-black;
    }

    .free-note-detail-cart {
        padding: 0;
        max-width: 240px;
        width: 100%;
        margin-left: 8px;
        @media (min-width: $breakpoint-xlarge) {
            margin-left: -4px;
        }
    }

    .uk-radio:not(:checked) {
        & + .free-note {
            opacity: 0.5;
        }
    }
}

form.google-input {
    background: $white;
    border-radius: 16px;
    margin-bottom: 5em !important;

    //Serve per modificare il margin bottom dell'input nella visualizzazione mobile per evitare l'overlay
    @media (min-width: $breakpoint-large) {
        margin-bottom: 0 !important;
    }

    //ICONA CANCEL
    button:first-of-type {
        position: absolute;
        top: 4px !important;
        right: 9px !important;
        background-color: transparent !important;
        width: auto !important;
        z-index: 10;
        display: none;
        @media (min-width: $breakpoint-large) {
            right: 112px !important;
        }

        &:hover {
            background-color: transparent !important;
        }
    }
    .google-input-line1 {
        z-index: 1;
        background: transparent;
        padding-right: 45% !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:focus {
            z-index: 0;
            background-color: rgba(254, 212, 4, 0.2);
        }
        &:not(:placeholder-shown) {
            & ~ button {
                display: block !important;
            }
        }
    }

    .google-input-line1 + label {
        text-align: left;
    }
    //LABEL FOR PDV_NUMBER
    .google-input-line2 + label {
        width: 45%;
        height: 100%;
        right: 15px;
        top: auto;
        position: absolute;
        text-align: left;
    }

    input.google-input-line2 {
        position: absolute;
        background: transparent;
        top: auto;
        width: 45%;
        right: 0;
        border-left: none;
        border-color: transparent;
        padding-left: 3px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        z-index: 1;
        &:focus {
            background: transparent;
            & + label {
                height: 100%;
                right: 15px;
                top: 5px;
                position: absolute;
                @media (max-width: $breakpoint-medium-max) {
                    width: 45%;
                }
            }
        }
        &:not(:placeholder-shown) {
            & + label {
                top: 5px;
            }
            & ~ button {
                display: block !important;
            }
        }
    }

    .submitButton {
        width: 110px;
        height: 50px;
        font-weight: bold;
        font-size: 16px;
        z-index: 1;
        .lds-ring {
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            transform: none;
            line-height: 44px;
            top: 0;
            background: $lotion;
            border-radius: 14px;
        }
    }

    .uk-form-icon-flip {
        @media (max-width: $breakpoint-medium-max) {
            cursor: default;
            border-radius: 14px;
            color: $white;
            width: 100%;
            height: 50px;
            top: 65px;
            right: 0;
            transition: all 0.3s ease-in-out;
            width: -webkit-fill-available;
        }
    }
}

.breadcrumb-container {
    padding-top: 32px;
    height: 16px;
}

.breadcrumb-custom-listing {
    .component-Breadcrumb {
        margin-top: 20px;
    }
}

//Listing Header Title + Description + Approfondisci
.listing-header {
    box-sizing: border-box;
    margin-top: 9px;

    .header {
        &-title {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 11px;
        }
        &-desc {
            font-size: 16px;
            margin-bottom: 12px;
            p {
                margin: 0;
            }
        }
    }
    a {
        font-size: 14px;
        font-weight: 500;
    }

    @media (max-width: $breakpoint-large-2-max) {
        margin-top: -2px;
        .header {
            &-title {
                font-size: 28px;
                margin-bottom: 18px;
            }
            &-desc {
                margin-bottom: 10px;
            }
        }
    }
}

//EXTRA
#onetrust-banner-sdk {
    a:focus {
        outline: none;
    }
}

//CUSTOM SPECIFIC BROWSER
.browser-type__ios {
    .uk-tag {
        &.active {
            box-shadow: 0 0 10px -2px var(---dusty-orange);
        }
    }
}

//CHATBOX
.ch-conad-chat-topbar {
    h1.ch-conad-chat-topbar-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        color: $white;
    }

    .ch-conad-chat-topbar-close {
        font-weight: normal !important;
        font-size: 2.6em;
        line-height: 20px;
    }
}
.cp-conad-chat-content {
    p#initialText-2 {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
        text-align: left;
        margin: 32px 16px 24px;
        @include color-primary-black();
    }
}

.cp-conad-chat-start {
    &-field {
        .cp-conad-chat-start-submit {
            width: 50%;
            margin: 24px 16px 77px;
            float: right;
        }
    }
}

.cp-conad-input-container {
    .slds-form-element__control {
        padding: 0 16px;
    }
    .slds-input {
        width: 100%;
        box-sizing: border-box;
        @media (max-width: 800px) {
            padding: 16px;
        }
    }
}

.custom-cursor-pointer {
    cursor: pointer;
}

.aem-classes {
    &-apply-display-block {
        display: block;
    }
}

.sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

/* Per nascondere label di accessibilità */
.visually-hidden {
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
    clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
    clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
    white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}

.device_mobile {
    .uk-notification-message {
        .uk-notification-close {
            display: block;
            color: $white;
        }
    }
}

.device_not_mobile_not_tablet {
    .conad-custom-native-scrollbar {
        /* Works on Firefox */
        & {
            scrollbar-width: thin;
            scrollbar-color: rgba(102, 102, 102, 1) rgba($black, 0.2);
        }

        /* Works on Chrome, Edge, and Safari */
        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: rgba($black, 0.2);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $black;
            border-radius: 12px;
            border: 1px solid rgba($black, 0.2);
        }
    }
}

@keyframes bounceInUp {
    0% {
        transform: translateY(0px);
    }
    30% {
        transform: translateY(-2px);
    }
    70% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulseBoxShadow {
    0% {
        box-shadow: 0 0 0 0 rgba($primary-orange, 0.4);
    }
    70% {
        box-shadow: 0 0 0 5px rgba($primary-orange, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba($primary-orange, 0);
    }
}

@keyframes loadingSK {
    100% {
        transform: translateX(100%);
    }
}

@mixin animation-pulse($duration, $color-name) {
    $name: animation-pulse-#{$color-name};

    @keyframes #{$name} {
        0% {
            -moz-box-shadow: 0 0 0 0 #{"rgba(var(---#{$color-name}), 0.4)"};
            box-shadow: 0 0 0 0 #{"rgba(var(---#{$color-name}), 0.4)"};
        }
        70% {
            -moz-box-shadow: 0 0 0 5px #{"rgba(var(---#{$color-name}), 0)"};
            box-shadow: 0 0 0 5px #{"rgba(var(---#{$color-name}), 0)"};
        }
        100% {
            -moz-box-shadow: 0 0 0 0 #{"rgba(var(---#{$color-name}), 0)"};
            box-shadow: 0 0 0 0 #{"rgba(var(---#{$color-name}), 0)"};
        }
    }

    @-webkit-keyframes #{$name} {
        0% {
            -webkit-box-shadow: 0 0 0 0 #{"rgba(var(---#{$color-name}), 0.4)"};
        }
        70% {
            -webkit-box-shadow: 0 0 0 5px #{"rgba(var(---#{$color-name}), 0)"};
        }
        100% {
            -webkit-box-shadow: 0 0 0 0 #{"rgba(var(---#{$color-name}), 0)"};
        }
    }

    animation-name: $name;
    animation-duration: $duration;
}

@media (max-width: $breakpoint-medium-max) {
}

@media (max-width: $breakpoint-small-max) {
}

@media (min-width: $breakpoint-medium) and (max-width: $breakpoint-medium-max) {
}

@media (min-width: $breakpoint-large) and (max-width: $breakpoint-large-max) {
}

@media (min-width: $breakpoint-xlarge) {
}

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
}

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
}

/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
}

/* iphone X , XS, 11 Pro, 12 Mini */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
}

/* iphone 12, 12 Pro */
@media only screen and (min-device-width: 390px) and (max-device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
}

/* iphone XR, 11 */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
}

/* iphone XS Max, 11 Pro Max */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
}

/* iphone 12 Pro Max */
@media only screen and (min-device-width: 428px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
}
