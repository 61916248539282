.component-card-how-it-work {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 445px;
    width: 100%;

    .uk-card-text {
        margin-top: 32px;

        .title{
            margin-bottom: 10px;
        }

        @media (min-width: $breakpoint-xlarge) {
            margin: 32px 0 auto 0;
            padding: 0 5%;
            max-width: 100%;
            box-sizing: content-box;
        }
        @media (min-width: $breakpoint-xxxxlarge) {
            max-width: 405px;
        }
    }

    .uk-card-img{
        height: 208px;
        width: 208px;

        img{
            max-width: 100%;
            max-height: 100%;
        }
    }
}

