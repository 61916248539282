.page-Listinglevel2 {

    &.no-typeofservice-selected {
        .breadcrumb-custom-listing {
            .component-Breadcrumb {
                margin-top: -47px;
            }
        }

        .component-Breadcrumb {
            margin-top: -70px;
            @media (max-width: $breakpoint-medium-max) {
                margin-bottom: 20px;
            }
        }

        .component-BannerListing + .main-content {
            .component-Breadcrumb {
                margin-top: 0;
            }
        }
    }

    .uk-pagination {
        margin-bottom: 30px;
    }

    h1.no-item {
        font-size: 28px;
        font-weight: bold;

        @media (min-width: $breakpoint-xlarge) {
            font-size: 40px;
        }
    }

    @media (min-width: $breakpoint-xlarge) {
        .filter-div {
            position: relative;
        }
        .component-SearchFilter {
            .filter-dropdown {
                top: 73px !important;
                left: 6px !important;
                margin-top: 0 !important;
            }
        }
    }

    .col-main {
        .component-CarouselBenessere {
            overflow: hidden;

            .section-benessere > * {
                border-radius: 12px;
            }
        }

        .product-results {
            align-items: end;
            .component-CarouselBenessere, .component-CarouselBannerSponsor {
                width: 100% !important;
            }

            .component-CarouselBenessere {
                .section-benessere > * {
                    border-radius: 12px;
                }

                .section-benessere {
                    padding: 0;
                    overflow: hidden;
                }
            }

            .component-CarouselBannerSponsor {
                .section-carousel {
                    padding: 0;
                }

                .swiper-carousel-borders-listingpage-without-pagination {
                    padding: 0;
                    margin-top: 0;
                }

                .swiper-pagination {
                    bottom: 0px;
                }

                .swiper-slide {
                    max-width: 100%;
                }

                .component-BannerSponsor {
                    padding: 0;
                }
            }

            // don't remove (used in AEM author side to expand the div container)
            .container-max-width {
                width: 100%;
            }
        }

        .component-CarouselBannerSponsor {
            .section {
                padding: 15px 0;
                .component-BannerSponsor {
                    padding-top: 0;
                }
            }

            + .component-CarouselBenessere {
                .section {
                    padding-top: 0;
                }
            }
        }
    }

    .section-evidenza-alternative {
        padding: 0;

        .component-ProdottiEvidenza .section-prod-evid .pagination-prod-evid {
            top: 2px !important;
            right: 64px !important;
        }
    }
}