.component-BannerShopInShop {
    .banner {
        max-height: 146px;;
        height: auto;
        border-radius: 12px;
        border: solid 1px rgba(51, 51, 51, 0.2);
        & > img {
            border-top-left-radius: 11px;
            border-bottom-left-radius: 11px;
            max-width: 80px;
            max-height: 144px;
            width: auto;
            object-fit: cover;
            object-position: center;
            @media (min-width: $breakpoint-medium) {
                max-width: 155px;
                max-height: 144px;

            }
        }
        .logo {
            max-height: 36px;
            margin-bottom: 10px;
        }
        .banner-body {
            padding: 16px 16px 20px;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            @media (min-width: $breakpoint-medium) {
                padding: 25px 16px;
            }
            .banner-title {
                font-size: 14px;
                line-height: 20px;
                color: #333;
                @media (min-width: $breakpoint-medium) {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            .link {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}