.entrypage-provinces{
    padding-top: 42px;

    > * {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section-title{
        text-align: center;
        margin-bottom: 52px;
    }

    .section-body{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 981px;
    }

    .uk-tag{
        background-color: $white;
        box-shadow: 0 0 10px 0 rgba(204, 204, 204, 0.25);
        line-height: 1;
        padding: 9px 24px;

        & > * {
            margin: 0;
            font-size: 1rem;
            line-height: 1;
        }
    }
}