.page-DetailOrder {

    .main-content {
        .uk-container {
            margin-bottom: 61px;

            @media(min-width: $breakpoint-xlarge) {
                margin-bottom: 49px;
            }

        }
    }

    .manage-order {
        a {
            text-decoration: none;
            @include color-primary-black();
            font-weight: bold;
        }

        @media (max-width: $breakpoint-large-2-max) {
            margin-top: 17px;
            text-align: right;
        }

        @media (min-width: $breakpoint-xlarge) {
            margin-left: auto;
        }

        .manage-drop {
            border-radius: 12px;
            overflow: hidden;
            padding: 0;

            & > * {
                width: 100%;
                text-align: left;
                background-color: $gray;
                padding: 16px 48px 16px 16px;
                color: $white;
                font-weight: normal;

                &:hover {
                    background-color: $barely-black;
                }

                span {
                    pointer-events: none;
                }

            }
        }

    }

    .row-order {

        &-header {
            display: flex;
            flex-direction: column-reverse;

            .n-ordine {
                margin: 14px 0 8px 0;
                font-size: 28px;
                line-height: 30px;
                font-weight: bold;
            }

            @media (min-width: $breakpoint-xlarge) {
                flex-direction: row;
                align-items: center;
                margin-bottom: 11px;

                .n-ordine {
                    margin: 0 16px 0 0;
                    font-size: 32px;
                    line-height: 40px;
                    font-weight: bold;
                    max-width: 50%;
                }
            }
        }

        &-info {

            font-size: 14px;
            line-height: 20px;

            .date {
                color: $primary-black;
            }

            .info {
                margin-top: 16px;

                .icon-info {
                    color: $white;
                    background-color: rgba($black, 0.4);
                }
            }
        }

    }

    .delete-order {

        a {
            font-size: 16px;
            padding: 20px 0;
            width: 100%;
            line-height: 1;

            @media (min-width: $breakpoint-xlarge) {
                max-width: 176px;
                padding: 20px 0;
            }
        }
    }

    .container-services {
        @include color-primary-black();
        margin: 24px 0;
        padding: 32px 24px;
        border-radius: 12px;
        box-shadow: 0 1px 1px 0 rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
        background-color: #fdfcf9;
        flex-direction: column;

        > * {
            &:not(:last-child) {
                padding: 0 0 24px 0;
                border-bottom: 1px solid #e0ded7;
            }
        }

        .address {
            padding: 24px 0 0 0;
        }

        .icon-calendar, .icon-pin, .icon-delivery {
            font-size: 24px;
            margin-right: 8px;
            position: relative;
            top: 4px;
        }

        .delivery-collect-day,
        .delivery-collect-pin,
        .delivery-collect-address {
            font-weight: bold;
        }

        .delivery-collect-day, .delivery-collect-address, .delivery-collect-pin {
            margin: 16px 0 8px 0;
        }

        .modify-day-address {
            font-size: 14px;
            line-height: 18px;
        }

        .pin {
            padding-top: 24px;

            .icon-locked {
                margin-right: 8px;
            }
        }
    }

    @media (min-width: $breakpoint-large-2) {
        .container-services {
            flex-direction: row;
            justify-content: space-between;

            > * {
                box-sizing: border-box;
                border-bottom: none !important;

                &:not(:first-child) {
                    border-left: 1px solid #e0ded7;
                    padding: 0 0 0 24px;
                    margin-left: 24px;
                }
            }

            .modify-day-address {
                font-size: 14px;
                margin-top: 16px;
                font-weight: 500;
            }

            .delivery-collect-day,
            .delivery-collect-address {
                margin: 16px 0 0 0;
            }
        }
    }

    .container-receipt-extra {
        @include color-primary-black();
        margin: 24px 0px 0px 0px;
        padding: 32px 24px;
        border-radius: 12px;
        box-shadow: 0 1px 1px 0 rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
        background-color: #fdfcf9;
        flex-direction: column;

        .line-extra {
            font-size: 16px;
            line-height: 1;
            @include color-primary-black();
            font-weight: 500;
            margin-bottom: 28px;
            display: flex;
            justify-content: space-between;


            &-detail {
                margin-top: -12px;
                color: rgba(51, 51, 51, 0.7);
                margin-left: 16px;
            }
        }

        .extra-info {
            font-size: 14px;
            line-height: 20px;
            @include color-primary-black(0.4);
        }

        .carta-insieme {
            margin-top: 24px;

            .icon {
                margin-right: 5px;
            }
        }
    }

    .receipt-details {
        margin-bottom: 24px;

        hr {
            margin: 0 0;

            &.dashed {
                border: none;
                height: 1px;
                background: $global-border;
                background: repeating-linear-gradient(90deg, $global-border, $global-border 6px, transparent 6px, transparent 12px);
            }
        }

        .tooltiptext {
            padding-top: 6px;

            p {
                margin: 0;
            }

            hr {
                margin: 10px 0;
            }
        }

        .info {
            font-size: 11px;
            line-height: 16px;
            font-weight: bold;
        }

        .receipt-title {
            font-size: 20px;
            line-height: 28px;
            font-weight: bold;

        }
        .receipt-fields {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 20px;
            @include color-primary-black();

            .scontrino-divider {
                margin-top: 9px !important;
                padding-top: 16px;
                border-top: 1px solid $global-border;

                &.dashed {
                    border-top: none;
                    background-image: linear-gradient(to right, $global-border 50%, rgba(255, 255, 255, 0) 0%) !important;
                    background-position: top;
                    background-size: 15px 1px;
                    background-repeat: repeat-x;
                }
            }

            .wrapper-border-first {
                display: block !important;

                & > div:first-of-type {
                    padding-top: 16px;
                    margin-top: 9px;
                    border-top: 1px solid $global-border;
                }

                &.dashed > div:first-of-type {
                    border-top: none;
                    background-image: linear-gradient(to right, $global-border 50%, rgba(255, 255, 255, 0) 0%);
                    background-position: top;
                    background-size: 15px 1px;
                    background-repeat: repeat-x;
                }

                & > div {
                    display: flex;
                    justify-content: space-between;
                }

                & > div:not(:last-of-type) {
                    margin-bottom: 9px;
                }
            }

            .preautorizzazione {
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 0;
            }

            .large {
                font-size: 18px;
                line-height: 24px;

                .amount {
                    font-weight: bold;
                }
            }

            .bigger-margin {
                &-top {
                    margin-top: 16px;
                }

                &-bottom {
                    margin-bottom: 16px;
                }
            }

            .field-no-detail {
                display: flex;
                justify-content: space-between;

                .detail {
                    .icon-info {
                        margin-left: 5px;
                    }
                }
            }

            .amount {
                font-weight: 500;
            }

            .field-with-detail {

                > * {
                    display: flex;
                    justify-content: space-between;
                }

                .single-detail {
                    margin-left: 16px;

                    .amount {
                        color: #e20714;
                    }
                }
            }
        }


        .receipt-total {
            display: flex;
            justify-content: space-between;
            @include color-primary-black();


            .total {
                font-weight: bold;
                font-size: 20px;
                line-height: 28px;
            }

            .iva {
                font-size: 10px;
                line-height: 14px;
                color: $primary-gray;
            }

            .amount {
                font-size: 20px;
                line-height: 20px;
                font-weight: bold;
            }
        }

        .receipt {
            &-payment-detail {
                margin-top: 24px;
                font-size: 16px;
                line-height: 24px;
                @include color-primary-black();

                .payment-field {
                    margin-bottom: 5px;

                    .detail {
                        font-weight: bold;
                    }

                    .icon-check {
                        margin-left: 10px;
                        margin-right: 6px;
                        color: $white;
                        background-color: $black;
                    }

                    a {
                        font-weight: 500;
                    }
                }
            }
            &-lotteria {
                .detail {
                    font-weight: bold;
                }
            }
        }
    }

    .container-products {
        margin-top: 40px;

        + hr {
            margin-bottom: 20px;
        }

        .products {
            position: relative;

            .products-counter {
                position: absolute;
                pointer-events: none;
                top: 0;
                right: 40px;
            }

            .modify-cart {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 24px;
                font-weight: bold;
            }
        }
        @media(min-width: $breakpoint-xlarge) {
            .uk-accordion-title {
                b {
                    font-size: 20px;
                }
            }
            .products {
                .products-counter {
                    font-size: 14px;
                    font-weight: bold;
                }
            }

            .component-CardProdottoOmaggio{
                margin-bottom: 10px;
            }
        }
    }
}