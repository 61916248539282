.modal-checkout-pdv-only {
    @media (min-width: $breakpoint-large) {
    }

    &.type-a {
        .type-a-payment-guest {
            display: block;
        }
        .type-b-payment-other {
            display: none;
        }
    }

    &.type-b {
        .type-a-payment-guest {
            display: none;
        }
        .type-b-payment-other {
            display: block;
        }
    }

    .uk-modal-body{
        padding-bottom: 0;
    }

    .btn-group{
        padding-top: 40px;
    }
}