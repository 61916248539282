@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';


.component-modal-generic {
    .uk {
        &-modal-title {

            @media (max-width: $breakpoint-large) {
                padding-right: 48px;
            }
        }

        &-button {
            line-height: 56px;
            font-size: 16px;
        }

        &-link {
            padding: 24px;
        }

        &-badge {
            margin-top: 24px;
        }
    }

    .subtitle {
        @media (max-width: $breakpoint-large) {
            padding-right: 48px;
        }
    }

    .desc {
        font-size: 20px;
        margin-top: 24px;
    }

    .text-box {
        max-height: 550px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        @include scrollbar();
    }

    @media (min-width: $breakpoint-medium) {
        .desc {
            font-size: 24px;
        }

        .uk {
            &-link {
                padding-top: 16px;
                padding-bottom: 16px !important;
            }

            &-modal-title {
                font-size: 24px;
            }
        }

        .btn-group {
            margin-top: 20px;
            flex-direction: row;
        }
    }
    @media (min-width: $breakpoint-large) {
        .btn-group {
            margin-top: 40px;
        }
    }
}

.modal {
    &-barra-cookies {
        @media (min-width: $breakpoint-medium) {
            .uk-modal-dialog {
                margin: auto 24px 24px 24px !important;
            }
        }
    }

    &-pre-autorizzazione {
        @include color-primary-black();

        .text-box {
            max-height: inherit;
            overflow-y: inherit;
            -webkit-overflow-scrolling: inherit;
        }

        .descrizione {
            font-size: 14px;

            p {
                margin-bottom: 15px;
            }
        }

        @media (min-width: $breakpoint-xlarge) {
            .text-box {
                padding-right: 20px;
            }
        }
    }

    &-fascia-oraria-superata {
        @include color-primary-black();
    }

    &-form-upload-error, &-form-upload-success,&-modifica-negozio {
        .modal-content-text {
            color: $primary-black !important;
        }

        .modal-content-img {
            max-width: 157px;
            max-height: 157px;
        }
    }

    &-user-logout {
        .uk-modal-body {
            padding-bottom: 0;
        }

        .text-box {
            margin-bottom: 40px;
        }

        @media (min-width: $breakpoint-large) {
            .uk-modal-body {
                padding-bottom: 24px;
            }
        }
    }


    &-lead-time-over, &-contingentamento-locker-add-to-cart {
        .modal-content-img{
            max-width: 157px;
            max-height: 157px;
        }

        .modal-content{
            margin-bottom: 0px !important;
        }
    }

    &-locker-oversize{

        .product-remove-list{
            margin-top: 14px;
            max-height: 216px;
            padding: 5px 2px;
            .invalid-product:not(:first-child){
                margin-top: 8px;
            }
        }

        .desc{
            color: #636260;
        }

        .invalid-product{
            display: flex;
            justify-content: space-between;
            color: rgba($black, 0.8);
            max-height: 56px;
            padding: 11px 27px 11px 18px;
            border-radius: 12px;
            border: none;
            box-shadow: 0 1.5px 1px 0 rgba(171, 147, 67, 0.15), 0 -0.5px 1px 0 rgba(171, 147, 67, 0.2);
            margin-right: 8px;

            .description{
                display: flex;
                align-items: center;
                font-size: 12px;
                line-height: 16px;
            }

            .price{
                font-size: 20px;
                line-height: 24px;
            }
        }
    }

    &-contingentamento-locker-add-to-cart{
        
        .modal-content-img{
            width: 157px;
            height: 123px;

            img{
                max-width: 100%;
                max-height: 100%;
            }
        }

        .modal-content-text{
            color: rgba($black, 0.8) !important;
            margin-bottom: 40px !important
        }

        .locker-max-quantity{
            font-weight: bold;
        }
    }
    &-blacklist {
        .btn-group {
            gap: 20px;
            margin-top: 40px;
            @media (min-width: $breakpoint-medium) {
                gap: 30px;
            }
        }
    }

    &-overlay-prodotti-checkout{
        @media screen and (max-width: 599px){
            overflow-y: hidden;
            height: 100%;
            width: 100%;
            padding: 0;
            border-radius: 0;

            .uk-modal-dialog{
                height: 100%;
                width: 100%;
                border-radius: 0;
            }

            .overlay-prodotti{
                height: calc(100% - 122px) !important;
                border-radius: 0 !important;
            }

        }
        .overlay-prodotti {
            box-shadow: 0px 0px 1px rgba(171, 147, 67, 0.2), 0px 8px 16px -4px rgba(171, 147, 67, 0.15);
            border-radius: 0 0 12px 12px;
            padding: 16px 24px 8px 24px;
            background-color: $cultured;
            height: 400px;
    
            .gruppo-prodotti {
                width: 100%;
                height: 100%;
                overflow-y: scroll;
                background-color: transparent;
                padding-right: 16px;
    
                &::-webkit-scrollbar {
                    display: block;
                    width: 4px;
                }
    
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    border-radius: 4px;
                }
    
                &::-webkit-scrollbar-thumb {
                    border-radius: 4px;
                    background-color: black;
                }
    
                .singolo-prodotto {
                    background: white;
                    border-radius: 12px;
                    box-shadow: 0px 0px 1px rgba(171, 147, 67, 0.2), 0px 1px 1px rgba(171, 147, 67, 0.15);
                    background-color: white;
                    margin-bottom: 8px;
                    padding: 12px 27px 11px 24px;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;

                    @media screen and (min-width: $breakpoint-medium){
                        flex-direction: row;
                    }

                    .foto-e-descrizione{
                        display: flex;
                        align-items: center;
                    }
    
                    .foto-prodotto-container {
                        margin-right: 8px;
                        max-width: 42px;
                        max-height: 38px;
                        width: 100%;
                        height: 100%;

                        .foto-prodotto {
                            max-width: 100%;
                            max-height: 100%;
                            width: 100%;
                            height: 100%;
                        }
                    }
    
                    .descrizione {
                        width: 206px;
                        font-size: 12px;
                        font-weight: 400;
                    }
    
                    .pezzi-euro {
                        width: 165px;
                        margin-top: 6px;
                        margin-left: 51px;

                        @media screen and (min-width: $breakpoint-medium){
                            margin: 0;
                        }
    
                        .pezzi {
                            font-size: 10px;
                            font-weight: 700;
                            margin-bottom: 4px;
                        }
    
                        .euro {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }

                    &.not-available{
                        .unavailable-badge {
                            margin-top: 0;
                            white-space: nowrap;
                            background-color: $pink-light;
                            max-width: 121px;
                        }

                        .descrizione{
                            color: rgba(0, 0, 0, 0.6);
                        }

                        .foto-prodotto{
                            filter: grayscale(1);
                        }
                    }

                    .alert-quantita-aggiornata{
                        margin-bottom: 4px;

                        i {
                            background-color: var(---colour-03-semantic-03-warnin-2) !important;
                            margin-right: 4px;
                        }

                        span{
                            font-size: 9px;
                            line-height: 16px;
                            font-weight: bold;
                            text-shadow: #ffb602 0px 0 0.3px;
                        }
                    }
                }

                
            }
        }
    }

    &-quantita-aggiornata{
        .modal-content-img{
            width: 100%;
            height: 100%;
            max-height: 157px;
            max-width: 157px;
        }

        .btn-group{
            margin-top: 40px;
        }

        @media(min-width: $breakpoint-xlarge){
            .btn-group{
                margin-top: 61px;
            }
        }
    }
}
