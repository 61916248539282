@import '../../scss/uikit/variables-theme.scss';

.component-footer-semplicato {
    background-color: $global-emphasis-color;
    padding: 40px 0;

    a {
        text-decoration: none;
    }

    .box-footer-item {
        border-radius: 16px;
        padding: 20px 0;
        background-color: rgba($black, 0.2);
        min-height: 40px;
        color: rgba($white, 0.8);

        i {
            color: rgba($white, 0.8);
            font-size: 30px;
            line-height: 40px;

            &.illustration {
                font-size: 70px;
                position: relative;
                top: 14px;
                line-height: 15px;
                &.icon-faq {
                    font-size: 30px;
                    top: 7px;
                }
            }
        }

        .title {
            font-weight: bold;
            color: rgba($white, 0.6);
        }

        .subtitle {
            color: rgba($white, 0.6);
            font-size: 14px;
            text-align: left;
        }
    }

    .box-servizi {
        min-width: 100%;
        padding: 12px 0 0;
        @media (min-width: $breakpoint-large) and (max-width: $breakpoint-large-2-max) {
            flex-direction: column-reverse;
        }

        .mobile-accordions {
            box-sizing: content-box;
        }

        .uk-grid,
        .uk-accordion {
            min-width: 100%;
            margin-bottom: 20px;
            > .uk-grid-margin {
                margin-top: 32px;
            }
            @media (min-width: $breakpoint-xlarge) {
                min-width: 65%;
                > .uk-grid-margin {
                    margin-top: 24px;
                }
            }
        }

        .uk-accordion-title:before {
            content: '\e911';
            font-size: 24px;
            color: $white;
            position: relative;
            top: -3px;
        }

        .uk-open > .uk-accordion-title:before {
            content: '\e919';
        }

        .title {
            font-weight: bold;
            line-height: 1;
            color: rgba($white, 0.6);
            text-decoration: none;
            padding-bottom: 10px;
            padding-top: 10px;
        }

        .link-utili-box {
            padding-bottom: 24px;
            line-height: 1;
            min-width: 160px;

            a {
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
            }
        }

        @media (min-width: $breakpoint-xlarge) {
            padding: 40px 0 20px;
            .title {
            }
        }
    }

    .footer-info-last {
        .border-line {
            > * {
                margin-top: 24px;
                margin-bottom: 10px;
                display: block;
                width: 100%;
                border-top: 1px solid rgba($white, 0.2);
            }
        }

        .item-direction {
            flex-direction: column;

            .text {
                font-size: 14px;
                line-height: 24px;
                color: rgba($white, 0.6);
                margin: 0 10px 24px 0;
            }

            .box-carte, .box-social{
                a {
                    text-decoration: none;
                }

                img{
                    display: inline-block;
                    margin-right: 24px;
                }
            }

            .box-app{
                a{
                    text-decoration: none;
                }

                img{
                    display: inline-block;
                    margin-right: 5px;
                }
            }

            &.item-right {
                .text {
                    margin-right: 0;
                }
            }
        }

        @media (max-width: $breakpoint-medium-max) {
            .item-direction {
                &.item-right {
                    margin-top: 20px;
                    margin-bottom: 50px;
                }
            }
        }
        @media (min-width: $breakpoint-large) {
            flex-direction: row;
            margin-bottom: 20px;
            .item-left {
                justify-content: flex-start;
            }
            .item-center {
                justify-content: center;
            }
            .item-right {
                justify-content: flex-end;
            }
        }
        @media (min-width: $breakpoint-large-2) {
            .item-direction {
                display: flex;
            }
        }

        @media (min-width: $breakpoint-large) and (max-width: $breakpoint-large-2-max) {
            //qui bisogna gestire l'ipad
            margin-bottom: 40px;
        }

        .footer-info-item:nth-child(3n+1) .item-direction{
            align-content: flex-start;
        }

        .footer-info-item:nth-child(3n+2) .item-direction{
            align-content: center;
        }

        .footer-info-item:nth-child(3n+3) .item-direction{
            align-content: flex-end;
        }

        .footer-info-item .item-direction{

            .box-social{
                a{
                    width: 24px !important;
                    height: 24px !important;
    
                    img{
                    
                        max-width: 24px !important;
                        max-height: 24px !important;
                    }
                }
            }
    
            .box-carte{
    
                img{
                    max-width: 34px;
                    max-height: 24px;
                }
            }
    
            .box-app{
                a:first-child{
                    img{
                        max-height: 33px;
                        max-width: 101px;
                    }
                    
                }
    
                a:last-child{
                    img{
                        max-height: 33px;
                        max-width: 111px;
                    }
                    
                }
            }
        }

        .footer-info-item-container{

            .footer-info-item{
                margin-bottom: 40px;
            }

            @media screen and (min-width: $breakpoint-large){
                .footer-info-item{
                    margin-bottom: 0px;
                }
                
                display: flex;
            }
        }
        
    }
}
