.component-AreaOfferte {
    .section-nostre-off {

        .swiper-carousel {
            overflow: visible;
            @media (min-width: $breakpoint-xlarge) {
                padding-bottom: 42px;
                overflow: hidden;
            }
        }

        .section-title {
            float: left;
            font-size: 18px;
            font-weight: normal;
            line-height: 1.33;
            margin-bottom: 10px;

            @media (min-width: $breakpoint-xlarge) {
                font-size: 28px;
                line-height: 1.29;
                margin-bottom: 20px;
            }
        }

        .section-title:before {
            bottom: -12px !important;

            @media (min-width: $breakpoint-xlarge) {
                bottom: -18px !important;
            }
        }

        .link-nostre-off {
            float: right;
            font-weight: 500;
            font-size: 14px;
            color: #0072ca;
            line-height: 1.29;

            @media (min-width: $breakpoint-xlarge) {
                font-size: 16px;
                line-height: 1.5;
                padding-top: 30px;
            }
        }

        .section-body {
            clear: both;

            .swiper-nostre-off {
                padding-top: 25px;

                @media (min-width: $breakpoint-xlarge) {
                    padding-top: 24px;
                }
            }

            .pagination-nostre-off {
                bottom: 3px !important;
                left: 5px !important;
                padding-top: 15px;

                .swiper-pagination-bullet {
                    margin: 0 4px;
                    opacity: 1;
                    width: 16px;
                    height: 16px;
                    position: relative;
                    background: transparent;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;

                    &:before {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 4px;
                        background-color: $black;
                        border-radius: 50%;
                    }

                    &-active {
                        &:before {
                            width: 16px;
                            height: 16px;
                            background-color: $yellow;
                        }
                    }
                }
            }

            .swiper-slide {
                width: 253px;

                @media (min-width: $breakpoint-xlarge) {
                    width: 311px;
                }
            }
        }
    }
}