.component-card-negozio {
    cursor: pointer;
    transition: all .3s ease-in-out;
    font-family: 'DM Sans', sans-serif;

    &.disabled {
        opacity: 0.4;
        pointer-events: none;
    }

    .uk-card {
        width: 100%;
        background-color: transparent;

        &-body {
            background-color: $white;
            width: 100%;
            padding: 16px 24px 10px;
            transition: all .3s ease-in-out;

            .availability {
                [class*='icon'] {
                    font-size: 23px;
                }
            }

            .orari {
                .SmallPar {
                    font-size: 12px;
                    white-space: nowrap;
                }
                display: none;
            }

            .title-row {
                margin-top: 16px
            }

            .par-row {
                margin-bottom: 22px;
            }

            .uk-badge {
                font-weight: 500;
                .day,
                .time{
                    margin: 0 3px;
                }
            }

            .card-title {
                font-size: 16px;
                font-weight: bold;
                margin-top: 16px;
            }

            .card-text {
                font-size: 14px;
                margin-bottom: 24px;
            }

            .programma-carta-insieme {
                font-size: 12px;
                font-weight: 500;
                [class*='icon'] {
                    position: relative;
                    top: -2px;
                    font-size: 22px;
                    margin-right: 8px;
                }
            }
            
            @media (max-width: 345px){
                .uk-badge,
                .SmallPar {
                    font-size: 10px;
                }
            }
        }

        .negozio-customer-list{
            font-size: 11px;
            width: 100%;
            background-color: #7dac33;
            font-weight: bold;
            color: white;
            text-align: center;
            align-content: center;
            min-height: 32px;
            bottom: 0px;
            border-bottom-left-radius: 12px;
            position: absolute;
            border-bottom-right-radius: 12px;

            &+ .uk-card-body{
                padding: 16px 24px 32px;
            }
        }
    }

    &.available {
        .uk-card {
            &-body {
                .uk-badge {
                    background-color: $honeydew;
                }

                .not-available {
                    display: none;
                }
            }
        }
    }

    &.not-available {
        .uk-card {
            .uk-card-body {
                .uk-badge {
                    background-color: $misty-rose;
                }

                .available {
                    display: none;
                }
            }
        }
    }

    &.selected {
        .uk-card {
            &-body {
                .orari {
                    display: block;
                    hr {
                        margin: 15px 0 15px 0;
                        border-top: 1px solid rgba(51, 51, 51, 0.27);
                    }
                }
            }
        }
    }

    &.selected {
        .uk-card {
            &-body {
                background-color: $pin-yellow;
                border-color: $banana-yellow;
            }
        }
        &.available {
            .uk-card {
                &-body {
                    .uk-badge {
                        background-color: $white;
                        color: $forest !important;
                    }
                }
            }
        }
    }
}

.device_not_mobile_not_tablet{
    .component-card-negozio:hover{
        .uk-card {
            &-body {
                background-color: $pin-yellow;
                border-color: $banana-yellow;
            }
        }
        &.available {
            .uk-card {
                &-body {
                    .uk-badge {
                        background-color: $white;
                        color: $forest !important;
                    }
                }
            }
        }
    }
}

/* SKELETON */
.card-negozio-skeleton {
    overflow: hidden;

    .card-skeleton {
        &-description {
            height: 45px;
            line-height: 1.3rem;
            margin-top: 16px;
        }

        &-icon {
            height: 24px;
            width: 24px;
        }
        &-availability {
            max-width: 200px;
            width: 100%;
            height: 24px;
        }
        &-info {
            height: 22px;
            width: 100%;
            max-width: 70%;
        }
    }

    .loading {
        position: relative;
        background-color: #E2E2E2;

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            background: linear-gradient(90deg, transparent, rgba(255, 255, 255, .2), transparent);
            animation: loadingSK 1.5s infinite;
        }
    }
}
