.component-BannerListing {
    &.banner-iniziative {
        .banner-iniziative {
            display: block;
        }

        .component-FlatCategory {
            border: none;
        }
    }

    .banner-iniziative {
        margin-bottom: 50px;

        .component-FlatCategory {
            border: none;
            img {
                width: 88px;
            }

            .text {
                font-size: 14px;
                font-weight: bold;
                line-height: 1.43;
                max-width: 91px;
                margin: 0 auto;
            }
        }

        .swiper-flat-cat {
            padding-bottom: 32px;
        }

        .swiper-pagination {
            bottom: -7px !important;
            left: 5px !important;
            padding-top: 15px;

            .swiper-pagination-bullet {
                margin: 0 4px;
                opacity: 1;
                width: 16px;
                height: 16px;
                position: relative;
                background: transparent;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                &:before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 4px;
                    background-color: $black;
                    border-radius: 50%;
                }

                &-active {
                    &:before {
                        width: 16px;
                        height: 16px;
                        background-color: $yellow;
                    }
                }
            }
        }
    }
}