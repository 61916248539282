$FontPath: './resources/ecommerce/fonts' !default;
//$RobotoSlabVersion: "1.0" !default;
$DMSans: '6.0' !default;
$iconVersion: 'v5.2' !default;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,700&display=swap');

//@mixin fontRobotoSlab($FontPath, $RobotoSlabVersion, $FontType: "Regular") {
//    src: url("#{$FontPath}/opensans/OpenSans-#{$FontType}.woff2?v=#{$RobotoSlabVersion}") format("woff2"),
//    url("#{$FontPath}/opensans/OpenSans-#{$FontType}.woff?v=#{$RobotoSlabVersion}") format("woff");
//}

//@mixin fontDMSans($FontPath, $DMSans, $FontType: 'Regular') {
//    src: url('#{$FontPath}/dmsans/DMSans-#{$FontType}.woff2?v=#{$DMSans}') format('woff2');
//}

@mixin iconFont($FontPath, $iconVersion) {
    src: url('#{$FontPath}/icomoon/icomoon.eot?#{$iconVersion}');
    src: url('#{$FontPath}/icomoon/icomoon.eot?#{$iconVersion}#iefix') format('embedded-opentype'),
        url('#{$FontPath}/icomoon/icomoon.woff2?#{$iconVersion}') format('woff2'),
        url('#{$FontPath}/icomoon/icomoon.ttf?#{$iconVersion}') format('truetype'),
        url('#{$FontPath}/icomoon/icomoon.woff?#{$iconVersion}') format('woff'),
        url('#{$FontPath}/icomoon/icomoon.svg?#{$iconVersion}') format('svg');
}

@font-face {
    font-family: 'icomoon';
    @include iconFont($FontPath, $iconVersion);
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
  
[class^='icon-'], [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-coupon:before {
    content: "\e96e";
}
.icon-tag:before {
    content: "\e971";
}
.icon-locker-2:before {
    content: "\e96d";
}
.icon-plane:before {
    content: "\e96c";
}
.icon-shop-3:before {
    content: "\e96b";
}
.icon-spesa:before {
    content: "\e969";
}
.icon-delivery-2:before {
    content: "\e96a";
}
.icon-conad-card:before {
    content: "\e970";
}
.icon-sostenibilita:before {
    content: "\e96f";
}
.icon-bookmark:before {
    content: "\e968";
}
.icon-unlocked .path1:before {
    content: "\e963";
    color: rgb(0, 0, 0);
}
.icon-unlocked .path2:before {
    content: "\e964";
    margin-left: -1em;
    color: rgb(255, 255, 255);
    opacity: 0.2;
}
.icon-unlocked .path3:before {
    content: "\e965";
    margin-left: -1em;
    color: rgb(0, 0, 0);
}
.icon-unlocked .path4:before {
    content: "\e966";
    margin-left: -1em;
    color: rgb(255, 255, 255);
    opacity: 0.2;
}
.icon-store-2:before {
    content: "\e962";
}
.icon-locked:before {
    content: "\e961";
}
.icon-carta-insieme:before {
    content: "\e960";
}
.icon-margherita:before {
    content: "\e95f";
}
.icon-carta-insieme-2:before {
    content: "\e95b";
}
.icon-switch:before {
    content: "\e949";
}
.icon-expand:before {
    content: "\e95a";
}
.icon-trash-2:before {
    content: "\e958";
}
.icon-close1 .path1:before {
    content: "\e94b";
    color: rgb(0, 0, 0);
}
.icon-close1 .path2:before {
    content: "\e94c";
    margin-left: -1em;
    color: rgb(255, 255, 255);
    opacity: 0.2;
}
.icon-close1 .path3:before {
    content: "\e94d";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-prev:before {
    content: "\e900";
}
.icon-next:before {
    content: "\e901";
}
.icon-search:before {
    content: "\e902";
}
.icon-weight:before {
    content: "\e903";
}
.icon-users:before {
    content: "\e904";
}
.icon-user:before {
    content: "\e905";
}
.icon-user-check:before {
    content: "\e906";
}
.icon-upload:before {
    content: "\e907";
}
.icon-trash:before {
    content: "\e908";
}
.icon-text-field-dropdown:before {
    content: "\e909";
}
.icon-text-field-drop-updown:before {
    content: "\e90a";
}
.icon-target:before {
    content: "\e90b";
}
.icon-sliders:before {
    content: "\e90c";
}
.icon-shopping-bag:before {
    content: "\e90d";
}
.icon-settings:before {
    content: "\e90e";
}
.icon-refresh:before {
    content: "\e90f";
}
.icon-quality:before {
    content: "\e910";
}
.icon-plus:before {
    content: "\e911";
}
.icon-pin:before {
    content: "\e912";
}
.icon-phone:before {
    content: "\e913";
}
.icon-pending:before {
    content: "\e914";
}
.icon-pending-mortgage:before {
    content: "\e915";
}
.icon-paperclip:before {
    content: "\e916";
}
.icon-notification:before {
    content: "\e917";
}
.icon-more:before {
    content: "\e918";
}
.icon-minus:before {
    content: "\e919";
}
.icon-message-circle:before {
    content: "\e91a";
}
.icon-menu:before {
    content: "\e91b";
}
.icon-map:before {
    content: "\e91c";
}
.icon-mail:before {
    content: "\e91d";
}
.icon-lock:before {
    content: "\e91e";
}
.icon-loading:before {
    content: "\e91f";
}
.icon-list:before {
    content: "\e920";
}
.icon-like-on:before {
    content: "\e921";
}
.icon-like-off:before {
    content: "\e922";
}
.icon-info:before {
    content: "\e923";
}
.icon-home:before {
    content: "\e924";
}
.icon-file-text:before {
    content: "\e925";
}
.icon-eye-on:before {
    content: "\e926";
}
.icon-eye-off:before {
    content: "\e927";
}
.icon-expander:before {
    content: "\e928";
}
.icon-edit:before {
    content: "\e929";
}
.icon-download:before {
    content: "\e92a";
}
.icon-delivery:before {
    content: "\e92b";
}
.icon-cursor:before {
    content: "\e92c";
}
.icon-credit-card:before {
    content: "\e92d";
}
.icon-close:before {
    content: "\e92e";
}
.icon-chevron-up:before {
    content: "\e92f";
}
.icon-chevron-right:before {
    content: "\e930";
}
.icon-chevron-left:before {
    content: "\e931";
}
.icon-chevron-down:before {
    content: "\e932";
}
.icon-check:before {
    content: "\e933";
}
.icon-cart:before {
    content: "\e934";
}
.icon-car-park:before {
    content: "\e935";
}
.icon-car-park-covered:before {
    content: "\e936";
}
.icon-calendar:before {
    content: "\e937";
}
.icon-buoni-pasto:before {
    content: "\e938";
}
.icon-archive:before {
    content: "\e939";
}
.icon-alert:before {
    content: "\e93a";
}
.icon-flag-ita .path1:before {
    content: "\e95c";
    color: rgb(94, 178, 35);
}
.icon-flag-ita .path2:before {
    content: "\e95d";
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-flag-ita .path3:before {
    content: "\e95e";
    margin-left: -1em;
    color: rgb(226, 7, 20);
}
.icon-fidelity-double .path1:before {
    content: "\e94e";
    color: rgb(240, 108, 0);
}
.icon-fidelity-double .path2:before {
    content: "\e94f";
    margin-left: -1.0625em;
    color: rgb(255, 255, 255);
    opacity: 0.2;
}
.icon-fidelity-double .path3:before {
    content: "\e950";
    margin-left: -1.0625em;
    color: rgb(248, 246, 239);
}
.icon-fidelity-double .path4:before {
    content: "\e951";
    margin-left: -1.0625em;
    color: rgb(0, 0, 0);
    opacity: 0.4;
}
.icon-fidelity-double .path5:before {
    content: "\e952";
    margin-left: -1.0625em;
    color: rgb(255, 255, 255);
    opacity: 0.2;
}
.icon-fidelity-grey .path1:before {
    content: "\e953";
    color: rgb(248, 246, 239);
}
.icon-fidelity-grey .path2:before {
    content: "\e954";
    margin-left: -0.75em;
    color: rgb(0, 0, 0);
    opacity: 0.4;
}
.icon-fidelity-grey .path3:before {
    content: "\e955";
    margin-left: -0.75em;
    color: rgb(255, 255, 255);
    opacity: 0.2;
}
.icon-fidelity-orange .path1:before {
    content: "\e956";
    color: rgb(240, 108, 0);
}
.icon-fidelity-orange .path2:before {
    content: "\e957";
    margin-left: -1em;
    color: rgb(255, 255, 255);
    opacity: 0.2;
}
.icon-star:before {
    content: "\e93b";
}
.icon-star-half .path1:before {
    content: "\e93c";
    color: rgb(51, 51, 51);
    opacity: 0.1;
}
.icon-star-half .path2:before {
    content: "\e93d";
    margin-left: -1em;
    color: rgb(254, 212, 4);
}
.icon-star-full:before {
    content: "\e93e";
    color: #fed404;
}
.icon-faq:before {
    content: "\e967";
}
.icon-salse:before {
    content: "\e93f";
}
.icon-verdura:before {
    content: "\e940";
}
.icon-surgelati:before {
    content: "\e941";
}
.icon-store:before {
    content: "\e942";
}
.icon-servizio-clienti:before {
    content: "\e943";
}
.icon-pesce:before {
    content: "\e944";
}
.icon-pane:before {
    content: "\e945";
}
.icon-locker:before {
    content: "\e946";
}
.icon-drive:before {
    content: "\e947";
}
.icon-detersivi:before {
    content: "\e948";
}
.icon-chat:before {
    content: "\e959";
}
.icon-all:before {
    content: "\e94a";
}