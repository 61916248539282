.page-FAQ {
    .breadcrumb-container {
        &-form {
            background-color: $floral-white;
            height: auto;
        }
    }

    .main-content {
        padding-top: 0;

        .section {

            &-main {

                margin: 0;
        
                .title {
                    color: $primary-black;
                    font-size: 32px;
                    font-weight: bold;
                    line-height: 1.25;
                    margin: 0 0 16px;
                }
        
                .desc {
                    color: $primary-gray;
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 1.5;
                    margin: 0 0 24px;

                    @media (min-width: $breakpoint-xlarge) {
                        font-size: 14px;
                        line-height: 1.43;
                    }
                }
        
            }
        }
    }
}