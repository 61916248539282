.component-faqSectionMain {
    .section-main-domande {
        .section-main {
            padding: 32px 0 0 0 !important;
            width: 100%;
        }

        .searching {
            font-size: 14px;
            @include color-primary-black();
        }

        ul {
            margin-left: 0;
            padding-bottom: 0 !important;

            li {
                &:first-child {
                    padding-top: 0 !important;
                }
            }
        }
    }
}