/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/

/*
CONTENTS:
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited).
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar.
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars.
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars.
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/

/*
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE
------------------------------------------------------------------------------------------------------------------------
*/

.mCustomScrollbar {
    -ms-touch-action: pinch-zoom;
    touch-action: pinch-zoom; /* direct pointer events to js */
}

.mCustomScrollbar.mCS_no_scrollbar,
.mCustomScrollbar.mCS_touch_action {
    -ms-touch-action: auto;
    touch-action: auto;
}

.mCustomScrollBox {
    /* contains plugin's markup */
    position: relative;
    overflow: hidden;
    height: 100%;
    max-width: 100%;
    outline: none;
    direction: ltr;
}

.mCSB_container {
    /* contains the original content */
    overflow: hidden;
    width: auto;
    height: auto;
}

/*
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR
y-axis
------------------------------------------------------------------------------------------------------------------------
*/

@media (min-width: $breakpoint-large) {
    .mCSB_inside > .mCSB_container {
        margin-right: 18px;
    }
    .mCSB_scrollTools .mCSB_draggerContainer {
        opacity: 1 !important;
    }
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-right: 0;
}

/* non-visible scrollbar */

.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
    /* RTL direction/left-side scrollbar */
    margin-right: 0;
    margin-left: 30px;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-left: 0;
}

/* RTL direction/left-side scrollbar */

.mCSB_scrollTools {
    /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
    position: absolute;
    width: 4px;
    height: auto;
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
}

.mCSB_outside + .mCSB_scrollTools {
    right: -26px;
}

/* scrollbar position: outside */

.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
    /* RTL direction/left-side scrollbar */
    right: auto;
    left: 0;
}

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
    left: -26px;
}

/* RTL direction/left-side scrollbar (scrollbar position: outside) */

.mCSB_scrollTools .mCSB_draggerContainer {
    /* contains the draggable element and dragger rail markup */
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.mCSB_scrollTools a + .mCSB_draggerContainer {
    margin: 20px 0;
}

.mCSB_scrollTools .mCSB_draggerRail {
    width: 2px;
    height: 100%;
    margin: 0 auto;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.mCSB_scrollTools .mCSB_dragger {
    /* the draggable element */
    cursor: pointer;
    width: 100%;
    height: 30px; /* minimum dragger height */
    z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    /* the dragger element */
    position: relative;
    width: 4px;
    height: 100%;
    margin: 0 auto;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
    width: 12px; /* auto-expanded scrollbar */
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand
    .mCSB_dragger.mCSB_dragger_onDrag_expanded
    + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    width: 8px; /* auto-expanded scrollbar */
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
    display: block;
    position: absolute;
    height: 20px;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown {
    bottom: 0;
}

/*
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR
x-axis
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_horizontal.mCSB_inside > .mCSB_container {
    margin-right: 0;
    margin-bottom: 30px;
}

.mCSB_horizontal.mCSB_outside > .mCSB_container {
    min-height: 100%;
}

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
    margin-bottom: 0;
}

/* non-visible scrollbar */

.mCSB_scrollTools.mCSB_scrollTools_horizontal {
    width: auto;
    height: 16px;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
}

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    bottom: -26px;
}

/* scrollbar position: outside */

.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
    margin: 0 20px;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 2px;
    margin: 7px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
    width: 30px; /* minimum dragger width */
    height: 100%;
    left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    width: 100%;
    height: 4px;
    margin: 6px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
    .mCSB_dragger.mCSB_dragger_onDrag_expanded
    .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
    .mCSB_draggerContainer:hover
    .mCSB_dragger
    .mCSB_dragger_bar {
    height: 12px; /* auto-expanded scrollbar */
    margin: 2px auto;
}

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand
    .mCSB_dragger.mCSB_dragger_onDrag_expanded
    + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
    height: 8px; /* auto-expanded scrollbar */
    margin: 4px 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
    display: block;
    position: absolute;
    width: 20px;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
    left: 0;
}

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
    right: 0;
}

/*
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS
yx-axis
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_container_wrapper {
    position: absolute;
    height: auto;
    width: auto;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-right: 30px;
    margin-bottom: 30px;
}

.mCSB_container_wrapper > .mCSB_container {
    padding-right: 30px;
    padding-bottom: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
    bottom: 20px;
}

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    right: 20px;
}

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
    bottom: 0;
}

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden
    + .mCSB_scrollTools
    ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    right: 0;
}

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    left: 20px;
}

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl
    > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside
    > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden
    + .mCSB_scrollTools
    ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    left: 0;
}

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
    /* RTL direction/left-side scrollbar */
    margin-right: 0;
    margin-left: 30px;
}

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
    padding-right: 0;
}

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
    padding-bottom: 0;
}

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
    margin-right: 0; /* non-visible scrollbar */
    margin-left: 0;
}

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
    margin-bottom: 0;
}

/*
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS
------------------------------------------------------------------------------------------------------------------------
*/

.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
    -webkit-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, /* auto-expanded scrollbar */
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
    -webkit-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s,
        margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
        opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
    -moz-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s,
        margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
        opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
    -o-transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s,
        margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
        opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
    transition: width 0.2s ease-out 0.2s, height 0.2s ease-out 0.2s, margin-left 0.2s ease-out 0.2s,
        margin-right 0.2s ease-out 0.2s, margin-top 0.2s ease-out 0.2s, margin-bottom 0.2s ease-out 0.2s,
        opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

/*
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS
------------------------------------------------------------------------------------------------------------------------
*/

/*
----------------------------------------
6.1 THEMES
----------------------------------------
*/

/* default theme ("light") */

.mCSB_scrollTools {
    opacity: 0.75;
    filter: 'alpha(opacity=75)';
    -ms-filter: 'alpha(opacity=75)';
}

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
    opacity: 0;
    filter: 'alpha(opacity=0)';
    -ms-filter: 'alpha(opacity=0)';
}

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
    opacity: 1;
    filter: 'alpha(opacity=100)';
    -ms-filter: 'alpha(opacity=100)';
}

.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.4);
    filter: 'alpha(opacity=40)';
    -ms-filter: 'alpha(opacity=40)';
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.75);
    filter: 'alpha(opacity=75)';
    -ms-filter: 'alpha(opacity=75)';
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.85);
    filter: 'alpha(opacity=85)';
    -ms-filter: 'alpha(opacity=85)';
}

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.9);
    filter: 'alpha(opacity=90)';
    -ms-filter: 'alpha(opacity=90)';
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
    //background-image: url(mCSB_buttons.png); /* css sprites */
    background-repeat: no-repeat;
    opacity: 0.4;
    filter: 'alpha(opacity=40)';
    -ms-filter: 'alpha(opacity=40)';
}

.mCSB_scrollTools .mCSB_buttonUp {
    background-position: 0 0;
    /*
    sprites locations
    light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
    dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
    */
}

.mCSB_scrollTools .mCSB_buttonDown {
    background-position: 0 -20px;
    /*
    sprites locations
    light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
    dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
    */
}

.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: 0 -40px;
    /*
    sprites locations
    light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
    dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
    */
}

.mCSB_scrollTools .mCSB_buttonRight {
    background-position: 0 -56px;
    /*
    sprites locations
    light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
    dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
    */
}

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
    opacity: 0.75;
    filter: 'alpha(opacity=75)';
    -ms-filter: 'alpha(opacity=75)';
}

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
    opacity: 0.9;
    filter: 'alpha(opacity=90)';
    -ms-filter: 'alpha(opacity=90)';
}

/* theme: "dark" */

.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
    background-color: transparent;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #333333;
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: rgba(0, 0, 0, 0.85);
}

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: rgba(0, 0, 0, 0.9);
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
    background-position: -80px 0;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
    background-position: -80px -20px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -80px -40px;
}

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
    background-position: -80px -56px;
}

/* ---------------------------------------- */

//****** OVERRIDE ********//
.mCSB_scrollTools {
    .mCSB_buttonUp,
    .mCSB_buttonDown {
        width: auto;
        color: rgba($white, 0.75);
        font-size: 17px;
        &:after {
            font-family: 'icomoon' !important;
            display: block;
        }
    }
    .mCSB_buttonUp {
        left: -7px;
        top: 7px;
        &:after {
            content: '\e900';
            transform: rotate(90deg);
        }
    }
    .mCSB_buttonDown {
        left: -7px;
        bottom: 10px;
        &:after {
            content: '\e901';
            transform: rotate(90deg);
        }
    }
}

.uk-offcanvas,
.section-order-receive {
    .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden,
    .mCSB_inside > .mCSB_container,
    .box-overflow-container {
        padding: 0 12px;
    }

    @media (min-width: $breakpoint-large) {
        .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden,
        .mCSB_inside > .mCSB_container,
        .box-overflow-container {
            margin-right: 26px;
            padding: 0 12px;
        }
    }
}
