.component-SearchFilter {

    .active {
        border: solid 1px rgba($black, .8) !important;
        font-weight: bold;
    }

    .filter {
        border: 1px solid var(---effects-01-opacity-30-2);
        border-radius: 16px;
        padding: 16px;
        line-height: 16px;
        background-color: transparent;
        margin: 6px;

        &:not([disabled]) {
            cursor: pointer;
        }

        &-title {
            margin-right: 10px;
            font-size: 17px;
        }

        &-no-subcat {
            min-height: 56px;

            /* & *{
                pointer-events: none;
            } */
        }

        &.bassi-fissi {
            min-height: 24px;
            text-rendering: optimizespeed;
            display: flex;
            align-items: center;
        }

        .arrow {
            font-size: 24px;

            &:before {
                display: block;
                transition: all .3s ease-in-out;
            }
        }

        .checkmark {
            top: -4px;
        }

        &.enabled-filter {
            border: solid 1px rgba($black, .8) !important;
            font-weight: bold;
        }

        &[aria-expanded="true"] {
            border: solid 1px rgba($black, .8) !important;
            font-weight: bold;

            .arrow:before {
                transform: rotate(180deg);
            }
        }

        .uk-badge-custom {
            min-width: 24px;
            height: 24px;
            padding: 0px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 6px;
            /* 1 */
            background: $primary-orange;
            color: $white;
            font-size: 12px;
            /* 2 */
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }

        &-dropdown {
            border: solid 1px rgba($black, .8);
            box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            padding: 16px 14px 16px 16px;
            border-radius: 16px;
            min-width: 330px;
            z-index: 1100;

            .custom-list {
                max-height: 160px;
                overflow-y: auto;
            }

            .uk-search {
                &-icon {
                    color: $black;
                }

                &-input {
                    border: 1px solid rgba($black, 0.4);
                    min-width: 100%;
                    @include color-primary-black(0.6);
                    transition: all .3s ease-in-out;

                    &:focus {
                        background-color: rgba($yellow, 0.1);
                        border: solid 2px rgba($yellow, 0.6);
                        color: rgba($gray, 0.9);
                    }
                }

                .clear-search {
                    background-color: black;
                    color: $white;
                    width: 16px;
                    height: 16px;
                    margin: auto;
                    top: 0;
                    right: 16px;


                    i {
                        font-size: 14px;
                    }
                }
            }

            .uk-checkbox-custom {
                margin: 8px 0;

                label {
                    font-size: 14px;
                }

                .checkmark {
                    left: 0;
                    top: 0;
                }

                .label-text {
                    @include color-primary-black();
                    font-weight: 500;
                }

                .uk-badge {
                    font-weight: 800;
                    background: $gray-chips;
                }
            }

            .single-choice-radio {
                > * span {
                    margin-left: 8px;
                    @include color-primary-black;
                    font-weight: 500;
                    font-size: 14px;
                }

                .uk-badge {
                    font-weight: 800;
                    background-color: $gray-chips;
                }

                label {
                    margin: 8px 0;
                }

                > :first-child {
                    margin-top: 0;
                }

                > :last-child {
                    margin-bottom: 0;
                }

                &.custom-list {
                    label {
                        display: flex;
                        align-items: center;

                        input {
                            position: relative;
                            top: 2px;
                        }
                    }
                }
            }

            .button-box {
                margin-top: 8px;

                button,
                .uk-button {
                    margin: 0 4px;

                    &:not(.uk-button-icon) {
                        span {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        &-sort {
            top: 80px !important;
            left: 8px !important;
        }
    }

    .delete-filter {
        color: $primary-orange;
        margin-left: 16px;
    }

    .uk-disabled {
        color: var(---effects-01-opacity-30-2);

        button {
            border-color: var(---02-effects-01-opacity-30-2);
            color: var(---effects-01-opacity-30-2);

            .filter-title {
                color: var(---effects-01-opacity-30-2) !important;
            }
        }
    }
}
