.component-IspirazionaleHotspot {
    .section-lasciati-ispirare-hotspot {
        background-color: var(---colour-02-secondary-01-s-2);
        .section-title {
            font-size: 18px;
            font-weight: normal;
            line-height: 1.33;
            @include color-primary-black();
            margin: 0 0 10px;

            @media (min-width: $breakpoint-xlarge) {
                font-size: 20px;
                line-height: 1.4;
                margin-bottom: 20px;
            }

            &:before {
                bottom: -12px;
            }
        }

        .swiper-button-hotspot {
            display: flex;
            flex-direction: row;
            padding: 21px 19px 21px 8px;
            list-style: none;
            overflow: auto;
            margin-left: -8px;


            > * {
                padding-left: 16px;

                &:first-child {
                    padding-left: 0;
                }

                .uk-tag {
                    margin: 0;
                    padding: 0 24px;
                    white-space: nowrap;
                }

                &.uk-active {
                    .uk-tag {
                        background-color: $white;
                        text-decoration: none;
                        @include color-primary-black;
                        box-shadow: 0 0 10px -2px var(---dusty-orange), 0 0 0 4px inset rgba(243, 137, 51, 0.1);
                        transition: all 0.3ms ease-in-out;
                    }
                }
            }
        }

        .card-to-switch {
            display: flex;
            .img-container {
                width: 100%;
                @media (min-width: $breakpoint-xlarge) {
                    margin: 0 16px 0 0;
                }

                .img-lasciati-ispirare {
                    width: 100%;
                    border-radius: 12px;
                    overflow: hidden;
                    height: 300px;

                    @media (min-width: $breakpoint-xlarge) {
                        height: 514px;
                    }
                }

                .uk-drop {
                    width: 150px;
                }

                @media (min-width: $breakpoint-xlarge) {
                    max-width: 610px;
                }
            }

            .card-container {
                .component-ProductCard {
                    display: none;
                    @media (min-width: $breakpoint-xlarge) {
                        height: 100%;
                    }
                }
            }

            .component-ProductCard {
                width: 318px;
                .uk-dark {
                    @media (min-width: $breakpoint-xlarge) {
                        height: 100%;
                    }
                }
                .product-card {
                    background-color: $white;
                    @media (min-width: $breakpoint-xlarge) {
                        height: 100%;
                        max-height: 520px;
                    }
                }
            }
        }

        .swiper-slide {
            width: auto;
        }

        .button-section {
            display: flex;

            @media (min-width: $breakpoint-xlarge) {
                margin-top: -10px;
            }

            .button-container {
                width: auto;
                margin-right: 16px;
            }
        }
    }
}