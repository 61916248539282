@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';

.page-DetailCart {
    background-color: $cultured;

    //FIX CR NEW CHECKOUT
    .component-CardProdottoOmaggio {
        background-color: $white;
    }

    #embedded-service {
        display: none !important;
        visibility: hidden !important;
    }

    #alert-scelta-carrello-myconad {
        margin-top: 10px !important;
        background: $lavander !important;
    }

    .swiper-card-consegna-gratuita {
        max-width: 100%;
        width: 100%;
        padding-bottom: 30px;

        .swiper-pagination-bullets {
            bottom: 0;
        }

        .swiper-pagination-bullet {
            margin: 0 4px;
            opacity: 1;
            width: 16px;
            height: 16px;
            position: relative;
            background: transparent;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: '';
                position: absolute;
                width: 4px;
                height: 4px;
                background-color: $black;
                border-radius: 50%;
            }

            &-active {
                &:before {
                    width: 16px;
                    height: 16px;
                    background-color: $yellow;
                }
            }
        }
    }

    .content-grid {
        justify-content: space-between;
        margin-bottom: 30px;
    }

    .primary-panel {
        width: 100%;

        @media (min-width: $breakpoint-large) {
            min-width: 404px;
        }

        @media (min-width: $breakpoint-xlarge) {
            max-width: 893px;
        }

        .header {
            &-title {
                font-size: 24px;
                font-weight: bold;
                margin-bottom: 24px;

            }

            .uk-alert-primary {
                &.uk-alert-button-action {
                    .store {
                        font-weight: bold;
                    }

                    .uk-button {
                        &-primary-outline {
                            border: 2px solid var(---colour-03-semantic-04-inform-2);
                            color: var(---colour-03-semantic-04-inform-2);
                            box-shadow: none;
                            min-width: 164px;
                            margin-left: 40px;
                            margin-right: 8px;

                        }
                    }

                    @media (max-width: $breakpoint-small-max) {
                        flex-direction: column;
                        align-items: flex-start;
                        .icon-info {
                            margin-bottom: 10px;
                        }
                        .uk-button {
                            &-primary-outline {
                                margin-top: 10px;
                                min-width: inherit;
                                margin-left: 0;
                                margin-right: 0;
                            }
                        }
                    }
                    @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
                        flex-direction: column;
                        align-items: flex-start;
                        .icon-info {
                            margin-bottom: 10px;
                        }
                        .uk-button {
                            &-primary-outline {
                                margin-top: 10px;
                                min-width: inherit;
                                margin-left: 0;
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .total-product {
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 16px;

                b {
                    font-size: 14px;
                    font-weight: bold;
                }
            }

            &-card-id {
                font-size: 12px;
                font-weight: normal;
                margin-top: 4px;
                margin-bottom: 12px;
                @include color-primary-black();

                @media (min-width: $breakpoint-xlarge) {
                    margin-top: -10px;
                    margin-bottom: 16px;
                }
            }

            [class^='uk-alert-'] {
                p {
                    padding-right: 0;
                }

                @media (min-width: $breakpoint-large-2) {
                    .store {
                        font-weight: bold;
                    }
                }
            }

            .uk-alert-primary-mobile {
                padding: 16px;

                .top {
                    align-self: flex-start;

                    p {
                        margin: 0 0 0 17px;
                    }
                }

                button {
                    align-self: flex-end;
                    position: unset;
                    margin-top: 16px;
                }
            }

            @media (min-width: $breakpoint-xlarge) {
                &-title {
                    margin-bottom: 16px;
                }
            }
        }

        .content {
            @include color-primary-black();
            margin-top: 24px;

            &-header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                label {
                    font-size: 12px;
                }

                .uk-button-link {
                    padding: 0;
                    line-height: normal;
                    border: none;
                    color: $primary-black;
                    font-weight: bold;
                    font-size: 16px;
                    cursor: pointer;

                    span {
                        font-size: 14px;
                    }
                }

                .icon-trash-2 {
                    position: relative;
                    margin-right: 0px;
                    top: 2px;
                }

                .select-all {
                    .uk-checkbox-custom .checkmark {
                        top: -2px;
                    }
                }
            }

            .custom-margin {
                margin-top: 12px;
                margin-bottom: 25px;
            }

            .container-prodotti-omaggio {

                & > *:last-child {
                    margin-bottom: 16px;
                }

                .component-CardProdottoOmaggio:not(:last-child) {
                    margin-bottom: 16px;
                }
            }

            .category-list {
                margin: 0;

                &-mobile {
                    max-width: 100%;
                    margin-bottom: 20px;
                }

                .category {
                    position: relative;

                    &:not(:last-child):after {
                        content: '';
                        display: block;
                        margin-top: 12px;
                        margin-bottom: 25px;
                        border-bottom: 1px solid rgba($black, 0.1);
                    }

                    &:not(:first-child) {
                        margin-top: 16px;
                    }

                    .uk-accordion {
                        &-title {
                            text-decoration: none;
                            font-size: 14px;
                        }

                        &-content {
                            .component-ProductCardDetailCart:not(:first-child) {
                                margin-top: 14px;
                            }

                            .uk-alert-primary {
                                .top {
                                    p {
                                        margin: 4px 0 4px 10px;
                                    }
                                }

                                @media (max-width: 350px) {
                                    .top {
                                        p {
                                            max-width: 110px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .accordion-badge {
                        font-weight: bold;
                        position: absolute;
                        top: -6px;
                        right: 26px;
                    }

                    @media (min-width: $breakpoint-xlarge) {
                        position: relative;

                        .uk-accordion {
                            &-title {
                                font-size: 14px;
                                text-decoration: none;
                                padding-right: 16px;
                                padding-left: 16px;
                            }
                        }

                        .accordion-badge {
                            position: absolute;
                            top: -6px;
                            right: 50px;
                        }
                    }
                }

                /* .category-mobile{
                    
                } */
                /* .category-desktop {
                    position: relative;
    
                    .uk-accordion {
                        &-title {
                            font-size: 14px;
                            text-decoration: none;
                            padding-right: 16px;
                            padding-left: 16px;
                        }
                    }
    
                    .accordion-badge {
                        position: absolute;
                        top: -6px;
                        right: 50px;
                    }
                } */
            }
        }

        &[data-replacement-cart = NO_SUBSTITUTION] {
            .replacement-mode-desktop {
                .footer {
                    .desc.no-substitution {
                        display: block;
                    }
                }
            }

        }

        &[data-replacement-cart = PUNCTUAL_SUBSTITUTION] {
            .replacement-mode-desktop {
                .footer {
                    .desc.punctual-substitution {
                        display: block;
                    }
                }
            }
        }

        &[data-replacement-cart = AUTOMATIC_SUBSTITUTION] {
            .replacement-mode-desktop {
                .footer {
                    .desc.automatic-substitution {
                        display: block;
                    }
                }
            }
        }

        &[data-replacement-cart = SEMI_AUTOMATIC_SUBSTITUTION] {
            .replacement-mode-desktop {
                .footer {
                    .desc.semi-automatic-substitution {
                        display: block;
                    }
                }
            }
        }
    }

    .secondary-panel {
        display: flex;
        flex-direction: column;
        width: 100%;

        .sidebar-container-scroll {
            .annulla-ordine-container {
                text-align: center;

                .uk-button {
                    width: 100%;
                    padding: 8px 5px;
                    font-size: 14px;
                    font-weight: 500;
                    text-decoration: underline;
                    max-width: 400px;
                }
            }

            @media (min-width: $breakpoint-large) {
                .annulla-ordine-container {
                    .uk-button {
                        padding: 16px 5px;
                    }
                }
            }
        }

        @media (min-width: $breakpoint-large) {
            max-width: 304px;
            margin-left: 16px;
            padding-left: 0;
        }

        //@media (min-width: $breakpoint-xxlarge) {
        //    max-width: 370px;
        //}

        .card-consegna-gratuita {
            box-sizing: border-box;
            @include color-primary-black();
            position: relative;
            border-radius: 12px;
            min-height: 116px;
            background-color: $very-pale-yellow;
            padding: 12px 0 13px 15px;
            margin-left: 0;

            &.bg {
                @include color-primary-black();

                .text, a {
                    @include color-primary-black();
                }
            }

            .text {
                position: relative;
                padding: 0;

                h2 {
                    font-size: 14px;
                    margin-bottom: 0;
                }

                span {
                    margin-top: 5px;
                    font-size: 10px;
                    color: var(--02-effects-03-lighten-20);
                }

                a {
                    position: absolute;
                    bottom: 0;
                    font-size: 12px;
                }
            }

            .img-carrello-borse {
                position: relative;
                padding-left: 0;

                img {
                    position: absolute;
                    min-width: 146px;
                    height: 153px;
                    top: -40px;
                }

                @media (max-width: $breakpoint-large) {
                    img {
                        position: absolute;
                        min-height: 0;
                    }
                }
            }

            &-v3 {
                position: relative;
                padding: 18px 0 16px 16px;
                background-color: $blue-info;
                overflow: hidden;
                height: 159px;

                .logo {
                    max-height: 36px;
                    max-width: 200px;
                }

                .img {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    max-width: 77px;
                    @media (min-width: $breakpoint-large) {
                        max-width: 95px;
                    }
                }

                .text {
                    margin-bottom: 4px;
                    margin-top: 9px;
                    margin-right: 77px;
                    color: $white;

                    @media (min-width: $breakpoint-large) {
                        margin-right: 95px;
                    }

                    div {
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 24px;
                    }

                    p {
                        font-size: 12px;
                        line-height: 16px;
                        margin-top: 4px;
                        margin-bottom: 4px;
                        @include line-clamp(2)
                    }
                }

                a {
                    color: $white;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        .card-scontrino {
            box-sizing: border-box;
            box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            border-radius: 12px;
            margin-top: 9px;
            padding: 20px 16px 25px 16px;
            background-color: $white;
            @include color-primary-black();

            .wrapper-border-first {
                display: block !important;

                & > div:first-of-type {
                    padding-top: 9px;
                    margin-top: 9px;
                    border-top: 1px solid $global-border;
                }

                &.dashed > div:first-of-type {
                    border-top: none;
                    background-image: linear-gradient(to right, $global-border 50%, rgba(255, 255, 255, 0) 0%);
                    background-position: top;
                    background-size: 15px 1px;
                    background-repeat: repeat-x;
                }

                & > div {
                    display: flex;
                    justify-content: space-between;
                }
            }

            .scontrino-divider {
                margin-top: 9px !important;
                padding-top: 9px;
                border-top: 1px solid $global-border;

                &.dashed {
                    border-top: none;
                    background-image: linear-gradient(to right, $global-border 50%, rgba(255, 255, 255, 0) 0%) !important;
                    background-position: top;
                    background-size: 15px 1px;
                    background-repeat: repeat-x;
                }
            }

            .extra-costi-consegna:first-of-type {
                margin-top: 9px !important;
                padding-top: 9px !important;
                border-top: none;
                background-image: linear-gradient(to right, $global-border 50%, rgba(255, 255, 255, 0) 0%);
                background-position: top;
                background-size: 15px 1px;
                background-repeat: repeat-x;
            }

            .voci-promo-spesa:first-of-type {
                margin-top: 9px;
                padding-top: 9px;
                border-top: 1px solid $global-border;
            }

            &-top {
                box-sizing: border-box;

                h2 {
                    font-size: 16px;
                    margin-bottom: 7px;
                }

                .costs {
                    & > * {
                        display: flex;
                        justify-content: space-between;
                    }

                    .prodotti-pesanti {
                        margin-bottom: 0px;
                        margin-top: 20px;
                        margin-bottom: 3px;

                        .text,
                        .value {
                            font-size: 14px;
                            justify-content: flex-start;
                        }

                        .value {
                            font-weight: bold;
                        }

                        &-no-margin {
                            margin-top: 0px;
                        }
                    }
                }

                .products-costs,
                .delivery-costs,
                .points-and-cost {
                    .text,
                    .value {
                        [class*='icon-'] {
                            position: relative;
                            top: 3px;
                            margin-left: 4px;
                            cursor: pointer;
                        }
                    }
                }

                .products-costs {
                    .text,
                    .value {
                        font-size: 14px;
                    }

                    .value {
                        font-weight: bold;
                    }
                }

                .delivery-costs {
                    .text,
                    .value {
                        font-size: 12px;
                    }
                }

                .delivery-costs {
                    margin-bottom: 7px;

                    .tooltiptext {
                        width: 292px;
                        padding: 6px 12px 38px 12px;

                        p {
                            margin: 0;
                        }

                        hr {
                            margin-top: 9px;
                            margin-bottom: 9px;
                            border-color: rgba($white, 0.6);
                        }
                    }

                    &.total-delivery-costs {
                        margin-bottom: 0px;

                        .text,
                        .value {
                            font-size: 14px;
                        }
                    }
                }

                .products-costs {

                    .sconto-applied {
                        text-align: right;

                        .sconto-old {
                            text-decoration: line-through;
                            margin-right: 5px;
                            font-size: 12px;
                            font-weight: normal;
                        }

                        b {
                            color: var(---colour-01-primary-01-p-2);
                        }
                    }
                }
            }

            &-bottom {
                box-sizing: border-box;
                margin-top: 3px;
                /* height: 273px; */

                .points-and-cost {
                    .text,
                    .value {
                        [class*='icon-'] {
                            position: relative;
                            top: 3px;
                            margin-left: 4px;
                            cursor: pointer;
                        }
                    }
                }

                .points-and-cost {
                    margin-bottom: 5px;

                    > * {
                        display: flex;
                        justify-content: space-between;

                        /* > * {
                            border: 1px solid black;
                        } */

                        .text,
                        .value {
                            font-size: 12px;
                            justify-content: flex-start;
                        }
                    }

                    .totale-ordine {
                        margin-bottom: 7px;

                        .text {
                            font-size: 16px;

                            h2 {
                                font-size: 16px;
                                margin: 0;
                            }

                            span {
                                font-size: 10px;
                                color: $base-body-color;
                            }
                        }

                        .value {
                            font-size: 16px;
                        }
                    }

                    .pre-authorization {
                        .info {
                            display: block;
                            font-size: 12px;
                            color: $blue-info;
                            text-decoration: underline;
                            font-weight: 500;
                            line-height: 16px;

                            @media (min-width: $breakpoint-xlarge) {
                                margin-top: 4px;
                            }
                        }
                    }
                }

                &-footer {
                    .info-punti-bollini {
                        color: $primary-black;
                        margin-bottom: 13px;
                        font-size: 10px;
                    }

                    .info-sconto {
                        color: var(--02-effects-03-lighten-20);
                        margin-bottom: 15px;
                        font-size: 12px;
                    }

                    button, a {
                        font-size: 14px;
                        padding: 0;
                    }

                    .carta-insieme {
                        font-size: 12px;

                        img {
                            max-height: 16px;
                            margin-right: 5px;
                        }
                    }
                }

                .voce-dettaglio-risparmio {
                    margin-top: 5px;
                    margin-left: 16px;
                    color: rgba(51, 51, 51, 0.7);
                }

                button {
                    padding: 0;
                }
            }
        }
    }

    .logos {
        justify-content: flex-start;
        margin-bottom: 3px;

        > * {
            margin-right: 4px;
        }

        > *:last-child {
            margin-right: 0px;
        }
    }

    .component-Breadcrumb {
        @media (min-width: $breakpoint-large) {

        }
    }

    .container-sostituti {
        .logos {
            img {
                max-width: 24px !important;
            }
        }

        /* Modale desktop */
        .uk-modal {
            &-dialog {
                width: 100%;
                max-width: 944px !important;
                padding-bottom: 0px;
                padding-left: 0;
                padding-right: 0;
            }

            &-body {
                padding-top: 32px;
            }

            &-title {
                padding-right: 24px;
                padding-left: 24px;
            }

            &-footer {
                padding: 16px 24px;
            }
        }

        /* Offcanvas mobile */
        .uk-offcanvas {
            &-wrapper {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            &-header {
                padding-top: 66px;
                padding-bottom: 0px;

                @media (min-width: $breakpoint-large) {
                    padding-right: 32px;
                    padding-left: 32px;
                }
            }

            &-body {
                background-color: var(---colour-02-secondary-01-s-2);
                flex-grow: 1;
                padding: 0;

                .mCSB {
                    &_container {
                        display: grid;
                        margin-right: 0;
                        padding: 24px 16px;
                        gap: 16px;

                        @media (min-width: $breakpoint-large) {
                            grid-template-columns: repeat(2, 1fr);
                            padding: 32px 34px;

                            .scegli {
                                grid-column: 1 / -1;
                            }
                        }
                    }

                    &_draggerContainer {
                        display: none;
                    }
                }
            }

            &-footer {
                position: unset;
                box-shadow: 0 -4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);

                .uk-button {
                    float: right;
                    padding: 0 24px;
                    top: 0;
                }
            }
        }

        .replace-list {
            background-color: var(---colour-02-secondary-01-s-2);

            .component-ProductCard {
                background-color: $white;
                border-radius: 12px;
                pointer-events: none;
            }

            .manage-preferite-product {
                pointer-events: auto !important;
            }

            .card-overlay {
                border-radius: 12px;
                @media (max-width: $breakpoint-large-2-max) {
                    padding-bottom: 16px;
                }

                .badge-top {
                    width: max-content;
                    height: 52px;
                    margin-bottom: -24px;
                    background-color: $primary-orange;
                    border-radius: 12px;
                    color: $white;
                    font-size: 14px;
                    line-height: 14px;

                    span {
                        margin-top: 6px;
                        color: $white;
                    }
                }

                .orange-overlay {
                    border-radius: 12px;

                    &.active {
                        box-shadow: 0 0 10px -2px var(---dusty-orange);
                    }

                    @media (min-width: $breakpoint-xlarge) {
                        min-width: 384px;
                        max-width: 896px;
                    }
                }

                @media (min-width: $breakpoint-large) {
                    justify-content: flex-end;
                }
            }

            .scegli {
                @include color-primary-black();
                margin-bottom: 16px;
                font-size: 16px;
                line-height: 24px;
                margin-top: 10px;

                @media (min-width: $breakpoint-xlarge) {
                    margin-top: 16px;
                    margin-bottom: -16px;
                    padding-left: 24px;
                }
            }

            .component-ProductCard {

                .product-card-horizontal {
                    width: 100%;
                    max-height: 260px !important;

                    .card-bottom .uk-button {
                        display: none;
                    }

                    .card-top {
                        padding-bottom: 0px;
                    }

                    .card-middle {
                        .product {
                            flex-direction: row;
                            max-height: 109px !important;

                            &-img {
                                position: revert;
                                margin-bottom: 20px;
                                height: 100%;
                                width: 100%;
                                max-width: 80px;
                                max-height: 80px;
                            }

                            &-description {
                                margin-left: 8px;

                                .badge-marchi-conad {
                                    top: 0 !important;
                                    right: 0 !important;
                                    max-height: 36px !important;
                                }
                            }

                            &-info {
                                align-items: flex-start;

                                @media (min-width: $breakpoint-xxxlarge) {
                                    max-width: calc(100% - 88px) !important;
                                }
                            }

                            .product-price {
                                font-size: 26px;
                            }

                            .badge-marchi {
                                right: inherit;
                                top: 106px;
                                left: 18px;
                                @media (min-width: $breakpoint-xlarge) {
                                    top: 46px;
                                }
                            }

                        }

                        .info-weight {
                            display: none;
                        }
                    }

                    &.product-conad60 {
                        .card-middle {
                            .product {
                                &-info {
                                    align-items: flex-start;
                                    margin-left: 90px !important;
                                    @media (min-width: $breakpoint-xlarge) {
                                        width: auto;
                                        max-width: inherit !important;
                                    }
                                    @media (min-width: $breakpoint-xxxlarge) {
                                        margin-left: 80px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media (min-width: $breakpoint-xlarge) {
                padding-bottom: 19px;

                .component-ProductCard {

                    .product-card-horizontal {
                        max-height: 260px !important;
                        max-width: 384px !important;

                        .card-top {
                            padding-bottom: 0px;
                        }

                        .card-middle {
                            .product {
                                max-height: 109px !important;

                                &-img {
                                    position: revert;
                                }
                            }
                        }
                    }
                }
            }
        }

        .current-element {
            padding-bottom: 24px;

            .data > * {
                margin-top: 8px;

                &:first-child {
                    margin-top: 0px;
                }

                &:last-child {
                    margin-top: 4px;
                }
            }
        }

        .product-desc {
            @include color-primary-black();
            font-size: 20px;
            font-weight: bold;
            line-height: 24px;
        }

        .img {
            flex-shrink: 0;
            margin-right: 8px;
            position: relative;

            img {
                max-width: 56px;
                max-height: 56px;

            }

            .badge-marchi-conad {
                position: absolute;
                bottom: -44px;
                left: 8px;
                max-width: 40px;
            }
        }

        .unit-price {
            font-size: 16px;

            &-red {
                color: var(---colour-01-primary-01-p-2);
            }

            &-original {
                text-decoration: line-through;
                margin-left: 5px;
                font-size: 10px;
                color: rgba(0, 0, 0, 0.8);

                @media (min-width: $breakpoint-large) {
                    font-size: 16px;
                }
            }

            @media (min-width: $breakpoint-large) {
                font-size: 20px;
            }
        }

        .price-qty {
            color: rgba(0, 0, 0, 0.4);
            font-size: 10px;
        }

        .discount {
            background-color: var(---colour-01-primary-01-p-2);
            color: $white;
            padding: 6px 8px;
            font-size: 12px;
            border-radius: 8px;
        }

        .swiper-sostituti {
            box-sizing: border-box;
            padding: 32px 24px 22px 24px;
            width: 100%;

            .swiper-scrollbar {
                max-width: 345px;
                left: 0;
                right: 0;
                margin: auto;
            }

            .swiper-slide {
                width: auto;
            }

            .swiper-button-prev {
                top: 1px;
                right: 58px;
                left: unset;
            }

            .swiper-button-next {
                top: 1px;
                right: 24px;
            }
        }
    }

    //Section carousel
    .section-carousel {
        .swiper-container {
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            padding-right: 16px;

            .swiper-button-prev,
            .swiper-button-next {
                min-width: 30px;
                min-height: 22px;
                text-align: center;
            }

            @media (max-width: 374px) {
                .swiper-scrollbar {
                    width: calc(100% - 32px);
                    left: 16px;
                }
            }

            @media (min-width: $breakpoint-large) {
                padding-bottom: 45px;
            }

            @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xxlarge-max) {
                margin-left: -32px;
                margin-right: -32px;
                padding-left: 32px;
                padding-right: 32px;
                .swiper-pagination-custom {
                    right: 85px;
                }
                .swiper-button-prev {
                    right: 65px;
                }
                .swiper-button-next {
                    right: 30px;
                }
            }

            @media (min-width: $breakpoint-xlarge) {
                width: 100%;
            }

            @media (min-width: $breakpoint-xlarge) and (max-width: $breakpoint-xxlarge-max) {
                margin-left: -40px;
                margin-right: -40px;
                padding-left: 40px;
                padding-right: 40px;
            }
            @media (min-width: $breakpoint-xxxlarge) {
                .swiper-pagination-custom {
                    right: 70px;
                }
            }

            @media (min-width: 375px) {
                .swiper-scrollbar {
                    left: 50% !important;
                    max-width: 300px;
                    margin-left: -150px;
                }
            }
        }
    }

    //Empty Cart Page
    .section-empty {
        @include color-primary-black();
        flex-direction: column-reverse;

        .lens {
            margin: 15px 0 25px;

            img {
                width: 80px;
            }
        }

        .title {
            margin-bottom: 8px;
            font-size: 32px;
        }

        .box-accedi {
            margin-top: 40px;
        }

        .cta-login {
            color: $base-body-color;
            padding-top: 8px;
        }

        .btn-start {
            margin: 30px 0 48px;
            line-height: 56px;
        }

        @media (min-width: $breakpoint-large) {
            margin-top: 50px;
            flex-direction: row;
            .lens {
                margin-left: 80px;

                img {
                    width: 135px;
                }
            }
            .title {
                font-size: 40px;
            }
            .box-accedi {
                margin-top: 20px;
            }
            .btn-start {
                margin-bottom: 152px;
            }
        }
    }

    .section-evidenza-alternative {
        padding: 0;

        .component-ProdottiEvidenza .section-prod-evid .pagination-prod-evid {
            top: 2px !important;
            right: 64px !important;
        }
    }

    .component-ProductCardLastView {
        .product-card {
            background-color: $white;
        }
    }

    //Alert Limite Superamento Litri
    //
    .alert-limit-exceeding-liters {
        margin-bottom: 16px;
        h4 {
            color: $danger;
            font-weight: bold;
            font-size: 14px;
            line-height: 1.43;
            margin: 0;
        }
        p {
            margin: 0;
        }
    }
}
