.component-CarouselBannerSponsor {
    .section-banner-sponsor {
        .swiper-pagination-bullet {
            margin: 0 4px;
            opacity: 1;
            width: 16px;
            height: 16px;
            position: relative;
            background: transparent;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: '';
                position: absolute;
                width: 4px;
                height: 4px;
                background-color: $black;
                border-radius: 50%;
            }

            &-active {
                &:before {
                    width: 16px;
                    height: 16px;
                    background-color: $yellow;
                }
            }
        }
    }
}