
@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';

.component-store-pin-locator {
    box-shadow: 0 4px 8px -2px rgba($black, 0.15), 0 0 1px 0 var(--02-effects-02-darker-20-2);
    border-radius: 8px;
    background-color: $pin-yellow;
    line-height: 24px;
    padding: 0 3px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    @include color-primary-black;
    cursor: pointer;
    &.w-arrow {
        position: relative;
        &:after {
            content: '';
            top: 100%;
            left: 50%;
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border: solid transparent;
            border-color: rgba($white, 0);
            border-top-color: $pin-yellow;
            border-width: 5px;
            margin-left: -5px;
        }
    }
    &.active {
        color: $white;
        background-color: var(---colour-01-primary-03-p-2);
        &.w-arrow {
            &:after {
                border-top-color: var(---colour-01-primary-03-p-2);
            }
        }
    }
    &.disabled {
        color: var(---effects-01-opacity-40-2);
        background-color: $white;
        &:after {
            display: none;
        }
    }
}
