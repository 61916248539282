.component-PromoComponent {

    .uk-container {
        display: flex;
        flex-direction: column;

        @media (min-width: $breakpoint-xlarge) {
            flex-direction: row;
        }

/*         > .component-PromoIdm {
            margin-top: 10px;
        } */
    }

    .section-promo-idm {
        padding: 0;
        .swiper-promo-idm {
            padding: 0;
            margin: 0;
            .pagination-promo-idm {
                @media (min-width: $breakpoint-xlarge) {
                    width: unset;
                    top: 20px;
                    left: 18px;                
                }
            }

            .swiper-pagination {
                height: min-content;

                &-bullet {
                    margin: 0 4px;
                    opacity: 1;
                    width: 16px;
                    height: 16px;
                    position: relative;
                    background: transparent;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
    
                    &:before {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 4px;
                        background-color: $black;
                        border-radius: 50%;
                    }
    
                    &-active {
                        &:before {
                            width: 16px;
                            height: 16px;
                            background-color: $yellow;
                        }
                    }
                }
            }
        }
    }


    &.type-A {
        .uk-container > .component-PromoIdm {
            display: none;
        }

        .section-promo-idm {
            width: 100%;

            @media (max-width: $breakpoint-large-2-max){
                .pagination-promo-idm{
                    position: relative;
                    bottom: 0px;
                    margin: 16px 0 0 0;
                }
            }
        }
    }

    &.type-B {

        .section-promo-idm {
            margin-right: 16px;

            width: 100%;

            @media (min-width:$breakpoint-xlarge) {
                width: 70%;
            }

            @media (max-width: $breakpoint-large-2-max){
                .pagination-promo-idm{
                    position: relative;
                    bottom: 0px;
                    margin: 16px 0 25px 0;
                }
            }
        }
    }

    &.type-C {
        .section-promo-idm {
            display: none;
        }

        .uk-container > .component-PromoIdm {
            &:nth-last-child(2) {
                margin-bottom: 16px;
            }

            @media (min-width:$breakpoint-xlarge) {
                &:nth-last-child(2) {
                    margin-right: 12px;
                    margin-bottom: 0;
                }
                &:last-child {
                    /* margin-left: 12px; */
                }
            }
            
        }
    }
}