.component-faqSectionDomande {
    .section-main-domande {
        .section-container {
            padding: 0 0 32px 0;
            display: flex;
            border-bottom: 1px solid #e6e6e6;
            flex-direction: column;
            @media (min-width: $breakpoint-xlarge) {
                flex-direction: row;
            }

            .sidebar {

                &-mobile {
                    .uk-list {
                        display: flex;
                        flex-direction: row;
                        overflow: scroll;
                        align-items: center;
                        height: 63px;
                        margin-bottom: 0;

                        li {
                            margin: 0 0 0 6px;

                            .uk-tag {
                                width: max-content;
                                padding: 0 24px;
                                background-color: $gray-chips;
                            }
                        }
                    }
                }

                li {
                    margin: 25px 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                .list-el {

                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1;
                    color: $black;
                    text-decoration: none;

                    &.active {
                        color: $circle-color;
                        font-weight: bold;
                    }
                }
            }

            .section-main {
                @media (min-width: $breakpoint-xlarge) {
                    padding: 0 0 0 46px;
                }

                .title {
                    color: $primary-black;
                    font-size: 32px;
                    font-weight: bold;
                    line-height: 1.25;
                    margin: 0;
                }

                .section-content {
                    padding: 0;

                    ul {

                        padding-bottom: 53px;
                        margin-left: 0;

                        li {
                            padding: 36px 0 13px;
                            margin: 0 0 0 16px;
                            border-bottom: 1px solid #e6e6e6;

                            .title {
                                color: $primary-black;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 1;
                                text-decoration: none;
                            }

                            div {
                                font-size: 14px;
                                font-weight: normal;
                                line-height: 1.43;
                                color: $global-muted-color;
                                padding: 0 0 3px;
                                max-width: 80ch;
                            }

                            a:before {
                                font-size: 24px;
                            }

                            &.uk-open + li {
                                padding-top: 13px;
                            }

                            h2 {
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }
                    }


                    .review-alert {
                        @include color-primary-black(0.8);
                        margin: 56px 0 36px;
                        clear: both;
                        border: 1px solid rgba(51, 51, 51, 0.2);
                        border-radius: 12px;
                        padding: 24px;
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 1.43;

                        @media (min-width: $breakpoint-xlarge) {
                            padding: 14px 24px;
                        }

                        .question {
                            display: flex;
                            flex-direction: column;

                            @media (min-width: $breakpoint-xlarge) {
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                            }

                            .title {
                                font-size: 14px;
                                margin: 0;
                                padding: 0;
                            }

                            .button-container {
                                margin-top: 10px;
                                align-self: flex-end;

                                button {
                                    @include color-primary-black(0.8);
                                    padding: 9px 24px;
                                    background-color: $white;
                                    font-size: 14px;
                                    font-weight: bold;
                                    line-height: 1;
                                    border: 2px solid $gray;
                                    border-radius: 12px;
                                    text-decoration: none;

                                    &:first-child {
                                        margin-right: 12px
                                    }
                                }
                            }

                            &-yes {
                                /* display: flex; */
                                flex-direction: row;
                                align-items: center;

                                display: none;

                                i {
                                    background-color: $gray;
                                    color: $white;
                                    font-size: 16px;
                                    padding: 4px;
                                    border-radius: 90px;
                                    margin-right: 8px;
                                }

                                span {
                                    font-size: 16px;
                                    font-weight: bold;
                                    line-height: 1.5;
                                }
                            }

                            &-no {

                                display: none;

                                .title {
                                    font-size: 14px;
                                    font-weight: bold;
                                    line-height: 1.43;
                                    margin: 0 0 8px;
                                }

                                .desc {
                                    color: $primary-gray;
                                    margin: 0 0 21px;
                                    font-size: 12px;
                                    font-weight: normal;
                                    line-height: 1.33;
                                }

                                .form-check {
                                    label {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: flex-start;

                                        margin-bottom: 16px;

                                    }

                                    span.label {
                                        margin-top: 6px;
                                        font-size: 12px;
                                        font-weight: normal;
                                        line-height: 1.33;

                                    }

                                    .button-submit {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: center;

                                        padding: 12px 24px;
                                        border-radius: 12px;
                                        background-color: $primary-orange;
                                        color: $white;
                                        font-size: 14px;
                                        font-weight: bold;
                                        line-height: 1;
                                        border: none;
                                        margin-top: 40px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}