.component-SearchRecommended {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    .title {
        color: $gray;
        display: none;
        padding-left: 0;
        margin-bottom: 12px;
        font-weight: bold;
    }

    ul {
        display: flex;
        flex-wrap: nowrap;
        margin: 0;
        overflow-x: auto;

        li {
            white-space: nowrap;
            padding: 5px;
            margin-top: 0;

            .uk-tag {
                line-height: 25px;
                background-color: $gray-chips;
                &.active {
                    @media (min-width: $breakpoint-xlarge) {
                        color: var(---colour-01-primary-03-p-2);
                        box-shadow: none;
                    }
                }
            }
        }
    }

    @media (min-width: $breakpoint-xlarge) {
        ul {
            display: block;
            margin-top: -4px;
            margin-bottom: 10px;
            overflow-x: inherit;

            li {
                padding-left: 16px;
                margin: 0 !important;

                .uk-tag {
                    background-color: transparent;
                }

                > * {
                    background-color: transparent;
                    color: $black;
                    font-weight: 500;
                    border: none;
                    padding: 4px 0;
                    display: block;
                    text-align: left;
                }

                &.active {
                    background-color: transparent;
                    border: none;
                    color: var(---colour-01-primary-03-p-2);
                }
            }
        }
        .title {
            display: block;
            @include color-primary-black(0.5);
            margin: 12px 0 4px !important;
        }
    }
}

.component-categoryList {
    display: block;
    overflow-x: auto;
    
    ul {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .cat-back {
        min-width: 25px;
        line-height: 25px;
        margin: 0;
        position: absolute;
        bottom: 5px;
        font-size: 20px;
        padding: 0;
        display: none;
        background-color: $gray-chips;
        @media (max-width: $breakpoint-large-2-max) {
            display: inline-block;
        }
        i {
            position: relative;
            top: 2px;
        }
    }
    ul {
        li:first-child {
            margin-top: 10px;
        }
    }
    .listing-cat-2 {
        ul {
            flex-wrap: nowrap;
            display: flex;
            max-width: calc(100% - 35px);
            margin-left: auto;
            overflow-x: auto;
            position: relative;
            @media (min-width: $breakpoint-xlarge) {
                max-width: 100%;
                overflow-x: inherit;
                margin-left: inherit;
                display: block;
            }
        }
    }

    @media (min-width: $breakpoint-xlarge) {
        ul {
            margin-top: 0;
            li {
                padding: 0;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.component-categoryListAccordion {
    margin-top: 10px;
    .all-cat {
        text-decoration: none;
        font-size: 16px;
        @include color-primary-black();
        font-weight: bold;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 24px;
        &.active {
            color: $primary-orange;
        }
    }
    .uk-list {
        > * {
            margin-bottom: 24px;
            padding-left: 0;
            .toggle-icon {
                font-size: 20px;
                margin-left: auto;
                cursor: pointer;
                padding-left: 5px;
                &:before {
                    display: block;
                    transition: all .3s ease-in-out;
                    transform: rotate(0deg);
                }
            }
            &:before {
                display: none;
            }
            > * {
                display: flex;
            }
        }
        .uk-accordion-title {
            margin: 0 !important;
        }
        .uk-accordion-content {
            display: none;
            margin-top: 0;
        }
        .uk-open {
            .uk-accordion-content {
                display: block;
            }
            .toggle-icon {
                &:before {
                    display: block;
                    content: "\e919";
                    transition: all .3s ease-in-out;
                    transform: rotate(180deg);
                }
            }
        }
        .active {
            color: $primary-orange;
        }
    }
    a,.title {
        text-decoration: none;
        font-size: 16px;
        @include color-primary-black();
        font-weight: bold;
        line-height: 24px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &:hover {
            color: $primary-orange;
        }
    }
    .uk-accordion-title {
        //&[aria-expanded="true"] {
        //    color: $primary-orange;
        //}
        &:before {
            display: none;
        }
        //&[aria-expanded="true"],
        //&:hover {
        //    &:before {
        //        @include color-primary-black();
        //    }
        //}
    }

    .uk-open > .uk-accordion-title:before {
        content: '\e919';
        top: 3px;
        @include color-primary-black();
    }
    .uk-accordion-content {
        > * {
            font-size: 14px;
            display: block;
            padding: 3px 0 3px 21px;
            font-weight: 500;
            margin-bottom: 7px;
        }
    }
    @media (min-width: $breakpoint-xlarge) {
        ul {
            margin-top: 0;
            li {
                padding: 0;
                > * {
                    padding: 8px 0;
                }
            }
        }
    }
}
