@import 'tailwindcss/base';

button {
    text-align: inherit;
}

img {
    display: inline; /* as old-fe: some images are aligned using text-align:center */
}

svg {
    display: inline;
}

[x-cloak] {
    display: none !important;
}

.splide__sr {
    visibility: hidden;
}