.component-faqSectionContattaci{
    .section-contattaci {

        .title {
            color: $primary-black;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.33;
            margin: 0;

            strong {
                font-weight: bold;
            }
        }

        .card-container {
            display: flex;
            flex-direction: column;
            padding: 40px 0 24px;
            width: 100%;

            @media (min-width: $breakpoint-xlarge) {
                flex-direction: row;
            }

            .card {
                cursor: pointer;
                padding: 24px;
                border: 1px solid rgba(51, 51, 51, 0.2);
                width: auto;
                margin-bottom: 16px;
                border-radius: 12px;
                display: flex;
                flex-direction: column;

                @media (min-width: $breakpoint-xlarge) {
                    width: calc((100% - 32px) / 3);

                    &:not(:first-child) {
                        margin-left: 8px;
                    }
    
                    &:not(:last-child) {
                        margin-right: 8px;
                    }
                }

                &-title {
                    color: $primary-black;
                    display: flex;
                    align-items: center;

                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.5;
                    margin: 0 0 16px;

                    i {
                        font-size: 24px;
                        margin-right: 8px;
                    }
                }

                &-desc {
                    color: $primary-black;
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.43;
                    margin: 0 0 16px;
                }

                &-button {
                    width: 100%;
                    line-height: normal;
                    padding: 20px 0;
                    font-size: 16px;
                    margin-top: auto;
                }

                .info-chiama {
                    flex-direction: row;
                    align-items: center;
                    display: none;
                    padding: 18px 16px;
                    background-color: $light-grayish-blue;
                    color: $blue-info;
                    font-size: 14px;
                    line-height: 1.43;
                    border-radius: 12px;

                    i {
                        background-color: $blue-info;
                        border-radius: 90px;
                        color: $white;
                        font-size: 16px;
                        margin-right: 16px;
                    }

                    span {

                        text-align: left;
                        font-weight: normal;

                        span {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}