.component-ordina-ritira-scelta-pdv {
	.add-box-shadow{
		position: relative;
		&::before{
			content: '';
			display: block;
			box-shadow: 0 5px 5px -5px var(---02-effects-02-darker-05-2-15) inset;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height: 5px;
			z-index: 1;
		}
		
	}
	.vedi_mappa,
	.vedi_lista {
	    text-align: center;
		width: calc(100% - 34px);
		max-width: 576px;
		font-size: 16px;
		line-height: 44px;
		height: 44px;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-radius: 12px;
		transition: all .3s ease-in-out;
		margin: 0 auto;
		background-color: $gray;
		border: none;
		color: $white;
		display: block;
		cursor: pointer;
		padding: 0;
		z-index: 1;
		i {
			margin-right: 8px;
		}

	}
	.vedi_lista {
		max-width: 100%;
	}
	&.onScroll {
		@media (max-width: $breakpoint-medium-max) {
			.vedi_mappa {
				position: relative;
				top: -45px;
				left: 0;
				right: 0;
				width: 100%;
				max-width: 100%;
				border-radius: 0;
			}
			.section-view-pdv-content {
				position: relative;
				top: -45px;
				height: calc(100dvh - 302px) !important;
				.lista-negozi-section {
					margin-top: 0 !important;
					.uk-list {
						padding-top: 15px;
					}
				}
			}
		}
	}

	.section-view-pdv-content{
		width: 100% !important;
		padding: 0 !important;
		.lista-negozi-section{
			padding: 0 16px !important;
			box-sizing: border-box;
		}
	}

	.vedi_lista {
		box-shadow: 0 3px 5px 0 var(--02-effects-01-opacity-20);
		display: none;
		@media (max-width: $breakpoint-xlarge-max) {
			border-radius: 0;
			width: 100%;
		}
	}
	
	//.section-view-negozi {
	//	max-height: calc(100vh - 287px);
	//}

	.map-pdv-container {
		.component-card-negozio {
			display: none;
		}
	}

	.lista-negozi-section.lista-negozi-section-hidden + .map-pdv-container{
		.component-card-negozio{
			display: block !important;
		}
	}
	
	
	.map-pdv-container {
		width: 100%;
		display: none;
		position: relative;

		.map-pdv-frame {
			width: 100%;
			height: 100%;
			position: relative !important;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			max-width: 100%;
			min-width: 100%;
		}

		.toList, .toMap {
			position: absolute;
			background-color: $white;
			color: $black;
			font-size: 24px;
			line-height: 0;
			padding: 11px;
			top: 24px;
			left: 30px;
			flex-grow: 0;
			box-shadow: 2px 5px 8px 0 rgba(0, 0, 0, 0.36);
			border: solid 1px #999;
			z-index: 1;

			i {
				position: relative;
				left: -1px;
				margin: 0;
			}
		}

		.toMap {
			display: block;
			@media (max-width: $breakpoint-large-2) {
				display: none;
			}
		}

		.toList {
			display: none;
			font-size: 20px;
			line-height: 24px;
			font-weight: 500;
			i {
				top: 1px;
			}
			@media (max-width: $breakpoint-large-2) {
				display: none !important;
			}
		}

		.gm-style {
			.gm-style-iw-t {
				&:after {
					display: none;
				}
			}
			.gm-style-iw-tc {
				display: none;
			}
			.gm-style-iw-c {
				padding: 0;
				border-radius: 12px;
				box-shadow: 2px 6px 8px -2px rgba($black, 0.3);
				> button {
					display: none !important;
				}
				.gm-style-iw-d {
					overflow: auto !important;
				}
			}

			.map-label {
				margin-bottom: 8px;
			}
		}
		
		.gm-bundled-control {
			right: 69px !important;
			bottom: 165px !important;
		}

		//@media (max-width: $breakpoint-medium-max) {
		//	.map-pdv-frame {
		//		margin-left: -17px;
		//		width: calc(100% + 34px);
		//		max-width: calc(100% + 34px);
		//	}
		//}
	}

	@media (min-width: $breakpoint-large) {

	}
	
	@media (min-width: $breakpoint-large-2) {
		.title-section {
			margin-bottom: 32px;

			.title {
				font-size: 24px;
			}
		}
		.vedi_mappa,
		.vedi_lista {
			display: none;
		}
		.map-pdv-container,
		.lista-negozi-section {
			display: block;
		}
	}
}

.zoom-control {
	top: 174px !important;
	display: none;

	&-content {
		border-radius: 12px;
		box-shadow: 2px 5px 10px 0 var(---effects-02-darker-40-2);
		background-color: $white;
		text-align: center;
		width: 48px;
		height: 97px;

		margin-top: -150px;
		margin-left: -80px;

		> * {
			height: 50%;
			font-size: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			&:hover {
				background-color: $primary-disabled;
				&:after {
					display: none;
				}
			}
		}
	}

	&-in {
		position: relative;
		&:hover {
			border-radius: 12px 12px 0 0;
		}
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 16px;
			height: 1px;
			background-color: #e6e6e6;
			bottom: 0px;
			left: calc(50% - 8px);
		}
	}

	&-out {
		&:hover {
			border-radius: 0 0 12px 12px;
		}
	}
	@media (min-width: $breakpoint-large-2) {
		display: block;
	}
}

.device_mobile, .device_tablet {
	.component-ordina-ritira-scelta-pdv {
		.lista-negozi-section {
			max-height: 100%;
		}
	}
}