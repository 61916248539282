.component-ModalDetailProduct {
    .offcanvas-detailProduct {

        .label-gratis-omaggio{
            font-size: 32px;
            margin-bottom: 25px;
            @media (min-width: $breakpoint-xlarge){
                margin-bottom: 36px;
            }
            
        }

        .product-promo {
            font-family: "DM Sans";

            & > * {
                color: var(---colour-01-primary-01-p-2);
                font-size: 14px;
                line-height: 1.43;
                margin-bottom: 4px;
            }

            .promo-target {
                font-weight: bold;
            }

            .prezzo-tagliato {
                text-decoration: line-through;
            }
        }

        .badge-container {
            margin-bottom: 16px;

            @media (min-width: $breakpoint-xlarge) {
                margin-bottom: 38px;
            }
        }

        .zoomArea {
            width: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;

            #NZoomContainerMobile {
                width: 100%;
                height: 100%;
            }
        }

        .uk-offcanvas-close {
            top: 16px;
            right: 20px;

            @media (min-width: $breakpoint-large) {
                top: 30px;
                right: 70px;
            }
        }

        .message-feedback {
            position: absolute !important;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100% !important;
            display: none;
            z-index: 2 !important;

            &.ok {
                background: url('./resources/ecommerce/img/icon-margherita-feedback-ok.svg') no-repeat center rgba($white, 0.8);
            }
        }

        .marchio-conad {
            z-index: 2;
            right: 40px;
            background-color: $white;
            padding: 8px;
            display: flex;
            flex-direction: column;

            .conad-60 {
            }

            img {
                max-width: 70px;
            }

            @media (min-width: $breakpoint-large-2) {
                right: 0;
                img {
                    max-width: 150px;
                }
            }
        }

        //sottocosto
        .element-sottocosto {
            .component-PromoBadgeCard{
                margin-right: 4px;
                
                .badge-text {
                    font-size: 16px;
                    line-height: 1;
                    padding: 8px;
                    font-weight: bold;
                    border-radius: 8px;
                    margin-right: 4px;
                }
            }
    
            img{
                margin-right: 8px;
            }
        }

        //Promozione
        .element-promozione {
            font-size: 16px;
            line-height: 24px;
            color: var(---colour-01-primary-01-p-2);
        }

        //preferite
        .element-preferite {
            color: $primary-orange;
            position: absolute;
            background: transparent;
            top: 0;
            right: -6px;
            font-size: 24px;
            border: none;
            box-shadow: none;
            cursor: pointer;
            z-index: 2;

            > i {
                &.active:before {
                    content: '\e921';
                }
            }

            @media (min-width: $breakpoint-large-2) {
                position: static;
                text-align: right;
            }
        }

        .product-image-container {
            padding-top: 48px;
            width: 100%;

            .product-image-content {
                > * {
                    margin: 0 auto;
                    text-align: center;
                }
            }

            img {
                max-height: 100%;
            }

            .btn-zoom {
                z-index: 2;
                font-size: 20px;
                @include color-primary-black(0.2);
                padding: 3px 5px;
                text-decoration: none;
                bottom: 45px;

                @media (min-width: $breakpoint-xlarge) {
                    display: none;
                }
            }

            @media (min-width: $breakpoint-large-2) {
                .preview-image-container {
                    height: 370px;
                    margin-bottom: 10px;
                    position: relative;

                    .lds-ring {
                        height: auto;
                        top: 50%;
                        margin-top: -7px;
                        margin-left: -7px;
                    }

                    > * {
                        height: 100%;
                        position: relative;
                        z-index: 1;
                    }
                }
            }

            @media (min-width: $breakpoint-xlarge) {
                .preview-image-container {
                    height: 385px;
                }
            }

            //swiper image product
            .swiper-button-next,
            .swiper-button-prev {
                font-size: 18px;
                padding: 7px;
                margin-top: -16px;
            }

            .swiper-button-next {
                padding-left: 0;
                right: -10px;
            }

            .swiper-button-prev {
                padding-right: 0;
                left: -10px;
            }

            .modal-swiper-product-detail-image {
                padding-bottom: 60px;

                .swiper-wrapper {
                    align-items: center;
                }

                .swiper-slide {
                    text-align: center;

                    img {
                        max-width: 170px;
                    }
                }

                .swiper-pagination-bullet {
                    margin: 0 10px;
                    opacity: 1;
                    width: 4px;
                    height: 4px;
                }

                .swiper-pagination-bullet-active {
                    background: $yellow;
                    width: 16px;
                    height: 16px;
                    margin: -6px 6px;
                }

                @media (min-width: $breakpoint-large-2) {
                    padding: 5px;
                    width: calc(100% - 66px);
                    .swiper-slide {
                        > div {
                            width: 53px;
                            height: 53px;
                            padding: 8px;
                            box-sizing: border-box;
                            cursor: pointer;
                            border-radius: 12px;
                            box-shadow: 
                                            0 4px 8px -2px rgba(171, 147, 67, 0.15),
                                            0 0 1px 0 rgba(171, 147, 67, 0.2);
                        }

                        &.active {
                            > div {
                                box-shadow: 
                                                0 0 5px 0px var(---dusty-orange),
                                                0 0 0px 4px rgba($dusty-orange, 0.15) inset;
                            }
                        }

                        &.slide-video {
                            > div {
                                position: relative;

                                &:after {
                                    background: url('./resources/ecommerce/img/icon-play-bg.svg') no-repeat center;
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    display: block;
                                }
                            }
                        }

                        img {
                            max-width: 100%;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center center;
                        }
                    }
                }
            }
        }

        .product-category {
            font-size: 16px;
            font-weight: bold;
            line-height: 1;
            color: $primary-black;
            margin-bottom: 16px;
        }

        .product-title {
            margin-top: 0;
            font-size: 32px;
            font-weight: bold;
            line-height: 1.25;
        }

        .product-quantity {
            font-weight: 500;
            line-height: 1;
            color: rgba($black, 0.6);
            margin-bottom: 16px;

            .price {
                color: var(---colour-01-primary-01-p-2);
                font-size: 14px;
                margin-top: 8px;

                > *:last-child {
                    margin-left: 3px;
                    font-size: 12px;
                    color: $primary-black;
                }
            }
        }

        .product-fidelity {
            padding: 6px 8px;
            border-radius: 8px;
            background: var(---effects-02-darker-05-2);
            display: inline-flex;
            align-items: center;

            [class*='icon-'] {
                margin-right: 8px;
            }

            .price {
                color: $black;
                font-size: 12px;
                font-weight: bold;
                line-height: 1;

                &.sale {
                    margin-left: 2px;
                    color: var(---colour-01-primary-01-p-2);
                }
            }
        }

        .product-weighty {
            font-weight: 500;
            font-size: 12px;
            color: $primary-black;
            margin-top: 16px;
            margin-bottom: 24px;
            display: flex;
            align-items: center;

            [class*='icon-'] {
                font-size: 16px;
                margin-right: 5px;
            }

            &-info {
                margin-bottom: 16px;
                font-weight: 500;
                display: flex;
                align-items: center;

                [class*='icon-'] {
                    background-color: rgba($black, 0.4);
                    margin-right: 6px;
                }
            }
        }

        .product-promo {
            color: var(---colour-01-primary-01-p-2);
            font-size: 14px;
            line-height: 1.43;
            margin-bottom: 4px;
        }

        .product-price {
            margin: 24px 0;

            > *:first-child {
                font-size: 32px;
                font-weight: 500;
                @extend %roboto-font;
                display: flex;
                align-items: center;
                color: var(---colour-01-primary-01-p-2);
            }

            [class*='icon-'] {
                color: inherit;
                font-size: 34px;
                margin-right: 8px;
            }

            .old {
                @include color-primary-black;
                font-size: 16px;
                position: relative;
                top: -4px;
                margin-right: 6px;
            }

            .price-note {
                font-size: 12px;
                line-height: 1.33;
                color: $primary-black;
                margin-top: 5px;
            }
        }

        .product-button-container {
            margin-bottom: 24px;
            flex-direction: column;

            .fixed-button-add-cart {
                animation: 240ms ease 0s 1 normal none running animation-btn-add-cart;
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                z-index: 1000;
                border-radius: 0;
            }

            @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
                flex-direction: row;
            }
        }

        .product-preparation-notes {
            width: 100%;

            .component-SearchFilter {
                .filter-dropdown {
                    z-index: 1000;
                }
            }

            @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
                margin-right: 8px;
            }
        }

        .product-button-add {
            .uk-button {
                line-height: 56px;
                font-size: 16px;
                width: 100%;
            }

            @media (min-width: $breakpoint-large) {
                max-width: 408px;
            }
        }

        .product-other-info {
            .uk-accordion {
                > * {
                    border-bottom: 1px solid #e6e6e6;

                    &:nth-child(n + 2) {
                        margin: 0;
                    }
                }

                &-title {
                    font-size: 14px;
                    font-weight: 500;
                    padding: 13px 0;

                    &:before {
                        font-size: 26px;
                        margin-top: -6px;
                    }
                }

                &-content {
                    margin: 5px 0 10px;
                }
            }
        }

        .wysiwyg_editor {
            .html-esb-field {
                *:first-child {
                    margin-top: 0;
                }
                table {
                    tr {
                        th,td {
                            min-width: 100px;
                            max-width: 250px;
                        }
                        th:first-child,
                        td:first-child {
                            min-width: 90px;
                            @media (min-width: $breakpoint-large) {
                                min-width: 160px;
                            }
                        }
                    }
                }
            }
        }
    }
}