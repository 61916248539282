.component-LearnMore {
    margin: 30px 0 48px;
    color: $primary-gray;

    @media (min-width: $breakpoint-xlarge) {
        margin-top: 97px;
        margin-bottom: 48px;
    }

    @media (min-width: $breakpoint-xxlarge) {
        margin: 95px 0 40px;
    }

    .section-title {
        font-size: 28px;
        font-weight: normal;
        line-height: 1.07;
        margin-bottom: 44px;

        @media (min-width: $breakpoint-large) {
            font-size: 32px;
            line-height: 1.25;
            margin-bottom: 52px;
        }

        &:before {
            bottom: -20px;
        }
    }

    p {
        b {
            color: $primary-black;
        }
    }
}