:root {
    ---colour-01-primary-01-p-2: #e20714;
    ---colour-02-secondary-04-s-2: #4bacc6;
    ---colour-03-semantic-01-succes-2: #01c120;
    ---colour-01-primary-02-p-2: #fed404;
    ---colour-02-secondary-01-s-2: #f8f6ef;
    ---colour-02-secondary-02-s-2: #5eb223;
    ---colour-02-secondary-03-s-2: #9f0839;
    ---colour-01-primary-03-p-2: #EB5305;
    ---colour-01-primary-03-p-2-rgb: 240, 108, 0;
    ---colour-03-semantic-02-error-2: #fb0000;
    ---colour-03-semantic-03-warnin-2: #ffb602;
    ---colour-03-semantic-04-inform-2: #0072ca;
    ---colour-03-semantic-04-inform-2-rgb: 0, 114, 202;
    ---colour-05-support-colours-01-es-2: #c49f60;
    ---colour-05-support-colours-02-sa-2: #81272c;
    ---effects-01-opacity-05-2: rgba(51, 51, 51, 0.05);
    ---effects-01-opacity-10-2: rgba(51, 51, 51, 0.1);
    ---effects-01-opacity-20-2: rgba(51, 51, 51, 0.2);
    ---effects-01-opacity-30-2: rgba(51, 51, 51, 0.3);
    ---effects-01-opacity-40-2: rgba(51, 51, 51, 0.4);
    ---effects-01-opacity-50-2: rgba(51, 51, 51, 0.5);
    ---effects-01-opacity-60-2: rgba(51, 51, 51, 0.6);
    ---effects-01-opacity-70-2: rgba(51, 51, 51, 0.7);
    ---effects-01-opacity-80-2: rgba(51, 51, 51, 0.8);
    ---effects-02-darker-05-2: rgba(0, 0, 0, 0.05);
    ---effects-01-opacity-90-2: rgba(51, 51, 51, 0.9);
    ---effects-02-darker-10-2: rgba(0, 0, 0, 0.1);
    ---effects-02-darker-20-2: rgba(0, 0, 0, 0.2);
    ---effects-02-darker-40-2: rgba(0, 0, 0, 0.4);
    ---effects-02-darker-50-2: rgba(0, 0, 0, 0.5);
    ---effects-02-darker-60-2: rgba(0, 0, 0, 0.6);
    ---effects-02-darker-90-2: rgba(0, 0, 0, 0.9);
    ---effects-02-darker-80-2: rgba(0, 0, 0, 0.8);
    ---02-effects-02-darker-05-2-15: rgba(0, 0, 0, 0.15);

    ---dusty-orange: #f38933;
    ---tsd-hour-distance-mobile-type-a: 34px;
    ---tsd-hour-distance-desktop-type-a: 28px;
    ---tsd-timeline-width-mobile-type-a: 300px;
    ---tsd-timeline-width-desktop-type-a: 400px;
    ---tsd-dragzone-width-desktop-type-a: 302px;
    ---tsd-active-timeslot-mobile-width-type-a: 46px;
    ---tsd-margin-left-overflow: -6px;
    ---tsd-active-timeslot-desktop-width-type-a: 60px;
    ---tsd-drag-dropzone-width: 42px;
}

@import './scss/fonts';

// Overwritten variables.
@import './scss/uikit/variables-theme.scss';

// Import default variables and available mixins.
@import './scss/uikit/variables.scss';
@import './scss/uikit/mixins.scss';

//Swiper
@import './scss/swiper';

// Import UIkit.
@import './scss/uikit/uikit.scss';

@import './scss/mixins';

//Plugins
@import './scss/jquery.mCustomScrollbar';
@import './scss/image-zoom';
@import './scss/perfect-scrollbar';

//Base
@import './scss/base';

// Components and Page
@import './pages/entrypage/entrypage';
@import './components/footer-semplificato/footer-semplificato';
@import './components/header-semplificato/header-semplificato';
@import './components/dot-button/dot-button';
@import './components/dot-card/dot-card';
@import './components/badge-card/badge-card';
@import './components/card-image-top/card-image-top';
@import './components/card-question/card-question';
@import './components/card-store/card-store';
@import './components/card-delivery-day/card-delivery-day';
@import './components/modal-generic/modal-generic';
@import './components/modal-manage-cookies/modal-manage-cookies';
@import './components/card-review/card-review';
@import './components/card-store-map/card-store-map';
@import './components/store-pin-locator/store-pin-locator';
@import './components/card-how-it-work/card-how-it-work';
@import './components/modal-login/modal-login';
@import './pages/homepage/homepage';
@import './components/feedback/feedback';
@import './components/pre-header/pre-header';
@import './components/header/header';
@import './pages/search/search';
@import './components/breadcrumb/breadcrumb';
@import './components/modal-search-filter/modal-search-filter';
@import './components/search-filter/search-filter';
@import 'components/sidebar/search-recommended';
@import './components/product-card/product-card';
@import './pages/listinglevel1/listinglevel1';
@import './pages/listinglevel2/listinglevel2';
@import './pages/detail-product/detail-product';
@import './components/product-card-last-view/product-card-last-view';
@import './pages/detail-cart/detail-cart';
@import './components/modal-product-images/modal-product-images';
@import './pages/checkout/checkout';
@import './components/modal-fatturazione-elettronica/modal-fatturazione-elettronica';
@import './pages/thank-you/thank-you';
@import './components/modal-cart/modal-cart';
@import './components/replacement-mode/replacement-mode-new';
@import './components/product-card-detail-cart/product-card-detail-cart';
//@import './components/modal-payment-card-conad/modal-payment-card-conad';
//@import './components/modal-payment-card/modal-payment-card';
@import './components/modal-checkout-address-delivery/modal-checkout-address-delivery';
@import './pages/brands/brands';
@import './pages/recipes/recipes';
@import './pages/general/general';
@import './pages/landingpage/landingpage';
@import './components/tile-brand/tile-brand';
@import './components/tile-partner/tile-partner';
@import './components/card-simple/card-simple';
@import './components/promo-idm/promo-idm';
@import './components/flat-category/flat-category';
@import './components/modal-user-menu/modal-user-menu';
@import './components/modal-reserved-functionality/modal-reserved-functionality';
@import './components/modal-slot-prenotato-scadenza/modal-slot-prenotato-scadenza';
@import 'components/banner-sponsor/banner-sponsor';
@import './components/banner-stripe/banner-stripe';
@import './components/offer-card/offer-card';
@import './components/card-suggest/card-suggest';
@import './components/card-category/card-category';
@import 'components/banner-promo/banner-promo';
@import './components/learn-more/learn-more';
@import './pages/errors/errors';
@import './pages/privacy/privacy';
@import './pages/faq/faq';
@import './components/promo-mdd/promo-mdd';
@import 'components/1-3-prodotti-evidenza/1-3-prodotti-evidenza';
@import './components/1-4-marchi-conad/1-4-marchi-conad';
@import './components/area-utente/area-utente';
@import './components/1-6-storytelling-a/1-6-storytelling-a';
@import './components/1-6-storytelling-b/1-6-storytelling-b';
@import './components/1-6-storytelling-c/1-6-storytelling-c';
@import './components/1-6-storytelling-d/1-6-storytelling-d';
@import './components/area-offerte/area-offerte';
@import './components/offerte-scadenza-a/offerte-scadenza-a';
@import './components/offerte-scadenza-b/offerte-scadenza-b';
@import './components/1-10-ispirazionale-hotspot/1-10-ispirazionale-hotspot';
@import './components/1-10-ispirazionale/1-10-ispirazionale';
@import './components/spinte-marketing/spinte-marketing';
@import 'components/1-13-navigazione/1-13-navigazione';
@import 'components/1-14-shop-idm/1-14-shop-idm';
@import './components/1-1-promo-component/1-1-promo-component';
@import './components/1-7-carousel-banner-sponsor/1-7-carousel-banner-sponsor';
@import './components/carousel-banner-stripe/carousel-banner-stripe';
@import './components/scopri-novita/scopri-novita';
@import 'components/1-13-naviga-promozioni/1-13-naviga-promozioni';
@import './components/offerte-in-corso/offerte-in-corso';
@import './components/1-15-carousel-benessere/1-15-carousel-benessere';
@import './components/modal-detail-product/modal-detail-product';
@import './components/pagination/pagination';
@import './components/faq-section-contattaci/faq-section-contattaci';
@import './components/faq-section-main/faq-section-main';
@import './components/faq-section-categories/faq-section-categories';
@import './components/faq-section-domande/faq-section-domande';
@import './components/faq-section-form-main/faq-section-form-main';
@import './pages/pageoffers/pageoffers';
@import './components/promo-badge-card/promo-badge-card';
@import './components/promo-badge-potential-discount/promo-badge-potential-discount';
@import './components/modal-checkout-error-popup/modal-checkout-error-popup';
@import './components/empty-listing/empty-listing';
@import './pages/detail-order/detail-order';
@import './components/badge-stati-ordine/badge-stati-ordine';
@import './components/banner-shop-in-shop/banner-shop-in-shop';
@import './components/modal-agevolazione/modal-agevolazione';
@import './components/modal-faq-form/modal-faq-form';
@import './components/accordion-timeslot-info/accordion-timeslot-info';
@import './components/modal-verify-email/modal-verify-email';
@import './components/banner-sponsor-search/banner-sponsor-search';
@import './components/0-2-cards-how-it-work/0-2-cards-how-it-work';
@import './components/0-6-provinces/0-6-provinces';
@import './components/0-3-cards-image-top/0-3-cards-image-top';
@import './components/0-4-cards-question/0-4-cards-question';
@import './components/0-5-cards-review/0-5-cards-review';
@import './components/card-storytelling-c/card-storytelling-c';
@import './components/modal-onboarding/modal-onboarding';
@import './components/modal-timeslot/modal-timeslot';
@import './components/card-spesa-online/card-spesa-online';
@import './components/card-negozio/card-negozio';
@import './components/0-1-banner-address/0-1-banner-address';
@import './components/negozi-list-map/negozi-list-map';
@import './components/card-timeslot/card-timeslot';
@import './components/card-prodotto-omaggio/card-prodotto-omaggio';
@import './components/card-opzione-consegna/card-opzione-consegna';
@import './components/slider-timeslot-tsd/slide-timeslot-tsd';
@import './components/banner-landing-idm/banner-landing-idm';
@import './components/1-16-banner-promo-landing/1-16-banner-promo-landing';
@import './components/5-2-title-and-description/5-2-title-and-description';
@import './components/2-1-banner-listing-image/2-1-banner-listing-image';
@import './components/2-2-banner-listing-text-image/2-2-banner-listing-text-image';
@import './components/2-2-banner-listing-hotspot/2-2-banner-listing-hotspot';
@import './components/2-3-banner-listing-iniziative/2-3-banner-listing-iniziative';
@import './components/2-4-banner-listing-marchiconad/2-4-banner-listing-marchiconad';
@import './components/2-5-banner-listing-offerte-video/2-5-banner-listing-offerte-video';
@import './components/5-1-tiles-brands-partner/5-1-tiles-brands-partner';
@import './pages/checkout-new/checkout-new';
@import './pages/landingidm/landingidm';
@import './components/modal-checkout-deliveryarea-error/modal-checkout-deliveryarea-error';
@import './components/modal-checkout-pdv-only/modal-checkout-pdv-only';


@import 'scss/ie';
