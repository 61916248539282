.component-CardOpzioneConsegna{
    width: 235px;
    
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .band-type{
        background-color: $white;
        border-color: none;
        color: $primary-black;
    }

    .description-consegna{
        background-color: $white;
        border-color: none;
        color: $primary-black;
    }

    &.normal{
        .band-type{
            .normal{
                display: block !important;
            }
        }
    }

    &.promo{
        .band-type{
            .promo{
                display: flex !important;
            }
        }
    }

    &.free{
        .band-type{
            .free{
                display: block !important;
            }
        }
    }

    &.selected{
        .band-type{
            background-color: #fffae1;
            border-color: $pin-yellow;
            box-shadow: 0 0 25px 0 rgba(135, 135, 135, 0.17);
        }

        .description-consegna{
            background-color: $pin-yellow;
            border-color: $pin-yellow;
        }
    }

    //&:hover{
    //    .band-type{
    //        background-color: #fffae1;
    //        border-color: $pin-yellow;
    //    }
    //
    //    .description-consegna{
    //        background-color: $pin-yellow;
    //        border-color: $pin-yellow;
    //    }
    //}

    .band-type{
        transform: translate(0, 50%);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        border: 1px solid $global-muted-color;
        width: 170px;
        background-color: $white;
        font-size: 14px;
        line-height: 24px;
        height: 40px;
        cursor: pointer;

        .band-price{
            font-size: 16px;
            font-weight: bold;
            margin-right: 2px;

            .free{
                color: red;
            }

            .promo{
                .old{
                    text-decoration: line-through;
                    font-size: 12px;
                    margin-right: 4px;
                }

                .new{
                    color: red;
                }
            }

            .normal, .free, .promo{
                display: none;
            }
        }
    }

    .description-consegna{
        border: 1px solid $global-muted-color;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 24px 16px 16px 16px;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        width: 100%;
        cursor: pointer;

        .band-width{
            font-weight: bold;
        }
    }
}