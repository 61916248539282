.component-ModalReservedFunctionality {

    &.uk-open {
        .uk-offcanvas-bar {
            left: 0;
        }
    }

    &.uk-close {
        .uk-offcanvas-bar {
            left: -100%;
        }
    }

    .uk-offcanvas {
        &-bar {
            left: -100%;
        }

        &-header {
            @media (min-width: $breakpoint-large) {
                padding: 48px 120px 10px 72px;
            }
        }

        &-body {
            height: calc(100vh - 410px);
            padding: 32px 6px 0 6px;
            position: relative;

            @media (min-width: $breakpoint-large) {
                height: calc(100vh - 405px);
                padding: 0 56px;
            }

            .box-overflow {
                position: relative;
                width: 100%;
                height: 100%;
                //overflow-y: auto;
                padding: 0 0;
                box-sizing: border-box;
            }
        }

        &-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .uk-button {
                padding: 0 24px;

                @media (min-width: $breakpoint-large) {
                    padding: 0 32px;
                }
            }

            a {
                font-weight: 500;
                font-size: 14px;
                line-height: 1.29;
                padding: 10px 16px;

                @media (min-width: $breakpoint-large) {
                    margin-right: 10px;
                    font-size: 16px;
                    line-height: 1.5;
                }
            }
        }

        &-title {
            margin: 2px 0 16px;
            font-size: 28px;
            font-weight: bold;
            line-height: 1.07;

            @media (min-width: $breakpoint-large) {
                font-size: 32px;
                line-height: 1.25;
            }
        }
        &-desc {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;

            span {
                color: $primary-black;
            }
        }
    }

    .img-container {
        display: flex;
        justify-content: center;

        @media (min-width: $breakpoint-large) {
            margin-top: -4px;
            margin-left: -20px;
            margin-bottom: 6px;
            justify-content: flex-start;
        }

        .modal-image {
            max-width: 120px;
            max-height: 120px;

            @media (min-width: $breakpoint-large) {
                max-width: 136px;
                max-height: 136px;
            }
        }
    }

    .section-title {
        @include color-primary-black(0.8);
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        text-decoration: underline;
        margin-bottom: 36px;

        &::before {
            bottom: -10px;
        }
        @media (min-width: $breakpoint-large) {
            padding-top: 25px;
            margin-bottom: 44px;
        }
    }

    .modal-container {
        @include color-primary-black(0.8);

        .container-elem {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 16px;
            line-height: 1.43;

            &:last-child {
                margin-bottom: 0;
            }

            [class*='icon-'] {
                font-size: 24px;
                margin-right: 16px;
            }

            @media (min-width: $breakpoint-large) {
                margin-bottom: 24px;
            }
            font-size: 14px;
        }
    }

    @media (max-height: 500px) and (min-width: $breakpoint-xlarge) {
        .img-container {
            display: none;
        }
        .uk-offcanvas-body {
            height: calc(100vh - 275px);
        }
    }
}

.browser-type {
    &__ios {
        &.device_tablet {
            .component-ModalReservedFunctionality .uk-offcanvas-body {
                height: calc(100vh - 470px);
            }
        }

        &.device_mobile {
            .component-ModalReservedFunctionality .uk-offcanvas-body {
                // height: calc(100vh - 525px);
                height: calc(100vh - 410px);
            }
        }
    }
}