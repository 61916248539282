.component-BannerListing {
    position: relative;

    .main-container {
        display: none;
    }
}

/* 2.2 Banner Listing Text Image (type hotspot) */
.component-BannerListing {
    &.banner-hotspot {

        .banner-hotspot {
            display: flex;

            .img-container {
                .s7zoomview {
                    background-color: unset;
                }
            }

            .uk-drop {
                width: 150px;
            }
        }
    }
    .banner-hotspot {
        flex-direction: column;
        padding-bottom: 16px;
        @media (min-width: $breakpoint-xlarge) {
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding-bottom: 0;
            height: 456px;
        }

        .img-container {
            height: 456px;
            max-height: 100%;
            width: 100%;
            margin-top: 15px;
            @media(max-width: $breakpoint-large-2-max) {
                margin-left: auto;
                margin-right: auto;
            }
            @media(min-width: $breakpoint-xlarge) {
                margin-top: auto;
                margin-bottom: auto;
                margin-left: auto;
                max-height: 100%;
                max-width: 600px;
            }

            .img-banner-hotspot {
                background: transparent;
                max-height: 100%;
                max-width: 100%;
            }
        }

        .text {
            margin-top: 20px;

            @media (min-width: $breakpoint-xlarge) {
                margin-top: 88px;
                max-width: 423px;
            }

            &-title {
                color: $primary-black;
                margin: 0;
                font-size: 28px;
                font-weight: 500;
                line-height: 1.29;

                @media (min-width: $breakpoint-xlarge) {
                    font-size: 32px;
                    line-height: 1.25;
                }
            }

            &-desc {
                color: $primary-gray;
                margin-top: 8px;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.43;

                @media (min-width: $breakpoint-xlarge) {
                    margin-top: 12px;
                    max-width: 381px;
                }
            }

            &-link {
                margin-top: 8px;
                color: $blue-info;
                font-size: 12px;
                font-weight: 500;
                line-height: 1.33;

                @media (min-width: $breakpoint-xlarge) {
                    font-size: 14px;
                    line-height: 1.29;
                }
            }
        }
    }
}