.general-tile-container {
    margin-top: 28px !important;
    row-gap: 16px !important;

    @media (min-width: $breakpoint-large) {
        margin-top: 37px !important;
        row-gap: 24px !important;
    }

    @media (min-width: $breakpoint-xxxlarge) {
        margin-top: 53px !important;
    }

    .component-TileBrand,
    .component-TilePartner {

        &:nth-child(odd) {
            margin-right: 8px;
        }

        &:nth-child(even) {
            margin-left: 8px;
        }

        @media (min-width: $breakpoint-large) {

            &:nth-child(3n-2) {
                margin-left: 0;
                margin-right: 11px;
            }

            &:nth-child(3n-1) {
                margin-left: 5px;
                margin-right: 5px;
            }

            &:nth-child(3n) {
                margin-left: 11px;
                margin-right: 0;
            }
        }

        @media (min-width: $breakpoint-xlarge) {

            &:nth-child(4n-3) {
                margin-left: 0;
                margin-right: 12px;
            }

            &:nth-child(4n-2) {
                margin-left: 4px;
                margin-right: 8px;
            }

            &:nth-child(4n-1) {
                margin-left: 8px;
                margin-right: 4px;
            }

            &:nth-child(4n) {
                margin-left: 12px;
                margin-right: 0;
            }
        }

        @media (min-width: $breakpoint-xxxlarge) {

            &:nth-child(4n-3) {
                margin-left: 0;
                margin-right: 18px;
            }

            &:nth-child(4n-2) {
                margin-left: 6px;
                margin-right: 12px;
            }

            &:nth-child(4n-1) {
                margin-left: 12px;
                margin-right: 6px;
            }

            &:nth-child(4n) {
                margin-left: 18px;
                margin-right: 0;
            }
        }
    }
}