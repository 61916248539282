@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';


.component-modal-login {
    .uk-offcanvas {
        &-bar {
            overflow-y: hidden;
            -webkit-overflow-scrolling: unset;
        }

        &-title {
            font-size: 20px;
            margin-top: 33px;
            margin-bottom: 8px;
        }

        &-body {
            height: calc(100vh - 108px);
            padding-top: 10px;
            position: relative;

            .box-overflow {
                position: relative;
                width: 100%;
                height: 100%;
                //overflow-y: auto;
                padding: 0 0;
                box-sizing: border-box;
                //display: flex;
                //flex-direction: column;
            }

            .desc {
                flex-direction: column;
                font-size: 16px;
                line-height: 1.5;
                margin-bottom: 24px;
                @include color-primary-black();
                a {
                    font-size: 14px;
                    font-weight: 500;
                }
                > *:last-child {
                    margin-top: 10px;
                }
                @media (min-width: $breakpoint-large) {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;
                    > *:first-child {
                        max-width: 395px;
                    }
                    > *:last-child {
                        text-align: right;
                    }
                }
            }

            .recovery {
                font-size: 14px;
                font-weight: 500;
                margin-top: 24px;
                @include color-primary-black();
            }

            .divider {
                margin: 30px 0 24px;
            }

            .or {
                font-size: 16px;
                line-height: 1.5;
                margin-bottom: 25px;
                @include color-primary-black();
            }

            .social-login {
                margin: 0 2px;
                > * {
                    margin-bottom: 16px;
                }
            }

            .reg {
                @include color-primary-black;
                position: absolute;
                bottom: 25px;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                
            }
        }
    }

    .uk-button {
        line-height: 52px;
        font-size: 16px;
    }

    .back {
        position: absolute;
        top: 8px;
        left: 6px;
        width: 40px;
        height: 40px;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        @include color-primary-black;
    }

    @media (min-width: $breakpoint-medium) {
        .uk-offcanvas {
            &-body {
                .button-container {
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: flex-end;
                    .btn {
                        order: 1;
                    }
                }

                .recovery {
                    font-size: 12px;
                    margin-top: 0;
                }

                .divider {
                    margin: 30px 0;
                }
            }
        }
    }

    @media (min-width: $breakpoint-large) {
        .uk-offcanvas {
            &-title {
                font-size: 24px;
            }

            &-body {
                padding: 0 56px;
                height: calc(100vh - 125px);
                .box-overflow {
                    padding: 0 16px;
                }
            }
        }
        .back {
            top: 53px;
            left: 57px;
            font-size: 22px;
        }
    }
}

.browser-type {
    &__ios {
        &.browser_safari {
            .component-modal-login {
                .uk-offcanvas {
                    &-body {
                        /* ----------- iPhone 6, 6S, 7 and 8 ----------- */
                        /* Portrait and Landscape */
                        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
                            height: calc(100vh - 223px);
                        }

                        @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
                            height: calc(100vh - 184px);
                        }
                        @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
                            height: calc(100vh - 184px);
                        }
                    }
                }
            }
        }
    }
}
