.component-OfferteScadenzaB {
    .section-off-scad-b {

        .section-off-scad-container {

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            background-color: #e83843;
            border-radius: 12px;

            padding: 24px 24px 28px;

            @media (min-width: $breakpoint-xlarge) {
                padding: 16px 24px;
            }

            .section-body {
                width: 100%;
            }

            .section-title {
                color: $white;
                font-size: 20px;
                margin: 0;
                line-height: 1.4;
                margin-bottom: 8px;
                max-width: 179px;

                @media (min-width: $breakpoint-xlarge) {
                    font-size: 28px;
                    line-height: 1.29;
                    margin-bottom: 40px;
                    max-width: unset;
                }
            }

            .section-title:before {
                display: none;
                bottom: -12px;

                @media (min-width: $breakpoint-xlarge) {
                    display: block;
                }
            }

            .link-off-scad {
                font-weight: 500;
                font-size: 14px;
                text-align: right;
                margin: -64px -4px 0 auto;
                line-height: 1.29;
                color: $yellow;

                @media (min-width: $breakpoint-xlarge) {
                    color: white;
                    margin: -46px 6px 0 auto;
                }
            }

            .row-wrapper {
                padding: 51px 0 4px;

                @media (min-width: $breakpoint-xlarge) {
                    padding: 24px 0 0;
                }
            }

            .row {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media (min-width: $breakpoint-xlarge) {
                    flex-direction: row;
                }

                .separator {
                    width: 100%;
                    height: 0;
                    border-radius: 1px;
                    border-bottom: 1px solid $white;

                    @media (min-width: $breakpoint-xlarge) {
                        height: 95px;
                        width: 0;
                        border-right: 1px solid $white;
                    }
                }

                .component-ProductCardLastView {
                    padding: 0;

                    &:not(:first-child) {
                        padding-top: 13px;
                    }

                    &:not(:last-child) {
                        padding-bottom: 24px;
                    }

                    @media (min-width: $breakpoint-xlarge) {
                        padding: 0;

                        &:not(:first-child) {
                            padding-left: 19px;
                            padding-top: 0;
                        }

                        &:not(:last-child) {
                            padding-right: 19px;
                            padding-bottom: 0;
                        }
                    }

                    span {
                        color: $white !important;
                    }

                    .product {
                        color: $white !important;

                        &-card {
                            box-shadow: none;
                            padding: 5px 0;
                        }

                        .product-img {
                            min-width: 80px;
                            min-height: 80px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 12px;
                            background-color: $white;
                            overflow: hidden;

                            img {
                                max-height: 80px;
                                max-width: 80px;
                            }
                        }

                        .product-description-container {
                            margin-top: -10px;
                            margin-left: 8px;

                            .description {
                                margin-top: 8px;
                                min-width: 135px;
                                line-height: 1.4;
                                color: $white;

                                @media (min-width: $breakpoint-xlarge) {
                                    margin-top: 9px;
                                }
                            }
                        }

                        .feedback-overlay-pcard {
                            background-color: rgb(232, 56, 67, .6);
                        }
                    }

                    .icon-container {
                        color: $white;
                        margin-right: 36px;

                        @media (min-width: $breakpoint-xlarge) {
                            margin-right: 4px;
                            margin-top: -2px;
                        }

                        [class*='icon-'] {
                            color: $white;
                        }

                        .manage-preferite-product {
                            padding: 0 0 7px 6px;
                            margin-right: 2px;
                            font-size: 16px;

                            @media (min-width: $breakpoint-xlarge) {
                                padding: 0 6px 10px;
                            }
                        }

                        .add-cart {
                            box-shadow: 0 0 0 2px inset $white;
                            color: $white;

                            .lds-ring {
                                box-shadow: 0 0 0 2px inset $white;
                                background-color: rgba(#e83843, 0.95);
    
                                svg {
                                    color: $white;
                                }
                            }

                            @media (min-width: $breakpoint-xlarge) {
                                margin-right: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}