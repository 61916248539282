
@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';

.component-card-store-map {
    cursor: pointer;
    transition: all .3s ease-in-out;
    position: absolute;
    width: 100%;
    //max-width: 500px;
    top: 0;
    left: 0;
    height: calc(100% - 209px);
    background-color: $pin-yellow;
    z-index: 3001;
    
    .close-card{
        font-size: 25px;
        padding: 0;
        top: 14px;
        right: 0;
        width: 60px;
        height: 60px;
        cursor: pointer;
        color: $black;
        border: none;
        background:none;
        position: absolute;
        z-index: 3001;
    }
    
    .uk-card {
        width: 100%;
        background-color: transparent;
        
        &-body {
            border: none;
            width: 100%;
            padding: 16px 24px 10px;
            margin-top: 56px;
            transition: all .3s ease-in-out;
            
            .availability {
                [class*='icon'] {
                    font-size: 23px;
                }
            }
            
            .orari {
                .SmallPar {
                    font-size: 12px;
                    white-space: nowrap;
                }
                hr {
                    margin: 15px 0 15px 0;
                    border-top: 1px solid rgba(51, 51, 51, 0.27);
                }
            }
            
            .title-row {
                margin-top: 16px
            }
            
            .par-row {
                margin-bottom: 22px;
            }
            
            .uk-badge {
                font-weight: 500;
                background-color: $white;
                color: $forest !important;
                .day,
                .time{
                    margin: 0 3px;
                }
            }
            
            .card-title {
                font-size: 16px;
                font-weight: bold;
                margin-top: 16px;
            }
            
            .card-text {
                font-size: 14px;
                margin-bottom: 24px;
            }
            
            .programma-carta-insieme {
                font-size: 12px;
                font-weight: 500;
                [class*='icon'] {
                    position: relative;
                    top: -2px;
                    font-size: 22px;
                    margin-right: 8px;
                }
            }
    
            @media (max-width: 345px){
                .uk-badge,
                .SmallPar {
                    font-size: 10px;
                }
            }
            
        }
    }
    
    &.available {
        .uk-card {
            &-body {
                .not-available {
                    display: none;
                }
            }
        }
    }
    
    &.not-available {
        .uk-card {
            .uk-card-body {
                .available {
                    display: none;
                }
            }
        }
    }
    
    /* @media (min-width: $breakpoint-small) {
        height: calc(100% - 263px);
        background-color: transparent;
        display: flex;
        align-items: center;
        top: auto;
        
        .card-store-map-content {
            margin-left: auto;
            margin-right: auto;
            background: $pin-yellow;
            @include border-top-left-radius(12px);
            @include border-top-right-radius(12px);
            
            .close-card {
                position: relative;
                left: 80%;
            }
            
            .uk-card {
                background-color: transparent;
                margin-left: auto;
                margin-right: auto;
                max-width: 350px;
                
                .uk-card-body {
                    margin-top: 0;
                }
            }
        }
        
        
    } */
    
    @media (min-width: $breakpoint-xlarge) {
        display: none;
        
    }
}
