.page-Privacy {
    
    .component-Breadcrumb {
        margin-bottom: 32px;
    }

    section {
        padding: 0 0 32px;

        @media (min-width: $breakpoint-xlarge) {
            padding-right: 80px;
        }
    }

    .header-row {
        @media (min-width: $breakpoint-xlarge) {
            padding-right: 0;
        }
    }

    .section-main.section-privacy{
        h1 {  
            @include color-primary-black(0.8);
            font-size: 28px;
            font-weight: bold;
            line-height: 1.07;
            margin: 0 0 18px;
    
            @media (min-width: $breakpoint-xlarge) {
                font-size: 32px;
                line-height: 1.25;
                margin-bottom: 16px;
            }
        }
    
        p {
            @include color-primary-black(0.8);
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
            margin: 0;
    
            a {
                font-weight: 500;
                color: $blue-info;
            }
        }
    }
    .section-privacy{
        h1 {
            @include color-primary-black(0.8);
            font-size: 24px;
            font-weight: bold;
            line-height: 1.33;
            margin: 0 0 8px;
        }
    
        p, ul {
            @include color-primary-black(0.6);
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
            margin: 0 0 8px;
        }
    
        ul {
            margin: 0;
    
            @media (min-width: $breakpoint-xlarge) {
                margin-bottom: 8px;
            }
        }
    }
    

    
}