@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer components {
    .btn {
        @apply inline-flex cursor-pointer items-center justify-center gap-2 rounded-xl border-2 border-transparent p-[10px] px-[22px] text-sm leading-none text-current no-underline outline-2 outline-offset-2 transition duration-200 hover:bg-opacity-70 focus-visible:outline disabled:cursor-default disabled:bg-opacity-70 disabled:text-opacity-70;
    }

    body.page-personal-area > main {
        @apply pt-[50px] lg:pt-[30px];
    }

    .conad-std-loader {
        display: block;
        width: 64px;
        height: 64px;
        margin: 5px;
        border: 5px solid #eb5305;
        border-color: #eb5305 transparent transparent transparent;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }

    .coupon-left-hole-bg {
        background: radial-gradient(
            circle at left,
            rgb(255, 255, 255),
            rgb(255, 255, 255) 27%,
            #ab9343 90%);
    }

    .coupon-right-hole-bg {
        background: radial-gradient(
            circle at right,
            rgb(255, 255, 255),
            rgb(255, 255, 255) 27%,
            #ab9343 90%);
    }
}
