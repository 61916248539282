@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';

.component-modal-cart {
    &-generic {
        .uk-modal {
            &-dialog {
                width: 100%;
                max-width: calc(100vw - 32px) !important;

                @media only screen and (min-width: $breakpoint-large) {
                    max-width: 784px !important;
                }
            }

            &-title {
                font-size: 20px;
                margin-bottom: 8px;
                padding-right: 48px;
            }

            @media only screen and (min-width: $breakpoint-large) {
                &-title {
                    font-size: 24px;
                }
            }

            &-footer {
                padding: 0;

                button {
                    margin-left: 24px;
                    line-height: 56px;

                    &.single-button {
                        margin-left: 0;
                        width: 100%;
                    }

                    @media only screen and (min-width: $breakpoint-large) {
                        float: right;

                        &.single-button {
                            width: unset;
                        }
                    }
                }
            }
        }

        .modal-content {
            margin-bottom: 65px;

            &-text {
                font-size: 16px;
                margin-top: 8px;

                @include color-primary-black(0.6);
            }

            &-img {
                display: none;
            }

            @media only screen and (min-width: $breakpoint-large) {
                &-img {
                    display: block;
                }
            }
        }
    }

    &-how-it-works-replace {
        .modal-content {
            margin-bottom: 0px;

            &-text {
                @include color-primary-black();
                font-size: 14px;

                @media only screen and (min-width: $breakpoint-large) {
                    font-size: 16px;
                }
            }
        }
    }

    &-note-preparazione-modal {
        hr {
            margin: 0 0 0 32px;
        }

        .uk-modal-dialog {
            max-width: 304px !important;
            width: calc(100vw - 32px);
        }

        .uk-modal-body {
            padding: 16px;
        }

        .uk-modal {
            &-title {
                color: $black;
                font-size: 17px;
                line-height: 17px;

                span {
                    font-size: 12px;
                    @include color-primary-black(0.2);
                }
            }

            &-footer {
                padding: 8px 0 0 0;

                .uk-button {
                    padding: 13px 24px;
                    line-height: 14px;
                }

                .uk-button:first-child {
                    margin-right: 8px;
                }
            }
        }

        .modal-content {
            padding-top: 16px;
            font-size: 14px;

            label {
                margin-bottom: 16px;
                color: $black;
            }

            input {
                margin-right: 8px;

                &.free-note {
                    margin-right: 0;
                    margin-left: -4px;
                    color: $primary-black;
                }
            }
        }
    }

    &-note-preparazione-offcanvas {
        .uk-accordion {
            margin-top: 0;
            @include color-primary-black();

            &-title {
                .choice {
                    @include color-primary-black(0.6);
                    font-size: 14px;
                    margin-top: 8px;
                }

                span {
                    color: $primary-black;
                }
            }

            li.uk-open {
                .choice {
                    display: none;
                }
            }
        }
    }

    &-info-consegna {
        color: $black;

        .uk-offcanvas {
            &-header {
                padding-top: 52px;
            }

            &-title {
                font-size: 20px;
            }

            &-body {
                padding-top: 0;

                section {
                    font-size: 13px;
                    line-height: 16px;

                    p {
                        margin: 0;
                    }
                }

                hr {
                    margin: 16px 0;
                }
            }
        }
    }

    &-annulla-ordine {
        .uk-modal-footer {
            a:last-child {
                margin-left: 24px;
            }
        }
    }

    &-sostituzione {
        .modal-content {
            @media (min-width: $breakpoint-large) {
                &-img {
                    max-width: 157px;
                    max-height: 157px;
                }
            }
        }
    }

    &-opzioni-sostituzione {
        @media (max-width: $breakpoint-xlarge-max) {
            background-color: $white;
        }

        .uk-modal-body {
            box-sizing: border-box;
            height: 100%;
            @media (max-width: $breakpoint-xlarge-max) {
                padding: 24px 0 0;
                min-height: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        .uk-modal-dialog {
            width: 659px;
            height: 465px;
            position: relative;
        }

        .substitution-option {
            text-align: left;
            background: none;

            &.selected {
                background-color: $pin-yellow;
            }
        }

        h2 {
            @media (max-width: $breakpoint-xlarge-max) {
                font-size: 20px;
                padding: 24px 0 0 0;
            }
        }

        &.component-modal-cart-scelta {
            padding: 0;
        }

        .uk-modal-dialog {
            box-shadow: none;

            @media (max-width: $breakpoint-xlarge-max) {
                width: 100%;
                height: 100%;
                border-radius: 0;
            }
        }

        i.icon-close {
            font-weight: 700;
        }

        .btn-group {
            margin-top: 0;
            position: absolute;
            left: 50%;
            bottom: -24px;
            @media (max-width: $breakpoint-xlarge-max) {
                position: inherit;
                left: inherit;
                bottom: inherit;
                margin-top: auto !important;
            }

            @media (min-width: $breakpoint-large-2) {
                bottom: 24px;
                right: 24px;
            }
        }

        .substitution-options-container {
            padding: 8px 0 24px 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;

            *[class^='icon-'] {
                margin-right: 6px;
                font-size: 20px;
            }

            @media (min-width: $breakpoint-large-2) {
                flex-wrap: wrap;
                column-gap: 15px;
                flex-direction: row;
                padding-bottom: 0;
            }

            .substitution-option {
                display: flex;
                flex-direction: column;
                margin-top: 16px;
                width: 100%;
                min-height: 100px;
                padding: 16px;
                cursor: pointer;
                color: $primary-black;

                @media (min-width: $breakpoint-large-2) {
                    max-width: 298px;
                }

                input {
                    margin-bottom: 10px;
                }

                .uk-modal-title {
                    font-size: 16px;
                    margin-bottom: 8px !important;
                }

                .subtitle {
                    font-size: 14px;
                }

                .uk-radio {
                    margin-top: 0;
                }
            }
        }

        .btn-group {
            margin-top: 0;
            @media (max-width: $breakpoint-xlarge-max) {
                width: 100%;
            }

            justify-content: space-between;
            column-gap: 16px;


            a, button {
                padding: 0;
                height: 56px;
                line-height: 56px;
                width: 164px;
            }

            @media (min-width: $breakpoint-large-2) {
                justify-content: flex-end;
                a, button {
                    width: 122px;
                }
            }
        }
    }
}
