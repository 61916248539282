.component-BannerLandingIdm {
    .color {
        &-e83943 {
            background: rgb(232, 57, 67);
            background: linear-gradient(180deg, rgba(232, 57, 67, 0.20) 0%, rgba(232, 57, 67, 1) 100%);
        }

        &-333333 {
            background: rgb(51, 51, 51);
            background: linear-gradient(180deg, rgba(51, 51, 51, 0.20) 0%, rgba(51, 51, 51, 1) 100%);
        }

        &-fedd36 {
            background: rgb(254, 221, 54);
            background: linear-gradient(180deg, rgba(254, 221, 54, 0.20) 0%, rgba(254, 221, 54, 1) 100%);
        }

        &-f38933 {
            background: rgb(243, 137, 51);
            background: linear-gradient(180deg, rgba(243, 137, 51, 0.20) 0%, rgba(243, 137, 51, 1) 100%);
        }

        &-7ec14f {
            background: rgb(126, 193, 79);
            background: linear-gradient(180deg, rgba(126, 193, 79, 0.20) 0%, rgba(126, 193, 79, 1) 100%);
        }

        &-b23961 {
            background: rgb(178, 57, 97);
            background: linear-gradient(180deg, rgba(178, 57, 97, 0.20) 0%, rgba(178, 57, 97, 1) 100%);
        }

        &-6fbdd1 {
            background: rgb(111, 189, 209);
            background: linear-gradient(180deg, rgba(111, 189, 209, 0.20) 0%, rgba(111, 189, 209, 1) 100%);
        }

        &-34cd4d {
            background: rgb(52, 205, 77);
            background: linear-gradient(180deg, rgba(52, 205, 77, 0.20) 0%, rgba(52, 205, 77, 1) 100%);
        }

        &-fc3333 {
            background: rgb(252, 51, 51);
            background: linear-gradient(180deg, rgba(252, 51, 51, 0.20) 0%, rgba(252, 51, 51, 1) 100%);
        }

        &-ffc535 {
            background: rgb(255, 197, 53);
            background: linear-gradient(180deg, rgba(255, 197, 53, 0.20) 0%, rgba(255, 197, 53, 1) 100%);
        }

        &-338ed5 {
            background: rgb(51, 142, 213);
            background: linear-gradient(180deg, rgba(51, 142, 213, 0.20) 0%, rgba(51, 142, 213, 1) 100%);
        }

        &-d0b280 {
            background: rgb(208, 178, 128);
            background: linear-gradient(180deg, rgba(208, 178, 128, 0.20) 0%, rgba(208, 178, 128, 1) 100%);
        }

        &-9a5256 {
            background: rgb(154, 82, 86);
            background: linear-gradient(180deg, rgba(154, 82, 86, 0.20) 0%, rgba(154, 82, 86, 1) 100%);
        }

        // DESKTOP
        @media (min-width: $breakpoint-xlarge) {

            &-e83943 {
                background: rgb(232, 57, 67);
                background: linear-gradient(90deg, rgba(232, 57, 67, 0.20) 0%, rgba(232, 57, 67, 1) 100%);
            }

            &-333333 {
                background: rgb(51, 51, 51);
                background: linear-gradient(90deg, rgba(51, 51, 51, 0.20) 0%, rgba(51, 51, 51, 1) 100%);
            }

            &-fedd36 {
                background: rgb(254, 221, 54);
                background: linear-gradient(90deg, rgba(254, 221, 54, 0.20) 0%, rgba(254, 221, 54, 1) 100%);
            }

            &-f38933 {
                background: rgb(243, 137, 51);
                background: linear-gradient(90deg, rgba(243, 137, 51, 0.20) 0%, rgba(243, 137, 51, 1) 100%);
            }

            &-7ec14f {
                background: rgb(126, 193, 79);
                background: linear-gradient(90deg, rgba(126, 193, 79, 0.20) 0%, rgba(126, 193, 79, 1) 100%);
            }

            &-b23961 {
                background: rgb(178, 57, 97);
                background: linear-gradient(90deg, rgba(178, 57, 97, 0.20) 0%, rgba(178, 57, 97, 1) 100%);
            }

            &-6fbdd1 {
                background: rgb(111, 189, 209);
                background: linear-gradient(90deg, rgba(111, 189, 209, 0.20) 0%, rgba(111, 189, 209, 1) 100%);
            }

            &-34cd4d {
                background: rgb(52, 205, 77);
                background: linear-gradient(90deg, rgba(52, 205, 77, 0.20) 0%, rgba(52, 205, 77, 1) 100%);
            }

            &-fc3333 {
                background: rgb(252, 51, 51);
                background: linear-gradient(90deg, rgba(252, 51, 51, 0.20) 0%, rgba(252, 51, 51, 1) 100%);
            }

            &-ffc535 {
                background: rgb(255, 197, 53);
                background: linear-gradient(90deg, rgba(255, 197, 53, 0.20) 0%, rgba(255, 197, 53, 1) 100%);
            }

            &-338ed5 {
                background: rgb(51, 142, 213);
                background: linear-gradient(90deg, rgba(51, 142, 213, 0.20) 0%, rgba(51, 142, 213, 1) 100%);
            }

            &-d0b280 {
                background: rgb(208, 178, 128);
                background: linear-gradient(90deg, rgba(208, 178, 128, 0.20) 0%, rgba(208, 178, 128, 1) 100%);
            }

            &-9a5256 {
                background: rgb(154, 82, 86);
                background: linear-gradient(90deg, rgba(154, 82, 86, 0.20) 0%, rgba(154, 82, 86, 1) 100%);
            }
        }
    }

    .box {
        &-container {
            height: 100%;
            border-radius: 12px;

            &>div {
                padding: 8px;
                gap: 8px;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                @media (min-width: $breakpoint-large) {
                    padding: 16px;
                    gap: 16px;
                }
            }

            @media (max-width: $breakpoint-medium-max) {
                &>div {
                    flex-direction: column;
                    align-items: center;
                }
            }

            .img-container {
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: $breakpoint-medium-max) {
                    margin-top: 3px;
                    margin-bottom: 10px;
                }

                .img-banner {
                    width: auto;
                    height: auto;
                    max-width: 158px;

                    @media (max-width: $breakpoint-medium-max) {
                        max-height: 180px;
                    }

                    @media (min-width: $breakpoint-large) {
                        max-width: 177px;
                        max-height: 284px;
                    }
                }
            }

            &-content {
                min-width: 152px;
                background-color: $white;
                padding: 16px;
                overflow: hidden;
                position: relative;
                border-radius: 12px;
                display: flex;
                flex-direction: column;

                @media (min-width: $breakpoint-large) {
                    min-height: 284px;
                    .banner-title,
                    .banner-description {
                        @include line-clamp(3);
                    }
                }

                .img-logo {
                    max-width: 60px;
                    max-height: 60px;
                }

                .button-spesa {
                    width: 152px;
                    height: 40px;
                    border: 2px solid $barely-black;
                    font-size: 16px;
                    font-weight: bold;
                    color: $barely-black;
                    line-height: 36px;
                    padding: 0;
                    outline: none;
                    box-shadow: none;
                }

                .logo-top {
                    min-height: 60px;
                    width: 100%;
                    margin: 0 0 8px 0;
                }

                .logo-bollino {
                    background: url("./resources/ecommerce/img/bollino.svg") no-repeat right top / contain;
                    position: absolute;
                    margin-left: auto;
                    top: 0;
                    right: 0;
                    width: 120px;
                    height: 80px;

                    &-content {
                        color: $white;
                        font-size: 20px;
                        font-weight: 500;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        min-height: 75px;
                        margin: 0 0 0 36px;
                        img {
                            max-width: 24px;
                            max-height: 24px;
                        }
                        span {
                            display: block;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            max-width: 100%;
                        }
                    }
                }

                .banner-period {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1;
                    margin: 0 0 16px 0;
                }

                .banner-title {
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 1.2;
                    margin: 0 0 8px 0;
                }

                .banner-description {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 1.43;
                    margin: 0;
                    margin-bottom: 16px;
                }

                .link-banner-sponsor {
                    margin-top: auto;
                    margin-bottom: 10px;
                }
            }
        }
    }
}


.section-banner-landing-idm-row {
    //Visualizzazione 1 Banner
    &.uk-child-width-expand {
        .box {
            &-container {
                &-content {
                    .link-banner-sponsor {
                        margin-bottom: 0;
                    }
                    @media (min-width: $breakpoint-large) {
                        .img-logo {
                            max-width: 80px;
                            max-height: 80px;
                        }
                    }
                }
                .img-container {
                    min-width: 405px;
                    max-height: 316px;
                    .img-banner {
                        @media (min-width: $breakpoint-large) {
                            max-width: 405px;
                            max-height: 284px;
                        }
                    }
                }
                @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
                    .img-container {
                        .img-banner {
                            max-width: 300px;
                        }
                    }
                }
            }
        }
    }

    //Visualizzazione 2 Banner
    &.uk-child-width-1-2\@xl {
        .box {
            &-container {
                @media (min-width: $breakpoint-large) {
                    &-content {
                        .img-logo {
                            max-width: 80px;
                            max-height: 80px;
                        }
                    }
                }
                .img-container {
                    .img-banner {
                        max-width: 160px;

                        @media (min-width: $breakpoint-large) {
                            max-width: 221px;
                        }
                        @media (min-width: $breakpoint-xlarge) and (max-width: $breakpoint-xxlarge-max) {
                            max-width: 180px;
                        }
                    }
                }
            }
        }
    }

    //Visualizzazione 3 Banner
    &.uk-child-width-1-3\@xl {
        @media (min-width: $breakpoint-xlarge) and (max-width: $breakpoint-xxlarge-max) {
            > * {
                width: calc(100% * 1 / 2.001);
            }
        }

        .box {
            &-container {
                .img-container {

                }
            }
        }
    }
}