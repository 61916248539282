
@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';
.component-card-store {
    + .component-card-store {
        margin-top: 16px;
    }
}

.uk-card-type-store {
    cursor: pointer;
    transition: all 0.3ms ease-in-out;
    border-radius: 12px;
    border: none;
    display: block;
    width: 100%;
    padding: 0;
    text-align: left;
    text-rendering: unset;

    .uk-card {
        &-body {
            border-radius: 12px;
            padding: 16px;
            background-color: $white;
            transition: all 0.3ms ease-in-out;
            border: none;
            .badge-km {
                background-color: $gray-chips;
            }
        }
        &-image {
            text-align: center;
            width: 80px;
            min-width: 80px;
            margin-right: 16px;
            margin-top: 20px;
            @media (min-width: $breakpoint-large) {
                margin-top: 0px;
            }
            > * {
                color: var(---effects-01-opacity-20-2);
                font-size: 80px;
            }
            svg {
                width: 80px;
            }
        }
        &-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 4px;
        }
    }
    .address {
        font-size: 14px;
        line-height: 1.43;
        @include color-primary-black;
        margin-bottom: 9px;
    }

    .info-box,
    .alert-box {
        color: var(---effects-02-darker-40-2);
        font-size: 12px;
        font-weight: bold;
        line-height: 1.33;
        position: relative;
        padding-left: 24px;
        margin-bottom: 8px;
        i {
            position: absolute;
            top: 0;
            left: 0;
        }
        p {
            margin: 0;
        }
    }
    .delivery-note {
        margin-top: 8px;
        background-color: $gray-chips;
        &.no-available {
            background-color: rgba($danger, 0.1);
        }
        &.alert {
            background-color: rgba($warning, 0.1);
            font-weight: 500;

            i {
                margin-right: 4px;
            }
        }
    }
    //&:hover {
    //    &:not(.disabled) {
    //        box-shadow: 0 0 10px -2px var(---dusty-orange);
    //        transition: all .3ms ease-in-out;
    //    }
    //}

    &.active {
        box-shadow: 0 0 10px -2px var(---dusty-orange);
        transition: all 0.3ms ease-in-out;
        > * {
            border-color: rgba($dusty-orange, 0.3);
            box-shadow: 0 0 0 4px inset rgba($dusty-orange, 0.1);
        }
        .uk-card-image {
            > * {
                color: var(---colour-01-primary-03-p-2);
            }
        }
    }

    &.disabled {
        opacity: 0.4;
        cursor: default;
    }

    @media (min-width: $breakpoint-large) {
        .delivery-note {
            margin-left: 95px;
        }
    }

    .tooltiptext {
        padding: 6px 12px;
        max-width: 235px;
    }
}
