
.component-ProductCardDetailCart {

    .alert-quantita-aggiornata{

        margin-bottom: 5px;

        i {
            background-color: var(---colour-03-semantic-03-warnin-2) !important;
            margin-right: 4px;
        }

        span{
            font-size: 9px;
            line-height: 16px;
            font-weight: bold;
            text-shadow: #ffb602 0px 0 0.3px;
        }

        @media screen and (max-width: $breakpoint-large-max){
            margin-top: 8px;
        }
    }

    //custom select
    .select-native-ui {
        &-scale {
            select {
                border: none;
                padding: 9px 0px;
                line-height: inherit;
                border-radius: 13px;
                width: 50px;
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS4xMzUgNmExIDEgMCAwIDAtLjc2OCAxLjY0bDIuODY1IDMuNDM4YTEgMSAwIDAgMCAxLjUzNiAwbDIuODY1LTMuNDM4QTEgMSAwIDAgMCAxMC44NjUgNmgtNS43M3oiIGZpbGw9IiMwMDAiLz4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS4xMzUgNmExIDEgMCAwIDAtLjc2OCAxLjY0bDIuODY1IDMuNDM4YTEgMSAwIDAgMCAxLjUzNiAwbDIuODY1LTMuNDM4QTEgMSAwIDAgMCAxMC44NjUgNmgtNS43M3oiIGZpbGw9IiNmZmYiIGZpbGwtb3BhY2l0eT0iLjIiLz4KPC9zdmc+Cg==");
                background-position-x: 100%;
                font-weight: bold;
                &:focus {
                    border: none;
                }
            }
        }

        @media (max-width: $breakpoint-large-2-max) {
            display: flex;
            font-size: 14px;
            label {
                margin-left: 5px;
            }
            select {
                border-color: transparent;
                line-height: inherit;
                background-size: 16px;
                background-position-x: 100%;
                min-width: 50px;
                padding: 0 5px;
                font-weight: normal;
            }
        }
    }

    .component-PromoBadgeCard {
        margin-right: 4px;

        .badge-text {
            line-height: 1;
            padding: 6px 8px;
            font-size: 12px;
            font-weight: bold;
            border-radius: 8px;
            margin-right: 4px;
        }
    }

    .alert-promo-prezzo-scontabile {
        background: transparent;
        color: var(---effects-02-darker-40-2);
        padding: 0 0 10px 15px;

        i {
            background: var(---effects-02-darker-40-2);
            color: $white;
        }
    }

    .price-promo-label {
        font-size: 9px;
        line-height: 16px;
        font-family: "DM Sans", sans-serif;
        @media (min-width: $breakpoint-xlarge) {
            max-width: 85px;
        }
    }

    .price {
        .unit-price-red {
            color: var(---colour-01-primary-01-p-2);
        }
    }

    .product-img {
        position: relative;

        img {
            max-width: 45px;
            max-height: 45px;
            @media (min-width: $breakpoint-large) {
                max-width: 56px;
                max-height: 56px;
            }
        }
    }

    .category-mobile-product {
        border-radius: 12px;
        box-shadow: 0 1px 1px 0px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
        box-sizing: border-box;
        width: 100%;
        padding: 8px 8px 0px 8px;
        position: relative;
        background-color: $white;

        .unavailable-badge {
            padding: 0 16px;
            white-space: nowrap;
            background-color: $pink-light;
            max-width: 121px;
        }

        .logos {
            width: 100%;
            margin-bottom: 8px;

            & > :not(:last-child) {
                margin-right: 4px;
            }

            .icon-fidelity-double, .icon-fidelity-grey {
                font-size: 24px;
            }

            .logo-iniziativa {
                max-width: 24px;
                max-height: 24px;
            }
        }

        .img {
            margin-bottom: 8px;

            img {
                max-width: 46px;
                max-height: 46px;
            }

            .img-product{
                text-decoration: none;
            }
            
            .badge-marchi-conad {
                max-height: 32px;
                position: relative;
                top: 5px;
                left: 5px;
            }
        }

        .desc-price-qty {
            padding-left: 8px;
            padding-bottom: 8px;
            width: 100%;

            .desc {
                @include color-primary-black();
                text-decoration: none;
                font-size: 12px;
                line-height: 16px;
                padding-right: 15px;
            }

            .price {
                &-unit {
                    font-size: 14px;

                    &-red {
                        color: var(---colour-01-primary-01-p-2);
                    }
                }

                &-qty {
                    color: $primary-black;
                    font-size: 10px;
                }

                &-original {
                    text-decoration: line-through;
                    margin-left: 5px;
                    font-size: 10px;
                }

                &-cumulative {
                    margin-left: auto;
                    font-size: 16px;
                    text-align: right;
                }
            }

            .quantity {
                .js-select-custom {
                    width: 64px;

                    .options {
                        width: 179px;
                        left: auto;
                        top: -100%;

                        .selection:after {
                            left: initial;
                            right: -70%;
                        }
                    }

                    .styledSelect {
                        font-size: 14px;
                        box-shadow: none;
                        border: none;
                    }
                }
            }

            .delete-item {
                line-height: 1.5;
            }
        }

        .button-more {
            position: absolute;
            top: 8px;
            right: 8px;
            line-height: 1;
            margin-left: auto;
            transform: rotate(90deg);
            height: unset;

            .icon-more {
                margin: 0;
            }

            .product-choice {
                transform: rotate(-90deg);
                border-radius: 16px;
                background-color: $global-emphasis-color;
                font-size: 17px;
                font-weight: 500;
                color: $white;
                min-height: unset;
                width: 230px;
                top: 47px;
                left: -80px;
                display: none;

                > * {
                    width: 198px;
                    padding: 16px;
                    text-align: left;
                }

                &.show {
                    display: block;
                }
            }

        }

        .remove-product {
            position: absolute;
            top: 8px;
            right: 24px;
            line-height: 1;
            margin-left: auto;
        }

        &.not-available{
            padding: 11px 8px 0 8px;

            .img{
                img{
                    filter: grayscale(100%);
                }
            }

            .desc{
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }

    .category-desktop {
        &-product {
            border-radius: 12px;
            box-shadow: 0 1px 1px 0px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            box-sizing: border-box;
            position: relative;
            padding: 16px 16px 16px 16px;
            min-height: 145px;
            max-width: 100%;
            flex-grow: 1;
            background-color: $white;

            &.unavailable-shadow {
                box-shadow: 0 7px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            }

            &.unavailable-product {
                min-height: 0;

                .product-img {
                    filter: grayscale(100%);
                }

                .product-desc{
                    .product-desc-text{
                        color: rgba(0, 0, 0, 0.6);
                    }
                }

                .info-grid {
                    display: flex;
                    align-items: center;

                    & > * {

                        padding-top: 0 !important;
                        padding-bottom: 0 !important;
                    }
                }
            }

            .info-grid {
                box-sizing: border-box;

                > * {
                    box-sizing: border-box;
                }

                .product-img {
                    padding-right: 8px;
                    padding-top: 20px;
                    text-decoration: none;
                    @include color-primary-black();
                }

                .product-desc {
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    font-size: 12px;
                    line-height: 16px;
                    min-width: 184px;
                    margin-right: 10px;

                    @media only screen and (min-width: $breakpoint-xxxlarge) {
                        max-width: 450px;
                    }

                    &-text {
                        display: flex;
                        align-items: center;
                        height: 67px;
                        width: 100%;
                        text-decoration: none;
                        @include color-primary-black();

                        span {
                            @include line-clamp(4);
                            padding-top: 18px;
                        }

                        @media (min-width: $breakpoint-xxxlarge) {
                            align-items: flex-start;
                        }
                    }

                    &-footer {
                        margin-top: 13px;

                        span {
                            color: $base-body-color;
                        }

                        .extra {

                            a, span {
                                color: $black;
                                text-decoration: none;
                                font-weight: bold;
                            }

                            .icon-edit {
                                font-size: 16px;
                                margin-left: 1px;
                            }

                            &-weight {
                                color: $primary-black;

                                i {
                                    margin-right: 2px;
                                }
                            }

                        }
                    }
                }

                .badge-marchi-conad {
                    position: absolute;
                    @media (min-width: $breakpoint-xlarge) {
                        bottom: -10px;
                        left: 8px;
                        max-height: 40px;
                    }
                }

                .price-info {
                    display: flex;
                    padding-top: 5px;

                    &-no-logos {
                        padding-top: 27px;
                    }

                    &-container {
                        height: min-content;

                        .uk-divider-vertical {
                            height: auto;
                            margin: 0 8px 0 0;
                        }

                        .unavailable-badge {
                            padding: 0 16px;
                            white-space: nowrap;
                            background-color: $pink-light;

                        }
                    }

                    &-details {
                        height: min-content;

                        .unit-price {
                            font-size: 16px;

                            &-red {
                                color: var(---colour-01-primary-01-p-2);
                            }

                            &-original {
                                text-decoration: line-through;
                                margin-left: 5px;
                                font-size: 10px;
                            }
                        }

                        .price-qty {
                            color: $primary-black;
                            font-size: 10px;
                        }
                    }

                }

                .quantity-details {
                    min-width: 102px;
                    min-height: 112px;
                    position: relative;
                    padding-top: 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;

                    .quantity {
                        height: 56px;

                        .js-select-custom {

                            .options {
                                width: 179px;

                                .selection:after {
                                    left: initial;
                                    right: -70%;
                                }
                            }

                            .styledSelect {
                                padding-top: 9px !important;
                                padding-right: 30px !important;

                                &:after {
                                    content: "\e90a";
                                    font-family: "icomoon";
                                    position: absolute;
                                    line-height: 10px;
                                    margin-top: -5px;
                                    transform: rotate(0);
                                }
                            }

                            @media (min-width: $breakpoint-xlarge) {

                                .options {
                                    right: initial;
                                    // top: calc(100% + 36px);
                                    top: calc(100% - 110px);

                                    .selection {
                                        position: relative;

                                        &:after {
                                            position: absolute;
                                            top: 19px !important;
                                            right: 10% !important;
                                        }
                                    }
                                }
                                .select-label {
                                    display: none;
                                }
                            }

                        }

                        /* &-format{
                            height: 56px;
                            width: 121px;

                            @media (min-width: $breakpoint-xlarge){
                                .js-select-custom{

                                    .styledSelect{
                                        box-shadow: none;
                                        font-size: 12px;
                                    }
                                }
                            }
                        } */


                        &-format {
                            width: 121px;
                            position: absolute;
                            left: 4px;
                            bottom: 0;
                            font-size: 12px;

                            /* a {
                                text-decoration: none;
                                color: $black;
                                font-weight: bold;
                            } */

                            .select-label {
                                font-weight: bold;
                                font-size: 12px;
                                left: 0px;
                                bottom: 0px;
                                line-height: 13px;
                            }

                            .styledSelect {
                                box-shadow: none;
                                font-size: 12px;
                                border: none;
                            }
                        }
                    }

                    &.heavy-product .custom-select {
                        .select-selected {
                            box-shadow: 0 0 0 1px inset rgba($black, 0.4);
                            color: $primary-black;
                        }
                    }
                }

                .price, .last-col {
                    display: flex;
                    justify-content: flex-start;
                    padding-top: 35px;
                    font-size: 20px;

                    .delete-item {
                        color: $black;
                        font-size: 12px;
                        font-weight: bold;
                        padding-right: 4px;
                    }
                }

                .price {
                    min-width: 95px;
                    width: auto;
                    justify-content: flex-end;
                    text-align: right;
                    align-items: center;
                    padding-top: 0;
                    margin-top: -20px;
                }

                .remove-product {
                    margin-left: 24px;
                    display: flex;
                    align-items: center;
                    margin-top: -20px;
                    cursor: pointer;

                    i {
                        font-size: 24px
                    }

                    .custom-tooltip {
                        position: relative;

                        .tooltiptext {
                            display: none;
                            position: absolute;
                            bottom: -8px;
                            left: 30px;
                            width: max-content;
                            padding: 16px;
                            border-radius: 10px;
                            color: $white;
                            font-weight: 500;
                            font-size: 14px;
                            background-color: $gray;
                        }

                        &:hover {
                            .tooltiptext {
                                display: block;
                            }

                        }
                    }
                }

                .last-col {
                    align-items: center;
                }
            }

            .logos {

                width: 100%;
                margin-bottom: 8px;

                .icon-fidelity-double, .icon-fidelity-grey {
                    font-size: 24px;
                    margin-right: 4px;
                }

                & > :not(:last-child) {
                    margin-right: 4px;
                }

                .top {
                    margin-bottom: 4px;

                    img {
                        margin-right: 4px;
                    }
                }

                .logo-iniziativa {
                    max-width: 24px;
                    max-height: 24px;
                }
            }

        }
    }

    .uk-checkbox-custom {
        margin-right: 4px;

        @media only screen and (min-width: $breakpoint-large) {
            margin-right: 10px;
        }

        @media only screen and (min-width: $breakpoint-xlarge) {
            margin-right: 17px;
        }
    }

    .subs {
        box-sizing: border-box;
        background-color: $lavander;
        height: 44px;
        margin: 8px -8px 0 -8px;
        padding: 8px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        @media only screen and (min-width: $breakpoint-xlarge) {
            margin: 19px -16px -16px -16px;
            padding: 16px;
        }

        &.unavailable {
            background-image: linear-gradient(170deg, #dfddd7, #95948f);
            pointer-events: none;

            .uk-switch {

                &-slider:before {
                    background-color: #dfddd7;
                }

            }
        }

        &.no-substitute {
            pointer-events: none;

            .uk-switch{
                display: none;
            }
        }

        &.deselected {
            .uk-switch-slider:before {
                background-color: #dfddd7;
            }

            .scelgo-io {
                a {
                    pointer-events: none;
                }
            }

            button {
                pointer-events: none;
                opacity: 0.3;
            }
        }

        .toggle {
            width: $switch-width-small;
            height: $switch-height-small;
            margin-right: 8px;

            @media only screen and (min-width: $breakpoint-xlarge) {
                width: $switch-width;
                height: $switch-height;
            }
        }

        .subs-item {
            margin-right: auto;
            padding: 0;

            span {
                @include line-clamp(2);
                font-size: 12px;
                line-height: 12px;
                color: $black;
            }

            a {
                @include line-clamp(1);
                font-size: 12px;
                line-height: 12px;
                color: $black;
            }
        }


        i {
            align-items: flex-end;
            margin-left: 4px;

            @media only screen and (min-width: $breakpoint-xlarge) {
                margin-left: 0px;
                font-size: 18px;
            }
        }

        button {
            font-size: 14px;
            color: $blue-info;
        }

        .scelgo-io, .sceglie-conad, .chiamami, .no-sostituto{
            display: none;
        }

        &.punctual-substitution{
            .scelgo-io{
                display: flex;
            }

            .uk-button{
                display: block;
            }
        }

        &.automatic-substitution{
            .sceglie-conad{
                display: block;
            }
        }

        &.semi-automatic-substitution{
            .chiamami{
                display: block;
            }
        }

        &.no-substitute{
            .no-sostituto{
                display: block;
            }
        }

        .uk-button{
            display: none;
        }

    }

    /* Grafica per card nel dettaglio ordine guest */
    &.order-detail {
        @include color-primary-black(0.8);

        .quantity-order-detail {
            &-mob {
                font-size: 14px;
                line-height: 1;
                font-weight: bold;
            }

            &-dsk {
                border: 1px solid rgba($black, 0.6);
                border-radius: 16px;
                padding: 16px;
            }
        }

        .quantity-format, .extra {
            font-weight: bold;
        }
    }

    .info-limit-exceeding-liters {
        @media (max-width: $breakpoint-large-2-max) {
            margin-top: -20px;
        }
        .badge-text {
            font-size: 14px;
            color: $danger;
            font-weight: bold;
            .icon-info {
                background-color: $danger;
                position: relative;
                top: 2px;
            }
        }
        .tooltiptext-drop .tooltiptext {
            padding: 12px;
            max-width: 205px;
        }
    }

    .banner-promo {

        & + .category-mobile-product{
            border-top-left-radius: unset;
            border-top-right-radius: unset;
        }
        margin-top: -10px;
        height: 24px;
        border-top-left-radius: 12px;
        background-color: #f6e7ec;
        border-top-right-radius: 12px;
        z-index: 1;
        position: relative;
        top: 19px;
        display: flex;
        align-items: center;
        color: #9f0839;

        &.mobile{
            margin-top: unset;
            top: 3px;
        }

        .icon-promo {
            line-height: 1.17;
            margin-left: 8px;
            position: relative;
            width: 16px;
            height: 16px;
        }

        .promo-desc {
            font-weight: bold;
            font-size: 12px;
            line-height: 1.17;
            margin-left: 5px;
        }

        .tooltip-margin {
            margin-left: 8px;
            padding: 12px;

            .icon-info {
                line-height: inherit;
            }

            .tooltiptext-drop {
                .tooltiptext {
                    margin: 0 15px 0 15px;
                }
            }
        }
    }
}