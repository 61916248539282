.component-ModalOnboarding {
    overflow-y: hidden; //la modale intera non scrollerà

    &[data-status='1'] {
        .box-info-address {
            display: block !important;
        }
    }

    &:not(.step-2) {
        .section-address-complete {
            .address {
                .icon-delivery,
                .icon-shopping-bag {
                    display: none;
                }
            }
        }
    }

    &.hide-card-scegli-spesa{
        .component-card-spesa-a-casa{
            display: none !important;
        }
    }

    .box-info-address {
        display: none;
        background-color: $cultured;
        padding: 16px 15px;
        border-radius: 16px;
        width: auto;
        margin: 0 16px;
        position: sticky;
        bottom: 104px;
        left: 0;
        right: 0;
        max-width: 576px;
        
        h3 {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 8px;
        }
        p {
            font-size: 12px;
            font-weight: 500;
        }
        a {
            font-weight: bold;
            color: $primary-black;
            text-decoration: none;
            white-space: nowrap;
        }
        
        @media(min-width: $breakpoint-large){
            margin-left: auto;
            margin-right: auto;
        }
    }
    &.type-checkout {
        &.on-loading {
            .section-scelta-spesa,
            .section-spesa-a-casa,
            .section-ordina-e-ritira {
                opacity: 0.1;
            }
        }

        &.add-delivery-address {
            .section-scelta-spesa {
                display: block !important;

                .title-section {

                    
                    .default {
                        display: none;
                    }

                    .add-delivery-address {
                        display: block;
                    }

                }
            }
        }

        .modal-top-bar {
            visibility: hidden;
        }

        .section-address {
            display: none !important;
        }

        .section-spesa-a-casa {
            .section-title {
                .add-delivery-address {
                    display: block;
                }
            }

            .lista-negozi-section {
                height: calc(100dvh - 252px ) !important;
            }
        }

        .section-scelta-spesa {
            .component-card-ordina-e-ritira,
            .component-card-spesa-a-casa {
                max-width: 282px;
            }
        }

        @media(max-width: $breakpoint-large-2-max){
            .section-ordina-e-ritira{

                .section-view-pdv {
                    &-content {
                        height: calc(100dvh - 274px) !important;
                    }
                }
                
                @media (min-width: $breakpoint-medium) and (min-height: 500px) {
                    .section-view-pdv {
                        &-content {
                            max-height: calc(100dvh - 303px);
                            height: 100dvh;
                            padding: 0 0;
                        }
                    }
                }
            }
        }
    }

    &.address {
        //nello stato di indirizzo inserito
        &-complete {
            &.type-checkout {
                .section-address-checkout {
                    display: block !important;

                    .section-address-edit {
                        display: none;
                    }

                    .section-address-complete {
                        display: block;
                    }
                }
            }

            &:not(.type-checkout) {
                .section-address {
                    display: block !important;

                    .section-address-edit {
                        display: none;
                    }

                    .section-address-complete {
                        display: block;
                    }
                }
            }


            .uk-modal-dialog {
                .modal-content {
                    .section-scelta-spesa {
                        @media (max-width: $breakpoint-large-max) {
                            height: calc(100vh - 170px);
                        }
                    }
                }
            }
        }

        //nello stato dell'input visibile
        &-edit {
            &, &-no-btn-annulla {
                &.type-checkout {
                    .section-address-checkout {
                        display: block !important;

                        .section-address-edit {
                            display: block;
                        }

                        .section-address-complete {
                            display: none;
                        }
                    }
                }

                &:not(.type-checkout) {
                    .section-address {
                        display: block !important;

                        .section-address-edit {
                            display: block;
                        }

                        .section-address-complete {
                            display: none;
                        }
                    }
                }
            }

            &-no-btn-annulla{
                .btn-annulla-mobile, .to-back{
                    display: none !important;
                }
                
            }

            .uk-modal-dialog {
                .modal-content {
                    .section-scelta-spesa {
                        height: calc(100vh - 175px);

                        @media (max-width: $breakpoint-large-max) {
                            height: calc(100vh - 240px);
                        }
                    }
                }
            }

            .card-scegli-spesa {
                opacity: 0.3;

                .uk-card {
                    .uk-button {
                        display: none !important;
                    }
                }
            }

            .title-section{
                opacity: 0.3;
            }

            .uk-modal-dialog {
                .modal-content {
                    .box-info-address{
                        & + .btn-annulla {
                            z-index: 5000;
                            @media (min-width: $breakpoint-large) {
                                visibility: visible;
                                opacity: 1;
                            }
    
                        }
                    }
                    
                }
            }
        }
    }

    .lds-ring {
        z-index: 2999;
    }

    .uk-modal-close-default {
        text-align: center;
        font-size: 25px;
        padding: 0;
        top: 14px;
        right: 0;
        width: 60px;
        height: 60px;
        cursor: pointer;
        color: $primary-black;
    }

    .modal-top-bar {
        .uk-progress {
            display: flex;
            width: 100%;
            height: 9px;
            background-color: $cosmic-latte;

            &-bar {
                background-color: $yellow;
                width: 50%;
                transition: all 0.3s ease-in-out;

                &.full {
                    width: 100%;
                }
            }
        }

        .n-step {
            font-weight: bold;
            padding: 0 70px;

            .s1 {
                display: inline-block;
            }

            .s2 {
                display: none;
            }
        }
    }

    .uk-modal-dialog {
        border-radius: 0;
        box-shadow: none;

        .modal-content {
            width: 100%;
            max-width: 100%;
            padding-top: 0;
            margin: 22px 0 0 0;

            > [class^='section-'] {
                height: 100dvh;
                max-height: calc(100dvh - 70px);
                overflow: auto;
                @media (min-width: $breakpoint-large) {
                    max-height: calc(100dvh - 70px);
                }
            }

            .section-address, .section-address-checkout {
                margin-bottom: 16px;
                width: 100%;
                max-width: 545px;
                margin-left: auto;
                margin-right: auto;
                @media (min-width: $breakpoint-large) {
                    position: relative;
                    margin-bottom: 24px;
                }

                .to-back {
                    color: $primary-black;
                    font-size: 25px;
                    position: absolute;
                    top: 13px;
                    left: 0;
                    padding: 0;
                    width: 60px;
                    height: 60px;
                    transition: none;

                    i {
                        margin: 0;
                    }

                    @media (min-width: $breakpoint-large) {
                        position: absolute;
                        top: -13px;
                        left: -60px;
                    }
                }

                &-complete {
                    .address {
                        background: none;
                        border: none;
                        font-size: 20px;
                        color: $blue-info;
                        padding: 0;
                        cursor: pointer;

                        span {
                            font-weight: 500;
                            display: inline-block;
                            border-bottom: 1px solid $blue-info;
                            max-width: 220px;

                            @media (min-width: 375px) {
                                max-width: 280px;
                            }

                            @media (min-width: 425px) {
                                max-width: 315px;
                            }

                            @media (min-width: $breakpoint-medium) {
                                max-width: 480px;
                            }
                        }

                        i {
                            font-weight: 500;
                            position: relative;
                            top: -2px;
                            font-size: 23px;

                            &.icon-delivery,
                            &.icon-shopping-bag {
                                padding-right: 4px;

                            }

                            &.icon-edit {
                                padding-left: 4px;
                            }
                        }
                    }
                }

                &-edit {
                    padding: 0 16px;
                    max-width: 576px;

                    .btn-annulla-mobile {
                        position: absolute;
                        top: 29px;
                        left: 16px;
                        text-decoration: underline;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                        @media (min-width: $breakpoint-large) {
                            display: none;
                        }
                    }
                }

                @media (min-width: $breakpoint-large) {
                    &-complete {
                        .address {
                            .uk-link {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }

            .title-section {
                text-align: center;

                //CHECKOUT
                .add-delivery-address {
                    display: none;
                }

                //
                .title {
                    font-size: 20px;
                    font-weight: bold;
                    margin-top: 0;
                    margin-bottom: 16px;

                    @media (min-width: $breakpoint-large) {
                        font-size: 24px;
                        margin-bottom: 32px;
                    }
                }

                .consegna-disabled,
                .ritiro-disabled,
                .consegna-ritiro-disabled,
                .checkout-indirizzo-disabled {
                    display: none;
                }

                &.consegna-disabled {
                    .title {
                        margin-bottom: 8px;
                    }

                    .sub-section {
                        margin-top: 0;
                    }

                    .default,
                    .ritiro-disabled,
                    .consegna-ritiro-disabled {
                        display: none;
                    }

                    .consegna-disabled {
                        display: block;
                    }

                }

                &.ritiro-disabled {
                    .title {
                        margin-bottom: 8px;
                    }

                    .sub-section {
                        margin-top: 0;

                    }

                    .default,
                    .consegna-disabled,
                    .consegna-ritiro-disabled {
                        display: none;
                    }

                    .ritiro-disabled {
                        display: block;
                    }

                }

                &.consegna-ritiro-disabled {
                    .default,
                    .consegna-disabled,
                    .ritiro-disabled {
                        display: none;
                    }

                    .title {
                        margin-bottom: 8px;
                    }

                    .sub-section {
                        margin-top: 0;

                    }

                    .consegna-ritiro-disabled {
                        display: block;
                    }
                }

                &.checkout-indirizzo-disabled {
                    .default,
                    .consegna-disabled,
                    .ritiro-disabled,
                    .consegna-ritiro-disabled {
                        display: none;
                    }

                    .checkout-indirizzo-disabled {
                        display: block;
                    }
                }
            }

            //SECTION SCELTA SPESA
            .section-scelta-spesa {
                padding: 0 16px;
                height: calc(100dvh - 154.5px);
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;
                box-sizing: border-box;

                @media (max-width: $breakpoint-large-max) {
                    padding-bottom: 40px;
                    height: calc(100dvh - 212px);
                }

                @media (max-height: 500px) {
                    height: 100dvh;

                    .card-section {
                        height: 100dvh;
                    }
                }

            }

            //SECTION SPESA A CASA
            .section-spesa-a-casa {
                padding: 0;
                width: 100%;
                max-width: 576px;
                margin: 0 auto;
                box-sizing: border-box;

                .title-section{
                    
                    position: relative;
                    z-index: 2;

                    .title{
                        margin-bottom: 0px;
                        padding-bottom: 16px;
                    }

                    &.add-box-shadow{
                        box-shadow: 0 5px 5px -5px var(---02-effects-02-darker-05-2-15);
                    }
                }

                .lista-negozi-section {
                    overflow: auto;
                    height: calc(100dvh - 180px);
                    margin-bottom: 0;
                    margin-top: 0;
                    padding: 20px 16px 90px;
                    box-sizing: border-box;

                    .ps__rail-y {
                        margin-top: 5px !important;
                        margin-bottom: 8px !important;
                    }

                    .uk-list {
                        padding-bottom: 15px;
                    }
                }

                @media (min-width: $breakpoint-large) {
                    width: 600px;
                    .lista-negozi-section {
                        height: calc(100dvh - 287px);
                        padding-right: 24px;
                        margin-right: -24px;
                    }
                    .uk-list {
                        padding-bottom: 0;
                    }
                }

                @media (max-height: 500px) {
                    height: 100dvh;

                    .lista-negozi-section {
                        height: calc(100dvh - 158px);
                        padding-bottom: 20px;
                    }
                }


            }

            //SECTION ORDINA E RITIRA
            .section-ordina-e-ritira {
                .section-view-pdv {
                    &-content {
                        height: calc(100dvh - 214px);
                        padding: 0 17px;
                    }
                }

                .lista-negozi-section {
                    width: 100%;
                    max-width: 576px;
                    margin-top: 16px;
                    margin-left: auto;
                    margin-right: auto;

                    .uk-list {
                        li {
                            &:before {
                                display: none;
                            }
                        }
                    }

                    .component-card-negozio {
                        max-width: 100%;
                        width: 100%;
                    }

                }

                @media (min-width: $breakpoint-medium) {
                    .section-view-pdv {
                        &-content {
                            //max-height: calc(100dvh - 243px);
                            height: 100dvh;
                            padding: 0 0;
                        }
                    }

                    .lista-negozi-section {
                        .component-card-negozio {
                            max-width: 546px;
                        }

                        .ps__rail-y {
                            right: 0 !important;
                            margin-bottom: 15px;
                            margin-top: 15px;
                        }
                    }
                }

                @media (min-width: $breakpoint-medium) and (min-height: 500px) {
                    .section-view-pdv {
                        &-content {
                            max-height: calc(100dvh - 243px);
                            height: 100dvh;
                            padding: 0 0;
                        }
                    }
                }

                @media (min-width: $breakpoint-xlarge) {
                    .section-view-pdv {
                        &-content {
                            //max-height: calc(100dvh - 199px);
                            height: 100dvh;
                        }
                    }

                    .lista-negozi-section {
                        margin-top: 0;
                        max-width: 42%;

                        .uk-list {
                            padding: 10px 41px 15px 47px;
                            margin-top: -10px;
                            margin-bottom: -10px;
                        }

                        .ps__rail-y {
                            right: 17px !important;
                        }

                        .component-card-negozio {
                            margin: 0 auto;
                        }
                    }
                }

                @media (min-width: $breakpoint-large-2) and (min-height: 500px) {
                    .section-view-pdv {
                        &-content {
                            max-height: calc(100dvh - 199px);
                            height: 100dvh;
                        }
                    }
                }

                @media (min-width: $breakpoint-xxxlarge) {
                    .lista-negozi-section {
                        max-width: 36%;
                    }
                }
            }

            //SECTION FORM ESTENSIONE SERVIZIO
            .section-estensione-servizio {
                padding-top: 45px;
                //padding-bottom: 20px;
                max-height: calc(100dvh - 22px);
                box-sizing: border-box;

                .form-section {
                    width: 100%;
                    max-width: 658px;
                    margin: 0 auto;
                    padding: 0 16px;
                    box-sizing: border-box;

                    &-content {
                        max-width: 576px;
                        margin: 0 auto;
                    }

                    .desc {
                        text-align: center;
                        line-height: 1.25;
                        margin-bottom: 24px;
                    }

                    .form-control {
                        .uk-input {
                            &:focus {
                                z-index: 0;
                                background-color: rgba(254, 212, 4, 0.2);
                            }
                        }
                    }

                    .privacy-text {
                        font-size: 14px;
                        line-height: 1.43;
                        padding-bottom: 20px;
                        color: $primary-disabled-text;
                    }
                }

                .bottom-section {
                    opacity: 1;
                    visibility: visible;
                    position: sticky;
                    bottom: 0;

                    .uk-button {
                        width: 100%;
                    }
                }

                .ps__rail-y {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                @media (min-width: $breakpoint-medium) {
                    .bottom-section {
                        .uk-button {
                            width: auto;
                        }
                    }
                }

                @media (min-width: $breakpoint-large) {
                    .form-section {
                        max-width: 640px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                .feedback-estensione {
                    text-align: center !important;

                    &-title {
                        font-size: 16px;
                    }

                    &-desc {
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }

            .card-section {
                gap: 16px;
                margin-top: 16px;
                width: 100%;
                justify-content: center;
                align-items: center;
                @media (min-width: $breakpoint-large) {
                    flex-direction: row;
                    margin-top: 40px;
                }
            }

            .bottom-section {
                visibility: hidden;
                opacity: 0;
                transition: all .3s ease-in-out;
                position: -webkit-sticky;
                position: sticky;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100vw;
                height: 70px;
                text-align: center;
                padding: 16px;
                box-sizing: border-box;
                box-shadow: -2px 6px 8px -2px rgba(171, 147, 67, 0.15), -5px 0 9px 0 rgba(171, 147, 67, 0.20);
                background-color: $white;
                margin: 0;
                z-index: 3002;

                &.btn-conferma-pdv {
                    z-index: 4000;
                    &.active {
                        visibility: visible;
                        opacity: 1;
                    }
                }

                @media (min-width: $breakpoint-large) {
                    //position: absolute !important;
                }
                @media (min-width: $breakpoint-large) {
                    height: 88px;
                }
            }
        }
    }

    &.selected-pdv {
        .bottom-section {
            &.btn-conferma-pdv {
                visibility: visible;
                opacity: 1;
            }
        }

        .uk-modal-dialog .modal-content .section-ordina-e-ritira .lista-negozi-section {
            padding-bottom: 70px !important;
        }

        .map-pdv-container .map-pdv-frame {
            max-height: calc(100% - 88px);
            @media (max-width: $breakpoint-large-max) {
                max-height: calc(100% - 70px);
            }
        }

        @media (max-width: $breakpoint-large-max) {
            .uk-modal-dialog .modal-content .section-spesa-a-casa .lista-negozi-section {
                //padding-bottom: 155px;
            }
        }

        @media (min-width: $breakpoint-large) {
            .uk-modal-dialog .modal-content .section-ordina-e-ritira .lista-negozi-section {
                padding-bottom: 88px;
            }
        }
    }

    /* PROGRESS BAR STEP 2 */
    &.step-2 {
        .modal-top-bar {
            .uk-progress {
                &-bar {
                    width: 100%;
                }
            }

            .n-step {
                .s1 {
                    display: none;
                }

                .s2 {
                    display: inline-block;
                }
            }
        }

        .uk-modal-dialog {
            .modal-content {
                .box-info-address{
                    display: none;
                }
            }
        }

    }


    &.service-type-consegna {
        .section-address, .section-address-checkout {
            .address {
                .icon-shopping-bag {
                    display: none;
                }
            }
        }
    }

    &.service-type-ritiro {
        .section-address, .section-address-checkout {
            .address {
                .icon-delivery {
                    display: none;
                }
            }
        }

        &.view-map {
            //.lista-negozi-section {
            //    display: none;
            //}
        }

        &.view-list {
            .component-card-store-map {
                display: none !important;
            }
        }
    }


    /* LP GOOGLE USER */
    .lp-onboarding {
        &-content {
            padding: 80px 16px 130px;
            height: 100dvh;
            max-height: 100dvh;
            box-sizing: border-box;
        }

        &-title {
            font-weight: 500;
            font-size: 32px;
            line-height: 1.25;
            margin-bottom: 24px;
            max-width: 75%;
            z-index: 1;
        }

        &-desc {
            font-size: 20px;
            line-height: 1.2;
            margin: 0 0 36px;
            max-width: 80%;
            z-index: 1;
        }

        &-accedi {
            margin-top: 60px;
            font-weight: 500;
            line-height: 1.38;
            z-index: 1;
        }

        .google-input {
            max-width: 560px;
            z-index: 1;
        }

        [class*='img-top-'] {
            position: absolute;
            left: 0;
        }

        [class*='img-bottom-'] {
            position: absolute;
            right: -16px;
        }

        .img-top {
            &-mobile {
                top: 80px;
                max-width: 58px;
                max-height: 90px;
            }

            &-desktop {
                top: 130px;
                max-width: 232px;
                max-height: 170px;
            }
        }

        .img-bottom {
            &-mobile {
                top: -10px;
                max-width: 75px;
                max-height: 170px;
            }

            &-desktop {
                bottom: -150px;
                max-width: 150px;
                max-height: 330px;
            }
        }

        @media (min-width: $breakpoint-large-2) {
            &-content {
                padding: 80px 0 80px;
                min-height: 340px;
                justify-content: center;
            }
            &-title {
                max-width: 54%;
                font-size: 48px;
                line-height: 56px;
            }
            &-desc {
                font-size: 24px;
                line-height: 24px;
            }
            &-accedi {
                margin-top: 34px;
            }
            [class*='img-bottom-'] {
                right: 0;
            }
        }
        @media (min-width: $breakpoint-xlarge) {
            &-title {

            }

        }
        @media (min-width: $breakpoint-xxlarge) {
            &-title {
                max-width: 80%;
            }
        }
    }


    /* LP GOOGLE USER */
    .lp-onboarding {
        &-content {
            padding: 80px 16px 130px;
            height: 100dvh;
            max-height: 100dvh;
            box-sizing: border-box;
        }

        &-title {
            font-weight: 500;
            font-size: 32px;
            line-height: 1.25;
            margin-bottom: 24px;
            max-width: 75%;
            z-index: 1;
        }

        &-desc {
            font-size: 20px;
            line-height: 1.2;
            margin: 0 0 36px;
            max-width: 80%;
            z-index: 1;
        }

        &-accedi {
            margin-top: 60px;
            font-weight: 500;
            line-height: 1.38;
            z-index: 1;
        }

        .google-input {
            max-width: 560px;
            z-index: 1;
        }

        [class*='img-top-'] {
            position: absolute;
            left: 0;
        }

        [class*='img-bottom-'] {
            position: absolute;
            right: -16px;
        }

        .img-top {
            &-mobile {
                top: 80px;
                max-width: 58px;
                max-height: 90px;
            }

            &-desktop {
                top: 130px;
                max-width: 232px;
                max-height: 170px;
            }
        }

        .img-bottom {
            &-mobile {
                top: -10px;
                max-width: 75px;
                max-height: 170px;
            }

            &-desktop {
                bottom: -150px;
                max-width: 150px;
                max-height: 330px;
            }
        }

        @media (min-width: $breakpoint-large-2) {
            &-content {
                padding: 80px 0 80px;
                min-height: 340px;
                justify-content: center;
            }
            &-title {
                max-width: 54%;
                font-size: 48px;
                line-height: 56px;
            }
            &-desc {
                font-size: 24px;
                line-height: 24px;
            }
            &-accedi {
                margin-top: 34px;
            }
            [class*='img-bottom-'] {
                right: 0;
            }
        }
        @media (min-width: $breakpoint-xlarge) {
            &-title {

            }

        }
        @media (min-width: $breakpoint-xxlarge) {
            &-title {
                max-width: 80%;
            }
        }
    }


}

.device_mobile {
    .box-info-address{
        bottom: 16px;
    }

    &.browser-type__ios {
        @media screen and (orientation: landscape) {
            .box-info-address {
                display: none !important;
            }
        }
        &.browser_safari {
            //default ios version (lastest v16)
            .modal-content {
                .section-spesa-a-casa {
                    margin-bottom: 180px;
                }

                .section-estensione-servizio {
                    .form-section-wrapper {
                        height: calc(100% - 96px);
                    }

                    .bottom-section {
                        position: sticky;
                        bottom: 0;
                    }

                    @media screen and (orientation: landscape) {
                        padding-top: 0;
                        .form-section-wrapper {
                            height: initial;
                            padding-bottom: 10px;
                        }
                        .bottom-section {
                            position: relative;
                        }
                    }
                }
            }

            &.os-version__14 {
                .modal-content {
                    .section-address, .section-address-checkout {
                        @media screen and (orientation: landscape) {
                            .address {
                                span {
                                    max-width: 470px;
                                }
                            }
                        }
                    }

                    .section-spesa-a-casa {
                        @media screen and (orientation: portrait) {
                            padding-bottom: 114px;
                        }

                        @media screen and (orientation: landscape) {
                            padding-bottom: 143px;
                        }
                    }

                    .section-estensione-servizio {
                        @media screen and (orientation: portrait) {

                            .form-section-wrapper {
                                height: calc(100% - 70px);
                            }
                        }
                    }
                }
            }

            &.os-version__15 {
                .modal-content {
                    @media screen and (orientation: landscape) {
                        .section-address, .section-address-checkout {
                            .address {
                                span {
                                    max-width: 470px;
                                }
                            }
                        }
                        .section-spesa-a-casa {
                            .lista-negozi-section {
                                @media screen and (orientation: portrait) {
                                    padding-bottom: 160px;
                                }

                                @media screen and (orientation: landscape) {
                                    padding-bottom: 60px;
                                }
                            }

                        }
                    }
                }
            }
        }

        &.browser_chrome {
            .uk-modal-dialog .modal-content .section-spesa-a-casa .lista-negozi-section {
                height: calc(100dvh - 215px);
            }

            .modal-content {
                .section-address, .section-address-checkout {
                    max-width: 560px;
                }

                .section-estensione-servizio {
                    .bottom-section {
                        position: sticky;
                    }

                    @media screen and (orientation: landscape) {
                        .bottom-section {
                            position: relative;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }

    &.browser-type__android {
        @media screen and (orientation: landscape) {
            .box-info-address {
                display: none !important;
            }
        }
    }
}

.device_tablet {
    &.browser-type__ios {
        &.browser_safari {
            //default ios version (lastest v16)
            .modal-content {
                .section-estensione-servizio {
                    .form-section-wrapper {
                        height: calc(100% - 88px);
                    }
                }
            }

            &.os-version__14 {
                .modal-content {
                    .section-address, .section-address-checkout {
                        .address {
                            span {
                                max-width: 315px !important;
                            }
                        }
                    }

                    .section-spesa-a-casa {
                        @media screen and (orientation: portrait) {
                            margin-bottom: 180px;
                            padding-bottom: 0;
                        }

                        @media screen and (orientation: landscape) {
                            margin-bottom: 100px;
                            padding-bottom: 50px;
                        }
                    }
                }
            }

            &.os-version__15 {
                .modal-content {
                    .section-address, .section-address-checkout {
                        .address {
                            span {
                                max-width: 315px !important;
                            }
                        }
                    }

                    .section-spesa-a-casa {
                        @media screen and (orientation: portrait) {
                            margin-bottom: 180px;
                        }

                        @media screen and (orientation: landscape) {
                            margin-bottom: 100px;
                        }

                    }
                }
            }
        }

    }
}

.device_mobile, .device_tablet{
    .component-ModalOnboarding.type-checkout{
        .google-input{
            margin-bottom: 0 !important;
    
            .submitButton{
                opacity:0;
            }
    
            &.focused{
                margin-bottom: 5em !important;
    
                .submitButton{
                    opacity: 1;
                }
            }
        }
    }
}
