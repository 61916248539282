.component-TileBrand {
    box-shadow: 0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
    border-radius: 12px;

    a {
        text-decoration: none;
    }
    
    .uk-tile {
        box-sizing: border-box;
        padding: 16px 12px 24px;
        height: 100%;

        img {
            max-height: 48px;
        }

        &:before {
            background-image: none;
        }

        span {
            @include color-primary-black(0.8);
            text-align: center;
            font-size: 12px;
            font-weight: normal;
            line-height: 1.4;
            margin-top: 8px;
            max-width: 172px;
        }
    }
}