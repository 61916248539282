
@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';
.component-card-image-top {
}

.uk-card-type-image-top {

    display: flex;
    flex-direction: column;
    align-items: center;

    .uk-card-media-top {
        
        position: relative;
        overflow: hidden;
        height: 260px;
        width: 343px;
        max-width: 100%;
        text-align: center;
        img {
            border-radius: 12px;
            object-fit: cover;
            object-position: center center;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            max-width: 100%;
            max-height: 100%;
        }

        @media (min-width: $breakpoint-xlarge){
            width: 318px;
        }
    }
    .uk-card-body {
        width: 343px;
        max-width: 100%;
        padding: 0;
        border: none;
        border-radius: 0;
    }
    .uk-card-title {
        font-size: 18px;
        font-weight: normal;
        margin-top: 16px;
        margin-bottom: 0;
    }
    .text-container {
        margin-bottom: 16px;
    }
    .desc {
        margin: 16px 0 0;
        line-height: 1.5;
        color: $primary-gray;
        p {
            margin: 0;
        }
    }

    @media (min-width: $breakpoint-medium) {
        margin-bottom: 48px;
        .uk-card-title {
            /* font-size: 20px; */
        }
    
        .text-container {
            margin-bottom: 35px;
        }
    }
}
