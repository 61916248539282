
@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';
.component-card-delivery-day {
}

.uk-card-type-delivery-day {
    cursor: pointer;
    transition: all 0.3ms ease-in-out;
    border-radius: 12px;
    width: 112px;
    height: 112px;
    padding: 0;
    border: none;
    text-align: left;
    .uk-card-body {
        border-radius: 12px;
        box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
        padding: 16px;
        background-color: $white;
        transition: all 0.3ms ease-in-out;
        font-size: 12px;
        border: none;
        width: 112px;
        height: 112px;
    }
    .uk-card-title {
        font-size: 18px;
        font-weight: normal;
        line-height: 1.33;
        margin-bottom: 4px;
        @include color-primary-black;
    }
    .desc {
        line-height: 1.33;
        margin-bottom: 22px;
        color: #00000066;
    }

    .status {
        display: flex;
        align-items: center;
        min-height: 18px;
        margin-top: 22px;
        color: $forest;
        font-size: 12px;
        font-weight: bold;
        .point {
            border-radius: 50%;
            width: 8px;
            height: 8px;
            background-color: $forest;
            margin-left: auto;
        }
        &.out {
            color: var(---effects-02-darker-40-2);
            .point {
                background-color: var(---colour-03-semantic-02-error-2);
            }
        }
        &.warning {
            color: $barney-purple;
            .point {
                background-color: $barney-purple;
            }
        }
    }
    &:hover {
        &:not(.disabled) {
            box-shadow: 0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            transition: all 0.3ms ease-in-out;
        }
        //> * {
        //    box-shadow: 0 0 0 4px inset rgba($dusty-orange, 0.1);
        //}
    }

    &.active {
        box-shadow: 0 0 10px -2px var(---dusty-orange) !important;
        transition: all 0.3ms ease-in-out;
        > * {
            border-color: rgba($dusty-orange, 0.3);
            box-shadow: 0 0 0 4px inset rgba($dusty-orange, 0.1);
        }
        .uk-card-image {
            > * {
                color: var(---colour-01-primary-03-p-2);
            }
        }
    }

    &.disabled {
        opacity: 0.4;
        cursor: default;
        box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
    }
}
