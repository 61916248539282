@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .flex {
        flex: 1 1 auto !important;
        -ms-flex: 1 1 auto !important;
    }
    .uk {

    }


    //Component Product Card
    .component-ProductCard {
        .product-card {
            .logo-bassiefissi {
                img {
                    max-width: 48px;
                    max-height: 48px;
                }
            }
        }
    }

    @media (max-width: $breakpoint-medium) {

    }
}
