
.component-PromoIdm {

    & > div:first-child {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: $breakpoint-xlarge) {
            display: block;
        }
    }

    .section-title:before {
        bottom: -10px;
    }

    &.type-base {
        height: 478px;
        /* min-height: 478px; */
        width: 100%;
        /* max-width: 343px; */
        //padding: 24px 16px;
        border-radius: 12px;
        box-sizing: border-box;
        /* background: #fffbe6; */
        position: relative;

        .slider-placeholder {
            display: none;
        }

        @media only screen and (min-width: $breakpoint-xlarge) {
            .slider-placeholder {
                display: block;
            }
            padding: 24px 16px;
            height: 360px;
            /* min-height: unset; */
            /* max-width: 100%; */
            /* height: 100%; */
            padding: 24px;
        }


        .promo {
            @include color-primary-black();
           
            padding: 24px 16px 0;
            
            //max-height: 284px;
            @media (min-width: $breakpoint-xlarge) {
                max-height: 312px;
                height: 100%;
                padding: 0;
            }

            & > *:not(:last-child) {
                margin-bottom: 16px;
            }

            & > *:first-child {
                margin-bottom: 20px;

                @media (min-width: $breakpoint-xlarge) {
                    margin-bottom: 16px;
                }
            }

            .section-title {
                line-height: 16px;
                font-size: 12px;
                margin-bottom: 25px;
                color: $primary-black;

                b:empty:before {
                    content: "\200b";
                }
            }

            &-title {
                line-height: 40px;
                font-size: 32px;
                font-weight: 500;
                @include line-clamp(2);

                @media (min-width: $breakpoint-xlarge) {
                    font-size: 40px;
                    line-height: 48px;
                    max-width: 594px;
                }

                @media (max-width: 359px) {
                    line-height: 30px;
                    font-size: 25px;
                }
            }

            &-desc {
                @include line-clamp(3);
                font-size: 12px;
                line-height: 16px;
                @media (min-width: $breakpoint-xlarge) {
                    max-width: 594px;
                }
            }

            &-details {
                
                &.no-price {
                    display: none;
                }

                &.no-discount {
                    .promo-date {
                        display: none;
                    }

                    .discounted {
                        display: none;
                    }
                }

                &.discount {
                    .old {
                        text-decoration: line-through;
                    }
                }

                & > * {
                    font-size: 12px;
                    line-height: 16px;

                    &:first-child {
                        margin-right: 48px;
                    }

                    & > *:first-child {
                        margin-bottom: 6px;
                    }
                }

                .price {
                    line-height: 1;

                    > *:first-child {
                        font-size: 16px;
                        font-weight: 500;
                        @extend %roboto-font;
                        display: flex;
                        color: var(---colour-01-primary-01-p-2);
                    }

                    .old {
                        @include color-primary-black;
                        font-size: 12px;
                        margin-right: 6px;
                    }
                }
            }

            .button-scopri {
                border: 2px solid black;
                margin-top: 4px;
                padding: 13px 23px;
                font-size: 14px;
                line-height: 1;
                background: transparent;
                //position: absolute;
                //bottom: 0;

                @media (min-width: $breakpoint-xlarge) {
                    padding: 12px 23px;
                    margin-top: 0;
                    position: absolute;
                    bottom: 0;
                }
            }
        }

        .img-idm {
            //position: absolute;
            //bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 184px;
            object-fit: cover;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            background-position: top center;
            background-size: cover;

            @media (min-width: $breakpoint-xlarge) {
                margin-top: unset;
            }

            .wave {
                &-m {
                    margin-top: -11%;
                    /* margin-top: -15px;
                    @media (min-width: $breakpoint-medium) {
                        margin-top: -50px;
                    }
                    @media (min-width: $breakpoint-large) {
                        margin-top: -75px;
                    }
                    @media (min-width: $breakpoint-large-2) {
                        margin-top: -100px;
                    } */
                }
                &-d {
                    margin-left: -1px;
                }
            }

            &-logo {
               position: absolute;
               bottom: 8px;
               right: 8px;
               max-width: 80px;
               height: auto;
            }


            @media only screen and (min-width: $breakpoint-xlarge) {
                position: absolute;
                bottom: 0;
                max-height: 100%;
                max-width: 413px;
                right: 0;
                left: unset;
                border-bottom-left-radius: inherit;
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;

                &-logo {
                    float: right;
                    margin: 8px 16px;
                    position: unset;
                }
            }
        }

        &.type-b1 {
            @media only screen and (min-width: $breakpoint-xlarge) {

                /* min-height: 360px; */
                /* width: 623px; */
                padding: 24px;

                .promo {

                    &-title, &-desc {
                        max-width: 359px;
                    }
                }

                .img-idm {
                    height: 100%;
                    max-width: 322px;
                }
            }

        }

        &.type-b2 {
            background: #edf7fa;
            height: 360px;
            max-height: 360px;
            width: 100%;
            /* max-width: 343px; */
            padding: 24px;
            overflow: hidden;

            .slider-placeholder, .img-idm-logo {
                display: none;
            }

            .img-idm {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: right;
                max-height: 100%;

                .wave-m, .wave-d{
                    display: none;
                }

                @media screen and (max-width: $breakpoint-large-2-max){
                    position: absolute;
                    bottom: 0;
                }
            }

            .promo {
                max-height: 312px;

                @media (min-width: $breakpoint-xlarge) {
                    max-height: 100%;
                }

                &-title, &-desc, &-price {
                    margin-bottom: 8px;
                }

                &-title {
                    font-size: 20px;
                    line-height: 24px;
                }

                &-details {
                    flex-direction: column;
                    margin-bottom: 20px;
                }

                &-title, &-desc, &-details {
                    max-width: 140px;
                }

                &-price {
                    white-space: nowrap;

                    .price > *:first-child {

                        font-size: 20px;
                        line-height: 24px;

                        .old {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                }
            }

            .button-scopri {
                margin-bottom: 0;
                margin-top: 0;
            }

            @media only screen and (min-width: $breakpoint-xlarge) {

                /* width: 305px; */
                /* min-height: 360px; */

                .button-scopri {
                    /* display: none; */
                }

                .promo {

                    &-details {
                        /* display: none; */
                    }

                    &-title, &-desc {
                        max-width: 120px;
                    }

                    &-title {
                        font-size: 20px;
                        line-height: 24px;
                    }

                    &-desc {
                        @include line-clamp(2);
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }

        &.type-c {

            @media only screen and (min-width: $breakpoint-xlarge) {

                /* width: 464px; */
                /* min-height: 360px;
                max-height: unset; */
                
                .slider-placeholder {
                    display: none;
                }

                .button-scopri {
                    margin-bottom: 0;
                }

                .promo {

                    &-title, &-desc, &-details {
                        max-width: 264px;
                    }

                    &-title {
                        font-size: 30px;
                        line-height: 38px;
                    }
                }

                .img-idm {
                    height: 100%;
                    width: 100%;
                    max-width: 279px;
                }
            }
        }

        &.type-image-inside {
            svg,.wave-d {
                display: none;
                visibility: hidden;
            }

            .img-idm {
                margin: 8px;
                border-radius: 12px;
                max-width: calc(100% - 16px);
            }

            @media (min-width:$breakpoint-large) {

            }

            @media (max-width:$breakpoint-large-2-max) {
                .img-idm-logo {
                    bottom: 16px;
                    right: 16px;
                }
            }

            @media (min-width:$breakpoint-xlarge) {
                .promo {
                    max-width: calc(50% - 8px);
                }
                .img-idm {
                    top: 0;
                    max-width: calc(50% - 8px);
                    max-height: calc(100% - 16px);
                }
            }
        }
    }
}