.component-BannerListing {
    &.marchi-conad {
        .marchi-conad {
            display: flex;
        }
    }

    .marchi-conad {
        flex-direction: column;

        @media (min-width: $breakpoint-xlarge) {
            flex-direction: row;
            align-items: center;
        }

        .img-container {
            display: flex;
            justify-content: center;
            align-items: center;

            height: 200px;
            width: 100%;

            @media (min-width: $breakpoint-xlarge) {
                max-width: 25%;
            }

            img {
                max-width: 90%;
                max-height: 150px;
                @media (min-width: $breakpoint-xlarge) {
                    max-height: 80%;
                }
            }
        }

        .text {

            &-title {
                color: $primary-black;
                font-size: 32px;
                font-weight: bold;
                line-height: 1.25;
                margin: 0;

                @media (min-width: $breakpoint-xlarge) {
                    font-size: 40px;
                    line-height: 1.2;
                }
            }

            &-desc {
                color: $primary-gray;
                font-size: 16px;
                font-weight: normal;
                line-height: 1.5;
                margin-top: 16px;
            }

            &-link {
                margin-top: 8px;
                color: $blue-info;
                font-size: 12px;
                font-weight: 500;
                line-height: 1.33;

                @media (min-width: $breakpoint-xlarge) {
                    font-size: 14px;
                    line-height: 1.29;
                }
            }
        }
    }
}