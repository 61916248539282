//COLOR
$black: #000000;
$white: #ffffff;
//$orange-hover: #f27b19;
$orange-hover: #F06C00;
$yellow: #fed404;
$gray: #333333;
$primary-orange: #EB5305;
$dusty-orange: #f38933;
$blue-info: #0072ca;
$warning: #ffb602;
$danger: #fb0000;
$pin-yellow: #ffee9b;
$barely-black: #191919;
$silver: #bdbdbd;
$yellow-2: #fff8e6;
$light-grayish-blue: #e6f1fa;
$pink-light: #fde7e8;
$pink-light-2: #ffe6e6;
$very-pale-yellow: #fff7cd;
$wine: #9f0839;
$floral-white: #FBFAF6;
$primary-disabled: #D6D6D6;
$primary-disabled-text: #999999;
$green-pedestrian: #01C120;
$primary-black: #333333;
$primary-gray: #545454;
$pumpkin: #e46700;
$forest: #0f651c;
$barney-purple: #980f82;
$gray-chips: #F1F0EB;
$cosmic-latte: #fffbe6;
$cultured: #f5f5f5;
$honeydew: #e6f9e9;
$banana-yellow: #fedd36;
$gargoyle-gas: #fee04a;
$misty-rose: #ffe5e5;
$alabaster: #eff7e9;
$cultured: #f4f4f4;
$isabelline: #f5e6eb;
$bright-gray: #efefef;
$platinum: #e9e8e8;
$lavander: #e5f1fa;
$azureish-white: #cce3f5;
$lotion: #fafafa;
$red: #e20000;
$buttary-white: #FFFCEB;

//UIKIT BREAKPOINT MEDIA
$breakpoint-xxsmall: 320px;
$breakpoint-xsmall: 375px;
$breakpoint-small: 460px; //MOBILE
$breakpoint-medium: 600px; //CUSTOM
$breakpoint-large: 768px; //IPAD PORT
$breakpoint-xlarge: 1024px; //DESKTOP S
//custom
$breakpoint-large-2: 992px; //IPAD PORT MAX RES
$breakpoint-xlarge-max: ($breakpoint-large-2 - 1); //991
$breakpoint-large-2-max: ($breakpoint-xlarge - 1); //1023
$breakpoint-xxlarge: 1200px; //CUSTOM
$breakpoint-xxxlarge: 1440px; //DESKTOP
$breakpoint-xxxxlarge: 1920px; //CUSTOM
$breakpoint-xxlarge-max: ($breakpoint-xxxlarge - 1); //1439
$breakpoint-xxxlarge-max: ($breakpoint-xxxxlarge - 1); //1919


//UIKIT GRID GENERAL
$container-max-width: 1344px;
//$container-small-max-width:                       620px;
$container-padding-horizontal: 16px;
$container-padding-horizontal-s: 16px;
$container-padding-horizontal-m: 16px;
$container-padding-horizontal-l: 32px;
$container-padding-horizontal-xl: 40px;

$grid-gutter-horizontal:                            24px;
$grid-gutter-horizontal-l:                          24px;
$grid-small-gutter-horizontal:                      16px;
$background-secondary-background:                   var(---colour-02-secondary-01-s-2);

//UIKIT GLOBAL
$global-font-family:                                "DM Sans", sans-serif;
$global-link-color:                                 var(---colour-03-semantic-04-inform-2);
$global-link-hover-color:                           var(---colour-03-semantic-04-inform-2);
$global-primary-background:                         $primary-orange;
$global-medium-box-shadow:                          0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
$global-color:                                      $primary-black;
$base-heading-color:                                $primary-black;


//UIKIT BUTTON
$button-line-height:                                56px;
$button-small-line-height:                          40px;
$button-large-line-height:                          80px;
$button-padding-horizontal:                         32px;
$button-small-padding-horizontal:                   24px;
$button-large-padding-horizontal:                   48px;
$button-link-color:                                 $global-link-color;

$button-primary-background:                         $primary-orange;
$button-primary-color:                              $white;
$button-primary-hover-background:                   $orange-hover;
$button-primary-active-background:                  $orange-hover;
$button-primary-active-color:                       $white;

$button-default-background:                         $white;
$button-default-color:                              $primary-black;
$button-default-hover-background:                   $white;
$button-default-active-background:                  $white;
$button-default-active-color:                       $primary-black;

$button-secondary-background:                       $green-pedestrian;
$button-secondary-color:                            $white;
$button-secondary-hover-background:                 $green-pedestrian;
$button-secondary-hover-color:                      $white;
$button-secondary-active-background:                $green-pedestrian;
$button-secondary-active-color:                     $white;

$button-tertiary-background:                        var(---colour-01-primary-02-p-2);
$button-tertiary-color:                             $black;
//$button-tertiary-hover-background:                  $green-pedestrian;
$button-tertiary-hover-color:                       $black;
//$button-tertiary-active-background:                 $green-pedestrian;
$button-tertiary-active-color:                      $black;

$button-disabled-background:                        $primary-disabled;
//var(---effects-01-opacity-05-2);
$button-disabled-color:                             $primary-disabled-text;


//UIKIT NAV
$navbar-nav-item-height: 64px;
$navbar-nav-item-padding-horizontal: 12px;
$navbar-nav-item-color: $primary-black;
$navbar-nav-item-font-size: 16px;
$navbar-nav-item-hover-color: $primary-black;
$navbar-nav-item-onclick-color: $primary-black;
$navbar-nav-item-active-color: $primary-black;
$card-title-line-height: inherit;
$nav-item-padding-vertical: 5px;
$nav-item-padding-horizontal: 0;

//UIKIT CARD
$card-body-padding-horizontal: 24px;
$card-body-padding-vertical: 24px;
$card-body-padding-horizontal-l: 24px;
$card-body-padding-vertical-l: 24px;
$card-badge-top: 16px;
$card-badge-right: inherit;


//UIKIT FORM
$form-stacked-margin-bottom:                        6px;
$form-background:                                   $white;
$form-color: $primary-black;
$form-success-border: $green-pedestrian;
$form-danger-border: var(---colour-03-semantic-02-error-2);
$form-placeholder-color: $primary-black;
$form-large-height: 56px;
$form-large-font-size: 17px;
$form-large-padding-horizontal: 16px;
$form-icon-width: 60px;
$form-icon-color: $primary-black;
$form-icon-hover-color: $primary-black;
$form-radio-size: 20px;
$form-radio-background: $white;
$form-radio-checked-background: $white;
$form-radio-checked-icon-color: $white;
$form-radio-checked-focus-background: $white;
$internal-form-checkbox-image: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22#000%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A";
$search-placeholder-color: $primary-black;
$search-icon-color: $primary-black;
$search-default-width: 100%;
$search-default-height: 56px;
$search-default-background: $white;
$search-default-padding-horizontal: 16px;
$search-default-icon-width: 56px;


//UIKIT ACCORDION
$accordion-title-font-size: 16px;
$accordion-title-line-height: 1;
$accordion-title-hover-color: rgba($black, .8);
$accordion-item-margin-top: 8px;

//UIKIT TILE
$tile-padding-horizontal: 16px;
$tile-padding-horizontal-s: 16px;
$tile-padding-horizontal-m: 16px;
$tile-padding-vertical: 10px;
$tile-padding-vertical-m: 10px;

//UIKIT TAB

//UIKIT BADGE
$badge-size: 24px;
$badge-padding-vertical: 0 !default;
$badge-padding-horizontal: 16px;
$badge-border-radius: 24px;
$badge-background: $primary-disabled;
$badge-color: rgba($black, .8);
$badge-font-size:                                   12px;
$badge-hover-color:                                 rgba($black, .8);

//UIKIT OFFCANVAS
$close-color:                                       rgba($black, .8);
$offcanvas-bar-width:                               100%;
$offcanvas-bar-padding-vertical:                    0;
$offcanvas-bar-padding-horizontal:                  0;
$offcanvas-bar-background:                          $white;
$offcanvas-bar-color-mode:                          dark;
$offcanvas-bar-width-m:                             100%;
$offcanvas-bar-width-l:                             720px; //custom
$offcanvas-bar-padding-vertical-m:                  0;
$offcanvas-bar-padding-horizontal-m:                0;
$offcanvas-close-position:                          20px !default;
$offcanvas-close-padding:                           5px !default;
$offcanvas-overlay-background:                      var(---effects-01-opacity-80-2);
$offcanvas-z-index: 2000;


//UIKIT SLIDER

//UIKIT SUBNAV PILL
$subnav-margin-horizontal: 16px;
$subnav-pill-item-padding-horizontal: 14px;
$subnav-pill-item-color: $primary-disabled-text;
$subnav-pill-item-hover-background: transparent;
$subnav-pill-item-hover-color: rgba($black, .6);
$subnav-pill-item-onclick-background: transparent;
$subnav-pill-item-onclick-color: rgba($black, .6);
$subnav-pill-item-active-background: transparent;
$subnav-pill-item-active-color: rgba($black, .6);

//UIKIT SPINNER

//UIKIT MODAL
$modal-background: var(---effects-01-opacity-80-2);
$modal-padding-horizontal: 16px;
$modal-padding-horizontal-s: 16px;
$modal-padding-horizontal-m: 16px;
$modal-padding-vertical: 24px;
$modal-padding-vertical-s: 24px;
$modal-dialog-width: 660px !default;
$modal-dialog-background: $white;
$modal-body-padding-horizontal: 16px;
$modal-body-padding-vertical: 24px;
$modal-header-padding-horizontal: 16px;
$modal-header-background: $white;
$modal-footer-padding-horizontal: 16px;
$modal-footer-padding-vertical: 24px;
$modal-footer-background: $white;
$modal-title-font-size: 20px;
$modal-title-line-height: 1.2;
//$modal-close-position: $global-small-margin;
$modal-close-padding: 10px;
$modal-z-index: 3000;

//UIKIT ALERT
$alert-margin-vertical: 10px;
$alert-padding: 15px;
$alert-padding-right: 8px;
$alert-background: rgba($blue-info, 0.1);
$alert-color: $blue-info;
$alert-close-top: 11px;
$alert-close-right: 9px;
$alert-primary-background: rgba($blue-info, 0.1);
$alert-primary-color: $blue-info;
$alert-warning-background: $yellow-2;
$alert-warning-color: rgba($black, 0.6);
$alert-danger-background: rgba($danger, 0.1);
$alert-danger-color: $danger;
$alert-success-color: var(---colour-02-secondary-02-s-2);
$alert-success-background: #eff8e9;

//SWITCH
$switch-width: 40px;
$switch-height: 24px;
$switch-bullet-distance: 2px;
$switch-bullet-size: calc(#{$switch-height} - #{$switch-bullet-distance + $switch-bullet-distance});

//SWITCH VERSIONE SMALL
$switch-width-small: 28px;
$switch-height-small: 16px;
$switch-bullet-distance-small: 3px;
$switch-bullet-size-small: calc(#{$switch-height-small} - #{$switch-bullet-distance-small + $switch-bullet-distance-small});


//BREADCRUMB
$breadcrumb-item-font-size: 12px;
$breadcrumb-divider-font-size: 12px;
$breadcrumb-divider-margin-horizontal: 16px;
$breadcrumb-divider-color: $primary-black;
$breadcrumb-item-color: $primary-black;
$breadcrumb-item-active-color: $primary-black;

//UIKIT PAGINATION
$pagination-item-active-color: $primary-black;
$pagination-item-color: $primary-black;
$pagination-item-padding-vertical: 4px;
$pagination-item-padding-horizontal: 9px;

//UIKIT DROPDOWN
$dropdown-background: $white;

//UIKIT TOOLTIP
$tooltip-background: $gray;
$tooltip-color: $white;
$tooltip-max-width: 292px;
$tooltip-padding-horizontal: 16px;
$tooltip-padding-vertical: 8px;
$tooltip-font-size: 13px;
$tooltip-border-radius: 8px;
$tooltip-z-index: 10000;

//UIKIT NOTIFICATION
$notification-message-font-size: 14px;
$notification-message-primary-color: $white;
$notification-message-background: $blue-info;
$notification-z-index: 999999;
