.component-ShopIdm {
    .section-partner {
        .title {
            float: left;
            font-size: 20px;
            line-height: 1.4;
        }

        .link-partner {
            float: right;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.29;
        }

        .section-body {
            display: flex;
            flex-direction: column;
            @media (min-width: $breakpoint-xlarge){
                flex-direction: row;
            }
            width: calc(100% + 8px);
            margin-left: -4px;
        }

        .component-TilePartner {

            margin-bottom: 16px;

            a {
                width: 100%;
            }
        }


        .swiper-slide {
            max-width: 100%;

            @media (min-width: $breakpoint-xlarge) {
                flex-shrink: 1;
                max-width: calc(50% - 4px);

                &:not(:first-child) {
                    margin-left: 4px;
                }
                &:not(:last-child) {
                    margin-right: 4px;
                }
            }
        }
    }
}