.cards-review {
    .swiper-slide {
        @media (min-width: $breakpoint-large) {
            min-height: 300px !important;
        }

        .review {
            transition: all .3s;

            > * {
                opacity: 0;
                transform: translateY(30px);
                transition: all .5s;
            }
        }

        &-active {
            .review {
                > * {
                    opacity: 1;
                    transform: none;
                }

                @for $i from 0 to 15 {
                    &:nth-child(#{$i + 1}) {
                        transition-delay: $i * 0.1 + 0.5s;
                    }
                }
            }
        }
    }

    .swiper-wrapper {
        padding-bottom: 90px;
        @media (min-width: $breakpoint-large) {
            padding-bottom: 100px;
        }
    }

    .swiper-pagination {
        text-align: center;
        bottom: 0px;
    }
}