.component-BannerSponsorSearch {
    text-decoration: none;

    &:hover{
        text-decoration: none;

        * {
            text-decoration: none;
        }
    }
    @media (min-width: $breakpoint-large-2) {
        width: 100%;
    }

    .content-wrapper {
        display: flex;
        justify-content: space-between;
        border-radius: 12px;
        height: 270px;

        * {
            flex-grow : 0;
        }

        @media (min-width: $breakpoint-large-2){
            height: 141px;
        }

        .info-text {
            padding: 24px 0 24px 24px;
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: calc(100% - 163px);
            box-sizing: border-box;

            @media (min-width: $breakpoint-large-2) {
                flex-direction: row;
                max-width: calc(100% - 304px);
                padding: 27.3px 31.5px 13.5px 24px;
                align-items: center;
            }
            
            .text {
                
                @media (min-width: $breakpoint-large-2){
                    max-width: 455px;
                }

                &-container {
                    display: flex;
                    flex-direction: column;
    
                    @media (min-width: $breakpoint-large-2) {
                        flex-direction: column-reverse;
                        padding-right: 35.5px;
                    }

                    .subtitle {
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba($black, .4);
                        margin-bottom: 8px;
                        line-height: 1;

                        @media (min-width: $breakpoint-large-2) {
                            font-weight: 500;
                            margin-bottom: 14.4px;
                        }
                    }
    
                    .title {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.25;
                        color: rgba($black, .8);
                        margin-bottom: 11px;

                        @media (min-width: $breakpoint-large-2) {
                            margin-bottom: 10px;
                            font-size: 20px;
                            line-height: 1.2;

                        }
                    }
                }
                
                .cta-desktop {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.33;
                }
            }

            .product-info {

                @media (min-width: $breakpoint-large-2) {
                    padding-left: 24px;
                    border-left: 1px solid #333;
                }

                .product-title {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.33;
                    text-decoration: underline;
                    color: rgba($black, .6);
                    margin-bottom: 4px;
                }

                .product-price {
                    width: 111px;
                    > * {
                        display: flex;
                        flex-direction: row;
                        justify-content: right;
                        align-items: flex-start;

                        @media (min-width: $breakpoint-large-2) {
                            align-items: flex-end;
                        }
                    }

                    .price-full {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.25;
                        color:rgba($black, .8);
                        text-decoration: line-through;
                    }

                    .price {

                        &-red{
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 1.2;
                            color: #e20714;
                            margin: 0 10px 4px 4px;

                            @media (min-width: $breakpoint-large-2) {
                                margin: 0 7px;
                            }
                        }

                        &-black{
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 1.2;
                            color: rgba($black, .8);
                            margin: 0 10px 4px 4px;

                            @media (min-width: $breakpoint-large-2) {
                                margin: 0 7px;
                        }
                        }
                        
                    }

                    .price-kg {
                        font-size: 12px;
                        line-height: 1.33;
                        color: rgba($black, .6);
                        margin-bottom: 11px;

                        @media (min-width: $breakpoint-large-2) {
                            margin: 4px 0 0;
                        }
                    }
                }
            }

            .cta-mobile {
                font-size: 12px;
                font-weight: 500;
                line-height: 1.33;
            }
        }

        .img-container {
            border-radius: 0 12px 12px 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
            width: 163px;

            @media (min-width: $breakpoint-large-2) {
                width: 304px;
            }

            img {
                position: absolute;
                max-width: 87px;
                max-height: 56px;
                top: 16px;
                right: 16px;
            }
        }
    }
}