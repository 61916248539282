.component-Ispirazionale {
    .section-lasciati-ispirare {

        .section-body {

            .main-container {
                display: flex;
                flex-direction: column;

                @media (min-width: $breakpoint-xlarge) {
                    flex-direction: row;
                }
            }

            .img-container {
                /* margin-bottom: 24px; */
                border-radius: 12px;
                min-width: 100%;
                height: 384px;
                background-position: center center;
                background-size: contain;
                background-repeat: no-repeat;

                @media (min-width: $breakpoint-xlarge) {
                    min-width: 464px;
                    width: calc(100% - 478px);
                }
            }


            .swiper-lasciati-ispirare {
                overflow: visible;
                padding: 24px 0;
                width: 100%;


                @media (min-width: $breakpoint-xlarge) {
                    padding: 0 16px;
                    flex-direction: column;
                    width: unset;
                    height: 384px;
                    overflow: hidden;
                }
                .component-ProductCardLastView {
                    height: 84px;
                    
                    .uk-width-1-1:hover {
                        box-shadow: 0 0 10px -2px $dusty-orange;
                        border-radius: 12px;
                    }
                }
                
            }

            .box-overflow {
                height: 384px;
                margin-left: 16px;
                padding: 5px 7px;
                margin-top: -5px;

                .component-ProductCardLastView {
                    margin-bottom: 8px;
                    
                    .uk-width-1-1:hover {
                        box-shadow: 0 0 10px -2px $dusty-orange;
                        border-radius: 12px;
                    }
                }
            }
        }
    }
}