.page-Landingpage {
    .breadcrumb-container {
        padding-top: 32px;
        height: 16px;
        position:relative;
        z-index: 2;
    }

    .uk-switcher {
        label[for='pdv'] {
            font-weight: 500;
        }
    }

    .main-content {
        position: relative;
        padding-top: 0;

        .uk-container {
            .section-description {
                color: $primary-black;
            }
        }

        /* .component-Feedback {
            z-index: 1;

            a.uk-alert.uk-button-primary-outline {
                box-shadow: 0 0 0 2px var(---colour-03-semantic-04-inform-2);
                color: var(---colour-03-semantic-04-inform-2);
                right: 10px;
                position: absolute;
                line-height: 38px;
                padding: 0 17px;
                background-color: transparent;
                top: 50%;
                margin-top: -19px;
                font-weight: 500;
            }
        } */

        .uk-sticky-fixed {
            .component-Feedback {
                margin-top: 0;
            }
        }
    }

    .section-1 {
        padding-bottom: 12px !important;
    }

    .section-2 {
        padding-top: 12px !important;
    }

    .top-content {
        &.landing-detail {
            padding-top: 90px;
            @media (min-width: $breakpoint-xlarge) {
                margin-top: -47px;
                padding-top: 80px;
            }
        }

        .left-col {
            @media (min-width: $breakpoint-xlarge) {
                max-width: 50%;
            }
        }

        .img-no-region {
            flex: 1 1;
            min-width: 1px;
            text-align: center;
            img {
                max-width: 340px;
                max-height: 387px;
                @media (min-width: $breakpoint-xxxlarge) {
                    max-width: 370px;
                    max-height: 424px;
                }
            }
        }

        .img-with-region {
            position: absolute;
            top: 0;
            right: 0;
            width: 565px;
            height: 405px;
            pointer-events: none;
            @media (min-width: $breakpoint-xxxlarge){
                width: 712px;
                height: 552px;
            }
            &-mobile {
                position: absolute;
                top: 0;
                right: 0;
                margin: -47px auto 0;
                width: 180px;
                height: 140px;

                @media (min-width: $breakpoint-large){
                    width: 296px;
                    height: 230px;
                }

                img{
                    height: 100%;
                    width: 100%;
                }
            }

            img{
                height: 100%;
                width: 100%;
            }
            
        }

        form.google-input {
            max-width: 600px;
        }

        .landing-header {

            .header {

                &-title {
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 1.25;
                    margin: 19px 0 16px;

                    @media (min-width: $breakpoint-large) {
                        font-size: 40px;
                        line-height: 1.2;
                    }
                }

                &-desc {
                    font-size: 16px;
                    color: $primary-black;
                    line-height: 1.5;
                    width: 100%;
                    margin-top: 0;
                    margin-bottom: 0;

                    @media (min-width: $breakpoint-large) {
                        max-width: 523px;
                        margin-bottom: -11px;
                    }

                    @media (min-width: $breakpoint-large) {
                        max-width: 414px;
                        margin-bottom: -16px;
                    }

                }
            }

            .link-approfondisci {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.29;
                color: $blue-info;
                display: block;
                margin: -10px 0 32px;

                @media (min-width: $breakpoint-large) {
                    margin: 0;
                    display: inline;
                }
                
            }

            &.loaded-region {
                width: 100%;
                max-width: 414px;
            }
        }
        
        .section-address{
            .title{
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 16px;
                margin-top: 32px;
                @media (min-width: $breakpoint-large) {
                    font-size: 32px;
                    font-weight: normal;
                    margin-bottom: 8px;
                    margin-top: 40px;
                    
                }
            }
            .subtitle{
                margin: 0 0 16px;
                @media (min-width: $breakpoint-large) {
                    margin: 0 0 32px;
                }
                
            }
        }
    }

    .section-region {
        padding-bottom: 17px;

        & > * {
            hr {
                margin-top: 17px;
            }
        }

        &:first-child {
            margin-top: 29px;

            @media (min-width: $breakpoint-xlarge) {
                margin-top: -18px;
            }
        }

        &:after {
            bottom: -13px;
        }

        &.uk-open {
            &:after {
                bottom: -23px;
            }
        }

        &.general-paragraph {
            margin-top: 40px;
            margin-bottom: 40px;

            &:first-child {
                margin-top: 80px;
            }

            .section-title {
                margin-bottom: 44px;
            }
        }

        .uk-accordion-content {
            margin-bottom: 45px;
        }

        .uk-accordion-title {
            font-size: 14px;
            text-decoration: none;
            line-height: 14px;
            font-weight: 500;

            &:before {
                font-size: 24px;
                width: min-content;
                height: min-content;
            }
        }

        .component-CardSimple {
            margin-bottom: 16px;
            .card {
                img {
                    border-radius: 50%;
                }
            }


            @media (min-width: $breakpoint-large) {
                width: calc((100% - 32px) * 1 / 2);

                &:nth-child(odd) {
                    margin-right: 16px;
                }

                &:nth-child(even) {
                    margin-right: 0;
                }
            }

            @media (min-width: $breakpoint-xlarge) {

                width: calc((100% - 32px) * 1 / 3);

                &:nth-child(3n) {
                    margin-right: 0;
                }

                &:nth-child(3n-1), &:nth-child(3n-2) {
                    margin-right: 16px;
                }
            }

        }

        .component-CardSimple:last-child {
            margin-bottom: 0;
        }

        @media (min-width: $breakpoint-large) {
            .component-CardSimple {
                margin-bottom: 32px;
            }
            &::after {
                bottom: -32px;
            }
            .uk-accordion-content {
                display: flex !important;
                flex-wrap: wrap;
                margin-top: -30px;
                margin-bottom: 0;
            }
        }
        @media (min-width: $breakpoint-xlarge) {
            &::after {
                bottom: 0px;
            }
        }
    }

    .section-stores {
        padding-top: 0;
    }
    .list-card-store {

        li {
            margin-top: 0;
        }

        .component-card-store {
            margin-top: 56px;

            .uk-card-body {
                box-shadow: unset;
                border-radius: unset;
            }
        }
        .uk-card-type-store .info-box {
            margin-top: 8px;
            i {
                top: -1px;
            }
        }
        @media (min-width: $breakpoint-large) {
            .uk-card-type-store .info-box {
                margin-top: 13px;
            }
        }
    }

    /* Pagina landingpage pillar strategici */
    .section-info-top-content {

        @media (max-width: $breakpoint-large-2-max){
            padding-top: 122px;
        }

        .img-principale {
            position: absolute;
            top: 0;
            right: 0;
            width: 180px;
            height: 140px;
            text-align: center;
            margin-bottom: 40px;
            border-radius: 50%;
            
            img {
                height: 100%;
                max-width: 100%;
                float: right;
            }

            @media (min-width: $breakpoint-xlarge){
                width: 565px;
                height: 405px;
            }

            @media (min-width: $breakpoint-xxxlarge){
                width: 712px;
                height: 552px;
            }
        }

        .landing-header {
            .header-desc {
                color: $primary-gray;
            }
            @media (min-width: $breakpoint-xlarge) {
                width: 100%;
                max-width: 414px;

                .header-title {
                    font-size: 40px;
                    font-weight: bold;
                }

                .header-desc {
                    line-height: 24px;
                }
            }
        }

        @media (min-width: $breakpoint-xlarge) {
            .img {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        @media (min-width: $breakpoint-xxxlarge) {
            .landing-header {
                width: 100%;
                max-width: 556px;
            }
        }

        @media (min-width: $breakpoint-xxxxlarge) {
            .landing-header {
                width: 100%;
                max-width: 756px;
            }
        }
    }

    .section-info-bottom-content {
        .description {
            color: $primary-gray;
        }
        @media (min-width: $breakpoint-xlarge) {
            .description {
                max-width: 70%;
            }
        }
    }

    .section-learn-more {
        .component-LearnMore {
            margin: 0;
            width: 100% !important;

            @media (min-width: $breakpoint-xlarge) {
                max-width: calc(100% - 200px);
            }

            @media (min-width: $breakpoint-xxxlarge) {
                max-width: calc(100% - 550px);
            }

            @media (min-width: $breakpoint-xxxlarge) {
                max-width: calc(100% - 800px);
            }
        }
    }
}
