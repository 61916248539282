.component-faqSectionCategories{
    .section-categories {

        margin: 0;
        padding: 0;

        @media (min-width: $breakpoint-xlarge) {
            padding: 40px 0;
        }

        .title {
            color: $primary-black;
            font-size: 20px;
            line-height: 1.2;
            font-weight: bold;
            margin: 0 0 7px;
            padding: 40px 0 24px;

            @media (min-width: $breakpoint-xlarge) {
                font-size: 24px;
                line-height: 1.33;
            }
        }

        .card-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            /* padding: 20px 0; */

            .component-CardSimple {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                @media (min-width: $breakpoint-xlarge) {
                    width: calc((100% - 32px) / 3);
    
                    &:nth-child(3n) {
                        margin-right: 0;
                        margin-left: 8px;
                    }
    
                    &:nth-child(3n-1) {
                        margin-right: 8px;
                        margin-left: 8px;
                    }
    
                    &:nth-child(3n-2) {
                        margin-right: 8px;
                        margin-left: 0;
                    }
                }
            }

        }
    }
}