.component-MarchiConad {
    .section-scopri-marchi {

        &-mobile {

            .swiper-scopri-marchi-mobile {
                padding-bottom: 40px;
                overflow: hidden;
                margin-left: 0;
            }

            .section-title {
                font-size: 20px;
                margin-bottom: 39px;
            }

            .section-title:before {
                bottom: -16px !important;
                width: 30px !important;
            }

            .pagination-marchi-mob {
                bottom: -7px;
                left: 2px;
            }

            .swiper-pagination-bullet {
                margin: 0 4px;
                opacity: 1;
                width: 16px;
                height: 16px;
                position: relative;
                background: transparent;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                &:before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 4px;
                    background-color: $black;
                    border-radius: 50%;
                }

                &-active {
                    &:before {
                        width: 16px;
                        height: 16px;
                        background-color: $yellow;
                    }
                }
            }
        }

        &-desktop {
            .swiper-scopri-marchi-desktop {
                padding-bottom: 32px !important;
            }

            .section-title:before {
                bottom: -18px !important;
                width: 30px !important;
            }

            .scrollbar-marchi-des {
                width: 50% !important;
                left: 25% !important;
            }

            .pagination-marchi-des {
                top: 6px !important;
                right: 44px !important;
            }

            .next-marchi-des {
                top: 8px !important;
                right: 6px !important;
                text-decoration: none;
                &:hover {
                    color: $black;
                }
            }

            .prev-marchi-des {
                top: 8px !important;
                right: 40px !important;
                text-decoration: none;
                &:hover {
                    color: $black;
                }
            }

            .swiper-wrapper {
                margin-top: 5px !important;
            }

        }
    }
}