.page-Checkout:not(.page-CheckoutGuest) {
    background-color: $cultured;

    //rework bg ui
    .section-step,
    .section-codice-sconto .uk-card-body,
    .box-service,
    .box-scontrino {
        background-color: $white;
    }

    .select-native-ui-floor {

        select:not(:invalid) {
            line-height: 42px;
            padding: 12px 14px 0px;
        }

        select.uk-form-danger {
            border-color: $form-danger-border;
        }

        select:invalid {
            color: rgba(0, 0, 0, 0.6);
        }

        option:first-child {
            display: none !important;
        }
    }

    .form-phone-alert {
        font-weight: bold;
        font-size: 12px;
        color: #666666;
        padding-left: 16px;
    }

    .icon-locked {
        font-size: 12px !important;
    }

    .tooltiptext-drop {
        max-width: 250px;

        .tooltiptext {
            padding: 6px 12px;
            font-weight: normal;
        }
    }

    #embedded-service {
        display: none !important;
        visibility: hidden !important;
    }


    //edit mode ordine
    &.edit-mode {
        .section-codice-sconto {
            display: none;
        }
        
        .section-consegna,
        .section-giorno-orario {

            .title .edit {
                display: none !important;
            }
        }

        .section-pagamento {
            .payment-edit {
                &:first-child {
                    color: #333333B3;
                }

                .uk-form-controls {
                    label:not(:last-child) {
                        margin-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
        }
    }

    //fix global for message error
    .uk-label-float {
        margin-bottom: 24px;

        .message-error {
            font-size: 12px;
            bottom: -32px;
            @media (min-width: $breakpoint-large) {
                font-size: 14px;
            }
        }
    }

    //header simply
    .component-header {
        .bar-menu {
            display: none;
        }

        .bar-header {
            .btn-menu, .uk-navbar-right {
                display: none;
            }
        }

        @media (min-width: $breakpoint-xlarge) {
            padding-bottom: 16px;
        }
    }


    //breadcrumb
    .component-Breadcrumb {
        margin-bottom: 20px;
        @media (min-width: $breakpoint-xlarge) {
            margin-bottom: 24px;
        }
    }

    .main-content {
        padding-top: 24px;
        padding-bottom: 50px;
        @media (min-width: $breakpoint-xlarge) {
            padding-bottom: 30px;
        }
    }

    .accordion-align {
        flex-direction: column;
        @media (min-width: $breakpoint-xlarge) {
            flex-direction: row;
        }
    }

    .section {
        //section codice sconto
        &-codice-sconto {
            margin-bottom: 12px;

            .uk-accordion-title {
                line-height: 30px;
                font-size: 20px;
                font-weight: bold;

                .info {
                    font-weight: normal;
                }

                a {
                    text-decoration: none;
                }


            }

            .uk-alert {
                margin-bottom: 16px;

                &-button-action > p {
                    padding-right: 36px;
                }

                .icon-check {
                    font-size: 16px;
                    color: $white;
                    background: var(---colour-03-semantic-01-succes-2);
                }

                &-danger {
                    .icon-check {
                        background: $danger;
                    }
                }

                &-hide{
                    position: absolute;
                    right: 9px;
                }
            }

            .coupons-available {
                font-size: 14px;
                line-height: 1.43;
                border-bottom: 1px solid #e6e6e6;
                margin-bottom: 16px;
                padding-bottom: 16px;
                margin-top: 16px;
                @media (min-width: $breakpoint-xlarge) {
                    margin-top: 0;
                }
                @include color-primary-black();

                a {
                    font-weight: 500;
                }
            }

            .coupon-applied-list {
                margin-bottom: -10px;
                margin-top: 8px;

                .uk-tag {
                    margin-bottom: 10px;
                    position: relative;
                    padding-right: 35px;
                    padding-left: 25px;
                    text-align: center;

                    a {
                        @include color-primary-black();
                        border-radius: 50%;
                        display: flex;
                        text-decoration: none;
                        background-color: rgba($black, 0.2);
                        position: absolute;
                        top: 5px;
                        font-size: 15px;
                        right: 5px;
                        padding: 4px;
                    }
                }
            }

            @media (min-width: $breakpoint-xlarge) {
                margin-top: 0px;
                margin-bottom: 16px;
            }
        }

        //section step 1 ORitira Dati di contatto
        &-contact-details {
            .form-contactPhone, .form-contactName, .form-contactSurname, .form-contactEmail {
                font-weight: 500;

                .uk-form-icon {
                    display: none;

                    box-sizing: border-box;
                    text-align: center;
                    line-height: 50px;

                    * {
                        font-size: 15px;
                    }
                }

                &.blocked {
                    input {
                        border-color: $primary-disabled;
                        background-color: $white;
                    }

                    .uk-form-icon {
                        display: block;
                    }
                }
            }
        }

        //section step 1,2,3
        &-step {
            position: relative;
            margin-top: 12px;
            padding: 14px;
            border-radius: 12px;
            box-shadow: 0 1px 1px 0 rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            @media (min-width: $breakpoint-xlarge) {
                margin-top: 14px;
                padding: 24px;
            }

            .lds-ring {
                left: 0;
                top: 0;
                background: rgba($white, 0.90);
                width: 100%;
                height: 100%;
                position: absolute;
                border-radius: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .form-container {
                display: none;

                .uk-label-float input:placeholder-shown + label {
                    font-weight: 500;
                }

                .msg-error-street-address {
                    .label {
                        font-weight: 700;
                        top: 3px !important;
                    }
                }

                .form-address {

                    .uk-form-icon-flip {
                        &.remove {
                            right: 10px;
                        }

                        ~ .uk-input {
                            padding-right: 88px;
                        }

                        ~ .location-finder.street-number-visible {
                            padding-right: 140px !important;

                            @media (min-width: $breakpoint-large) {
                                padding-right: 200px !important;
                            }
                        }
                    }

                    .location-finder-number {
                        position: absolute;
                        width: 95px;
                        min-height: 52px;
                        height: 52px;
                        right: 55px;
                        bottom: 2px;
                        padding-left: 16px !important;
                        padding-right: 16px !important;

                        &-label {
                            position: absolute;
                            width: 98px;
                            right: 47px;
                            top: 0;

                            @media (min-width: $breakpoint-large) {
                                right: 79px;
                            }
                        }

                        &:focus, &:not(:placeholder-shown) {
                            + .location-finder-number-label {
                                transform: translate(16px, 6px);
                            }
                        }

                        @media (min-width: $breakpoint-large) {
                            width: 115px;
                        }
                    }

                    &.not-completed {
                        margin-bottom: 45px;

                        .msg-error-street-address {
                            bottom: -38px;
                        }
                    }
                }

                .uk-switch {
                    &-slider {
                        box-shadow: 0 0 0 2px $primary-orange;
                    }

                    input:not(:checked) + .uk-switch-slider {
                        box-shadow: 0 0 0 2px var(---effects-01-opacity-30-2);

                        &:before {
                            background-color: var(---effects-01-opacity-30-2);
                        }
                    }

                    input[disabled] + .uk-switch-slider {
                        opacity: 0.3;
                    }
                }

                &-bottom {
                    display: flex;
                    flex-direction: column;

                    .manage-preferite-address {
                        display: flex;
                        margin-bottom: 10px;

                        .toggle {
                            top: 1px;
                            margin-right: 10px;
                        }

                        .label {
                            @include color-primary-black();
                            line-height: 18px;
                        }
                    }

                    @media (min-width: $breakpoint-xlarge) {
                        flex-direction: row;
                        align-items: flex-end;
                        .manage-preferite-address {
                            .toggle {
                                top: -4px;
                            }
                        }
                    }
                }

                .form-phone.blocked, .form-email.blocked {
                    input {
                        border-color: $primary-disabled;
                        background-color: $white;
                    }
                }
            }

            .store {
                display: none;
            }

            &.disabled {
                .title {
                    color: $primary-black;
                }
            }

            &.complete {
                .title {
                    .edit {
                        display: block;
                    }
                }

                .reserved {
                    display: block !important;
                }

                .store:not(.reserved) {

                }
            }

            &.open {
                box-shadow: 0 0 10px -2px var(---dusty-orange);

                .title {
                    @include color-primary-black();
                    margin-bottom: 8px;
                    @media (min-width: $breakpoint-xlarge) {
                        margin-bottom: 16px;
                    }
                }

                .store,
                .form-container {
                    display: block;
                }

                .reserved,
                .store-summary {
                    display: none;
                }
            }

            .title {
                font-size: 20px;
                font-weight: bold;
                margin: 0;
                position: relative;
                display: flex;
                @include color-primary-black();

                .edit {
                    display: none;

                }

            }

            .edit {
                font-size: 14px;
                font-weight: 500;
                margin-left: auto;
                line-height: 2.1;
                @media (min-width: $breakpoint-xlarge) {
                    font-size: 14px;
                }
            }

            .reserved {
                display: none;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                margin-top: 9px;
                @include color-primary-black();
                @media (min-width: $breakpoint-xlarge) {

                }
            }

            .store {
                font-size: 14px;
                margin-bottom: 28px;
                border: 1px solid var(---effects-01-opacity-20-2);
                padding: 16px;
                border-radius: 16px;
                @include color-primary-black();

                .negozio-consegna, .negozio-spesa {
                    margin-bottom: 10px;
                }

                .cta-section {
                    .separator {
                        border-left: 1px solid var(---effects-01-opacity-20-2);
                        margin: 0 10px;
                    }

                    .edit {
                        margin-left: 0;
                        line-height: 1.29;
                        font-size: 14px;
                    }
                }

                .opzioni-modifica-indirizzo{
                    .separator{
                        display: none;
                    }

                    .scegli-indirizzo{
                        display: none;
                    }
                }

                .label {
                    color: #333333B3;
                    margin-bottom: 8px;
                }

                .desc {
                    flex-direction: column;
                }

                @media (min-width: $breakpoint-xlarge) {
                    margin-bottom: 16px;
                    .desc {
                        flex-direction: row;
                        align-items: flex-end;
                    }
                }
            }


            //FORM
            .form {
                &-container {
                    .uk-flex {
                        > [class*='form-'] {
                            + [class*='form-'] {
                                margin-left: 12px;
                            }
                        }

                        @media (max-width: $breakpoint-small-max) {
                            &.uk-child-width-1-2 {
                                flex-direction: column;

                                > * {
                                    width: 100%;
                                    margin-left: 0 !important;
                                }
                            }
                        }
                    }

                    .uk-form-icon-flip:not([disabled]) {
                        background-color: transparent;
                        @include color-primary-black();

                        &:hover,
                        &:active,
                        &:focus {
                            background-color: $primary-orange;
                            //@include color-primary-black();
                            color: $white;
                        }
                    }

                    .uk-checkbox-custom {
                        .label {
                            @include color-primary-black();
                            position: relative;
                            top: 2px;
                            margin-left: 2px;
                        }
                    }
                }

                &-title {
                    @include color-primary-black();
                    margin-bottom: 17px;
                    font-weight: bold;

                    .icon-info {
                        position: relative;
                        top: 2px;
                        margin-left: 5px;
                        @media (min-width: $breakpoint-xlarge) {
                            font-size: 24px;
                            width: 24px;
                            height: 24px;
                            top: 5px;
                        }
                    }
                }

                &-ascensore {
                    margin-bottom: 20px;

                    &:first-child {
                        border-bottom: 1px solid rgba($black, 0.2);
                        padding-bottom: 13px;
                    }

                    @media (min-width: $breakpoint-medium) {
                        width: 100%;
                        margin-top: 15px !important;
                        &:first-child {
                            border-bottom: none;
                            border-right: 1px solid rgba($black, 0.2);
                            padding: 0 15px 0 0;
                            margin: 0 15px 25px 0;
                            width: 44%;
                            min-width: 44%;
                        }
                    }

                    label {
                        margin-right: 7px;

                        span {
                            margin-left: 5px;
                            @include color-primary-black();
                        }
                    }

                    .icon-info {
                        cursor: pointer;
                    }

                    .message-error {
                        margin-top: 12px;
                    }
                }

                &-nota {
                    margin-bottom: 30px;

                    textarea {
                        min-height: 56px;
                        min-width: 100%;
                        width: 100%;
                        line-height: normal;
                        font-weight: 500;
                        padding: 16px 16px 10px;

                        &:placeholder-shown {
                            @include color-primary-black(0.6);
                        }

                        &::placeholder {
                            @include color-primary-black(0.5);
                        }

                        &:focus {
                            background-color: $white;
                            @include color-primary-black();
                        }

                        &::-webkit-resizer {
                            // border: 2px solid black;
                            // background: red;
                            // box-shadow: 0 0 5px 5px blue;
                            // outline: 2px solid yellow;
                        }
                    }
                }
            }
        }

        &-privacy {
            margin-top: 33px;
            font-size: 12px;
            line-height: 1.33;
            margin-bottom: -30px;
            @include color-primary-black();
            /*> * {
                padding-left: 35px;
                .label-text {
                    position: relative;
                    top: 4px;
                }
                + * {
                    margin-top: 12px;
                }
            }*/
            @media (min-width: $breakpoint-xlarge) {
                margin-bottom: 0;
            }

            a {
                font-weight: 500;
            }
        }

        &-consegna {
            .reserved {
                .label {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.71;
                    margin-bottom: 5px;
                }

                .address {
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 1.33;
                }

                .negozio-selezionato {
                    font-size: 12px;
                    line-height: 1.33;
                    font-weight: normal;
                    border-top: 1px solid var(---effects-02-darker-10-2);
                    padding-top: 11px;
                    margin-top: 9px;
                    color: #333333B3;
                }

                @media (min-width: $breakpoint-xlarge) {
                    .label {
                        font-size: 16px;
                        line-height: 1.5;
                        margin-bottom: 13px;
                    }
                    .address {
                        font-size: 14px;
                        line-height: 1.43;
                    }
                    .negozio-selezionato {
                        padding-top: 15px;
                        font-size: 14px;
                        line-height: 1.43;
                    }
                }
            }
        }

        &-giorno-orario {
            .giorno-orario-edit {
                display: none;

                .section-subtitle {
                    margin-top: 20px;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.5;
                    @include color-primary-black(0.6);
                }
            }

            &.open {
                .giorno-orario-edit {
                    display: block;
                }
            }

            .button-prenota {
                padding: 20px 33px;
                font-size: 16px;
                line-height: 1;

                .lds-ring {
                    background: $primary-orange !important;
                }
            }

            .info-costs {
                font-size: 14px;
                color: rgba($black, 0.6);
                font-weight: 500;

                .delivery-costs {
                    color: $primary-black;
                    font-size: 28px;
                    line-height: 36px;
                }
            }

            .custom-margin {
                margin-top: 5px;
                margin-bottom: 29px;
            }

            .swiper-container {
                width: calc(100% + 12px);
                //width: 100%;
                height: 100%;
                padding: 50px 12px 20px;
                margin-top: -50px;
                margin-left: -12px;
                margin-bottom: 0;
                box-sizing: border-box;

                .swiper-slide {
                    width: 112px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:nth-child(1n) {
                        width: 112px !important;
                    }
                }

                .swiper-button {
                    &-next,
                    &-prev {
                        padding: 5px 12px;
                        top: 3px;
                    }

                    &-next {
                        right: -5px;
                    }

                    &-prev {
                        left: inherit;
                        right: 30px;
                    }
                }

                .swiper-scrollbar {
                    width: 100%;
                    max-width: 320px;
                    left: 50%;
                    margin-left: -160px;
                }

                @media (min-width: $breakpoint-large) {
                    padding-bottom: 37px;
                    margin-bottom: 30px;
                }
            }
        }

        &-pagamento {
            &.disabled {
                .payment-edit {
                    display: none;
                }
            }


            .payment-edit {
                .label {
                    font-size: 16px;
                    font-weight: normal;
                    margin-top: 8px;
                    margin-bottom: 18px;
                    color: $primary-black;
                }

                .uk-form-controls {
                    label:not(:last-child) {
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                        border-bottom: 1px solid rgba($black, 0.2);
                    }

                    input {
                        margin-right: 8px;
                        position: relative;
                        top: 1px;

                        + span {
                            @include color-primary-black();
                            display: inline-flex;
                            justify-content: space-between;
                            flex-direction: column;
                            font-size: 14px;
                            font-weight: 500;
                            width: calc(100% - 33px);

                            > span {
                                display: block;
                                margin-top: 14px;
                                margin-bottom: -5px;

                                img {
                                    height: 24px;
                                    width: auto;
                                    margin-bottom: 5px;

                                    &:not(:last-child) {
                                        margin-right: 6px;
                                    }
                                }
                            }
                        }
                    }
                }

                .envoice-toggle {
                    padding-top: 20px;
                    margin-top: 20px;
                    border-top: 1px solid rgba($black, 0.2);
                }

                .uk-switch-label {
                    @include color-primary-black();
                    font-weight: bold;
                    padding-right: 20px;

                    i {
                        font-size: 18px;
                        margin-right: 8px;
                        position: relative;
                        top: 2px;
                    }
                }

                .uk-switch {
                    &-slider {
                        box-shadow: 0 0 0 2px $primary-orange;
                    }

                    input:not(:checked) + .uk-switch-slider {
                        box-shadow: 0 0 0 2px var(---effects-01-opacity-30-2);

                        &:before {
                            background-color: var(---effects-01-opacity-30-2);
                        }
                    }

                    input[disabled] + .uk-switch-slider {
                        opacity: 0.3;
                    }

                }

                @media (max-width: $breakpoint-large-2-max) {
                    .uk-switch {
                        width: $switch-width;
                        height: $switch-height;

                        input {
                            &:checked + .uk-switch-slider {
                                &:before {
                                    transform: translateX(
                                                    calc(#{$switch-width} - (#{$switch-bullet-size} * 2 - (#{$switch-bullet-size} - (#{$switch-bullet-distance + $switch-bullet-distance}))))
                                    );
                                }
                            }
                        }

                        &-slider {
                            &:before {
                                width: $switch-bullet-size;
                                height: $switch-bullet-size;
                                left: $switch-bullet-distance;
                                bottom: $switch-bullet-distance;
                            }
                        }
                    }
                }
                @media (min-width: $breakpoint-xlarge) {
                    .label {
                        margin-bottom: 24px;
                    }
                    .uk-form-controls {
                        label:not(:last-child) {
                            padding-bottom: 18px;
                            margin-bottom: 18px;
                        }

                        input {
                            + span {
                                flex-direction: row;

                                > span {
                                    margin: 0;
                                }
                            }
                        }
                    }
                    .envoice-toggle {
                        padding-top: 21px;
                        margin-top: 15px;
                    }
                }
            }

            label {
                cursor: pointer;

                @media (min-width: $breakpoint-xlarge) {
                    display: flex;
                    align-items: center;
                    span {
                        align-items: center;
                    }
                }
            }

            .cf {
                font-size: 14px;
                margin-top: 14px;
                line-height: 1.43;
                @include color-primary-black();

                a {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.29;
                }
            }

            .add-envoice {
                .uk-button {
                    font-size: 14px;
                    font-weight: bold;
                    text-align: left;
                    padding: 10px;
                    margin: 0 0 -10px -10px;
                    @include color-primary-black();
                }
            }

            .reserved {
                font-weight: normal;

                .data {
                    padding-bottom: 9px;
                    border-bottom: 1px solid rgba($black, 0.1);
                    margin-bottom: 22px;
                }

                .envoice {
                    .uk-switch {
                        &-slider {
                            box-shadow: 0 0 0 2px $primary-orange;
                        }

                        input:not(:checked) + .uk-switch-slider {
                            box-shadow: 0 0 0 2px var(---effects-01-opacity-30-2);

                            &:before {
                                background-color: var(---effects-01-opacity-30-2);
                            }
                        }
                    }
                }
            }
        }
    }

    .box-indirizzo-consegna {
        position: relative;
        padding-bottom: 50px;
        margin-bottom: 18px;

        .text-box {
            > * {
                min-width: 50%;
            }

            flex-direction: column;

            .label, .desc {
                font-size: 14px;
            }
        }

        .edit {
            position: absolute;
            font-size: 12px;
            font-weight: 500;
            right: 21px;
            bottom: 11px;
        }

        @media (min-width: $breakpoint-xlarge) {
            .text-box {
                flex-direction: row;
            }
            .edit {
                font-size: 14px;
            }
        }
    }

    .box-service {
        font-size: 14px;
        line-height: 20px;
        padding: 16px;
        border: 1px solid var(---effects-01-opacity-20-2);
        border-radius: 12px;
        margin-bottom: 14px;

        .separator {
            flex: none;
            width: 1px;
            border-left: 1px solid var(---effects-01-opacity-20-2);
            min-width: 10px;
            margin: 0 10px;
        }

        .title {
            color: #333333B3;
            margin-bottom: 8px;

        }

        [class*='icon-'] {
            padding-right: 8px;
            position: relative;
            top: 1px;
            font-size: 16px;
        }

        .icon-store-2 {
            font-size: 15px;
        }

        .text {
            @include color-primary-black();
            font-weight: bold;
            @media (min-width: $breakpoint-xlarge) {
                font-weight: normal;
            }
        }

        @media (min-width: $breakpoint-xlarge) {
            padding: 24px;
            margin: 0;
        }

        .countdown-scadenza > * {
            font-size: 14px;
            font-family: 'DM Sans', sans-serif;
        }

        .scaduto {
            color: var(---colour-01-primary-01-p-2);
            display: flex;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;

            .gif-orologio {
                max-height: 16px;
                max-width: 16px;
                margin-right: 8px;
            }
        }
    }

    .box-scontrino {
        margin-top: 30px;
        padding: 16px 17px;
        box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
        border-radius: 12px;
        @include color-primary-black();

        hr.dashed {
            margin: 0 0 13px 0;
            border: none;
            height: 1px;
            background: $global-border;
            background: repeating-linear-gradient(90deg, $global-border, $global-border 6px, transparent 6px, transparent 12px);
        }

        .tooltiptext {
            width: 292px;
            padding: 6px 12px 38px 12px;

            p {
                margin: 0;
            }

            hr {
                margin-top: 9px;
                margin-bottom: 9px;
                border-color: rgba($white, 0.6);
            }
        }

        .hr-divider {
            margin-top: 0;
            margin-bottom: 12px;
        }

        .new-hr-divider {
            padding-top: 12px;
            border-top: 1px solid $global-border;

            &.dashed {
                border-top: none;
                background-image: linear-gradient(to right, $global-border 50%, rgba(255, 255, 255, 0) 0%);
                background-position: top;
                background-size: 15px 1px;
                background-repeat: repeat-x;
            }
        }

        .wrapper-border-first {

            & > div:first-of-type {
                padding-top: 12px;
                border-top: 1px solid $global-border;
            }

            &.dashed > div:first-of-type {
                padding-top: 12px;
                border-top: none;
                background-image: linear-gradient(to right, $global-border 50%, rgba(255, 255, 255, 0) 0%);
                background-position: top;
                background-size: 15px 1px;
                background-repeat: repeat-x;
            }
        }

        .icon-info {
            position: relative;
            top: 3px;
            cursor: pointer;
            margin-left: 2px;
        }

        .title {
            margin-bottom: 5px;
            @include color-primary-black();

            @media (min-width: $breakpoint-xlarge) {
                margin-bottom: 15px;
            }
        }

        .uk-accordion-title {
            text-decoration: none;
            font-size: 13px;
            @include color-primary-black();
        }

        .uk-accordion {
            margin: 0 0 15px 0;

            .background-scontrino {
                background-color: var(---colour-02-secondary-01-s-2);
                margin: 15px -12px 0;
                padding: 0 6px 0 12px;
                max-height: 350px;

                .custom-list {
                    max-height: 350px;

                    .uk-card {
                        background-color: $white;
                        margin-bottom: 5px;
                        border-radius: 12px;

                        :first-child {
                            margin-top: 8px;
                        }
                    }

                    .component-CardProdottoOmaggioCheckout{
                        margin-top: 8px;
                    }
                }
            }
        }

        .products-costs {
            .sconto-prodotto-applied {
                text-align: right;

                .sconto-old {
                    text-decoration: line-through;
                    margin-right: 5px;
                    font-size: 12px;
                }

                b {
                    color: var(---colour-01-primary-01-p-2);
                    font-size: 14px;
                }

                .sconto-prodotto {
                    margin-top: 10px;
                    position: relative;
                    right: -24px;
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.6);
                }
            }
        }

        .line-price {
            width: 100%;
            font-size: 12px;
            line-height: 16px;
            padding-bottom: 13px;

            &.riepilogo {
                font-size: 14px;
                line-height: 20px;
            }

            &.extra {
                font-size: 11px;
                line-height: 16px;

                .value {
                    font-size: 12px;
                    line-height: 20px;
                }
            }
        }

        .card-scontrino {
            &-bottom {
                box-sizing: border-box;
                margin-top: 3px;

                .points-and-cost {
                    margin-bottom: 12px;

                    > * {
                        display: flex;
                        justify-content: space-between;

                        .text, .value {
                            font-size: 12px;
                            justify-content: flex-start;
                        }

                        &.font-medium {
                            .text {
                                font-size: 14px;
                            }

                            .value {
                                font-size: 15px;
                            }
                        }
                    }

                    .pre-authorization {
                        .text {
                            font-size: 13px;
                        }

                        .info {
                            display: block;
                            font-size: 11px;
                            color: $blue-info;
                            text-decoration: underline;
                            font-weight: 500;
                            line-height: 16px;

                            @media (min-width: $breakpoint-xlarge) {
                                margin-top: 4px;
                            }
                        }
                    }

                    .totale-ordine {
                        margin-bottom: 7px;

                        .text {
                            font-size: 16px;

                            h2 {
                                font-size: 15px;
                                margin: 0;
                            }

                            span {
                                font-size: 10px;
                                color: $base-body-color;
                                margin-top: -4px;

                                @media (min-width: $breakpoint-xlarge) {
                                    margin-top: -4px;
                                }
                            }
                        }

                        .value {
                            @include color-primary-black();
                            font-size: 15px;
                        }
                    }

                    .voce-dettaglio-risparmio {
                        margin-bottom: 13px;
                        margin-left: 16px;
                        margin-top: 10px;
                        color: rgba(51, 51, 51, 0.7);
                    }
                }

                .risparmio {
                    .value {
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.6);
                    }
                }

                &-footer {
                    .info-punti-bollini {
                        color: $primary-black;
                        margin-bottom: 13px;
                        margin-top: 5px;
                        font-size: 10px;
                    }

                    .info-sconto {
                        color: var(--02-effects-03-lighten-20);
                        margin-bottom: 15px;
                        font-size: 12px;
                    }

                    .carta-insieme {
                        font-size: 12px;

                        img {
                            height: 16px;
                            margin-right: 5px;
                        }
                    }

                    .hr-divider {
                        margin-top: 10px;
                        margin-bottom: 9px;
                    }

                    .uk-checkbox-custom {
                        font-size: 12px;
                        line-height: 2.2;

                        .label-text {
                            a {
                                font-weight: 500;
                            }
                        }
                    }
                }

                .btn-prosegui {
                    font-size: 16px;
                    margin-top: 16px;
                    line-height: 56px;
                }
            }
        }

        @media (min-width: $breakpoint-xlarge) {
            padding: 20px 12px;
            margin-top: 16px;
        }

        .checkout-sales-conditions-message-error {
            font-size: 13px;
            color: var(---colour-03-semantic-02-error-2) !important;
        }
    }

    @media (min-width: $breakpoint-xlarge) {
        .main-content {
            padding-top: 33px;
            padding-bottom: 18px;
        }
    }

    .section-codice-sconto{
        .coupon-error-feedback{
            .error-feedback{
                display: none;
            }

            &.ko{
                &-conditions{
                    .conditions-not-satisfied-error{
                        display: block;
                    }
                }

                &-expired{
                    .coupon-expired-error{
                        display: block;
                    }
                }

                &-other{
                    .other-error{
                        display: block;
                    }
                }

                &-single-coupon{
                    .single-coupon-error{
                        display: block;
                    }
                }

            }
        }
    }
}

// Page Checkout Guest
.container-guest {
    padding-bottom: 15px;
    @include color-primary-black();

    .box-info {
        margin-top: -5px;

        .uk-button {
            line-height: 56px;
        }

        @media (max-width: $breakpoint-medium-max) {
            .uk-button:not(.uk-button-large) {
                font-size: 16px;
            }
        }

        .uk-input {
            font-weight: 500;
        }

        &-content {
            .title {
                font-size: 28px;
                font-weight: bold;
                line-height: 1.07;
                margin-bottom: 12px;
            }

            .desc {
                font-size: 14px;
                line-height: 1.43;
                margin-bottom: 20px;
            }

            .button-action {
                margin: 20px 0 14px;
            }

            .underline-title {
                position: relative;
                margin-bottom: 35px;

                &:before {
                    border-radius: 4px;
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: -13px;
                    left: 0;
                    width: 32px;
                    height: 4px;
                    background-color: $yellow;
                }
            }

            &-guest {
                position: relative;

                @media(min-width: $breakpoint-xlarge) {
                    .form {
                        .uk-button {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        }
                    }
                }
            }
        }

        &-guest {
            .desc {
                font-size: 14px;
                line-height: 1.43;
                margin-bottom: 20px;
            }

            .form {
                margin-top: 30px;
                margin-bottom: 16px;
            }
        }

        .reasons {
            margin-top: 40px;
            padding-bottom: 17px;
            border-bottom: 1px solid var(---effects-01-opacity-30-2);

            .title {
                font-weight: normal;
                font-size: 16px;
                margin-bottom: 22px;
                padding-bottom: 0;
            }

            > * {
                font-size: 14px;
                padding-bottom: 15px;
                display: flex;
                align-items: center;

                i {
                    padding-right: 16px;
                    font-size: 24px;
                }
            }
        }
    }

    @media (min-width: $breakpoint-xlarge) {
        margin-bottom: 33px;
        padding-top: 11px;
        .box-info {
            &-content {
                &:not(.box-info-content-guest) {
                    border-right: 1px solid var(---effects-01-opacity-30-2);
                }

                .title {
                    font-size: 24px;
                }

                .desc {
                    font-size: 16px;
                }

                &-info {
                    padding-right: 39px;

                    .uk-button {
                        min-width: 182px;
                    }
                }

                &-guest {
                    padding-left: 39px;
                }
            }

            .reasons {
                border-bottom: none;

                > * {
                    padding-bottom: 24px;
                }
            }
        }
    }
}
