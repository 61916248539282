.card-scegli-spesa {
    width: 100%;
    position: relative;
    background-color: $white;
    transition: all .3s ease-in-out;

    //Card Generic
    .uk-card {
        &-body {
            color: $primary-black;
            width: 100%;
            padding: 16px 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .label-not-available {
            display: none;
        }

        .card-icon {
            font-size: 36px;
            margin-bottom: 14px;
        }

        .card-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            margin: 0;

            [class^='icon-'] {
                display: none;
                border-radius: 50px;
                background-color: $warning;
                color: $white;
                position: relative;
                top: 1px;
                left: -2px;
            }
        }

        .card-text {
            display: none;
            font-size: 14px;
            line-height: 1.43;
            text-align: center;
            margin: 0 0 18px;
            padding: 0 20px;
            &.default {
                display: block;
            }
        }

        .uk-button {
            width: 100%;
            margin-top: auto;
            display: none;

            &.default {
                display: block;
            }
            &-link {
                padding: 0 7px;
                line-height: 30px;
                text-decoration: underline;
                font-weight: 500;
                margin-bottom: 5px;
            }
        }


        .service-customer-list {
            font-size: 11px;
            width: 100%;
            background-color: #7dac33;
            font-weight: bold;
            color: white;
            text-align: center;
            align-content: center;
            min-height: 32px;
            bottom: 0px;
            border-bottom-left-radius: 12px;
            position: absolute;
            border-bottom-right-radius: 12px;

            @media (max-width: $breakpoint-large){
                &+ .uk-card-body{
                padding: 16px 16px 41px 16px;
                }
            }
        }
    }

    &.component-card-ordina-e-ritira {
        .uk-card {
            &-body {

            }
        }
    }

    &.component-card-spesa-a-casa {
        .uk-card {
            &-body {

            }
        }
    }

    //Card incompleta: Se l'indirizzo è incompleto
    &.incomplete {
        .uk-card {
            &-body {

            }
            .card-title {
                [class^='icon-'] {
                    display: inline-block;
                }
            }
            .card-text {
                &.default {
                    display: none;
                }
                &.incomplete {
                    display: block;
                }
            }
            .uk-button {
                &.default {
                    display: none;
                }
                &.incomplete {
                    display: block;
                }
            }
        }
    }

    //Card disabilitata: Se l'indirizzo non esiste
    &.disabled {
        .uk-card {
            &-body {
                background-color: $cultured;
                color: $primary-disabled-text;
                padding: 40px 16px 16px;
            }
            .label-not-available {
                display: block;
                position: absolute;
                top: 1px;
                background-color: $white;
                padding: 0 10px;
                border-radius: 0 0 10px 10px;
                box-shadow: 0 3px 5px 0 rgba(169, 169, 169, 0.25);
                font-size: 14px;
                line-height: 24px;
                color: $primary-black;
                font-weight: bold;
            }
            .card-title {
                color: $primary-disabled-text;
            }

            .card-text {
                margin-bottom: 5px;
                &.default {
                    display: block;
                }
            }

            .uk-button {
                background-color: transparent;
                color: $blue-info;
                text-decoration: underline;
                box-shadow: none;

                &.default {
                    display: none;
                }

                &.disabled {
                    display: block;
                }
            }
        }
    }

    @media (min-width: $breakpoint-large) {
        .uk-card {
            &-body {
                padding: 40px 24px 50px 24px;
            }
            .card-icon {
                margin-bottom: 20px;
            }
            .card-title {
                font-size: 16px;
            }

            .card-text {
                padding: 0;
            }

            .uk-button {
                width: auto;
                font-size: 14px;
                padding: 0 24px;
            }
        }

        &.disabled {
            .uk-card {
                .uk-card-body {
                    padding: 40px 24px 24px;
                }
                .card-text {
                    margin-bottom: 18px;
                }
                .uk-button {
                    padding: 0 5px;
                    margin-bottom: 0;
                    height: 40px;
                }
            }
        }
    }
}