.component-banner-address {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 71px 16px 50px;
    text-align: center;
    margin-top: -30px;
    height: 610px;

    .banner-address {
        &-title {
            font-weight: 500;
            max-width: calc(100% - 84px);
            z-index: 1;
        }

        &-desc {
            max-width: calc(100% - 52px);
            font-size: 24px;
            line-height: 1.2;
            z-index: 1;
            margin-bottom: 32px;
        }

        &-accedi {
            font-size: 16px;
            font-weight: 500;
            max-width: calc(100% - 84px);
            position: absolute;
            bottom: 50px;
            z-index: 0;
        }
    }

    [class*='img-top-'] {
        position: absolute;
        left: 0;
    }
    [class*='img-bottom-'] {
        position: absolute;
        right: 0;
    }

    .img-top {
        &-mobile {
            top: 0;
            max-width: 58px;
            max-height: 90px;
        }

        &-desktop {
            top: 130px;
            max-width: 232px;
            max-height: 170px;
        }
    }

    .img-bottom {
        &-mobile {
            bottom: 26px;
            max-width: 75px;
            max-height: 170px;
        }

        &-desktop {
            bottom: 0;
            max-width: 150px;
            max-height: 330px;
        }
    }

    .google-input {
        max-width: 560px;
        z-index: 1;
    }

    @media (min-width: $breakpoint-large) {
        height: 520px;
    }
    @media (min-width: $breakpoint-large-2) {
        .banner-address {
            &-accedi {
                bottom: 212px;
            }
        }
    }
}