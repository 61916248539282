.component-BannerStripePromo {
    padding: 24px 0;

    a {
        text-decoration: none;
    }

    &.banner-carousel {
        .uk-container {
            display: none;

            &.active {
                display: block;
            }
        }
    }

    .section-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        background-color: $cosmic-latte;
        border-radius: 12px;

    }

    .text-container {
        display: flex;
        flex-direction: column;

        padding: 16px 0 16px 16px;

        @media (min-width: $breakpoint-xlarge) {
            padding: 24px 0 24px 24px;
        }

        &.order-inverse {
            flex-direction: column-reverse;

            .banner-title {
                margin: 0 0 4px;
        
                @media (min-width: $breakpoint-xlarge) {
                    padding: 24px 0 0 16px;
                    margin-bottom: 8px;
                }
            }
        
            .banner-description {
                margin: 0;
                @media (min-width: $breakpoint-xlarge) {
                    padding: 0 0 24px 16px;
                }
            }

        }
    }

    .img-container {
        height: 96px;
        min-width: 80px;
        max-width: 80px;

        @media (min-width:$breakpoint-xlarge) {
            height: 158px;
            min-width: 339px;
            max-width: 339px;
        }

        .img-logo {
            height: 100%;
            width: 100%;
            border-radius: 0 12px 12px 0;

            @media (max-width: $breakpoint-large-2-max){
                object-fit: cover;
            }
        }
    }

    .banner-description {
        @include line-clamp(1);
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        margin: 0 0 4px;
        @include color-primary-black();

        @media (min-width: $breakpoint-xlarge) {
            font-size: 20px;
            line-height: 1.2;
            padding: 24px 0 0 16px;
            margin-bottom: 8px;
            font-weight: normal;
        }
    }

    .banner-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        @include color-primary-black();
        margin: 0;
        max-width: 239px;

        @media (min-width: $breakpoint-xlarge) {
            max-width: unset;
            font-size: 28px;
            line-height: 1.07;
            padding: 0 0 24px 16px;
        }
    }
}

.component-BannerStripeVisibilita {
    padding: 24px 0;

    a {
        text-decoration: none;
    }

    .section-body {
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        background-color: #f8f6ef;
        border-radius: 12px;
        box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);

        @media screen and (min-width: $breakpoint-xlarge){
            height: 158px;
        }
    }

    .img-container {
        height: 100%;
        max-height: 100%;
        width: 80px;

        @media (min-width:$breakpoint-xlarge) {
            width: 339px;
        }

        .img-logo {
            height: 100%;
            width: 100%;
            border-radius: 0 12px 12px 0;

            @media (max-width: $breakpoint-large-2-max){
                object-fit: cover;
            }
        }
    }

    .text-container {
        display: flex;
        flex-direction: column;

        padding: 12px 16px;
        width: 66%;

        @media (min-width: $breakpoint-xlarge) {
            padding: 33px 40px;
            width: unset;
        }

        &.order-inverse {
            flex-direction: column-reverse;

            .banner-description {
               
                margin: 0 0 4px;  
        
                @media (min-width: $breakpoint-xlarge) {
                    margin-bottom: 16px;
                }
            }
        
            .banner-title {
                margin: 0;
            }

        }
    }

    .banner-title {
        @include line-clamp(2);
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
        @include color-primary-black();
        margin: 0 0 4px;
        max-width: 239px;

        @media (min-width: $breakpoint-xlarge) {
            @include line-clamp(1);
            max-width: unset;
            font-size: 28px;
            line-height: 1.29;
            margin-bottom: 16px;
        }
    }

    .banner-description {

        @include line-clamp(1);

        max-width: 80%;
        height: 20px;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.43;
        @include color-primary-black();
        margin: 0;

        @media (min-width: $breakpoint-xlarge) {
            @include line-clamp(3);
            max-width: 533px;
            height: unset;
            overflow-y: unset;
        }
    }
}