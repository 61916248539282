.component-ModalSlotPrenotatoScadenza {
    @media (max-width: $breakpoint-xlarge-max) {
        &.uk-alert {
            margin-top: 70px;
        }
    }

    &.warning-first {
        box-sizing: border-box;
        padding: 18px 8px 18px 15px;

        .countdown {
            @include color-primary-black(0.6);
            font-weight: bold;

            &-timer > * {
                @include color-primary-black(0.6);
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .action {
            line-height: 1;
            padding: 13px 24px;
            .lds-ring {
                background-color: $yellow-2;
            }
        }

        .top-row p {
            margin: 0 32px 0 16px;
            padding-right: 0;
        }

        @media (max-width: $breakpoint-large-2-max) {
            flex-direction: column;

            .uk-alert-close {
                top: 0;
                margin-top: 5px;
                padding-right: 8px;
            }

            .top-row {
                align-self: flex-start;
                line-height: 1;
                margin-right: 32px;

                & > i {
                    min-width: 16px;
                    min-height: 16px;
                    background-color: var(---colour-03-semantic-03-warnin-2) !important;
                }
            }

            .uk-button-warning {
                position: unset;
                margin-top: 16px;
                margin-right: 8px;
                align-self: flex-end;
            }
        }
    }

    &.warning-last {
        padding: 16px;

        .uk-modal {
            &-dialog {
                position: relative;
            }

            &-title {
                @include color-primary-black();
                font-size: 24px;
                line-height: 32px;
                font-weight: bold;
                margin-right: 48px;
            }

            &-content {
                font-size: 16px;
                line-height: 24px;

                .countdown {
                    color: $danger;
                    margin-top: 8px;

                    &-timer > * {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .uk-countdown-minutes span:first-child {
                        display: none;
                    }
                }

                .text {
                    margin-top: 24px;
                    font-size: 24px;
                    line-height: 1.33;
                    color: $primary-black;
                }
            }

            &-actions {
                margin-top: 20px;
                .uk-button {
                    font-size: 16px;
                    line-height: 1;
                    padding: 20px 60px;
                    margin-top: 24px;
                    &-link {
                        text-decoration: underline;
                        font-weight: normal;
                        margin-top: 0;
                    }
                }
            }
            @media (min-width: $breakpoint-xlarge) {
                padding: 24px;

                &-dialog {
                    min-width: 784px;
                }

                &-title, &-content {
                    max-width: 545px;
                }

                &-actions {
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 80px;
                    .uk-button {
                        max-width: 200px;
                        padding: 20px 32px;
                        margin: 0 0 0 16px;
                    }

                    a {
                        margin-right: 24px;
                    }
                }
            }
        }
    }

    &.warning-slot-expired {
        .uk-modal {
            padding: 24px 16px;

            &-dialog {
                max-height: 400px;
            }

            &-title {
                @include color-primary-black();
                font-size: 24px;
                line-height: 32px;
                margin-right: 43px;
                font-weight: bold;
            }

            &-content {
                margin-top: 8px;
                font-size: 16px;
                line-height: 24px;
            }

            &-footer {
                align-items: center;
                padding: 0;

                .uk-button {
                    width: 100%;
                    margin-top: 40px;
                    margin-bottom: 24px;
                    padding: 20px 50px;
                    font-size: 16px;
                    line-height: 1;
                }
            }

            @media (min-width: $breakpoint-xlarge) {

                &-dialog {
                    min-width: 784px;
                }

                &-content {
                    margin-bottom: 37px;
                }

                &-top {
                    display: flex;

                    .modal-content-img {
                        margin-top: 0px;
                        margin-right: 26px;
                        width: 150px;
                        height: 157px;
                    }

                    &-text {
                        margin-right: 56px;
                    }
                }

                &-footer {
                    flex-direction: row;
                    justify-content: flex-end;
                    margin-top: 40px;

                    .uk-button {
                        max-width: 219px;
                        padding: 20px 32px;
                        margin: 0;
                        margin-left: 24px;
                    }
                }
            }
        }
    }

    &.slot-extend-alert {
        @include color-primary-black();
        background: $honeydew;

        .icon-check {
            font-size: 16px;
            color: $white;
            background: var(---colour-03-semantic-01-succes-2);
        }

        p {
            padding-right: 40px;
        }
    }

    &.slot-extend-popup {
        .uk-modal {
            &-top {
                min-height: 157px;
            }

            &-footer {

                .uk-button {
                    max-width: 202px;
                }
            }
        }
    }
}