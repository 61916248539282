.page-Errors {
    
    opacity: 1;
    visibility: visible;

    .section {
        padding: 24px 0 0 0;

        @media (min-width: $breakpoint-large) {
            padding-top: 32px;
        }

        &-title {
            @include color-primary-black(0.8);

            font-size: 32px;
            font-weight: bold;
            line-height: 1.25;
            max-width: 470px;
            margin: 0 0 44px 0;

            @media (min-width: $breakpoint-large) {
                font-size: 40px;
                line-height: 1.2;
                margin: 0;
            }
        }

        &-desc {
            color: $primary-black;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
            margin: 24px 0 48px 0;

            @media (min-width: $breakpoint-large) {
                margin: 30px 0 32px 0;
            }
        }

        &-carousel {
            
            @media (min-width: $breakpoint-large) {
                padding-bottom: 50px;
            }

            @media (min-width: $breakpoint-xlarge) {
                padding-bottom: 38px;
            }

            .section-title {
                font-size: 28px;
                font-weight: normal;
                line-height: 1.29; 

                @media (min-width: $breakpoint-xlarge) {
                    margin-top: 30px;
                }
            }

            .swiper-wrapper {
                margin-bottom: 24px;

                @media (min-width: $breakpoint-large) {
                    margin-bottom: 0;
                }
            }

            .component-ProductCard {

                .card-bottom {

                    .add-product {
                        margin-bottom: 0;
                        border: solid 2px var(--01-colour-01-primary-03-p);
                        background-color: $white;
                        i, span {
                            color: $primary-black;
                        }
                    }
                }
            }

            .swiper-scrollbar {
                display: block;
                width: 80%;
                left: 10%;
                margin-bottom: 18px;

                @media (min-width: $breakpoint-large) {
                    width: 48%;
                    left: 26%;
                    margin-bottom: 0;
                }

                @media (min-width: $breakpoint-xlarge) {
                    width: 36%;
                    left: 32%;
                }
            }
        }

        .uk-button.button-home {
            display: flex;
            flex-direction: row;

            width: 100%;
            justify-content: center;
            align-items: center;
            padding: 20px 32px;
            border-radius: 12px;
            background-color: $primary-orange;
            margin: 21px 0px;

            @media (min-width: $breakpoint-large) {
                width: 224px;
                margin-bottom: 32px;
            }

            span {
                flex-grow: 0;
                font-size: 16px;
                font-weight: bold;
                line-height: 1;
                text-align: center;
                color: $white;
            }
        }

        .error-img {
            max-height: 173px;
            float: none; 
            margin-bottom: 24px;

            @media (min-width: $breakpoint-large) {
                max-height: 236px;
                float: right;
                margin-left: 76px;
            }

            @media (min-width:$breakpoint-xlarge) {
                max-height: 318px;
            }
        }
    }
}