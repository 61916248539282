.page-Landingidm {
    .main-content {

        .component-banner-address {
            margin-top: 0;
        }
        .page-title {
            margin-bottom: 24px;
        }



        //fix spaziature in Landing Page
        .component-PromoComponent {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        //fix spaziature in Landing Page
        .component-BannerListing {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }
}