.component-Feedback {
    &.uk-alert {
        &-primary {
            background-color: $light-grayish-blue;
        }

        &-warning {

        }

        &-danger {

        }
    }
}
.page-with-preheader {
    @media (max-width: $breakpoint-xlarge-max) {
        margin-top: 56px;
    }
}
.feedback-full {
    z-index: 1000;
    position: relative;

    .component-Feedback {
        box-shadow: 0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
    }
}

.alert-accedi-scegli-servizio {
    z-index: 1;
    padding: 15px 15px;

    .feedback {
        &-content {
            width: 100%;
        }
        &-message {
            [class*='icon-'] {
                margin-right: 16px;
            }
            p {
                margin: 0;
            }
        }
        &-action {
            text-align: right;
            margin-top: 16px;
            .uk-alert.uk-button-primary-outline {
                box-shadow: 0 0 0 2px var(---colour-03-semantic-04-inform-2);
                color: var(---colour-03-semantic-04-inform-2);
                background-color: transparent;
                line-height: 38px;
                padding: 0 17px;
                font-weight: bold;
                display: inline-block;
                margin: 0;
            }
        }
        @media (min-width: $breakpoint-large-2) {
            &-content {
                display: flex;

            }
            &-action {
                margin-top: 0;
                margin-left: auto;
            }
        }
    }
}
