/*
* Container style
*/
.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
}

.js-scroll {
    height: 100%;
    position: relative;
}

/*
* Scrollbar rail styles
*/
.ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    width: 4px;
    background-color: #d9d9d9;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    /* there must be 'bottom' or 'top' for ps__rail-x */
    bottom: 0px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    -webkit-transition: background-color .2s linear, opacity .2s linear;
    width: 4px;
    background-color: #d9d9d9;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    /* there must be 'right' or 'left' for ps__rail-y */
    right: 0;
    left:auto !important;
    /* please don't change 'position' */
    position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: #d9d9d9;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    // opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
    background-color: #d9d9d9;
    // opacity: 0.9;
}

/*
* Scrollbar thumb styles
*/
.ps__thumb-x {
    background-color: #666666;
    border-radius: 4px;
    transition: background-color .2s linear, height .2s ease-in-out;
    -webkit-transition: background-color .2s linear, height .2s ease-in-out;
    height: 4px;
    /* there must be 'bottom' for ps__thumb-x */
    bottom: 0;
    /* please don't change 'position' */
    position: absolute;
}

.ps__thumb-y {
    background-color: #666666;
    border-radius: 4px;
    transition: background-color .2s linear, width .2s ease-in-out;
    -webkit-transition: background-color .2s linear, width .2s ease-in-out;
    width: 4px;
    /* there must be 'right' for ps__thumb-y */
    right: 0;
    /* please don't change 'position' */
    position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
    background-color: #000000e6;
    height: 4px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
    background-color: #000000e6;
    width: 4px;
}

.ps--scrolling-y,
.ps--scrolling-x {
    .ps__rail-x > .ps__thumb-x,
    .ps__rail-y > .ps__thumb-y {
        background-color: #000000e6;
    }
}

@media (min-width: $breakpoint-large) {

    .scroll-int {
        margin-right: 18px;
    }

    .ps__rail-x,
    .ps__rail-y {
        opacity: 1 !important;
    }
}



/* MS supports */
@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}
  