@import '../../scss/uikit/variables-theme.scss';

.component-header {
    z-index: 1101;
    position: sticky;
    top: 0;
    max-width: 100%;

    //&.uk-active {
    box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
    //}

    .logo-mobile {
        padding: 0 3px;
    }

    .logo{
        &-mobile, &-desktop {
            height: 32px;
            width: auto;
            max-width: 145px;
            text-align: left;

            img{
                height: 100%;
                max-width: 100%;
            }
        }
        &-mobile img {
            max-height: 36px;
        }
        &-desktop img {
            max-height: 54px;
        }
    }

    .bar-header {
        min-height: 56px;
        align-items: center;
        display: flex;
        position: relative;
    }

    .availability {
        .anim-pulseBoxShadow {
            @include animation-pulse(3s, colour-03-semantic-04-inform-2-rgb);
            animation-iteration-count: infinite;

            &.warning {
                @include animation-pulse(3s, colour-01-primary-03-p-2-rgb);
            }
        }

        .warning {
            background-color: var(---colour-03-semantic-03-warnin-2);
        }

        i {
            display: inline-block;
            position: relative;
            top: -3px;
            left: -4px;

            &.icon-info {
                top: 0;
            }
        }
    }

    .bar-menu {
        .uk-navbar-nav {
            > li {
                > a {
                    font-weight: bold;
                    position: relative;

                    &[aria-expanded='true'] {
                        &:before {
                            content: '';
                            position: absolute;
                            background-color: var(---colour-01-primary-02-p-2);
                            border-radius: 4px 4px 0 0;
                            width: 24px;
                            height: 4px;
                            bottom: 0;
                            left: 50%;
                            margin-left: -12px;
                        }
                    }
                }

                &.red {
                    > a {
                        color: var(---colour-01-primary-01-p-2);
                    }
                }

                &.sub-menu {
                    > a {
                        &:after {
                            font-family: 'icomoon';
                            content: '\e932';
                            margin-left: 7px;
                            transition: all 0.3s ease-in-out;
                        }

                        &[aria-expanded='true'] {
                            &:after {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }

    .btn-menu {
        padding: 5px 13px;
        @include color-primary-black;
        font-size: 24px;
        margin-left: -11px;
        text-decoration: none;

        .uk-icon {
            margin: 0;
        }

        svg {
            width: 20px;
        }
    }

    .uk-navbar-right {
        [class*='btn-'] {
            i {
                font-size: 24px;
                margin: 0;
                @include color-primary-black();
            }
        }

        .uk-button {
            &-link {
                line-height: 17px;
                padding: 5px 8px;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    .btn {
        &-accedi {
            font-weight: 500;
            font-size: 16px;
        }

        &-registrati {
            margin-left: 8px;
            @include color-primary-black;
        }

        &-user {
        }

        &-cart {
            position: relative;

            .uk-badge {
                padding: 0;
                background-color: $pink-light;
                color: var(---colour-01-primary-01-p-2) !important;
                bottom: -7px;
                right: -7px;
                position: absolute;
            }

            .text {
                font-weight: 500;
                margin-right: 8px;
            }

            &.uk-button-tertiary {
                padding: 0 12px;
                line-height: 40px;
                border-radius: 12px;

                i {
                    margin: 0;
                    font-size: 16px;
                    @include color-primary-black(1);
                }
            }
        }
    }

    //MINI CART
    .miniCartFull, .miniCartEmpty{
        display: none;
        /* max-height: calc(100vh - 56px); */
        min-height: 200px;
        box-shadow: 0 8px 16px -4px rgba(51, 51, 51, 0.15), 0 0 1px 0 var(---effects-01-opacity-20-2);
        background-color: $white;
        border-top: 4px solid $primary-orange;
        z-index: 3001;

        &.uk-open {
            display: block;
        }
        .lds-ring-2 {
            transform: translate(-15px, -15px);
        }

        ul.uk-accordion{
            margin-bottom: 16px;
            margin-top: 8px;
        }
    }

    .miniCartEmpty .cart-footer {
        box-shadow: 0 8px 16px -4px rgba(51, 51, 51, 0.15), 0 0 1px 0 var(---02-effects-01-opacity-20-2);
    }

    .header-mini-cart {
        a,
        button {
            text-decoration: none;
        }

        .text {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.5;
            min-height: 80px;
            @include color-primary-black();

            p {
                margin: 0;
            }

            small {
                font-size: 12px;
                font-weight: normal;
            }
        }

        .btn-close {
            font-size: 24px;
            top: 20px;
        }

        &.soglia-delivery-free-active {
            .cart-body {
                max-height: 50vh;
            }
        }

        .cart {
            &-title {
                padding: 8px 16px;

                .title {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.5;
                    margin: 0;
                    margin-right: auto;
                }

                .soglia-min {
                    font-size: 12px;
                    font-weight: 500;
                    color: $primary-black;

                    b {
                        font-family: 'Roboto Slab', serif;
                    }
                }
            }

            &-body {
                padding: 12px 15px 12px 15px;
                max-height: calc(100vh - 288px);
                box-sizing: border-box;

                // .mCustomScrollBox {
                //     padding-right: 15px;

                //     &.mCSB_inside > .mCSB_container {
                //         margin-right: 0;
                //     }

                //     .mCSB_scrollTools {
                //         right: 5px;
                //     }
                // }

                .ps__rail-y {
                    right: 5px;
                }

                .uk {
                    &-alert {
                        line-height: 20px;

                        p {
                            padding-right: 5px;
                        }

                        &.remove-alert{
                            align-items: flex-start;

                            div.uk-flex{
                                align-items: center;

                                .icon-info{
                                    margin-right: 16px;
                                }
                            }

                            .undo-remove{
                                align-self: flex-end;
                                margin-top: 16px;
                                margin-right: 8px;
                                position: initial;
                            }
                        }
                    }

                    &-badge {
                        font-weight: bold;
                    }

                    &-accordion {
                        > :nth-child(n + 2) {
                            margin-top: 15px;
                        }

                        &-title {
                            font-size: 14px;
                            font-weight: 500;
                            position: relative;
                            padding-right: 28px;

                            &:before {
                                position: absolute;
                                margin: -11px 0 0 5px;
                                right: 0;
                                top: 50%;
                                font-size: 20px;
                            }
                        }

                        &-content {
                            margin-top: 15px;
                        }
                    }

                    &-card {
                        + .uk-card {
                            margin-top: 5px !important;
                        }

                        &-body-content{
                            width: 100%;
                        }
                    }
                }

                &.ps--active-y + .cart-footer{
                    box-shadow: 0 -8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
                }
            }

            &-footer {
                padding: 18px 16px;

                /* All'overflow del cart si applica questo box-shadow */
                /* box-shadow: 0 -8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2); */

                hr {
                    margin: 10px 0;
                    &.dashed {
                        border: none;
                        height: 1px;
                        background: $global-border;
                        background: repeating-linear-gradient(90deg, $global-border, $global-border 6px, transparent 6px, transparent 12px);
                    }
                }
                
                .products-costs,
                .delivery-costs {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 12px;

                    &.smaller {
                        .text {
                            font-size: 12px;
                            line-height: 20px;
                        }
                        .value {
                            font-size: 12px;
                            line-height: 20px;
                            font-weight: normal;

                        }
                        
                    }

                    .text {
                        font-size: 14px;
                        min-height: inherit;
                        font-weight: normal;
                        line-height: inherit;

                        span {
                            color: $primary-black;
                            display: block;
                            font-size: 12px;
                            font-weight: normal;
                            line-height: 1;
                        }

                        [class*='icon-'] {
                            position: relative;
                            top: 3px;
                            margin-left: 4px;
                        }
                        .tooltiptext {
                            color: $white;
                        }

                        .tooltip-total-delivery{
                            margin-top: 0px;
                            .tooltiptext{
                                margin-top: 5px !important;
                                padding: 6px !important;
                                max-width: calc(100% - 30px);
                            }
                        }
                    }
                    .delivery-costs {
                        [class*='icon-'] {
                            cursor: pointer;
                        }
                        .tooltiptext {
                            &-drop {
                                .tooltiptext {
                                    max-height: 280px;
                                }
                            }
                        }
                    }

                    .value {
                        font-size: 14px;
                        font-weight: bold;
                        @include color-primary-black();

                        .icon-alert {
                            background-color: var(---colour-03-semantic-03-warnin-2);
                            display: inline-block;
                            position: relative;
                            top: -2px;
                            margin-right: 10px;
                        }
                    }
                }

                .delivery-costs {
                    .value {
                        font-weight: normal;
                    }
                }

                .delivery-costs-progress-bar {
                    margin-bottom: 18px;

                    &.doppia-soglia{

                        .checkpoint:first-child{
                            max-width: 25%;
                        }
                    }
                }
            }
        }
    }

    //SEARCH BAR
    .search-bar {
        margin-bottom: 16px;
        z-index: 3001;

        .uk-search {
            margin-bottom: 0;

            &-icon {
                color: $black;
            }

            &-input {
                //border: 1px solid rgba($black, 0.4);
                min-width: 100%;
                padding-left: 58px !important;
                @include color-primary-black(0.6);
                transition: all 0.3s ease-in-out;

                &:focus {
                    background-color: rgba($yellow, 0.1);
                    border: solid 2px rgba($yellow, 0.6);
                    color: rgba($gray, 0.9);
                    + label {
                        transform: translate(58px, -1px) !important;
                    }
                }
                &:not(:placeholder-shown) + label {
                    transform: translate(58px, -1px) !important;
                }
            }

            .clear-search {
                background-color: black;
                color: $white;
                width: 16px;
                height: 16px;
                margin: auto;
                top: 0;
                right: 16px;

                i {
                    font-size: 14px;
                }
            }
        }

        label {
            transform: translate(59px, 15px) !important;
        }

        .hidden-btn-close {
            display: none;
        }
    }

    .close-search {
        margin-left: 24px;
        background: none;
        border: none;
        color: var(---colour-03-semantic-04-inform-2);
        text-decoration: underline;
        font-size: 14px;
        cursor: pointer;
        z-index: 2;
        padding: 10px 15px;
        position: relative;
        top: -10px;
        margin-bottom: -10px;
        right: -10px;
    }

    //DROPDOWN
    .search-dropdown {
        width: 100% !important;
        margin: 0 -16px;
        background-color: $white;
        padding: 22px 20px;
        box-shadow: inset 0 11px 7px -8px rgb(171 147 67 / 15%);
        min-height: calc(100vh - 128px);
        height: 100%;
        overflow-y: auto;
        z-index: 3002;

        .dropdown-content {
            width: 100%;

            .suggested-products {
                .title {
                    color: rgba($black, 0.95);
                    font-weight: bold;
                    margin-bottom: 8px;
                }

                .list {
                    font-size: 14px;
                    border-bottom: 1px solid rgba($black, 0.2);
                    padding: 8px 0;
                    color: $primary-black;

                    &:last-child {
                        margin-bottom: 29px;
                    }

                    .tot {
                        font-size: 14px;
                        font-weight: bold;
                    }

                    .product {
                        text-decoration: none;
                        color: $primary-black;
                    }
                }
            }
        }

        .most-wanted-products {
            .title {
                color: rgba($black, 0.95);
                font-weight: bold;
                margin: 10px 0 0;
            }

            .list {
                padding: 16px 0;
                border-bottom: 1px solid rgba($black, 0.2);

                &:last-child {
                    border-bottom: unset;
                    margin-bottom: 60px;
                }

                .product-image {
                    width: 80px;
                    height: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img{
                        max-height: 100%;
                        max-width: 100%;
                    }
                }

                .content {
                    padding-left: 16px;
                    max-width: calc(100% - 80px);
                    width: 100%;
                    font-weight: bold;

                    .extra-info {
                        margin-bottom: 8px;

                        .store {
                            color: rgba($black, 0.2);
                        }

                        .badge {
                            .discount {
                                background-color: var(---colour-01-primary-01-p-2);
                                color: $white;
                                font-size: 16px;
                            }

                            .extra-point {
                                background-color: rgba($yellow, 0.2);
                                color: $primary-orange;
                                font-size: 16px;
                                white-space: nowrap;
                            }

                            .icon-fidelity-double, .icon-fidelity-orange{
                                font-size: 24px !important;
                            }
                        }

                        .component-PromoBadgeCard{
                            margin-right: 4px;
                            
                            .badge-text {
                                font-size: 16px;
                                line-height: 1;
                                padding: 8px;
                                font-weight: bold;
                                border-radius: 8px;
                                margin-right: 4px;
                            }
                        }
                    }

                    .product {
                        text-decoration: none;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        color: $primary-black;
                    }

                    .quantity {
                        color: $primary-black;
                    }
                }
            }
        }


    }

    @media (min-width: $breakpoint-large) and (max-width: $breakpoint-xlarge-max) {
        .search-dropdown {
            margin: 0 -32px;
        }
    }

    @media (min-width: $breakpoint-large-2) {
        padding-top: 16px;

        .uk-navbar-right {
            margin-right: 10px;
        }

        .search-bar {
            margin: 0 0 0 64px;

            .uk-search-input {
                min-width: 300px !important;
            }
        }

        .close-search {
            margin-left: 0;
        }

        .search-dropdown {
            margin: 0 auto;
            left: -130px !important;
            right: 0 !important;
            width: 920px !important;
            border: solid 1px rgba($gray, 0.2);
            min-height: 520px;
            height: auto;
            top: 60px;
            padding: 0;
            border-radius: 16px;
            box-shadow: unset;


            & > div:first-child{
                padding: 16px 30px 0 30px;
            }
            .dropdown-content {
                margin-top: -15px;
                .column_sx {
                    border-right: solid 1px rgba($gray, 0.2);
                    width: 50%;
                    padding: 0 40px 0 10px;

                    .suggested-products {
                        .title {
                            margin-bottom: 14px;

                            @media (min-width: $breakpoint-large-2){
                                margin-bottom: 5px;
                            }
                        }

                        .list {
                            border-bottom: unset;
                            padding: 12px 0;

                            @media (min-width: $breakpoint-large-2){
                                padding: 8px 0;
                            }

                            &:last-child {
                                margin-bottom: 36px;

                                @media (min-width: $breakpoint-large-2){
                                    margin-bottom: 16px;
                                }
                            }
                        }
                    }
                }

                .column_dx {
                    width: 50%;
                    padding: 0 10px 0 23px;

                    .most-wanted-products {
                        .title {
                            margin: 0 0 12px;
                        }

                        .list {
                            padding: 12px 0;
                            border-bottom: none;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }

        .bar-menu {
            margin-left: -12px;
        }
        .btn {
            &-cart {
                &.uk-button-tertiary {
                    margin-left: 8px;
                    line-height: 56px;
                    padding: 0 16px;
                    border-radius: 16px;
                    max-width: 214px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;

                    i {
                        font-size: 24px;
                        position: relative;
                        top: 4px;
                    }
                }
            }
        }
    }

    @media (min-width: $breakpoint-xlarge) {
        .search-bar {
            .uk-search-input {
                min-width: 400px !important;
            }
        }
    }
    @media (min-width: $breakpoint-xxlarge) {
        .search-bar {
            .uk-search-input {
                min-width: 400px !important;
            }
            &.autosuggest-visible {
                .uk-search {
                    &-input {
                        min-width: 575px !important;
                    }
                }
            }
        }
        .search-dropdown {
            left: -40px !important;
        }
    }

    @media (min-width: $breakpoint-xxxlarge) {
        .search-bar {
            &.autosuggest-visible {
                .uk-search {
                    &-input {
                        min-width: 800px !important;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }

            .uk-search {
                &-input {
                    &:focus,
                    &:not(:placeholder-shown) {
                        min-width: 800px !important;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }
        }

        .search-dropdown {
            left: 0 !important;
            .dropdown-content {
                .column_dx {
                    .most-wanted-products {
                        .list {
                            border-bottom: 1px solid rgba($black, 0.2);

                            &:last-child {
                                border-bottom: unset;
                            }
                        }
                    }
                }
            }
        }
    }

    //SEMPLIFICATO
    &-semplificato {
        @media (min-width: $breakpoint-large-2) {
            padding-bottom: 16px;
        }
    }

    .stepper-1-2 {
        height: 9px;

        .step-indicator-1 {
            background-color: $banana-yellow;
        }

        .step-indicator-2 {
            background-color: $cosmic-latte;
        }
    }

    .steps-checkout {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        padding: 16px 0 16px 0;
        width: 100%;
        margin: 0;

        .item {
            >* {
                padding: 5px 8px;
                display: inline-block;
            }

            &.active {
                >* {
                    color: $primary-black;
                    background: $pin-yellow;
                    border-radius: 70px;
                }
            }

            &:not(:first-child) {
                &:before {
                    content: "di";
                    position: relative;
                    display: inline-block;
                    color: $silver;
                    margin: 0 0 0 5px;
                    padding: 4px 8px;
                }
            }
        }

        @media (max-width: $breakpoint-large-2-max) {
            padding: 12px 0 20px 0;
        }
    }

    .menu-pagamento {
        width: 100%;
        font-size: 18px;
        justify-content: center;

        .paga-online, .paga-in-negozio{
            width: 100%;
            max-width: 172px;
            color: #666666;
            cursor: pointer;
        }

        .paga-online {
            font-weight: 700;
            
            
            #led-1 {
                height: 6px;
                background: transparent;
            }
        }

        .paga-in-negozio {
            font-weight: 700;
            
            #led-2 {
                height: 6px;
                background: transparent;
            }
        }

        .yellow-strip{
            height: 6px;
            background: transparent;
        }

        .selected{
            font-weight: 700;
            color: #333333;
            .yellow-strip {
                background: $banana-yellow;
            }
        }
    }

    .step-text-1 {
        display: block;
    }

    .step-text-1, .step-text-2{
        text-align: center;
    }

    .step-text-2 {
        display: none;
    }

    .menu-pagamento {
        display: none;
    }

    &.step-1 {
        .dist {
            width: 16px;
        }

        &.header-row {
            .component-header {
                padding: 0;
                height: 128px;

                @media (max-width: $breakpoint-large-2-max) {
                    height: 111px;
                }
            }
        }
    }

    .velina {
        background-color: rgba($black, 0.3);
        position: fixed;
        top: 0px;
        height: 0px;
        width: 0px;
        z-index: 1200;

        @media (max-width: $breakpoint-large-2-max) {
            width: 100%;
        }
    }
}

//MEGAMENU
.uk-sticky-fixed {
    .mega-submenu {
        &.uk-dropdown {
            height: calc(100vh - 136px);
        }
    }

    .header-mini-cart {
        max-height: 100vh;

        .cart-body {
            max-height: calc(100vh - 233px);
        }

        &.soglia-delivery-free-active {
            .cart-body {
                max-height: 50vh;
            }
        }
    }
}

.mega-submenu {
    display: none;
    &.uk-dropdown {
        margin-top: 0;
        padding: 37px 13px;
        background-color: $white;
        height: calc(100vh - 200px);
        box-shadow: inset 0 11px 7px -8px rgb(171 147 67 / 15%);

        &.uk-open {
            display: block;
        }
        &:before,
        &:after {
            content: '';
            position: absolute;
            display: block;
            background-color: $white;
            top: 0;
            bottom: 0;
            box-shadow: inset 0 11px 7px -8px rgb(171 147 67 / 15%);
        }

        &:before {
            left: -1000px;
            width: 1000px;
        }

        &:after {
            right: -1000px;
            width: 1000px;
        }

        .uk-dropdown-grid {
            > *:last-child {
                min-width: 224px;
                max-width: 224px;
                box-sizing: content-box;

                &:before {
                    display: none;
                }

                @media (min-width: $breakpoint-xxxlarge) {
                    min-width: 318px;
                    max-width: 318px;
                }
            }

            .megamenu-third-level {
                &:before {
                    border-color: transparent;
                }
            }
        }

        .uk-nav {
            margin-top: -5px;

            > li {
                > a {
                    @include color-primary-black();
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1;
                    margin-bottom: 5px;
                    position: relative;
                    transition: all 0.3s ease-in-out;

                    &:after {
                        font-family: 'icomoon';
                        content: '\e930';
                        position: absolute;
                        right: 10px;
                        font-size: 15px;
                        margin-top: -1px;
                        opacity: 0;
                        @include color-primary-black();
                        transition: all 0.3s ease-in-out;
                    }

                    &:hover {
                        font-weight: bold;

                        &:after {
                            opacity: 1;
                            right: 0;
                        }
                    }
                }

                &.all {
                    a {
                        text-decoration: underline;
                        color: var(---colour-03-semantic-04-inform-2);
                        &:after {
                            display: none;
                        }
                    }
                }

                &.uk-active {
                    > a {
                        color: var(---colour-01-primary-03-p-2);
                    }
                }
            }
        }

        .block-menu-item {
            margin-bottom: 24px;

            h4 {
                @include color-primary-black(0.6);
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 16px;
            }

            ul {
                margin: 0;
                padding: 0;
                border-bottom: 1px solid rgba($black, 0.1);

                li {
                    list-style: none;
                    margin: 16px 0;
                    font-size: 14px;

                    .single-partner {
                        display: flex;
                        justify-content: space-between;
                        font-weight: bold;
                        color: $primary-black;
                        text-decoration: none;
                    }

                    .all-partners {
                        font-weight: 500;
                        line-height: 1.29;
                    }
                }
            }

            .no-gradient::before {
                background: none !important;
            }
        }

        .uk-card-type-illustration {
            font-size: 12px;

            .img-container {
                width: 80px;

                img {
                    width: 100%;
                    border-radius: 12px 0 0 12px;
                }
            }
        }

        .uk-tile {
            + .uk-tile {
                margin-top: 16px;
            }
        }
    }

    @media (min-width: $breakpoint-large-2) {
        .block-card {
            margin-bottom: 0;
        }
    }

    @media (min-width: $breakpoint-large) {
    }

    img.promozioni-logo {
        max-height: 56px;
        width: auto;
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translate(-50%, 0%);
    }
 }

.overlay-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height: 56px;

    @media (min-width: $breakpoint-large-2) {
        bottom: 0;
        height: unset;
    }
}

//MENU MOBILE
.component-menuMobile {
    z-index: 2001;
    
    .uk-offcanvas-bar {
        .uk-navbar-container {
            background-color: $white;

            .uk-offcanvas-header {
                margin: auto;
                width: 100%;

                .back {
                    font-size: 24px;
                    position: absolute;
                    left: 10px;
                    @include color-primary-black(0.8);
                    cursor: pointer;
                }
            }
        }

        .scroll {
            box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);

            .category-title {
                display: block !important;
                @include color-primary-black(0.8);
                font-weight: bold;
                padding: 0 30px;
            }

            .uk-offcanvas-close {
                top: 0;
                bottom: 0;
            }
        }

        .uk-offcanvas-body {
            margin-top: 8px;

            .box-order-info {
                background-color: $cosmic-latte;
                padding: 24px;
                border-radius: 12px;
                @include color-primary-black;

                .order-receive {
                    white-space: nowrap;
                    //margin-bottom: 16px;

                    i {
                        margin-right: 8px;
                        font-size: 16px;
                    }
                }

                .manage {
                    font-weight: 500;
                    text-decoration: underline;
                }

                .delivery-address {
                    .delivery {
                        color: $primary-black;
                        margin-bottom: 8px;
                    }

                    .address {
                        @include line-clamp(2);
                    }
                }

                .uk-divider {
                    margin: 10px 0;
                }

                .availability {
                    .anim-pulseBoxShadow {
                        @include animation-pulse(3s, colour-03-semantic-04-inform-2-rgb);
                        animation-iteration-count: infinite;

                        &.warning {
                            @include animation-pulse(3s, colour-01-primary-03-p-2-rgb);
                        }
                    }

                    .uk-animation-shake {
                        animation-duration: 2s;
                        animation-delay: 500ms;
                        animation-iteration-count: infinite;
                    }

                    .warning {
                        background-color: var(---colour-03-semantic-03-warnin-2);
                    }

                    .availability-label {
                        color: $primary-black;
                        margin-bottom: 8px;
                    }

                    i{
                        &.icon-calendar {
                            margin-right: 8px;
                        }
                        &.icon-info {
                            margin-left: 8px;
                        }
                    }
                    
                }
            }

            .menu-container {
                > * {
                    animation-name: uk-fade-right-small;
                    animation-duration: 0.5s;
                    animation-timing-function: ease-out;
                    animation-fill-mode: both;

                    @for $i from 1 through 100 {
                        &:nth-child(#{$i}n) {
                            animation-delay: #{$i * 0.3}s;
                        }
                    }
                }

                .category-title {
                    font-weight: bold;
                    margin-bottom: 24px;
                }

                .category-menu {
                    margin: 24px 0;

                    .item {
                        padding: 8px 0;
                        border-bottom: 1px solid rgba($black, 0.2);
                        @include color-primary-black;
                        font-size: 14px;
                        text-decoration: none;
                        animation-name: uk-fade-right-small;
                        animation-duration: 0.5s;
                        animation-timing-function: ease-out;
                        animation-fill-mode: both;

                        @for $i from 1 through 100 {
                            &:nth-child(#{$i}n) {
                                animation-delay: #{$i * 0.1}s;
                            }
                        }

                        &-discount {
                            color: var(---colour-01-primary-01-p-2);
                        }

                        .label {
                            margin-right: 8px;
                            font-weight: 500;
                        }

                        i {
                            font-size: 24px;
                        }
                    }

                    .all-item {
                        .label {
                            text-decoration: underline;
                            color: var(---colour-03-semantic-04-inform-2);
                        }
                    }
                }
            }

            .block-menu-item {
                margin-top: 24px;

                h4 {
                    @include color-primary-black(0.6);
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 16px;
                }
                
                .uk-card-type-illustration {
                    font-size: 12px;
        
                    .img-container {
                        width: 80px;
        
                        img {
                            width: 100%;
                            border-radius: 12px 0 0 12px;
                        }
                    }
                }

                .block-title {
                    font-size: 14px;
                }

                .block-card {
                    margin-bottom: 16px;
                }

                .no-gradient::before {
                  background: none !important;
                }
            }
        }

        @media (min-width: $breakpoint-large) {
            .uk-offcanvas-close {
                top: 20px;
            }

            .back {
                left: 60px !important;
            }
        }
    }

    img.promozioni-logo {
        max-height: 56px;
        width: auto;
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translate(-50%, 0%);
    }

    .component-ShopIdm {
        .uk-container {
            padding: 0;

            .title {
                color: rgba(0, 0, 0, 0.6);
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 16px;
            }
        }
    }
}
