figure{

	&.containerZoom {
		background-position: 50% 50%;
		position: relative;
		width: 100%;
		overflow: hidden;
		cursor: zoom-in;
		margin: 0;

		img {
			transition: opacity .5s;
			display: block;
			width: 100%;
		}

		&.active {
			img {
				opacity: 0;
			}
		}
	}

}
