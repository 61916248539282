.component-ModalTimeslot {
    overflow-y: hidden; //la modale intera non scrollerà

    .type-a, .type-b{
        display: none;
    }

    &.locker-error{
        .section-timeslot{
            &.locker-error-many-units{
                display: block !important;

                > .uk-alert {
                    z-index: 1;
                    position: relative;
                    margin: 0 16px;
                    @media (min-width: $breakpoint-large) {
                        margin: 0;
                    }
                }
            }

            &.normal-timeslot{
                display: none !important;
            }
        }
    }
    
    @media (min-width: $breakpoint-large) {
        .component-AccordionTimeslotInfo {
            bottom: 40px;
        }
        &.selected-timeslot {
            .component-AccordionTimeslotInfo {
                bottom: 130px;
            }
        }
    }

    .lds-ring {
        z-index: 2999;
    }

    .ps__rail-x {
        height: 4px;
        opacity: 0.6;

        //@media (max-width: $breakpoint-small-max) {
        //    margin-left: 5%;
        //    width: 90% !important;
        //}
    }

    &.edit-mode {
        .uk-modal-dialog .to-back, .uk-modal-dialog .modal-content .section-address .to-back  {
            display: block;
            z-index: 3000;
        }
    }


    .uk-modal-close-default {
        font-size: 25px;
        padding: 0;
        top: 0;
        right: 0;
        width: 60px;
        height: 60px;
        cursor: pointer;
        color: $primary-black;
    }

    .uk-modal-dialog {
        border-radius: 0;
        box-shadow: none;

        .to-back {
            display: none;
            color: $primary-black;
            font-size: 25px;
            position: absolute;
            top: 2px;
            left: 0;
            padding: 0;
            width: 60px;
            height: 60px;
            transition: none;

            i {
                margin: 0;
            }

            @media (min-width: $breakpoint-large) {
                position: absolute;
                top: -13px;
                left: -40px;
            }
        }

        .modal-content {
            width: auto;
            max-width: 100%;
            padding-top: 0;
            padding: 56px 0 16px;
            box-sizing: border-box;

            .alert-fascia-non-disponibile{
                display: flex;
                width: 80%;
                max-width: 320px;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                z-index: 10000;
                opacity: 0;
                .icon-alert::before{
                    left: 0 !important;
                }

                &.unavailable-timeslot-selected{
                    animation-name: fade-in-opacity;
                    animation-duration: 0.5s;
                    animation-fill-mode: forwards;

                    & + .component-AccordionTimeslotInfo{
                        margin-top: 50px;
                    }
                }
            }

           #modal-timeslot-content  {
                height: 100vh;
                max-height: calc(100vh - 144px);
                overflow: auto;
                //padding-bottom: 50px;
                box-sizing: border-box;
            }

            @media (min-width: $breakpoint-large) {
                padding: 72px 0 0;
            }

            .section-address {
                margin-bottom: 16px;
                width: 100%;
                @media (min-width: $breakpoint-large) {
                    position: relative;
                    margin-bottom: 24px;
                    max-width: 100%;
                    padding: 0 180px;
                    box-sizing: border-box;
                }

                @media (min-width: $breakpoint-xlarge){
                    padding: 0 280px;
                }

                .to-back {
                    display: none;
                    color: $primary-black;
                    font-size: 25px;
                    position: absolute;
                    top: 2px;
                    left: 0;
                    padding: 0;
                    width: 60px;
                    height: 60px;
                    transition: none;

                    i {
                        margin: 0;
                    }

                    @media (min-width: $breakpoint-large) {
                        position: absolute;
                        top: -13px;
                        left: -40px;
                    }
                }
                &-complete {
                    .address {
                        background: none;
                        border: none;
                        font-size: 20px;
                        padding: 0;
                        font-weight: bold;
                        line-height: 1.2;
                        display: flex;
                        justify-content: center;

                        > span {
                            display: inline-block;
                            max-width: 70%;
                            @include line-clamp(2);

                            @media (min-width: $breakpoint-medium) {
                                max-width: 495px;
                            }
                        }

                        i {
                            font-size: 23px;
                            padding-right: 4px;
                            position: relative;
                            top: 2px;
                        }
                    }
                }
            }

            .title-section {
                text-align: center;

                .title {
                    font-size: 16px;
                    font-weight: normal;
                    margin-bottom: 16px;
                    margin-top: 0;
                    @include color-primary-black();

                    @media (min-width: $breakpoint-large) {
                        margin-bottom: 40px;
                    }
                }

                .subtitle{
                    font-size: 14px;
                }
            }

            .section-timeslot {
                padding: 0 0 20px;
                width: 100%;
                margin: 0 auto;
                height: auto;
                box-sizing: border-box;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 44px;
                    width: 16px;
                    height: 105px;
                    z-index: 1;
                    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                    background: rgb(255,255,255);
                    background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                    background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);

                    @media (min-width: $breakpoint-large) {
                        left: 16px;
                        top: 68px;
                        height: 120px;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 44px;
                    width: 16px;
                    height: 105px;
                    z-index: 1;
                    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                    background: rgb(255,255,255);
                    background: -moz-linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                    background: -webkit-linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                    background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);

                    @media (min-width: $breakpoint-large) {
                        right: 16px;
                        top: 68px;
                        height: 120px;
                    }
                }

                .js-scroll {
                    height: auto;
                    padding-left: 6px;
                }

                .list-delivery-days {
                    padding: 15px 16px 22px;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    width: fit-content;

                    li {
                        margin-top: 0;
                        padding-right: 16px;

                        &:before {
                            display: none;
                        }
                    }

                    @media (min-width: $breakpoint-medium) {
                        padding-left: 12px;
                        li {
                            padding-right: 25px;
                        }
                    }
                }

                .list-delivery-hours {
                    margin-top: 20px;
                    margin-bottom: 40px;
                    //padding: 0 16px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;

                    li {
                        margin: 0 8px 13px;

                        &:before {
                            display: none;
                        }
                    }

                    .chip-hour {
                        //width: 89px;
                        height: 34px;
                        border-radius: 16px;
                        border: solid 1px $bright-gray;
                        font-weight: 500;
                        font-size: 14px;
                        padding: 0 24px;
                        background-color: inherit;
                        cursor: pointer;
                        color: inherit;
                        transition: all .3s ease-in-out;

                        &.not-available {
                            background-color: $platinum;
                            opacity: 0.5;

                        }

                        &.selected {
                            border: solid 1px $gargoyle-gas;
                            background-color: $pin-yellow;
                            transform: scale(1.2);
                            padding: 0 32px;
                            margin: 0 16px;
                            
                        }

                        .date-continuativo{
                            font-weight: normal;
                            font-size: 12px;
                        }
                    }
                }

                @media (min-width: $breakpoint-large) {
                    padding: 0 197px;
                    margin: 0;
                    .list-delivery-hours {
                        padding-bottom: 100px;
                        margin-bottom: 0;
                    }
                }

                @media (min-width: $breakpoint-xlarge){
                    padding: 0 166px;
                    max-width: 676px;
                    margin: 0 auto 57px auto;
                    box-sizing: content-box;
                }

                &.locker-error-many-units{
                    display: none;
                }
            }

            .bottom-section {
                visibility: hidden;
                opacity: 0;
                transition: all .3s ease-in-out;
                position: -webkit-sticky;
                position: sticky;
                bottom: 0;
                left: 0;
                right: 0;
                max-height: 88px;
                width: 100vw;
                text-align: center;
                padding: 16px;
                box-sizing: border-box;
                box-shadow: -2px 6px 8px -2px rgba(171, 147, 67, 0.15), -5px 0 9px 0 rgba(171, 147, 67, 0.20);
                background-color: $white;
                margin: 0;
                z-index: 10002;
                height: 88px;

                &.btn-conferma-pdv {
                    &.active {
                        visibility: visible;
                        opacity: 1;
                    }
                }

                @media (max-width: $breakpoint-large-2-max){
                    .js-button-conferma-timeslot{
                        width: 343px;
                        max-width: calc(100% - 32px);
                        height: 100%;
                        max-height: 56px;
                    }
                }
            }
        }
    }

    &.service-type-consegna {
        .section-address {
            .address {
                .icon-shopping-bag {
                    display: none;
                }
            }
        }

        .title-section {
            .ritiro {
                display: none;
            }
        }

        .component-AccordionTimeslotInfo {
            .ritiro {
                display: none;
            }
        }
    }

    &.service-type-ritiro {
        .section-address {
            .address {
                .icon-delivery {
                    display: none;
                }
            }
        }

        .title-section {
            .consegna {
                display: none;
            }
        }

        .component-AccordionTimeslotInfo {
            .consegna {
                display: none;
            }
        }
    }

    &.selected-timeslot {
        .uk-modal-dialog {
            .modal-content {
                .bottom-section {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        //@media (max-width: $breakpoint-large-max) {
        //    .uk-modal-dialog .modal-content ... {
        //        padding-bottom: 155px;
        //    }
        //}
    }

    .ts-dinamico{
        display: none;

        &-hours{
            &-mobile, &-desktop{
                display: none;
            }
        }
    }

    &.modal-tsd{

        &.type-a{
            .type-a{
                display: block;
            }

            .ts-dinamico-hours-mobile{
                overflow: hidden;
                display: block;
    
                .container-timeslots{
                    width: 100%;
                    height: 95px;
                    position: relative;
                    overflow: hidden;
                    padding-bottom: 5px;
                    box-sizing: border-box;
                }
    
                .container-scrollbar{
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    overflow: hidden;
    
                    .active-timeslot{
                        position: relative;
                        width: var(---tsd-active-timeslot-mobile-width-type-a);
                        height: 6px;
    
                        .timeline-tsd{
                            border-radius: 8px;
                            position: absolute;
                            /* dinamico: tiene conto della width dei dot orari e della distanza tra ogni dot */
                            width: var(---tsd-timeline-width-mobile-type-a);
                            height: 6px;
                            display: flex;
                            background-color: #d9d9d9;
                            border: 1px solid #d9d9d9;
    
                            .timeslot-hour{
                                width: 6px;
                                height: 6px;
                                box-sizing: border-box;
                                z-index: 2;
                                position: relative;
                                display: flex;
                                align-items: center;
                                justify-content: center; 

                                &:first-child, &:last-child, &.median-hour{
                                    &::after{
                                        position: absolute;
                                        top: 100%;
                                        font-size: 14px;
                                    }
                                }

                                /* Content dinamico */
                                &:first-child::after{
                                    content: attr(data-formatted-hour);
                                }

                                &:last-child::after{
                                    content: attr(data-formatted-hour);
                                }

                                &.median-hour::after{
                                    content: attr(data-formatted-hour);
                                }

                                .timeslot-hour-dot{
                                    width: 6px;
                                    height: 6px;
                                    border-radius: 50%;
                                }
                            }

                            &.partial-day{
                                column-gap: var(---tsd-hour-distance-mobile-type-a);

                                .timeslot-hour{
    
                                    &.with-half-hour{

                                        &::before{
                                            content: '';
                                            width: 3px;
                                            height: 3px;
                                            box-sizing: border-box;
                                            background-color: white;
                                            border-radius: 50%;
                                            z-index: 2;
                                            position: absolute;
                                            left: -19px;
                                        }
    
                                        .timeslot-hour-dot{
                                            width: 3px;
                                            height: 3px;
                                        }
    
                                        &::before{
                                            width: 6px;
                                            height: 6px;
                                        }
                                    }

                                    &.show-half-hour{
                                        &::before{
                                            content: '';
                                            width: 3px;
                                            height: 3px;
                                            box-sizing: border-box;
                                            background-color: white;
                                            border-radius: 50%;
                                            z-index: 2;
                                            position: absolute;
                                            left: -19px;
                                        }
                                    }

                                    .timeslot-hour-dot{
                                        background-color: white;
                                    }
                                }
                            }

                            @media screen and (min-width: $breakpoint-xlarge){
                                column-gap: var(---tsd-hour-distance-desktop-type-a);
                                width: var(---tsd-timeline-width-desktop-type-a);
                            }

                            &.full-day{
                                justify-content: space-between;
                            }
                        }
    
                        .active-portion{
                            width: 100%;
                            height: 6px;
                            border: 1px solid $banana-yellow;
                            border-radius: 8px;
                            position: absolute;
                            background-color: $banana-yellow;
                            z-index: 1;
                            left: 0;
                            top: 0;
                        }
                    }
                }
            }
    
            @media screen and (min-width: $breakpoint-xlarge){
                .ts-dinamico-hours-desktop{
                    display: block;
                    margin-bottom: 50px;
                }
    
                .ts-dinamico-hours-mobile{
                    display: none;
                }
            }
    
            .list-tsd-desktop{
                display: flex;
                justify-content: center;
                width: var(---tsd-dragzone-width-desktop-type-a);
                margin-left: auto;
                margin-right: auto;
                position: relative;
                height: 74px;
            }
    
            .section-dropzone{
                position: relative;
    
                .scrollbar-dropzone{
                    position: absolute;
                    bottom: -20px;
                    width: var(---tsd-timeline-width-desktop-type-a);
                    left: 50%;
                    transform: translate(-50%, 0);
                    height: 2px;
                    border: 1px solid red;
                    border-radius: 8px;
                    height: 6px;
                    background-color: #d9d9d9;
                    border: 1px solid #d9d9d9;

                }

                .timeslot-hour-container{
                    position: absolute;
                    z-index: 1000;
                    display: flex;
                    column-gap: 30px;
                    bottom: -15px;
                    width: var(---tsd-timeline-width-desktop-type-a);
                    left: 50%;
                    transform: translate(-50%, 0);
                    height: 2px;
                    border-radius: 8px;

                    .timeslot-hour{
                        width: 6px;
                        height: 6px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;

                        &::after{
                            position: absolute;
                            top: calc(100% + 5px);
                            font-size: 14px;
                            content: attr(data-formatted-hour);
                        }

                        .timeslot-hour-dot{
                            width: 100%;
                            height: 100%;
                            background-color: white;
                            border-radius: 50%;
                        }

                        &.with-half-hour{

                            &:not(:first-child)::before{
                                content: '';
                                width: 3px;
                                height: 3px;
                                box-sizing: border-box;
                                background-color: white;
                                border-radius: 50%;
                                z-index: 2;
                                position: absolute;
                                left: -16px;
                            }

                            .timeslot-hour-dot{
                                width: 50%;
                                height: 50%;
                            }

                            &::before{
                                content: '';
                                width: 6px;
                                height: 6px;
                            }
                        }

                        &.show-half-hour{
                            &:not(:first-child)::before{
                                content: '';
                                width: 3px;
                                height: 3px;
                                box-sizing: border-box;
                                background-color: white;
                                border-radius: 50%;
                                z-index: 2;
                                position: absolute;
                                left: -16px;
                            }
                        }
                    }
                }
            }
        }

        &.type-b{
            .type-b{
                display: block;
            }

            .ts-dinamico-hours.type-b{

                list-style: none;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                row-gap: 22px;
                column-gap: 16px;

                &.visible-promotions{
                    .promo-tag{
                        display: flex;
                    }
                }

                li{
                    position: relative;

                    .promo-tag{
                        position: absolute;
                        display: none;
                        align-items: center;
                        column-gap: 2px;
                        border-radius: 4px;
                        background-color: red;
                        box-sizing: border-box;
                        max-height: 20px;
                        max-width: 100%;
                        color: white;
                        top: -13px;
                        right: -8px;
                        font-size: 12px;
                        line-height: 1;
                        padding: 2px 4px;
                        z-index: 1;

                        i{
                            font-size: 16px;
                        }

                        span{
                            font-size: 12px;
                            font-weight: bold;
                        }
                    }
                }

                .chip-hour {
                    //width: 89px;
                    height: 34px;
                    border-radius: 16px;
                    border: solid 1px $bright-gray;
                    font-weight: 500;
                    font-size: 14px;
                    padding: 0 24px;
                    background-color: inherit;
                    cursor: pointer;
                    color: inherit;
                    transition: all .3s ease-in-out;
                    position: relative;

                    &.not-available {
                        background-color: $platinum;
                        opacity: 0.5;

                    }

                    &.selected {
                        border: solid 1px $gargoyle-gas;
                        background-color: $pin-yellow;
                        transform: scale(1.2);
                        padding: 0 32px;
                        margin: 0 16px;
                        
                    }
                }
            }
        }

        .ts-standard-hours{
            display: none !important;
        }

        .ts-dinamico{
            display: block !important;
        }

        .title-orario{
            display: none;
        }

        .list-opzioni-consegna{
            display: flex;
            list-style: none;

            li:not(:first-child){
                margin-left: 8px;
            }

            li:last-child{
                padding-right: 16px;
            }

            
        }

        .tsd-opzione-consegna{
            position: relative;
            overflow: hidden;
            max-width: 100%;
            padding-left: 16px;

            .scrollbar{
                overflow-x: scroll;
            }


            .ps__thumb-x{
                background-color: rgba($black, 0.9);
            }

            &::before {
                content: "";
                position: absolute;
                left: 16px;
                bottom: 16px;
                width: 16px;
                height: 105px;
                z-index: 1;
                background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                background: rgb(255,255,255);
                background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);

                @media (min-width: $breakpoint-large) {
                    height: 120px;
                }
            }

            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 16px;
                width: 16px;
                height: 105px;
                z-index: 1;
                background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                background: rgb(255,255,255);
                background: -moz-linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                background: -webkit-linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);

                @media (min-width: $breakpoint-large) {
                    height: 120px;
                    right: -4px;
                }
            }
        }

        .title-section-tsd{
            margin-bottom: 40px;

            .title{
                margin-bottom: 0 !important;
            }

            .subtitle{
                margin: 0;
            }

            .all-day{
                display: none !important;
            }

            &.show-all-day{
                .all-day{
                    display: block !important;
                }

                :not(.all-day){
                    display: none !important;
                }

                & ~ .section-dropzone, & ~ .ts-dinamico-hours-mobile{
                    .timeslot-hour-dot{
                        display: none !important;
                    }

                    .timeslot-hour::before{
                        display: none !important;
                    }
                }
            }
        }

        .title-section-tsd, .ts-dinamico-hours-desktop, .ts-dinamico-hours-mobile, .ts-dinamico-hours.type-b{
            display: none !important;
        }

        .tsd-opzione-consegna.selected{
            & ~ .title-section-tsd{
                display: block !important;
            } 

            @media screen and (max-width: $breakpoint-large-2-max){
                & ~ .ts-dinamico-hours-mobile{
                    display: block !important;
                } 
            }

            @media screen and (min-width: $breakpoint-xlarge){
                & ~ .ts-dinamico-hours-desktop{
                    display: block !important;
                } 
            }
        }

        &.type-b{
            .tsd-opzione-consegna.selected{
                & ~ .ts-dinamico-hours.type-b{
                    display: flex !important;
                } 
            }
        }
    }

    .band-undo{
        justify-content: space-between;
        padding: 8px 16px;
        display: none;
        background-color: #fffae1;
        .text {
            font-size: 14px;
            font-weight: bold;
            line-height: 1.43;
        }

        .undo-changes {
            font-size: 14px;
            font-weight: bold;
            padding: 13px 24px;
            line-height: 1;
            max-height: 40px;
            box-sizing: border-box;
            white-space: nowrap;
        }
    }

    &.modify-order{

        .band-undo{
            display: flex;
        }
        
        .uk-modal-close-default{
            display: none;
        }
    }

    &.showing-skeleton{
        .ts-dinamico{
            display: none !important;
        }
    }
}
.device_mobile{
    &.browser-type__ios {
        &.browser_safari {
            @media screen and (orientation: portrait) {
                .component-ModalTimeslot {
                    .modal-content {
                        > #modal-timeslot-content  {
                            max-height: calc(100vh - 180px);
                        }
                    }
                }
            }
            @media screen and (orientation: portrait) and (min-width: $breakpoint-large){
                .component-AccordionTimeslotInfo .uk-accordion{
                    bottom: 30px
                }
                .component-ModalTimeslot {
                    .modal-content {
                        > #modal-timeslot-content  {
                            max-height: calc(100vh - 100px);
                        }
                        
                    }
                }
            }
            @media screen and (orientation: landscape) {
                .component-AccordionTimeslotInfo .uk-accordion {
                    bottom: 80px;
                    max-height: 150px;
                }
                .list-delivery-hours {
                    padding-bottom: 100px !important;
                }
                
                
            }
            @media screen and (orientation: landscape) and (min-width: $breakpoint-large) {
                .list-delivery-hours {
                    padding-bottom: 150px !important;
                }
            }
            .component-ModalTimeslot.selected-timeslot .component-AccordionTimeslotInfo {
                @media screen and (orientation: portrait) and (min-width: $breakpoint-large) {
                    bottom: 180px;
                }
            }
        }
        
        &.browser_chrome {
        
        }
    }
    
    &.browser-type__android {
        &.browser_chrome {
            @media screen and (orientation: landscape) {
                .component-ModalTimeslot .uk-modal-dialog .modal-content > div {
                    //max-height: calc(100vh - 180px);
                }
                .component-AccordionTimeslotInfo {
                    .uk-accordion {
                        bottom: 0;
                        max-height: 170px;
                    }
                    
                    .list-delivery-hours {
                        padding-bottom: 120px;
                    }
                    
                }
                
            }
            @media screen and (orientation: landscape) and (max-width: $breakpoint-medium-max) {
                .component-AccordionTimeslotInfo {
                    //margin-bottom: 50px;
                }
            }
            @media screen and (orientation: landscape) and (min-width: $breakpoint-medium) and (max-width: $breakpoint-large-max) {
                .component-AccordionTimeslotInfo {
                    //margin-bottom: 50px;
                }
            }
            @media screen and (orientation: landscape) and (min-width: $breakpoint-large) {
                .list-delivery-hours {
                    padding-bottom: 120px !important;
                }
                .component-AccordionTimeslotInfo {
                    margin-bottom: 50px;
                }
            }
            @media screen and (orientation: landscape) and (min-width: $breakpoint-xlarge) {
                .component-AccordionTimeslotInfo {
                    margin-bottom: 100px;
                }
            }
            @media screen and (orientation: portrait) and (min-width: $breakpoint-large) {
                .component-AccordionTimeslotInfo {
                    margin-bottom: 100px;
                }
            }
        }
    }
}

.device_mobile{
    #modal-timeslot-content  {
        height: 100dvh;
        max-height: calc(100dvh - 56px);
    }
}

@keyframes slide-draggable-right {
	0%{
    }

    100%{
        transform: translate(36px, 0);
    }
}

@keyframes slide-draggable-left {
	0%{
    }

    100%{
        transform: translate(-36px, 0);
    }
}

@keyframes skew-y-shakeing{
    0% { transform: skewY(-5deg); }
    5% { transform: skewY(5deg); }
    10% { transform: skewY(-5deg); }
    15% { transform: skewY(5deg); }
    20% { transform: skewY(0deg); }
    100% { transform: skewY(0deg); }
}

@keyframes fade-in-opacity{
    0% { opacity: 0;}
    100% { opacity: 1;}
}

.shake-timeslot{
    animation-name: skew-y-shakeing;
    animation-duration: 1s;

    ::after{
        animation-name: fade-in-opacity;
        animation-duration: 1s;
    }
}


