.component-BannerSponsor {
    padding: 24px 0;
    text-decoration: none;
    display: block;
    &:hover {
        text-decoration: none;
        * {
            text-decoration: none;
        }
        .uk-link {
            text-decoration: underline;
        }
    }

    .content-container {
        display: flex;
        flex-direction: column;

        @media (min-width: $breakpoint-xlarge) {
            flex-direction: row;
        }

        border-radius: 12px;
    }

    .logo-container {
        margin: 0 16px 10px;
        padding-top: 16px;
        width: 77px;
        height: 40px;

        .img-logo {
            max-width: 100%;
            max-height: 100%;
        }

        @media (min-width: $breakpoint-xlarge) {
            margin-left: 24px;
        }
    }

    .text-container {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;

        &.order-inverse {
            flex-direction: column-reverse;
        }

        .banner-title {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.25;
            @include color-primary-black();
            margin: 0 16px 8px;

            @media (min-width: $breakpoint-xlarge) {
                width: 500px;
                margin-left: 24px;
                font-size: 32px;
            }
        }

        .banner-description {
            font-size: 12px;
            line-height: 1.4;
            color: $primary-gray;
            margin: 0 16px 16px;

            @media (min-width: $breakpoint-xlarge) {
                margin: 0 24px;
                line-height: 1.33;
            }
        }
    }

    .img-container {
        @media (max-width: $breakpoint-large-2-max) {
            height: 178px;
        }

        @media (min-width: $breakpoint-xlarge) {
            width: 400px;
            height: auto;
        }

        .img-banner {
            width: 100%;
            height: 100%;
            max-height: 178px;
            border-radius: 0 0 12px 12px;
            object-fit: cover;
            object-position: center;

            @media (min-width: $breakpoint-xlarge) {
                border-radius: 0 12px 12px 0;
                max-height: 264px;
            }
        }
    }

    .link-banner-sponsor {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.33;
        margin-left: 24px;
        color: $blue-info;
        padding-bottom: 24px;
        margin-top: 22px;
        margin-bottom: 0;
    }
}