.page-ThankYou {
    .main-content {
        padding-top: 0;

        .section-thank-you {
            @include color-primary-black();
            flex-direction: column;
            align-items: center;

            .img-donuts {
                text-align: center;
                padding: 24px 0 0 0;
                width: 157px;
                height: 157px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .section-info-order {
                .ko-info {
                    @include color-primary-black();
                    font-weight: normal;

                    &:last-child {
                        margin-top: 50px;
                        margin-bottom: 15px;
                    }
                }

                .title {
                    padding: 0 0 9px 0;
                    margin: 0;
                    font-size: 28px;
                    line-height: 30px;

                    @media (min-width: $breakpoint-large-2) {
                        font-size: 32px;
                        line-height: 1.25;
                    }
                }

                .order-number {
                    color: $base-body-color;
                    padding: 0 0 24px 0;
                }

                .description {
                    padding: 0 0 24px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    p:last-child{
                        margin-top: 0;
                    }
                }

                .container-services {
                    max-width: 785px;
                    margin: 24px 0 32px 0;
                    padding: 32px 24px;
                    border-radius: 12px;
                    box-shadow: 0 1px 1px 0 rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
                    background-color: #fdfcf9;
                    flex-direction: column;

                    .day-hour {
                        padding: 0 0 24px 0;
                        border-bottom: 1px solid #e0ded7;
                    }

                    .address {
                        padding: 24px 0 0 0;
                    }

                    .icon-calendar,
                    .icon-pin,
                    .icon-delivery {
                        font-size: 24px;
                        margin-right: 8px;
                        position: relative;
                        top: 4px;
                    }

                    .delivery-collect-day,
                    .delivery-collect-address {
                        font-weight: bold;
                        margin: 16px 0 0 0;
                    }
                }

                @media (min-width: $breakpoint-large-2) {

                    .container-services {
                        flex-direction: row;

                        .day-hour {
                            padding: 0 24px 0 0;
                            border-right: 1px solid #e0ded7;
                            border-bottom: none;
                            min-width: 50%;
                            box-sizing: border-box;
                        }

                        .address {
                            padding: 0 0 0 24px;
                        }

                        .modify-day-address {
                            font-size: 14px;
                            margin-top: 16px;
                            font-weight: 500;
                        }

                        .delivery-collect-day {
                            //text-transform: capitalize;
                        }

                        .delivery-collect-day,
                        .delivery-collect-address {
                            margin-top: 16px;
                        }
                    }
                }

                .alert-temporization {
                    text-align: left;
                    max-width: 809px;

                    .temporization-scadenza {
                        .uk-countdown-number {
                            font-size: 12px;
                            font-weight: bold;
                        }
                    }
                }


                //BOX COUPON
                .box-coupon {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 12px;
                    background-color: $honeydew;
                    max-width: 464px;
                    padding: 24px 0 15px 0;
                    margin: 10px 0 0 0;
                    overflow: hidden;

                    .left-side {
                        z-index: 1;
                        padding: 0 0 14px 0;

                        .emicir-l {
                            width: 27px;
                            height: 54px;
                            background-color: $white;
                            border-radius: 0 27px 27px 0;
                        }
                    }

                    .right-side {
                        z-index: 1;
                        padding: 0 0 14px 0;

                        .emicir-r {
                            width: 27px;
                            height: 54px;
                            background-color: $white;
                            border-radius: 27px 0 0 27px;
                        }
                    }


                    .box-coupon-content {
                        text-align: center;
                        color: $primary-black;

                        .title {
                            margin: 0 0 16px 0;
                            font-size: 20px;
                            font-weight: bold;
                            line-height: 1.2;
                        }

                        .desc {
                            margin: 0;
                            font-size: 16px;
                            font-weight: normal;
                            line-height: 1.5;
                            padding: 0 40px 0 40px;
                        }

                        hr {
                            margin: 15px -26px;
                            border: none;
                            height: 1px;
                            background: repeating-linear-gradient(90deg, #000, #000 6px, transparent 6px, transparent 12px);
                        }

                        .images {
                            padding-left: calc((100% - 260px) / 2);
                            padding-right: calc((100% - 260px) / 2);
                            display: flex;
                            justify-content: space-between;
                        }
                    }

                    //Banner Buono Sconto - Campagna Refresh
                    &.banner-buono-sconto-campagna-refresh {
                        border-radius: 20px;
                        box-sizing: border-box;
                        width: 100%;
                        max-width: 810px;
                        margin-top: 26px;
                        padding: 16px 24px;
                        img {
                            font-size: 30px;
                            font-weight: 500;
                            line-height: 1.27;
                            margin-bottom: 16px;
                            max-width: 116px;
                            max-height: 116px;
                        }
                        .title {
                            margin-bottom: 16px;
                            padding: 0;
                            font-size: 30px;
                            font-weight: 500;
                        }
                        .desc {
                            margin-bottom: 16px;
                            padding: 0;
                            font-size: 14px;
                        }
                        .uk-button {
                            box-shadow: 0 0 0 2px inset $black;

                        }
                        @media (min-width: $breakpoint-xlarge) {
                            .title {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }

            .section-status-order {
                .section-title {
                    font-size: 16px;
                    text-align: center;
                    margin: 0 0 46px 0;

                    @media (min-width: $breakpoint-large-2) {
                        margin: 0 0 20px 0;
                    }

                    &::before {
                        border-radius: 4px;
                        content: "";
                        display: block;
                        position: relative;
                        top: 30px;
                        width: 32px;
                        height: 4px;
                        background-color: $yellow;

                        @media (min-width: $breakpoint-large-2) {
                            content: none;
                        }
                    }

                    @media (max-width: $breakpoint-xlarge-max) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:before {
                            position: absolute;
                            left: inherit;
                        }
                    }
                }

                .section-progress {
                    flex-direction: row;

                    .progress-desktop {
                        display: none;
                    }

                    .progress-mobile {
                        min-width: 60px;
                    }

                    .container-text {
                        padding-left: 24px;
                        color: var(---effects-02-darker-40-2);
                        flex-direction: column;

                        >div {
                            min-height: 145px;

                            &:first-child {
                                min-height: 160px;
                            }

                            &:last-child {
                                min-height: unset !important;
                            }
                        }
                    }

                    @media (min-width: $breakpoint-large-2) {
                        flex-direction: column;
                        align-items: center;
                        max-width: 860px;
                        margin: 0 auto;

                        >*:first-child {
                            //div images
                            width: 80.5%;
                            max-width: 949px;
                        }

                        .progress-desktop {
                            display: block;
                            width: 100%;
                        }

                        .progress-mobile {
                            display: none;
                        }

                        .container-text {
                            padding-left: 0;
                            margin-top: 10px;
                            flex-direction: row;

                            >div {
                                min-height: 154px !important;
                                padding-right: 10px
                            }

                            .text-item {
                                 text-align: center;
                                 padding-left: 10px;

                                @media (min-width: $breakpoint-large-2) {
                                    min-height: fit-content !important;
                                }

                                &:first-child {
                                    max-width: 290px;
                                    padding-left: 5px !important;
                                }

                                >* {
                                    overflow: hidden;
                                    text-overflow: ellipsis;

                                    &:first-child {
                                        margin-bottom: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .section-user {
                .container-actions-guest {
                    .container-info {
                        padding-top: 35px;
                        border-top: 1px solid #e0ded7;
                        flex-direction: column;

                        .left-text-container {
                            padding-bottom: 20px;

                            .title {
                                font-size: 20px;
                            }
                        }

                        .right-text-container {
                            font-size: 14px;

                            >* {
                                position: relative;
                                padding-left: 40px;

                                &:not(:first-child) {
                                    margin-top: 35px;
                                }
                            }

                            i {
                                padding-right: 16px;
                                font-size: 24px;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }

                        @media (min-width: $breakpoint-large-2) {
                            flex-direction: row;

                            .left-text-container {
                                padding-right: 50px;
                            }

                            .right-text-container {
                                >* {
                                    &:not(:first-child) {
                                        margin-top: 25px;
                                    }
                                }
                            }
                        }

                        @media (min-width: $breakpoint-xxlarge) {
                            >* {
                                width: 50%;
                            }
                        }

                        @media (min-width: $breakpoint-xxlarge) and (max-width: $breakpoint-xxlarge-max) {
                            .left-text-container {
                                padding-right: 20px;
                                max-width: 395px;
                            }
                        }

                        @media (min-width: $breakpoint-xxxlarge) {
                            .left-text-container {
                                padding-right: 60px;
                            }
                        }
                    }
                }

                .container-actions-logged {}

                .container-btn {
                    padding: 40px 0 30px 0;
                    justify-content: center;

                    >div {
                        @media (min-width: $breakpoint-large-2) {
                            margin-left: unset !important;
                        }
                    }

                    >* {
                        @media (max-width: $breakpoint-medium-max) {
                            display: flex;
                            flex-direction: column-reverse;
                            width: 100%;
                        }
                    }

                    a {
                        text-decoration: none;
                    }

                    .button-action {
                        text-decoration: none;
                        width: 100%;
                        font-size: 16px;
                        line-height: 56px;
                        margin-bottom: 16px;

                        @media (min-width: $breakpoint-large) {
                            margin-left: 16px;
                            width: auto;
                            padding: 0 54px;
                        }
                    }
                }
            }
        }
    }

    &GuestExpired {
        .section-thank-you-expired {
            position: relative;

            .section-title {
                font-weight: 500;
                margin-bottom: 40px;
                font-size: 32px;

                &::before {
                    bottom: -19px;
                }

                @media(min-width: $breakpoint-xlarge) {
                    font-size: 40px;
                }

            }

            .img-clessidra-mobile {
                height: 157px;
                width: 157px;
                margin: auto;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .img-clessidra-desktop {
                position: absolute;
                height: 215;
                width: 304px;
                bottom: 35px;
                right: 0;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            p {
                font-size: 16px;
                color: $primary-gray;
                margin-bottom: 32px;
                margin-top: 16px;

                @media (min-width: $breakpoint-xlarge) {
                    max-width: 542px;
                    margin-top: 32px;
                    margin-bottom: 74px;
                }
            }

            .section-buttons {
                text-align: center;
                margin-bottom: 40px;

                .button-action {
                    line-height: 56px;
                }

                @media (min-width: $breakpoint-xlarge) {
                    text-align: left;
                }
            }


        }
    }
}