.modal-checkout-deliveryarea-error {
    .btn-group {
        margin-top: 20px;
    }

    @media (min-width: $breakpoint-large) {
        .btn-group {
            margin-top: 80px;
        }
    }
}