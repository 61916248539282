.component-TilePartner{

    width: 100%;

    div {
        width: auto; 
        height: 120px;

        img {
            width: 80px;
            margin: 5px auto 15px;
        }

        span {
            position: absolute;
        }
    }
}