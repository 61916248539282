

.component-SliderTimeslotTsd{
    &.slider-timeslot{
        display: none;
        height: 80px;
        width: 100%;
        max-width: 291px;
        position: relative;
        border-radius: 8px;
        background-color: $white;
        border: 1px solid #ccc;

        &.active, &.next, &.prev{
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &.active{
            left: 0;
            right: 0;
            margin: auto;
            border-color: #ffee9b;
            background-color: #ffee9b;
        }

        &.next{
            left: 98%;

            @media screen and (min-width: $breakpoint-xsmall){
                left: 95%;
            }
        }

        &.prev{
            right: 98%;

            @media screen and (min-width: $breakpoint-xsmall){
                right: 95%;
            }
        }

        &.slide-slider-timeslot-right{
            animation-name: slide-slider-timeslot-right;
            animation-duration: 300ms;
        }

        &.slide-slider-timeslot-left{
            animation-name: slide-slider-timeslot-left;
            animation-duration: 300ms;
        }

        .slider-arrow{
            height: 44px;
            width: 44px;
            border-radius: 50%;
            position: absolute;
            background-color: $white;
            text-align: center;
            border: none;

            i{
                display: flex;
                justify-content: center;
                font-size: 24px;
                color: black;
            }

            &.left{
                top: 15px;
                left: 16px;
            }

            &.right{
                top: 15px;
                right: 16px;
            }
        }

        &::after{
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -10px;
            border-width: 10px;
            border-style: solid;
            border-color: #ffee9b transparent transparent transparent;
        }

        .timespan{
            height: 24px;
            font-size: 16px;
            line-height: 1.5;
            font-weight: 500;
        }

        .price{
            font-size: 24px;
            font-weight: bold;
            line-height: 1.5;

            .not-available{
                display: none;
                font-size: 20px;
            }

            .promo{
                display: none;
                color: red;
            }
        }

        &.not-available{

            background-color: $misty-rose;
            border-color: $misty-rose;

            .price{
                .not-available{
                    display: block;
                    font-size: 20px;
                }

                .normal{
                    display: none;
                }
                
            }

            &::after{
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -10px;
                border-width: 10px;
                border-style: solid;
                border-color: $misty-rose transparent transparent transparent;
            }
        }

        &.promo{

            &.in-promo, &.all-promo{
                .price{
                    .normal{
                        display: none;
                    }
    
                    .promo{
                        display: block;
                    }
                }
            }

            &.not-available{
                .price{
                    .normal{
                        display: none;
                    }
    
                    .promo{
                        display: none;
                    }

                    .not-available{
                        display: block;
                    }
                }
            }

            &.active{
                &.not-available{
                    &::before{
                        border-color: $misty-rose !important;
                    }
                }
            }

            &.in-promo{
                border-top-left-radius: 0;
                border-top-right-radius: 0;

                &::before{
                    content: 'In promozione';
                    position: absolute;
                    bottom: 100%;
                    left: -1px;
                    width: 100%;
                    height: 30px;
                    border: 1px solid #ccc;
                    background-color: white;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                }

                &.active{
                    &::before{
                        border-color: #ffee9b;
                    }
                }
            }

            &.out-promo{
                border-top-left-radius: 0;
                border-top-right-radius: 0;

                &::before{
                    content: 'Scorri per scoprire le promo';
                    position: absolute;
                    bottom: 100%;
                    left: -1px;
                    width: 100%;
                    height: 30px;
                    border: 1px solid #ccc;
                    background-color: white;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                }

                &.active{
                    &::before{
                        border-color: #ffee9b;
                    }
                }
            }


        }
    }

    &.drag-dropzone{
        height: 72px;
        width: var(---tsd-drag-dropzone-width);
        position: relative;
        border-radius: 8px;
        box-sizing: border-box;

        &:not(:first-child){
            margin-left: var(---tsd-margin-left-overflow);
        }

        &.draggable-dropzone--occupied{
            z-index: 10 !important;
        }

        &.draggable-dropzone--active{
            z-index: 11 !important;
        }

        &.hide-prev{
            .balloon-timeslot{
                .slider-arrow.go-prev{
                    display: none;
                }
            }
        }

        &.hide-next{
            .balloon-timeslot{
                .slider-arrow.go-next{
                    display: none;
                }
            }
        }
    }

}



.list-tsd-desktop .balloon-timeslot{
    z-index: 100;
    cursor: pointer;
    height: 72px;
    width: 230px;
    background-color: #ffee9b;
    border: 1px solid #ffee9b;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;

    .timespan{
        height: 24px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
    }

    .price{
        font-size: 24px;
        font-weight: bold;
        line-height: 1.5;

        .not-available{
            display: none;
        }

        .promo{
            display: none;
            color: red;
        }
    }

    &.not-available{

        background-color: $misty-rose;
        border-color: $misty-rose;

        .price{
            .not-available{
                display: block;
                font-size: 16px;
            }

            .normal{
                display: none;
            }
            
        }

        .triangle-cloud{
            border-color: $misty-rose transparent transparent transparent;
        }

        &::after{
            background-color: #e20000;
        }
    }

    &.promo{

        &.in-promo, &.all-promo{
            .price{
                .normal{
                    display: none;
                }

                .promo{
                    display: block;
                }
            }
        }

        &.not-available{

            .price{
                .normal{
                    display: none;
                }

                .promo{
                    display: none;
                }

                .not-available{
                    display: block;
                }
            }
        }

        &.in-promo, &.out-promo{
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            &::before{
                content: "";
                position: absolute;
                bottom: 100%;
                left: -1px;
                width: 100%;
                height: 30px;
                border: 1px solid #ffee9b;
                background-color: white;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
            }
            
            &::before{
                border-color: #ffee9b;
            }
        }

        &.not-available{
            &::before{
                border-color: $misty-rose;
            }
        }

        &.in-promo{
            &::before{
                content: 'In promozione';
            }
        }

        &.out-promo{
            &::before{
                content: 'Scorri per scoprire le promo';
            }
        }
    }

    &.slide-draggable-right{
        position: absolute;
        animation-name: slide-draggable-right;
        animation-duration: 250ms;
    }

    &.slide-draggable-left{
        position: absolute;
        animation-name: slide-draggable-left;
        animation-duration: 250ms;
    }

    &::after{
        content: '';
        position: absolute;
        width: var(---tsd-active-timeslot-desktop-width-type-a);
        height: 8px;
        border-radius: 8px;
        background-color: #fedd36;
        border: 1px solid #fedd36;
        margin-left: auto;
        margin-right: auto;
        bottom: -22px;
        left: 50%;
        transform: translate(-50%, 0);
        opacity: 0.8;
    }

    .triangle-cloud{
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: #ffee9b transparent transparent transparent;
    }

    .slider-arrow{
        height: 32px;
        width: 32px;
        border-radius: 50%;
        position: absolute;
        background-color: $white;
        text-align: center;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        i{
            font-size: 16px;
            color: black;
            height: 16px;
            width: 16px;
        }

        &.left{
            top: 20px;
            left: 16px;
        }

        &.right{
            top: 20px;
            right: 16px;
        }
    }
}

@keyframes slide-slider-timeslot-right{
    0%{

    }

    100%{
        transform: translate(-100%);
    }
}

@keyframes slide-slider-timeslot-left{
    0%{

    }

    100%{
        transform: translate(100%);
    }
}