@use "sass:math";
@import "../../scss/uikit/variables-theme.scss";
@import "../../scss/uikit/mixins";


.component-card-review {
    .review {
        display: flex;
        flex-direction: column;
        align-items: center;

        .range {
            font-size: 32px;
            @include color-primary-black;
            @media (min-width: $breakpoint-large) {
                font-size: 40px;
            }
            @media (min-width: $breakpoint-xxxlarge) {
                font-size: 48px;
            }
        }

        .stars {
            padding: 15px 0;
            @media (min-width: $breakpoint-xxxlarge) {
                padding: 5px 0;
            }
        }

        .desc {
            font-size: 18px;
            line-height: 24px;
            text-align: center;

            @media (min-width: $breakpoint-xlarge) {
                font-size: 20px;
                padding: 15px 0 40px;
                width: 50%;
            }
        }

        .author {
            font-size: 14px;
            padding-top: 32px;
            @media (min-width: $breakpoint-xlarge) {
                padding-top: 0;
            }
        }

        .swiper-pagination {
            text-align: left;
        }

        &-score {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

//parte di progress
$circle-background: white;
$circle-color: $primary-orange;
$inset-color: white;
$transition-length: 5s;

.ko-progress-circle {
    margin: 20px auto;
    width: 60px;
    height: 60px;
    background-color: $circle-background;
    border-radius: 50%;
    position: relative;
    transition: all 0.3s ease;
    outline: none;
    @media (min-width: $breakpoint-large) {
        width: 96px;
        height: 96px;
    }

    &__content {
        background: $circle-background;
        position: absolute;
        display: block;
        left: 6px;
        top: 6px;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        z-index: 1;

        @media (min-width: $breakpoint-large) {
            left: 8px;
            top: 8px;
            width: 80px;
            height: 80px;
        }
    }

    &.swiper-pagination-bullet-active {
        .ko-progress-circle__slice,
        .ko-progress-circle__fill {
            width: 60px;
            height: 60px;
            position: absolute;
            -webkit-backface-visibility: hidden;
            transition: transform $transition-length linear;
            border-radius: 50%;
            @media (min-width: $breakpoint-large) {
                width: 96px;
                height: 96px;
            }
        }

        .ko-progress-circle__slice {
            clip: rect(0px, 60px, 60px, 30px);
            @media (min-width: $breakpoint-large) {
                clip: rect(0px, 96px, 96px, 48px);
            }

            .ko-progress-circle__fill {
                clip: rect(0px, 30px, 60px, 0px);
                background-color: $circle-color;
                @media (min-width: $breakpoint-large) {
                    clip: rect(0px, 48px, 96px, 0px);
                }
            }
        }

        .ko-progress-circle__overlay {
            width: 54px;
            height: 54px;
            position: absolute;
            margin-left: math.div((60px - 54px), 2);
            margin-top: math.div((60px - 54px), 2);
            background-color: $inset-color;
            border-radius: 50%;
            @media (min-width: $breakpoint-large) {
                width: 89px;
                height: 89px;
                margin-left: math.div((96px - 89px), 2);
                margin-top: math.div((96px - 89px), 2);
            }
        }

        $i: 0;
        $increment: math.div(180deg, 100);
        @while $i <= 100 {
            &[data-progress="#{$i}"] {
                .ko-progress-circle__slice.full,
                .ko-progress-circle__fill {
                    transform: rotate($increment * $i);
                }

                .ko-progress-circle__fill.ko-progress-circle__bar {
                    transform: rotate($increment * $i * 2);
                }

                $i: $i + 1;
            }
        }
    }
}
