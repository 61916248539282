.component-BannerListing {
    &.banner-simple {

        @media (max-width: $breakpoint-xxxlarge-max) {
            .uk-container {
                padding-right: 0;
            }
        }

        @media (max-width: $breakpoint-xlarge) {
            .uk-container {
                padding-right: 16px;
            }
        }


        .banner-simple {
            display: flex;

            .text-desc {
                padding-bottom: 12px;
            }
        }
    }

    .banner-simple {
        flex-direction: column;
        padding-bottom: 16px;

        @media (min-width: $breakpoint-xlarge) {
            flex-direction: row-reverse;
            justify-content: flex-end;
            padding-bottom: 0;
            height: 456px;
        }

        .img-container {
            position: absolute;
            right: 0;
            height: 100%;
            max-height: 100%;

            @media(max-width: 599px) {
                width: calc(100% + 32px);
                position: relative;
                left: -16px;
                height: 210px;
            }

            @media(min-width: 600px){
                width: 257px;
            }

            @media(min-width: $breakpoint-xlarge){
                width: 590px;
            }

            @media(max-width: $breakpoint-large-2-max) {
                margin-left: auto;
                margin-right: auto;
            }
            @media(min-width: $breakpoint-xlarge) {
                margin-top: auto;
                margin-bottom: auto;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }

        .text {
            margin-top: 20px;

            @media(min-width: 600px){
                width: calc(100% - 257px);
            }

            @media(min-width: $breakpoint-xlarge){
                width: calc(100% - 590px);
            }

            @media (min-width: $breakpoint-xlarge) {
                margin-top: 88px;
                width: 423px;
            }

            &-title {
                color: $primary-black;
                margin: 0;
                font-size: 28px;
                font-weight: 500;
                line-height: 1.29;

                @media (min-width: $breakpoint-xlarge) {
                    font-size: 32px;
                    line-height: 1.25;
                }
            }

            &-desc {
                color: $primary-gray;
                margin-top: 8px;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.43;

                @media (min-width: $breakpoint-xlarge) {
                    margin-top: 12px;
                    max-width: 381px;
                }
            }

            &-link {
                margin-top: 8px;
                color: $blue-info;
                font-size: 12px;
                font-weight: 500;
                line-height: 1.33;

                @media (min-width: $breakpoint-xlarge) {
                    font-size: 14px;
                    line-height: 1.29;
                }
            }
        }
    }
}