.page-Recipes {
    .main-content {
        padding-top: 0;
    }

    .banner-container {
        width: 100%;
    }

    .badge-title > .component-BadgeCard {
        margin-top: 32px;

        @media (min-width: $breakpoint-large) {
            margin-top: 40px;
        }
    }

    .general-title {
        margin: 16px 0;
        font-size: 32px;
        font-weight: 500;
        line-height: 1.25;

        @media (min-width: $breakpoint-large) {
            margin-top: 8px;
        }

        @media (min-width: $breakpoint-xlarge) {
            margin-bottom: 0;
        }

        @media (min-width: $breakpoint-xxxlarge) {
            font-size: 48px;
            margin-bottom: 20px;
        }
    }

    .ric-container {
        margin-top: 16px;
        display: flex;
        flex-direction: row;

        @media (min-width: $breakpoint-large) {
            margin-top: 8px;
        }

        &-min {
            display: flex;
            flex-direction: column;
            width: 53px;
            height: 34px;
            margin-right: 10px;

            span {
                margin-top: 6px;
                align-self: stretch;
                flex-grow: 1;
                font-size: 12px;
                font-weight: 500;
                line-height: 1;
                color: $primary-black;
            }

            .icon-container {
                display: flex;
                flex-direction: row;
                justify-content: left;

                i:not(:last-child) {
                    margin-right: 4px;
                }
            }
        }
    }

    .general-desc {
        width: 100%;
        margin-top: 24px;
        font-size: 16px;
        line-height: 1.5;
        color: rgba($black, 0.6);

        @media (min-width: $breakpoint-large) {
            margin-top: 14px;
            font-size: 14px;
            line-height: 1.43;
            width: 420px;
        }

        @media (min-width: $breakpoint-xlarge) {
            font-size: 16px;
            line-height: 1.5;
            width: 431px;
        }

        @media (min-width: $breakpoint-xxxlarge) {
            margin-top: 16px;
            font-size: 14px;
            line-height: 1.43;
            width: 547px;
        }
    }

    .uk-button-primary {
        margin: 24px 0 0 0;
        width: 100%;
        height: 56px;
        font-size: 16px;

        @media (min-width: $breakpoint-large) {
            width: auto;
            float: right;
            margin: -56px 2px 0 0;
        }
    }

    .card-container-ricetta {
        margin: 27px 0 32px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @media (min-width: $breakpoint-large) {
            margin-top: 48px;
        }

        .component-ProductCard {
            width: 100%;
            padding-bottom: 16px;

            @media (min-width: $breakpoint-large) {
                width: 48.5%;
                padding-bottom: 20px;;

                &:nth-of-type(odd) {
                    padding-right: 20px;
                }

                .card-bottom {
                    margin-top: 32px;

                    button {
                        span {
                            font-weight: normal;
                        }
                    }
                }
            }

            @media (min-width: $breakpoint-xlarge) {
                width: 23.7%;
                padding-bottom: 24px;

                &:not(:nth-child(4n)) {
                    padding-right: 16px;
                }
            }

            @media (min-width: $breakpoint-xxxlarge) {
                width: 23.65%;
                padding-bottom: 24px;

                &:not(:nth-child(4n)) {
                    padding-right: 24px;
                }
            }
        }
    }
}