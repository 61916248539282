.component-OfferCard {
    
    .section-body {
        width: auto;
        display: flex;
        flex-direction: column;
        padding: 0;
        border-radius: 12px;
        box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
    }

    .img-container {
        width: 100%;
        height: 172px;
        border-radius: 12px 12px 0 0;
        background-position: center center;
        background-size: cover;

        .img-logo {
            margin: 16px;
            width: 48px;
            height: 48px;
        }
    }

    .text-offerta-container {
        padding: 16px;
        display: flex;
        flex-direction: column;

        @media (min-width: $breakpoint-xlarge) {
            padding: 23px 24px 24px 31px;
        }

        .card-offerta-title {
            font-size: 20px;
            font-weight: bold;
            line-height: 1.2;
            @include color-primary-black();
            margin: 0;
            padding-bottom: 8px;
        }
    
        .card-offerta-description {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.43;
            color: $primary-gray;
            padding-bottom: 24px;
            margin: 0;

            @media (min-width:$breakpoint-xlarge) {
                padding-bottom: 23px;
            }
        }
    
        .link-card-offerta {
            text-align: right;
            margin: auto 0 0;
            padding: 9px 0;

            a {
                font-size: 14px;
                font-weight: bold;
                border: 2px solid rgba($black, 0.8);
                padding: 9px 24px;
                border-radius: 12px;
                @include color-primary-black();
                text-decoration: none;

                @media (min-width:$breakpoint-xlarge) {
                    padding: 9px 23px;
                }
            }        
        }
    }
}