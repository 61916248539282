.page-Brands {

    .component-Breadcrumb {

        @media (min-width: $breakpoint-large) {
            margin-bottom: 27px !important;
        }

        @media (min-width: $breakpoint-xlarge) {
            margin-bottom: 41px !important;
        }
    }

    .general-title {
        margin: 28px 0 16px 3px;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.25;

        @media (min-width: $breakpoint-large) {
            margin: 0 0 0 17px;
        }

        @media (min-width: $breakpoint-xlarge) {
            margin-left: 8px;
        }

        @media (min-width: $breakpoint-xxxlarge) {
            margin-left: 0;
        }
    }

    .general-desc {
        font-size: 16px;
        line-height: 1.5;
        font-weight: normal;
        margin-bottom: 4px !important;

        @media (min-width: $breakpoint-large) {
            margin: 11px 0 0 17px !important;
            margin-bottom: 9px !important;
        }

        @media (min-width: $breakpoint-xlarge) {
            margin-left: 8px !important;
        }

        @media (min-width: $breakpoint-xxxlarge) {
            margin-left: 0 !important;
        }
    }

    .link-approfondisci {
        font-weight: 500;
        font-size: 14px;

        @media (min-width: $breakpoint-large) {
            margin-left: 18px;
        }

        @media (min-width: $breakpoint-xlarge) {
            margin-left: 10px;
        }

        @media (min-width: $breakpoint-xxxlarge) {
            margin-left: 2px;
        }
    }

    .learn-more {
        width: 90% !important;
    }
}