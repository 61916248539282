.cards-how-it-work {
    .section-title{
        margin-bottom: 42px;
        @media (min-width: $breakpoint-xlarge){
            margin-bottom: 60px;
        }

    }
    .container-cards-how-it-work-mobile{
        display: flex;
        align-items: flex-start;
    }

    .swiper-how-it-work {
        padding-bottom: 60px;


        .swiper-slide{
            display: flex;
            justify-content: center;
        }
        
        .swiper-pagination-bullet {
            margin: 0 7px;
            opacity: 1;
            width: 16px;
            height: 16px;
            position: relative;
            background: transparent;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            &:before {
                content: '';
                position: absolute;
                width: 4px;
                height: 4px;
                background-color: $black;
                border-radius: 50%;
            }
            &-active {
                &:before {
                    width: 16px;
                    height: 16px;
                    background-color: $yellow;
                }
            }
        }
    }

    @media (min-width: $breakpoint-xlarge) {
        .swiper-how-it-work {
            padding-bottom: 0;
            height: calc(90vh - 136px);

            .swiper-pagination-bullet {
                display: flex;
                margin: 20px 0;
                &:before {
                    width: 6px;
                    height: 6px;
                }
                &-active {
                    &:before {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}