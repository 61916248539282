.cards-image-top {
    .section-body {
        > * {
            flex-direction: column;
        }
    }

    .uk-grid-small{
        max-width: 100%;
    }

    .component-card-image-top:not(:last-child){
        margin-bottom: 60px;
    }

    .section-title{
        margin-bottom: 52px;
    }

    .section-description {
        box-sizing: border-box;
        margin-bottom: 40px;
    }

    .section-body {
        > * {
            align-items: center;
        }

        .uk-grid-small {
            align-items: center;
        }
    }

    @media (min-width: $breakpoint-xlarge) {
        .section-description {
            min-width: 425px;
            max-width: 425px;
        }

        .component-card-image-top{
            max-width: 318px;
        }
    }

    .swiper-offers{
        max-width: 100%;

        /* .swiper-slide{
            max-width: 318px;
        } */
    }

    .swiper-faq {
        padding: 0 0 58px;
    }

    .swiper-scrollbar {
        display: none;
    }

    .swiper-button-next {
        bottom: 1px;
        top: auto;
        margin-top: 0;
        right: 4px;
        --swiper-navigation-size: 16px;
        --swiper-theme-color: black;
    }

    .swiper-button-prev {
        bottom: 1px;
        top: auto;
        margin-top: 0;
        right: 40px;
        left: auto;
        --swiper-navigation-size: 16px;
        --swiper-theme-color: black;
    }

    @media (min-width: $breakpoint-xlarge) {
        .swiper-faq, .swiper-offers {
            padding: 80px 0 38px;
            margin-top: -95px;
        }
        .swiper-button-next, .swiper-button-prev {
            bottom: auto;
            top: 1px;
        }
        .swiper-scrollbar {
            display: block;
            width: 32%;
            left: 34%;
        }
    }
    @media (min-width: $breakpoint-xxxlarge) {
        .swiper-faq, .swiper-offers {
            padding: 95px 0 38px;
            margin-top: -85px;
        }
    }
}