.component-CardSuggest {
    //width: 146px;
    &, &:hover {
        text-decoration: none;
    }
    .product {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: auto;
        padding: 8px 16px;

        .alert-out-of-stock {
            box-sizing: border-box;
            display: flex;
            box-shadow: 0 8px 16px -4px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
            background-color: $pink-light-2;
            max-width: 100%;
            margin: 0 8px 0;
            position: absolute;
            bottom: 0;
            height: 100%;
            max-height: 150px;
            z-index: 1;
            p{
                padding-right: 0;
            }
        }

        .manage-preferite-product {
            font-size: 16px;
            padding: 0;
        }

        .product-img {
            width: 88px;
            height: 88px;
            margin: 8px 10px 16px; 
        }

        .price-container {
            display: flex;
            flex-direction: row;

            .price-full {
                @include color-primary-black();

                font-size: 16px;
                font-weight: 500;
                line-height: 1.25;
                margin: 0 4px;
            }

            .price-off {
                display: none;

                color: #e20714;
                font-size: 20px;
                line-height: 1;
                font-weight: 500;
                margin: 0 4px;
            }
        }

        .add-cart {
            position: relative;
            @include color-primary-black();
            font-size: 14px;
            font-weight: bold;
            line-height: 1;
            text-decoration: none;
            padding: 12px 24px;
            margin-top: 32px;
            height: 40px;
            width: 108px;

            .uk-badge{
                font-family: $global-font-family;
                padding: 0 3px;
                background-color: #fce2cc;
                color: var(---colour-01-primary-01-p-2) !important;
                top: -12px;
                left: 0;
                transform: translate(-50%, 0);
                position: absolute;
                z-index: 2;
            }
        }

        &-offer {
            .price-container {

                .price-full {
                    text-decoration: line-through;
                    
                }
    
                .price-off {
                    display: block;
                }
            }
        }
    }

    .feedback-overlay-pcard {
        display: none;
        opacity: 0;
        animation: 3000ms ease-in-out 0s 1 normal forwards running feedback-productcard-add-to-cart;

        &-icon {
            height: 88px;
            width: 88px;
            position: absolute;
            top: 37%;
            left: 50%;
            transform: translate(-50%, -50%);

            > img{
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
}