.component-CarouselBenessere {
    .section-benessere {

        .background-container {
            position: relative;
            background-position: center center;
            background-size: cover;

            .svg-container {
                position: absolute;
                height: 100%;
                width: 100%;

                & > * {
                    height: 100%;
                }
            }

            .main-content {
                padding: 22px 16px 13px;
                overflow: hidden;
                @media (min-width: $breakpoint-xlarge) {
                    padding: 16px 40px 24px;
                }

                @media (min-width: $breakpoint-xxxlarge) {
                    overflow: visible;
                }
            }
        }

        .title-container {
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;

            .logo-container {
                min-width: 70px;
                max-width: 90px;
                height: 48px;
                text-align: left;
                margin-right: 13px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .content-container {
                .title {
                    margin: 0 0 6px;
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 1.2;
                }
                a {
                    font-size: 16px;
                    font-weight: 500;
                    color: $blue-info;
                }
            }

            @media (min-width: $breakpoint-large) {
                margin-bottom: 16px;
                .logo-container {
                    min-width: auto;
                    max-width: 120px;
                    margin-right: 22px;
                    height: auto;
                    max-height: 70px;
                }
                .content-container {
                    .title {
                        font-size: 20px;
                        line-height: 1.4;
                        margin-bottom: 0;
                    }
                }
            }
            @media (min-width: $breakpoint-xlarge) {
                .content-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }


        .section-body {
            overflow: visible;
            clear: both;

            .swiper-benessere {
                overflow: visible;

                @media (min-width: $breakpoint-xlarge) {
                    padding: 0 0 20px 0;
                    margin-top: 0;

                    .next-benessere, .prev-benessere {
                        margin-top: -50px;
                    }
                }

                .next-benessere {
                    top: 2px;
                    left: unset;
                    right: -1px;
                    text-decoration: none;

                    &:hover {
                        color: $black;
                    }
                }

                .prev-benessere {
                    top: 1px;
                    left: unset;
                    right: 33px;
                    text-decoration: none;

                    &:hover {
                        color: $black;
                    }
                }

                .scrollbar-benessere {
                    width: 46%;
                    left: 27%;
                    height: 4px;
                    bottom: 0;
                }

                .component-ProductCard {
                    .product-card {
                        background-color: $white;
                    }
                }
            }
        }
    }
}