.component-PromoBadgeCard {

    .icon-fidelity-double, .icon-fidelity-grey {
        font-size: 24px;
    }

    .info {

        .prezzo-tagliato {
            text-decoration: line-through;
        }
    }

    .tooltiptext-drop {
        /* max-width: 210px; */
        width: auto;
        white-space: nowrap;

        .tooltiptext {
            padding: 6px 12px;
            font-size: 13px;
            line-height: 16px;
        }
    }

    &.badge-orange {
        display: flex;
        align-items: center;
        color: $primary-orange;

        .badge-text {
            background-color: rgba($yellow, 0.2);
            color: $primary-orange;
            text-decoration: none;

            .icon-delivery {
                display: none;
            }
        }
    }

    &.badge-orange-delivery {
        display: flex;
        align-items: center;
        color: $primary-orange;

        .badge-text {
            color: $primary-orange;
            text-decoration: none;
            background-color: rgba($yellow, 0.2);
        }
    }

    &.badge-red {

        display: flex;
        align-items: center;
        color: $white;

        .badge-text {
            background-color: var(---colour-01-primary-01-p-2);
            cursor: pointer;
            color: $white;
            text-decoration: none;

            .icon-delivery {
                display: none;
            }
        }

    }

}