.component-BadgeCard {

    > * {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 16px;
        border-radius: 12px;
        width: fit-content;
    }
   
    .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
    }
    .icon {
        display: flex;
        width: 16px;
        height: 16px;
        background-color: red;
        margin-right: 4px;
    }

    .badge-sponsorizzato {
        @include color-primary-black(0.8);
        background-color: #fdfcf9;
    }

    .badge-benessere {
        background-color: #f6e7ec;
        color: $wine;
    }

    .badge-territorio {
        background-color: $alert-success-background;
        color: #5eb223;
        .text {
            color: #5eb223;
        }
    }

    .badge-sostenibilita {
        background-color: #edf7fa;
        color: var(---colour-02-secondary-04-s-2);
        .text {
            color: var(---colour-02-secondary-04-s-2);
        }
    }

    .badge-popolare {
        background-color: #fef1e6;
        color: $primary-orange;
        padding: 3px 16px;

        .icon-star-full {
            margin-right: 6px;
        }
    }

    .badge-italiano {
        @include color-primary-black(0.8);
        background-color: rgba(245, 245, 245);
        padding: 3px 16px;

        .icon-flag-ita{
            margin-right: 6px;
        }
    }
}