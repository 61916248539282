@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';


.component-ModalUserMenu {
    &:before {
        background-color: transparent;
    }
    .uk-offcanvas {
        &-bar {
            overflow-y: hidden;
            -webkit-overflow-scrolling: unset;
            box-shadow: 0 8px 16px -4px rgba(51, 51, 51, 0.15), 0 0 1px 0 var(---effects-01-opacity-20-2);

            @media (min-width: $breakpoint-large) {
                min-width: 320px;
                max-width: 320px;
            }
        }

        &-title {
            font-size: 24px;
            line-height: 1.33;
            margin: 32px 1px 8px;
            font-weight: normal;

            @media (min-width:$breakpoint-xlarge) {
                margin-left: 0;
            }
        }

        &-body {
            box-sizing: border-box;
            height: calc(100dvh - 120px);
            padding: 8px 0 0;
            position: relative;

            .box-overflow{
                position: relative;
                width: 100%;
                height: 100%;
                //overflow-y: auto;
                padding: 0 0;
                box-sizing: border-box;

                .mCSB_container,
                .box-overflow-container {
                    margin: 0 16px !important;
                    padding: 0 !important;
                }
            }

            @media (min-width:$breakpoint-xlarge) {
                padding-top: 7px;
            }
        }

        &-close {
            top: 7px;
            right: 7px;
        }

       &-header {
            height: 120px;
            max-height: 120px;
            box-sizing: border-box;
            @media (min-width: $breakpoint-large) {
                padding: 16px;
            }
        }
    }
    .points-balance {
        font-size: 10px;
        display: flex;
        align-items: center;
        > .balance-previous {
            padding-right: 8px;
            margin-right: 8px;
            border-right: 1px solid $black;
        }
    }
    .uk-nav {
        padding-top: 21px;
        min-height: 70px;
        li {
            a {
                font-weight: 500;
                padding: 10px 0;
                margin: 5px 0;
                i:first-child {
                    @media (min-width:$breakpoint-xlarge) {
                        padding: 0 16px 0 0;
                    }
                }
                .icon-chevron-right {
                    font-size: 16px;
                    font-weight: bold;
                    margin-left: auto;
                }
            }
            &:first-child {
                margin-top: -10px;
                a {
                    margin-top: 0;
                }
            }

            &:last-child {
                a {
                    @media (min-width:$breakpoint-xlarge) {
                    }
                }
            }
        }
        
        > * {
            a {
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
            .elem-new {
                color: $yellow;
                background-color: $yellow-2;
                border-radius: 45px;
                padding: 4px 16px;
                font-size: 14px;
                font-weight: bold;
                margin-left: 5px;
            }

            .label {
                width: auto;
                max-width: 158px;
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                line-height: 18px;
                @media (max-width: 360px) {
                    max-width: 165px;
                }
            }
        }
    }
    .lds-ring-2 {
           margin-left: 10px;
    }
    .logout {
        [class*='icon-'] {
            &:before {
                transform: rotate(270deg);
                display: inline-block;
                position: relative;
                top: 1px;
            }
        }
    }
}
