.component-ProdottiEvidenza {
    .section-prod-evid {

        .section-title {

            font-size: 18px;
            float: left;
            line-height: 1.3;

            @media (min-width: $breakpoint-xlarge) {
                font-size: 28px;
            }

            &:before {
                bottom: -13px;

                @media (min-width: $breakpoint-xlarge) {
                    bottom: -19px;
                }
            }
        }

        .link-prod-evid {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            position: relative;
            z-index: 2;
        }

        .link-container {
            text-align: right;
            position: relative;
            right: 59px;

            @media (min-width:$breakpoint-xlarge) {
                right: 1px;
                top: 577px;
            }

            @media (min-width:$breakpoint-xxlarge) {
                top: 617px;
            }
        }


        .section-body {
            clear: both;

            @media (min-width: $breakpoint-xlarge) {
                position: relative;
                top: -31px;
            }
        }

        .next-prod-evid {
            top: 4px !important;
            right: 6px !important;
            text-decoration: none;
            &:hover {
                color: $black;
            }
        }

        .prev-prod-evid {
            top: 4px !important;
            right: 40px !important;
            text-decoration: none;
            &:hover {
                color: $black;
            }
        }

        .pagination-prod-evid {
            top: 2px !important;
            right: 45px !important;
        }

        .single-center-slide {
            margin: 0 auto !important;
        }
    }
}