.component-ScopriNovita {
    .section-scopri-novita {

        .section-title {
            font-size: 18px;
            line-height: 1.33;
            margin-bottom: 32px;

            @media (min-width: $breakpoint-xlarge) {
                font-size: 20px;
                line-height: 1.4;
            }
        }

        .section-title:before {
            bottom: -11px;

            @media (min-width: $breakpoint-xlarge) {
                bottom: -12px;
            }
        }

        .swiper-scopri-novita {
            overflow: visible;
            @media (min-width: $breakpoint-xlarge) {
                padding-top: 35px;
                overflow: hidden;
            }
        }

        .swiper-slide {
            width: 304px !important;

            .component-ProductCardLastView {

                .product {

                    .product-img {
                        @media (min-width: $breakpoint-xlarge) {
                            margin-left: 5px;
                        }
                    }
                }

                .icon-container {
                    margin-right: -4px;

                    .favorite-item {
                        color: var(---colour-01-primary-03-p-2);
                        font-size: 16px;
                    }

                    .add-cart {
                        margin-top: -2px;
                        box-shadow: 0 0 0 2px var(---colour-01-primary-03-p-2);
                        color: $black;
                    }
                }
            }
        }

        .prev-scopri-novita {
            top: -3px !important;
            right: 41px !important;
            text-decoration: none;
            &:hover {
                color: $black;
            }
        }

        .next-scopri-novita {
            top: -3px !important;
            right: 6px !important;
            text-decoration: none;
            &:hover {
                color: $black;
            }
        }

        .pagination-scopri-novita {
            top: -5px !important;
            right: 45px !important;
        }
    }
}