.component-StorytellingD {
    .section-storytelling {

        &-d {
            .section-body {
                border-radius: 12px;
                display: flex;

                @media (min-width: $breakpoint-xlarge) {
                    padding: 32px 40px;
                }

                .main-container {

                    width: 50%;
                    padding: 24px 8px 16px 24px;

                    @media (min-width: $breakpoint-xlarge) {
                        padding: 0;
                        width: 38%;
                    }

                    .section-title {
                        font-size: 12px;
                        font-weight: bold;
                        line-height: 1;
                        color: $primary-black;
                        margin: 0 0 28px;

                        @media (min-width: $breakpoint-xlarge) {
                            font-size: 24px;
                            font-weight: normal;
                            line-height: 1.33;
                            color: $primary-black;
                            margin-bottom: 44px;
                        }

                        &:before {
                            bottom: -12px;

                            @media (min-width: $breakpoint-xlarge) {
                                bottom: -21px;
                            }
                        }
                    }

                    .title {
                        margin: 0 0 8px;
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 1.2;
                        color: $primary-black;

                        @media (min-width: $breakpoint-xlarge) {
                            font-size: 40px;
                            margin-bottom: 16px;
                        }
                    }

                    .description {
                        margin: 0 0 40px;
                        font-size: 12px;
                        line-height: 1.4;
                        color: $primary-gray;
                        height: 32px;
                        overflow: hidden;

                        @media (min-width: $breakpoint-xlarge) {
                            font-size: 14px;
                            line-height: 1.43;
                            height: 63px;
                        }
                    }

                    .link-container {

                        margin: 0;
                        padding-bottom: 10px;

                        .link-storytelling-d {
                            font-size: 14px;
                            line-height: 14px;
                            font-weight: bold;
                            text-decoration: none;
                            padding: 9px 22px;
                            color: rgba($black, 0.9);
                            border: solid 2px rgba($black, 0.9);
                            border-radius: 12px;
                        }
                    }
                }

                .swiper-storytelling-d {

                    width: 50%;
                    padding: 24px 16px 16px;
                    max-width: 131px;

                    .next-storytelling-d {
                        bottom: -80px;
                        left: auto;
                    }

                    .prev-storytelling-d {
                        bottom: -80px;
                        right: auto;
                    }

                    .card{
                        position: relative;

                        .component-DotButton{
                            position: absolute;
                            top: calc(100% - 16px);
                            left: calc(50% - 16px);
                        }

                        img{
                            max-height: 100%;
                            max-width: 100%;
                        }
                    }
                }

                .hotspot-desktop {
                    width: 62%;

                    .s7zoomview {
                        background-color: unset;
                    }
                }
            }
        }
    }
}