.component-PromoBadgePotentialDiscount{
    padding: 4px 8px;
    font-size: 12px;
    line-height: 1;
    background: var(---effects-02-darker-05-2);
    text-decoration: none;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    &:hover {
        color: inherit;
    }

    .tooltiptext-drop{
        width: auto;
        white-space: nowrap;

        .tooltiptext{
            padding: 6px 12px;
            font-size: 13px;
            line-height: 16px;
        }

        .info{
            .prezzo-tagliato{
                text-decoration: line-through;
            }
        }
    }

    [class*='icon-'] {
        margin-right: 4px;
        font-size: 20px;
    }

    .price {
        color: $black;
        font-size: 12px;
        font-weight: normal;
        line-height: 1;

        &.sale {
            margin-left: 2px;
            color: var(---colour-01-primary-01-p-2);
        }
    }

    .text{
        margin-left: 2px;
        color: var(---colour-01-primary-01-p-2);
        font-size: 12px;
    }
}