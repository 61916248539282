.component-OfferteScadenzaA {
    .section-off-scad-a {

        background-color: var(---colour-02-secondary-01-s-2);

        padding: 26px 0 22px;
        @media (min-width: $breakpoint-xlarge) {
            padding: 32px 0;
        }

        .title-off-scad-a {
            font-size: 18px;
            margin: -9px 0 -1px 9px;

            @media (min-width: $breakpoint-xlarge) {
                margin: 0;
                padding-top: 18px;
                font-size: 28px;;
            }
        }

        .title-off-scad-a:before {

            bottom: -13px;
            @media (min-width: $breakpoint-xlarge) {
                bottom: -20px;
            }

        }

        .alert-mobile {
            padding: 24px 16px 16px;
            margin: 29px 0 16px;
            border-radius: 12px;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-position: center center !important;

            .end-offer {
                background-color: rgba($white, 0.9);
                color: #e83843;
                padding: 2px 16px;
                font-size: 14px;
                font-weight: bold;
                border-radius: 12px;
                width: 108px;
                margin-bottom: 15px;
            }

            .offer-description {
                font-size: 12px;
                line-height: 1.4;
                text-align: left;
                color: $white;
                margin-top: 0;
            }

            .offer-timer {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .time {
                    font-size: 28px;
                    color: $white;
                    line-height: 1.29;
                    font-weight: 500;
                    text-align: center;
                }

                .time-container {
                    padding: 0 12px 0 12px;
                    margin-top: -2px;
                }

                .time-container:last-of-type {
                    padding: 0 0 0 12px;
                }

                /* .time-container:first-of-type {
                    padding: 0 18px 0 12px;
                } */

                .label {
                    font-size: 10px;
                    line-height: 1.6;
                    color: rgba($white, 0.6);
                    text-align: center;
                    margin: 0 !important;
                    margin-top: -3px !important;
                    display: block !important;
                }

                .separator {
                    height: 28px;
                    border-right: 1px solid rgba($white, 0.6);
                    border-radius: 1px;
                    opacity: 0.2;
                    position: relative;
                    top: 2px;
                }

            }
        }

        .slide-alert {

            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 12px;
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-position: center center !important;
            position: relative;

            .end-offer {
                background-color: rgba($white, 0.9);
                color: #e83843;
                padding: 1px 16px;
                margin: 89px 42px 16px;
                font-size: 14px;
                font-weight: bold;
                border-radius: 12px;
            }

            .offer-description {
                margin: 0 18px;
                font-size: 12px;
                line-height: 1.4;
                text-align: center;
                color: $white;
            }

            .button-esplora {
                font-size: 16px;
                font-weight: bold;
                color: $white;
                padding: 14px 29px;
                border: 2px solid $white;
                border-radius: 12px;
                text-decoration: none;
                position: absolute;
                bottom: 20px;
            }

            .offer-timer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin: 48px 16px;

                .time {
                    font-size: 28px;
                    color: $white;
                    line-height: 1.29;
                    font-weight: 500;
                    text-align: center;
                }

                .time-container {
                    margin-top: -2px;
                    padding: 0 10px 0 10px;
                }

                .time-container:first-of-type {
                    padding: 0 10px 0 0;
                }

                .time-container:last-of-type {
                    padding: 0 0 0 10px;
                }

                .label {
                    font-size: 10px;
                    line-height: 1.6;
                    color: rgba($white, 0.6);
                    text-align: center;
                    margin: 0 !important;
                    margin-top: -2px !important;
                }

                .separator {
                    height: 24px;
                    border-right: 1px solid rgba($white, 0.6);
                    border-radius: 1px;
                    opacity: 0.2;
                    position: relative;
                    top: 6px;
                }

            }
        }

        .swiper-off-scad-a {
            overflow: visible;
            margin-top: 16px;
            padding-bottom: 30px;
            padding-top: 0;
            padding-left: 7px;
            margin-left: -7px;
            width: 100%;

            @media (min-width: $breakpoint-xlarge) {
                overflow: hidden;
                padding-top: 46px;
            }
        }

        .col-divider {
            .slide-alert {
                padding-bottom: 30px;
            }
            @media (min-width: $breakpoint-xlarge) {
                display: flex;
                padding-bottom: 14px;
                padding-top: 46px;
                .swiper-off-scad-a {
                    margin-top: -46px;
                    padding-bottom: 10px;
                }
                .slide-alert {
                    margin-bottom: 10px;
                }
            }
        }

        .pagination-off-scad-a {
            top: -1px !important;
            right: 45px !important;
        }

        .prev-off-scad-a {
            top: 1px !important;
            right: 40px !important;
            text-decoration: none;
            &:hover {
                color: $black;
            }
        }

        .next-off-scad-a {
            top: 1px !important;
            right: 6px !important;
            text-decoration: none;
            &:hover {
                color: $black;
            }
        }

    }

    .col-divider {
        @media (min-width: $breakpoint-xlarge) {
            .slide-alert {
                min-width: 224px;
                max-width: 224px;
                margin-right: 16px;
            }
        }
        @media (min-width: $breakpoint-xxlarge) {
            .slide-alert {
                min-width: 318px;
                max-width: 318px;
            }
        }
        @media (min-width: $breakpoint-xxxlarge) {
            .slide-alert {
                margin-right: 24px;
            }
        }
    }
}