.component-modal-faq-form {

    .uk-offcanvas-bar {
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .header-form-container {

        z-index: 1000;
        padding: 40px 0 20px 40px;
        background-color: $white;
        
    
        box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);
        

        
        .form-title {
            font-size: 24px;
            font-weight: bold;
            line-height: 1.33;
            margin: 0;
        }

        .uk-offcanvas-close {
            @media (min-width: $breakpoint-xlarge) {
                top: 24px;
                right: 24px;
            }
        }
    }
    
    
    .section-form-container {
        padding-top: 10px;
        .subtitle {
            margin-top: 0;
        }

        .uk-container {
            max-width: 500px;
            margin-left: 0;
        }

        .input-container {

            display: flex;
            flex-direction: column;

            @media (min-width: $breakpoint-xlarge) {
                flex-direction: row;

                div {
                    &:first-child {
                        margin-right: 8px;
                    }
                    
                    
                    &:last-child {
                        margin-left: 8px;
                    }
                } 
            }
        }

        .radio-container {
            padding-top: 24px;
            color: rgba(0,0,0,.8);
            font-size: 14px;

            .text {
                line-height: 1.43;
                margin-bottom: 8px;
            }

            label {
                margin: 0 12px 0 10px;
                font-weight: bold;
            }
        }

        .uk-label-float {
            position: relative;

            .uk-form-icon {
                display: none;

                box-sizing: border-box;
                text-align: center;
                line-height: 50px;

                * {
                    font-size: 15px;
                }
                ~ .uk-input {
                    padding-right: 45px !important;
                }
            }
            &.blocked {
                input {
                    border-color: $primary-disabled;
                    background-color: $white;
                }
                .uk-form-icon {
                    display: block;
                    background: transparent;
                    width: 40px;
                    @include color-primary-black();
                    * {
                        font-size: 12px;
                    }
                }
            }

            &.no-card {
                flex-direction: row;
                align-items: center;

                .text {
                    text-align: center;
                    font-size: 14px;
                    font-weight: bold;
                    @include color-primary-black(0.6);
                    line-height: 1;
                }

                .icon-info {
                    margin: 16px;
                }

                .tooltiptext-drop {
                    .info-blue {
                        padding: 6px 12px;
                    }

                    .info {
                        font-size: 13px;

                        a {
                            color: $white;
                        }
                    }
                }

                div {
                    &:first-child {
                        margin-right: unset;
                    }

                    &:nth-child(2) {
                        margin: 10px 10px 10px 0;
                    }

                    &:last-child {
                        margin-left: unset;
                    }
                }
            }
        }

        .allega-file {
            padding: 40px 0 0;

            .subtitle {
                font-size: 16px;
                font-weight: bold;
                line-height: 1.25;
                margin-bottom: 0;
                padding: 0;
            }

            .content {
                padding-top: 18px;
                border-bottom: 1px solid #e6e6e6;
                margin-top: 0;
                padding-bottom: 10px;

                > * {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    @media (min-width: $breakpoint-xlarge) {
                        align-items: center;
                    }
                }

                > *:nth-child(2n-1){
                    flex-direction: column;
                    
                    @media (min-width: $breakpoint-xlarge) {
                        flex-direction: row;
                    }
                }

                .desc {
                    font-size: 14px;
                    margin: 0;
                    @include color-primary-black(0.6);
                    .icon-info {
                        cursor: pointer;
                        position: relative;
                        top: 2px;
                        margin-left: 2px;
                    }
                }

                .subdesc {
                    text-decoration: underline;
                    font-size: 10px;
                    display: block;
                }

                .link-carica {
                    color: $primary-orange;
                    border: 2px solid $orange-hover;
                    width: 100%;
                    line-height: 1;
                    margin-top: 16px;
                    padding: 14px 14px 13px 10px;

                    @media (min-width: $breakpoint-xlarge) {
                        margin-top: 0;
                        width: auto;
                    }
                }

                .details {
                    font-size: 16px;
                    font-weight: bold;
                    @include color-primary-black();
                    display: flex;
                    flex-direction: column;
                    max-width: calc(100% - 40px);
                    .text {
                        display: flex;
                    }
                    .nome-allegato {
                        margin-left: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .label {
                        font-size: 10px;
                        font-weight: bold;
                        line-height: 2.4;
                        @include color-primary-black(0.6);
                    }
                    i {
                        position: relative;
                        font-size: 18px;
                        top: 3px;
                    }
                    .error {
                        color: $danger;
                        font-size: 13px;
                        line-height: 1.23;
                        margin-top: 5px;
                        display: block;
                        font-weight: normal;
                    }
                }
                .remove {
                    @include color-primary-black();
                    font-size: 22px;
                    padding: 5px 6px;
                    position: relative;
                    top: 14px;
                    right: -4px;

                }
            }
        }

        .check-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            padding: 42px 0;

            .text {
                color: $black;
                font-size: 12px;
                font-weight: 500;
            }
        }

        .footer-form {
            text-align: center;

            @media (min-width: $breakpoint-xlarge) {
                text-align: right;
            }
            
            .button-submit {
                width: 100%;

                @media (min-width: $breakpoint-xlarge) {
                    width: auto;
                }
            }
        }

        .allega-file {
            @include color-primary-black(0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            cursor: pointer;
            padding: 32px 24px;
            margin: 0 0 28px;
            border-radius: 12px;
            border: 1px dashed rgba(51, 51, 51, 0.2);
            text-align: center;

            @media (min-width: $breakpoint-xlarge) {
                padding: 12px 24px;
                text-align: left;
            }

            .title {
                margin: 0 0 6px;
                font-size: 16px;
                font-weight: bold;
                line-height: 1.5;
            }

            .desc {
                margin: 0 0 20px;
                font-size: 12px;
                font-weight: normal;
                line-height: 1.33;
            }

            .link-carica {
                @include color-primary-black(0.8);
                display: flex;
                justify-content: center;
                cursor: pointer;

                font-size: 14px;
                font-weight: bold;
                line-height: 1;
                text-decoration: none;

                i {
                    font-size: 16px;
                    margin-right: 4px
                }
            }

            input[type="file"] {
                display: none;
            }
        }

        .alert-allegati {
            display: none;

            &.active {
                display: flex;
            }

            .text {
                padding-right: 0 !important;
            }
        }

        .container-allegati {

            padding: 0 0 24px;

            .allegato {

                border-bottom: 1px solid #e6e6e6;
                padding: 12px 0;

                &-container {
                    @include color-primary-black(0.8);
                    display: flex;
                    align-items: center;

                    width: 100%;

                    i {
                        font-size: 16px;
                        margin-right: 8px;

                        &.icon-alert {
                            font-size: 12px;
                        }
                    }

                    .nome-allegato {
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 1.5;
                    }

                    .cestino {
                        background-color: $white;
                        border: none;
                        cursor: pointer;

                        i {
                            margin-right: 0;
                            font-size: 24px;
                        }
                    }
                }

                .error {
                    display: none;
                    width: 100%;
                    margin: 5px 0 0;
                    font-size: 13px;
                    font-weight: normal;
                    color: $danger;

                    &.visible {
                        display: block
                    }
                }

                .dz-error-message {
                    span {
                        width: 100%;
                        margin: 5px 0 0;
                        font-size: 13px;
                        font-weight: normal;
                        color: $danger;
                    }
                }
            }
        }

        .form-textarea {
            height: auto;
            margin-bottom: 24px;

            textarea {
                min-width: 100%;
                width: 100%;
                height: 56px;
                font-size: 17px;
                line-height: 17px;
                padding-top: 10px;
                transition: all 0.5s ease;

                @media (min-width: $breakpoint-large) {
                    line-height: 1;
                    padding-top: 20px;
                }

                &:placeholder-shown {
                    @include color-primary-black(0.6);
                }

                &::placeholder {
                    @include color-primary-black(0.6);
                }

                &:focus {
                    height: 188px;
                }
            }

            &::after{
                display: none;
            }

            .message-error {
                font-size: 13px;
                font-weight: normal;
                line-height: 1.23;
                padding: 0 16px 0px 16px;
                min-height: 30px;
            }
        }

        .footer-form {
            display: flex;
            flex-direction: column;

            @media (min-width: $breakpoint-xlarge) {
                justify-content: space-between;
                flex-direction: row;
            }

            .policy {
                @include color-primary-black(0.6);

                font-size: 12px;
                font-weight: normal;
                line-height: 1.33;
                margin: 0;

                .link-policy {
                    font-weight: 500;
                    color: $blue-info;
                }
            }

            .button-submit {
                align-self: end;
                max-width: 172px;
                padding: 20px 32px;
                font-size: 16px;
                font-weight: bold;
                line-height: 1;
                margin-top: 20px;

                @media (min-width: $breakpoint-xlarge) {
                    max-width: 179px;
                    margin-left: 39px;
                    margin-top: 0;
                }
            }
        }

        .dropzone {
            .dz {

                &-error-message {
                    color: $danger;
                    font-size: 13px;
                    line-height: 16px;
                }
            }
        }
    }

    .uk-textarea {
        height: 63px;
    }


}