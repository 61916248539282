
@import '../../scss/uikit/variables-theme.scss';
@import '../../scss/uikit/mixins';
.component-card-question {
}

.uk-card-type-question {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 280px;
    height: 250px;

    
    @media (min-width: $breakpoint-xxxlarge){
        width: 431px;
    }
    
    .uk-card-body {
        transition: all .3s ease-in;
    }
    .uk-card-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.5;
    }
    .desc {
        color: $primary-gray;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.43;
        max-height: 120px;
        
    }
    @media (min-width: $breakpoint-xlarge) {
        .uk-card-body {
            padding-bottom: 50px;
        }
    }

    .card-question-content{
        @include line-clamp(7);
    }

    a{
        font-size: 14px;
        margin-top: 16px;
    }
}
