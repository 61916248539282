.component-DotCard {

    pointer-events: auto;
    
    &:hover{
        display: block !important;
    }

    .dot-card {
        
        background-color: white;
        border-radius: 12px;
        width: 114px;
        height: 108px;
        padding: 16px 8px;

        .product {
            @include line-clamp(3);
            font-size: 12px;
            font-weight: 500;
            line-height: 1.33;
            @include color-primary-black();
            text-decoration: underline;
            margin: 0 0 16px;
        }

        .info {
            font-size: 12px;
            font-weight: 500;
            line-height: 1;
            color: rgba(0,0,0,0.4);
            margin: 0 0 8px;
        }

        .price {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;
            @include color-primary-black();
            margin: 0;
        }
    }

    
}